



























































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

declare const cropImageSettingFunction: any;
declare const initSettingSubmit: any;

interface Props {
  data: any;
  buttonLabel?: string;
}

interface Events {
  onChange?: Function;
}

@Component({
  inheritAttrs: false,
  components: { }
})
export default class FormUpload extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false })
  buttonLabel?: string;

  showPopup: boolean = false;

  uploadUrl: any = `${dataURL}/imgSaveToFile`;
  cropUrl: any = `${dataURL}/imgCropToFile`;
  randomId: any = ApiHelper.randomFormId();

  async created() {
    const _resetImage = this.resetImage;
    const _this = this;
    $.getScript("plugins/upload.js", function (data, textStatus, jqxhr) {
      initSettingSubmit(_this.onSettingSubmit);
      $(document).on("click", ".cropControlRemoveCroppedImage", function() {
        _resetImage();
      });
    });
  }

  async onSettingSubmit(settingSubmit: any) {
    const croppedImg: any = $(settingSubmit.croppedImg[0]);
    await this.onChangeFile(croppedImg.attr("src"));
  }

  async onChangeFile(base64: string) {
    // if (this.$props.data) {
    //   this.$props.data.preview = base64;
    //   this.$props.data.base64 = base64;
    // }
    this.$emit("change", base64, 'url');
  }

  async resetImage() {
    if (this.data) {
      this.data.delete = true;
    }
  }
}
