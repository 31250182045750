
































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "../helpers/all";

declare const $: any;
declare let dataURL: string;

@Component({
  inheritAttrs: false
})
export default class Header extends TSXComponent<void> {
  $loggedUser: any;
  $statsUpdated: any;
  loading = false;
  $customViews: any;
  defaultViews = [
    {
      path: "/dashboard",
      label: "DASHBOARD"
    },
    {
      path: "/todo",
      label: "TO DO"
    },
    {
      path: "/registration",
      label: "REGISTRATIONS"
    },
    {
      path: "/people",
      label: "PEOPLE/FAMILY"
    },
    {
      path: "/finance",
      label: "FINANCE"
    },
    {
      path: "/message",
      label: "MESSAGES"
    }
  ];

  getTotalBalance() {
    return this.$loggedUser.stats.Outstanding
      ? ApiHelper.dollarFormat(this.$loggedUser.stats.Outstanding, false, {
          useBrackets: true
        })
      : this.$loggedUser.stats.OutstandingFormatted || "";
  }

  getUnreadMessages() {
    return this.$loggedUser.stats.NewMessages || "";
  }

  refresh() {
    this.$forceUpdate();
  }

  updateProfileName(firstName: string, lastName: string) {
    this.$loggedUser.firstName = firstName;
    this.$loggedUser.lastName = lastName;
    this.$forceUpdate();
  }

  created() { 
    (async () => {
      if (this.$statsUpdated == 1) {
        await ApiHelper.updateProfileStats();
        this.$forceUpdate();
      }
    })();
  }

  mounted() {
    // disable items in case preview mode
    if (this.$loggedUser.type == 2) {
      // profile edit page
      const profileEditPage = $(".preview-mode .ProfileEditPage");
      profileEditPage
        .find(
          ".form-control.textInput, .form-select.textInput, button, #setting-upload-logo, input[type=radio]"
        )
        .prop("disabled", true);

      // financial
      const financialPage = $(".preview-mode .my-financials-page");
      financialPage.find("input[type=text]").prop("disabled", true);

      // complete app page
      // const completeAppPage = $(".preview-mode .complete-app-page");
      // completeAppPage.find("#stripeFrm input[type=text]").prop("disabled", true);
    }
  }

  get defaultRouteName() {
    const ret = "Dashboard";
    if((this.$customViews || []).length) {
      return this.$customViews[0].routeName || "";
    }

    return ret;
  }
}
