






















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { DirectiveBinding } from "vue/types/options";
import { VNode } from "vue";
import Inputmask from "inputmask";
declare const $: any;

interface Props {
  data: any;
  restrictSpecialCharacters?: boolean;
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {
  },
  directives: {
    letterOnly: {
      update(
        elem: HTMLElement,
        _: DirectiveBinding,
        vNode: VNode,
        oldVnode: VNode,
      ) {
        if (_.value) {
          const el: HTMLInputElement = elem as HTMLInputElement

          const newValue = vNode!.data!.domProps!.value
          const oldValue = oldVnode!.data!.domProps!.value

          if (/^([\w\\'\- ])*$/.test(newValue)) {
            return
          }

          var event = new Event('input', { bubbles: true })
          el.value = oldValue
          el.dispatchEvent(event)
        }
      },
    }
  }
})
export default class FormText extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false })
  restrictSpecialCharacters?: boolean;
  _uid: any;

  stripTags(value: string) {
    return ApiHelper.stripTags(value);
  }

  inputmask = "";

  created() {
    if(this.data.type && this.data.type === 'phone') {
      this.inputmask = "phonenumber";
    }
  }

  mounted() {
    // apply mask for phone
    if(this.inputmask == "phonenumber") {
      $(`.textbox_${this._uid} input[inputmask=phonenumber]`).each(function(i, obj) {
        Inputmask("(999) 999-9999", {
          autoUnmask: true
        }).mask(obj);
      });
    }
  }
}
