var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apexchart',{attrs:{"type":"bar","height":"350px","options":{
    chart: {
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: _vm.chartData.categories
    }
  },"series":_vm.chartData.series}})}
var staticRenderFns = []

export { render, staticRenderFns }