































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

declare const cropImageSettingFunction: any;
declare const initSettingSubmit: any;

interface Props {
  data: any;
  buttonLabel?: string;
}

interface Events {
  onChange?: Function;
}

@Component({
  inheritAttrs: false,
  components: { }
})
export default class FormUpload extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false })
  buttonLabel?: string;

  showPopup: boolean = false;
  uploadUrl: any = `${dataURL}/imgSaveToFile`;
  cropUrl: any = `${dataURL}/imgCropToFile`;
  randomId: any = ApiHelper.randomFormId();

  async created() {
    const _resetImage = this.resetImage;
    const _this = this;
    $.getScript("plugins/upload.js", function (data, textStatus, jqxhr) {
      initSettingSubmit(_this.onSettingSubmit);
      $(document).on("click", ".cropControlRemoveCroppedImage", function() {
        _resetImage();
      });
    });
  }

  async showPoupF(value = false) {
    this.showPopup = value;

    if( !value || !this.data.p_logo) {
      this.data.p_logo = "/img/avatar.8604c7ce.png";
    }
    $('.launch-tab.settings .cropControlReset').click();
    $('#settinguploadForm').html(` <label for="setting-upload-logo" class="setting-upload-logo noImage ellipsisText"
                             data-browseforfile="Click to browse for file"
                             data-exist="Remove current logo to upload another image..."
                             data-browse="Click to browse for file"
                      >
                        Click to browse for file...
                      </label>
                      <span class="error-view">File photo cannot be blank</span>
                      <input name="aLogoPicfile" id="setting-upload-logo" required="" type="file" value="">
                    `);
    $('#cropContainerMinimalSetting').html(`<img class="croppedImg mCS_img_loaded" src="` + this.data.p_logo + `" style="max-width: 100%">`)

  }

  async onSettingSubmit(settingSubmit: any) {
    const croppedImg: any = $(settingSubmit.croppedImg[0]);
    await this.onChangeFile(croppedImg.attr("src"));
  }

  async onChangeFile(base64: string) {
    this.$emit("change", base64, 'url', this.$props.data);
  }

  async resetImage() {
    if (this.data) {
      this.data.delete = true;
    }
  }
}
