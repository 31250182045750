





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import Loading from "../components/Common/Loading.vue";
import { ApiHelper } from "../helpers/all";
import {
  dollarFormat,
  downloadFile,
  downloadFileUrl
} from "../helpers/ApiHelper";
import NoData from "../components/Common/NoData.vue";
import ApexChart from "../components/Chart/ApexChart.vue";
import ApexLine from "../components/Chart/ApexLine.vue";
import directives from "../helpers/directives";
import moment from "moment";
import PageHeader from "@/components/PageHeader.vue";
import TransactionDetails from "@/components/TransactionDetails.vue";
import PlanDetails from "@/components/PlanDetails.vue";
import PaymentModal from "@/components/PaymentModal.vue";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    Loading,
    NoData,
    Pagination,
    ApexChart,
    ApexLine,
    TransactionDetails,
    PlanDetails,
    PaymentModal
  },
  directives
})
export default class Finance extends TSXComponent<void> {
  loading = false;
  exporting = false;
  $loggedUser: any;
  $campCartKey: any;
  $route: any;
  cartItems: any = [];
  barData: {
    isLoaded: boolean;
    data: {
      labels: any[];
      datasets: {
        label: string;
        stack: string;
        type: string;
        backgroundColor: string;
        data: any[];
      }[];
    };
  } = {
    isLoaded: false,
    data: {
      datasets: [],
      labels: []
    }
  };
  planItems: any = [];
  transactionItems: any = [];
  planTotalCnt = 0;
  outstandingBalance = 0;
  outstandingBalanceFmt = "$0.00";
  transTotalCnt = 0;
  plansTotalCnt = 0;
  unpaidRegistration: any = [];
  unpaidLoading = false;
  selectedToPay: any = [];
  paymentInfoLoading = false;
  paymentInfo: any = {};
  paymentType = "";
  paying = false;

  stripeToken = "";
  stripeLast4 = "";

  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  zipcode = "";

  form = {
    cardName: {
      value: "",
      error: ""
    },
    cardNumber: {
      value: "",
      error: ""
    },
    expMoth: {
      value: "",
      error: ""
    },
    expyear: {
      value: "",
      error: ""
    },
    cvc: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    }
  };

  stripeError = 0;
  paymentErrMessage = "";
  cardInfoError = "";
  filters: any = {
    profileId: 0
  };
  totalCheckout = 0;
  activeTab = "transactions";
  campStoreTabName = "";
  financial = {
    totalTransactions: 0,
    totalBalances: 0,
    paymentPlan: {
      status: "",
      chargePerMonth: 0,
      chargePerMonthFormatted: ""
    },
    fundingAmount: 0,
    fundingAmountFormatted: "",
    totalPriorBalace: 0,
    totalPriorBalaceFormatted: ""
  };
  fundBuckets: any = [];
  balances: any = [];
  singleSubscription = 0;
  viewTransactionId = "";
  viewTransactionIds = "";
  viewPlanId='';
  discountCode = "";
  applyingDiscount = false;
  availableDiscounts: any = [];
  discountsHasCode: any = [];
  appliedDiscount: any = [];
  paymentModal: any = {
    visible: false,
    selectedToPay: {}
  };
  preAppliedDiscounts: any = [];
  balanceCredit = 0;
  balanceCreditFormatted = "";

  async created() {
    // load stripe
    $.getScript("https://js.stripe.com/v2/", function(
      data,
      textStatus,
      jqxhr
    ) {}).fail(function(jqxhr, settings, exception) {
      // console.log("Stripe load failes");
    });

    if (this.$route.query.profileId != undefined) {
      this.filters.profileId = parseInt(this.$route.query.profileId) || 0;
    }

    // load cart was saved before
    // this.loadSavedCart();
    this.cartItems = await ApiHelper.loadSavedCart();
    await this.fetchData();

    // pay for a profile
    const payForProfile =
      typeof this.$route.query.payForProfile != "undefined"
        ? this.$route.query.payForProfile
        : 0;
    const action =
      typeof this.$route.query.action != "undefined"
        ? this.$route.query.action
        : "";
    if (payForProfile > 0) {
      await this.toggleSlideUp();
      const relatedRegistrations = this.unpaidRegistration.filter(
        (item: any) => {
          if ((item.sourceType || 1) == 2) {
            return false;
          }

          let ret = false;
          if ((item.profileid || 0) == payForProfile) {
            ret = true;
          } else if ((item.participants || []).length) {
            const inThisPlan = item.participants.find(
              (p: any) => (p.profileid || 0) == payForProfile
            );
            if (inThisPlan) {
              ret = true;
            }
          }
          return ret;
        }
      );
      let relatedIDs: any = [];
      for (const item of relatedRegistrations) {
        relatedIDs.push(this.getRegistrationID(item));
      }
      if (relatedIDs.length) {
        this.selectedToPay = [];
        for (const id of relatedIDs) {
          this.selectedToPay.push($(`#${id}`).data("value"));
        }
        this.getPaymentInfo();
      }
    } else if (action === "new") {
      await this.toggleSlideUp();

      const payForParticipant = this.$route.query.payForParticipant || 0;
      if(parseInt(payForParticipant) > 0 && $(`#p_${payForParticipant}`).length) {
        // auto select participant
        $(`#p_${payForParticipant}`).click();
      }
    }
  }

  mounted() {
    const $this = this;
    $(window).on("scroll", () => {
      const scrollTop = $(window).scrollTop();
      if (scrollTop >= 200) {
        $(".finance-page").addClass("sticky-items");
      } else {
        $(".finance-page").removeClass("sticky-items");
      }
    });
  }

  async loadChartData() {
    const labels: string[] = [];
    const data: any[] = [];
    for (let i = 1; i < 32; i++) {
      labels.push("1/" + i);
      data.push(ApiHelper.getRandomNumber(1000));
    }
    const datasets: any = [];
    datasets.push({
      label: "Data",
      data: data
    });
    this.barData.data.datasets = datasets;
    this.barData.data.labels = labels;
    this.barData.isLoaded = true;
  }

  async fetchData() {
    const loggedUser = ApiHelper.getLoggedUser();
    // this.outstandingBalanceFmt = loggedUser.stats.OutstandingFormatted;
    try {
      this.loading = true;
      // Finance dashboard
      const result = await axios.post(`${dataURL}/finance`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: loggedUser.id,
        skip: 0,
        take: 5,
        filters: this.filters
      });
      if (result.data.status == 1) {
        this.barData.isLoaded = false;
        const data = result.data.data;

        const last12months = data.chartData || [];
        this.barData.data.labels = last12months.map((value: any) => {
          return value.label;
        });
        this.barData.data.datasets.push({
          label: "New Charges",
          backgroundColor: "#000000",
          stack: "New Charges",
          type: "bar",
          data: last12months.map((value: any) => {
            return value.newChargeTotal;
          })
        });
        this.barData.data.datasets.push({
          label: "Payments",
          backgroundColor: "#5e886d",
          stack: "Payments",
          type: "bar",
          data: last12months.map((value: any) => {
            return value.paymentTotal;
          })
        });
        this.barData.data.datasets.push({
          label: "Balance",
          backgroundColor: "#000000",
          stack: "Balance",
          type: "line",
          data: last12months.map((value: any) => {
            return value.totalOwed;
          })
        });

        this.barData.isLoaded = true;

        this.planItems = data.paymentPlans;
        this.transactionItems = data.transactionLogs;

        this.transactionItems.forEach((i, index) => {
          i.transactionTSFormatted = ApiHelper.formatDate(
            i.transactionTS,
            "MM/DD/YYYY",
            ""
          );
          i.transactionTSFormatted_mobile = ApiHelper.formatDate(
            i.transactionTS,
            "M/DD/YY",
            ""
          );
        });
        // this.transTotalCnt = data.paging.length;
        const totalCount = data.paging.totalCount || 0;
        this.transTotalCnt = totalCount;
        this.plansTotalCnt = data.paymentPlansPaging.totalCount || 0;
        this.outstandingBalance = data.totalBalance;
        this.outstandingBalanceFmt = data.totalBalanceFormatted;
        this.financial.totalPriorBalace = data.priorBalance || 0;
        this.financial.totalPriorBalaceFormatted =
          data.priorBalanceFormatted || "";
        this.campStoreTabName = data.campStoreTabName || "Funds/Cash";
        this.fundBuckets = data.fundBuckets || [];
        this.financial.fundingAmount = data.fundingAmount || 0;
        this.financial.fundingAmountFormatted =
          data.fundingAmountFormatted || "";
        this.balances = data.balances || [];
        this.financial.totalBalances = this.balances.length;
        this.financial.totalTransactions = totalCount;
        this.balanceCredit = data.balanceCredit || 0;
        this.balanceCreditFormatted = this.balanceCredit > 0 ? data.balanceCreditFormatted : "";

        if ((data.paymentPlanInfo?.chargePerMonth || 0) > 0) {
          this.financial.paymentPlan = {
            status: "active",
            chargePerMonth: data.paymentPlanInfo?.chargePerMonth || 0,
            chargePerMonthFormatted: `${data.paymentPlanInfo?.chargePerMonthFormatted}/mo`
          };
        } else {
          this.financial.paymentPlan = {
            status: "",
            chargePerMonth: 0,
            chargePerMonthFormatted: `${dollarFormat(0)}/mo`
          };
        }
        this.singleSubscription = data.singleSubscription || 0;
      }
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async toggleSlideUp() {
    $("input[name=selectAll]").prop("checked", false);
    this.cardInfoError = "";
    const slideup = $(".finance-checkout-slideup");
    if (slideup.hasClass("invisible")) {
      this.appliedDiscount = [];
      /* this.loading = true; */
      await this.getUnpaidRegistration();
      /* this.loading = false; */
      var fullHeight = $(".menu-slide.select").outerHeight();
      var viewHieght = $("body").outerHeight();
      var viewWidth = $("body").width();

      $(".slide_container").height(fullHeight);
      if (viewWidth > 767) {
        slideup
          .css("height", 0)
          .removeClass("invisible")
          .animate({ height: fullHeight + 200 }, 50, "linear");
      } else {
        slideup
          .css("height", 0)
          .removeClass("invisible")
          .animate({ height: viewHieght }, 50, "linear")
          .promise()
          .done(function() {
            $(".slide_container").height(viewHieght - 140);
          });
      }
      $("body").addClass("no-scroll");
    } else {
      slideup.animate({ height: 0 }, 50, "linear", function() {
        $(".slide_container").removeAttr("style");
        slideup.removeAttr("style");
        slideup.addClass("invisible");
        slideup.find(".slide_wrapper").css({ left: "0px" });
      });
      $("body").removeClass("no-scroll");
    }
  }

  backToPlanSelection() {
    $(".card-info-error").addClass("d-none");
    const btn = $(".checkout_btn.slideDiv");
    var fullHeight = $(".menu-slide.select").outerHeight();
    $(".slide_container").height(fullHeight);
    $(".finance-checkout-slideup").animate(
      { height: fullHeight + 200 },
      50,
      "linear"
    );
    $(".slide_wrapper").animate({ left: 0 }, 500, "linear", function() {
      btn.html("Checkout");
    });
    this.paymentType = "";
  }

  async checkoutBtnClick() {
    $(".card-info-error").addClass("d-none");
    // stop and alert if have fund amount = 0
    const selectedToPay = this.selectedToPay.filter(
      (item: any) =>
        item.sourceType == 2 &&
        (item.cost <= 0 || item.cost == "" || isNaN(item.cost))
    );
    if (selectedToPay.length) {
      return;
    }

    const btn = $(".checkout_btn.slideDiv");
    const slideup = $(".finance-checkout-slideup");
    const $this = this;

    if (btn.html().toLowerCase() == "close") {
      slideup.animate({ height: 0 }, 100, "linear", function() {
        $(".slide_container").removeAttr("style");
        slideup.removeAttr("style");
        slideup.addClass("invisible");

        $this.backToPlanSelection();
        $this.selectedToPay = [];
      });
      $("body").removeClass("no-scroll");
    } else {
      if (
        btn.html().toLowerCase() == "pay now" ||
        btn.html().toLowerCase() == "submit"
      ) {
        // pay now
        await this.payNow();
      } else {
        this.form.cardName.error = "";
        this.form.cardNumber.error = "";
        this.form.expMoth.error = "";
        this.form.expyear.error = "";
        this.form.cvc.error = "";
        this.form.zipcode.error = "";
        this.form.cardName.value = "";
        this.form.cardNumber.value = "";
        this.form.expMoth.value = "";
        this.form.expyear.value = "";
        this.form.cvc.value = "";
        this.form.zipcode.value = "";
        this.paymentErrMessage = "";
        var fullHeight = $(".payment .table_container").outerHeight() + 95;
        if (this.applyFullDiscount()) {
          btn.html("Submit");
        } else {
          btn.html("Pay Now");
        }
        slideup.animate({ height: fullHeight + 200 }, 50, "linear");
        setTimeout(function() {
          $(".slide_container").animate({ height: fullHeight }, 50);
        }, 51);
        setTimeout(function() {
          $(".slide_wrapper").animate({ left: "-100%" }, 700, "linear");
        }, 102);
      }
    }
  }

  async getUnpaidRegistration() {
    this.unpaidLoading = true;
    this.unpaidRegistration = [];
    this.selectedToPay = [];
    const response = await axios.post(`${dataURL}/registrations`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: this.$loggedUser.id,
      type: "notFullPaid",
      getAll: true,
      orderBy: 1,
      orderDirection: 2,
      getInfo: "availableDiscounts"
    });
    if (response.data.status == 1) {
      const data: any = response.data.data;
      this.unpaidRegistration = data.registrations.notFullPaid.data;
      this.discountsHasCode = data.discountsHasCode || [];
      const availableDiscounts = data.availableDiscounts || [];

      // set participants if has same plan
      if (this.unpaidRegistration.length) {
        const unpaidRegistrationNew: any = [];
        const planIDs: any = [];
        for (const item of this.unpaidRegistration) {
          item.discountItems = [];
          let finalCost = item.balance;
          if (
            (item.sourceType || 1) == 1 &&
            (item.pRegistrationStep || 0) == 1
          ) {
            finalCost = item.participantCost;
            const discount = availableDiscounts.find(
              discount =>
                discount.eventId == item.eventID &&
                discount.participant_typeId == item.participant_typeID
            );
            let discountAmount = discount?.discountAmount || 0;
            if (discount && item.participantCost - discountAmount >= 0) {
              // auto discount
              finalCost -= discountAmount;
              finalCost = parseFloat(finalCost.toFixed(2));
              item.discountItems.push({
                discountId: discount.discountId,
                discountName: discount.discountName,
                discountAmount: discount.discountAmount || 0,
                discountAmountFormatted: dollarFormat(
                  discount.discountAmount || 0
                )
              });
            } else {
              discountAmount = 0;
            }
            item.discountAmount = discountAmount;
            item.discountAmountFormatted = ApiHelper.dollarFormat(
              item.discountAmount
            );
            item.discount = discount;
            item.finalCost = finalCost;
            item.finalCostFormatted = dollarFormat(finalCost);
          }

          // if using scholarship as a discount before
          if (
            (item.sourceType || 1) == 1 &&
            (item.pRegistrationStep || 0) == 1 &&
            (item.paidAmount || 0) == 0 &&
            (item.participant_discountCost || 0) > 0
          ) {
            let pPaymentJson = JSON.parse(item.participant_paymentJson || "{}");
            for (const d of pPaymentJson.discountInfo || []) {
              item.discountItems.push({
                eventId: d.eventId,
                participant_typeId: d.participant_typeId,
                discountId: d.discountId,
                discountCode: d.discountCode || "",
                discountName: d.discountName || d.discountCode,
                discountAmount: d.discountAmount || 0,
                discountAmountFormatted: dollarFormat(d.discountAmount || 0)
              });
            }
          }

          const tempItem = {
            ...item,
            participants: []
          };
          if (item.planId && !planIDs.includes(item.planId)) {
            planIDs.push(item.planId);
            tempItem.participants = this.unpaidRegistration.filter(
              (p: any) => p.planId == item.planId
            );
            tempItem.totalBalance = 0;
            for (const p of tempItem.participants) {
              p.balance = parseFloat(p.balance).toFixed(2);
              tempItem.totalBalance += parseFloat(p.balance) || 0;
            }
            tempItem.totalBalance = parseFloat(
              tempItem.totalBalance.toFixed(2)
            );
            unpaidRegistrationNew.push(tempItem);
          } else if (!item.planId) {
            unpaidRegistrationNew.push(tempItem);
          }
        }
        this.unpaidRegistration = unpaidRegistrationNew;
      }
    }

    this.unpaidLoading = false;
  }

  async getPaymentInfo(selectedItems: any = []) {
    this.paymentInfo = {};
    this.paymentType = "";
    this.totalCheckout = 0;

    $(".has-additional-costs.show").click();

    let selectedToPay = this.selectedToPay;
    if (selectedItems.length > 0) {
      selectedToPay = selectedItems;
    }

    if (selectedToPay.length > 0) {
      try {
        let totalCost = 0;
        for (const item of selectedToPay) {
          if ((item.sourceType || 1) == 1) {
            // total of registration cost
            totalCost +=
              item.registrationStep > 1 ? item.cost || 0 : item.participantCost;
          }
        }
        totalCost = parseFloat(totalCost.toFixed(2));

        let totalFund = 0;
        if (totalCost == 0) {
          // check if selected a fund?
          for (const item of selectedToPay) {
            if (
              (item.sourceType || 1) == 1 &&
              [0, 1].includes(item.registrationStep)
            ) {
              totalFund += parseFloat(item.totalFunds || 0);
              for (const fund of item.additionalCosts || []) {
                totalFund += fund.cost;
              }
            } else if ((item.sourceType || 1) == 2) {
              totalFund += isNaN(parseFloat(item.cost))
                ? 0
                : parseFloat(item.cost);
            }
          }
          totalFund = parseFloat(totalFund.toFixed(2));
        }

        // if (totalCost == 0) {
        //   // get funding amount
        //   for (const item of this.selectedToPay) {
        //     if ((item.sourceType || 1) == 2) {
        //       totalCost += isNaN(parseFloat(item.cost))
        //         ? 0
        //         : parseFloat(item.cost);
        //     }
        //   }
        // }
        if (totalCost > 0 || totalCost + totalFund > 0) {
          this.totalCheckout = totalCost + totalFund;
          // collect event ID
          const eventIDs = selectedToPay
            .map((item: any) => {
              if (item.sourceType == 2) return 0;

              let tempIDs = item.eventID || 0;
              if (item.participants && item.participants.length) {
                tempIDs = item.participants
                  .map((p: any) => p.eventID)
                  .join(",");
              }
              return tempIDs;
            })
            .join(",")
            .split(",");

          let participants = [];
          // const registrations = eventIDs.filter(id => id > 0);
          // if (registrations.length) {
          // send participants info if selected items has at least a registration
          participants = selectedToPay.map((item: any) => {
            let total = 0;
            let additionalCosts: any = [];
            if (item.sourceType == 2) {
              // fund bucket
              total = parseFloat(item.cost);
              additionalCosts = [];
            } else {
              // normal registrations
              total =
                item.registrationStep > 1 ? item.cost : item.participantCost;
              additionalCosts =
                item.registrationStep > 1
                  ? []
                  : item.additionalCosts.map((item: any) => ({
                      serviceName: item.serviceName,
                      cost: item.cost,
                      chargeMonthly: item.chargeMonthly || 0
                    }));

              // add total funds
              if ((item.totalFunds || 0) > 0) {
                additionalCosts.push({
                  serviceName: "Total Camp Store Funds",
                  cost: item.totalFunds,
                  isTotalFunds: true
                });
              }
            }

            return {
              sourceType: item.sourceType,
              eventID: item.eventID,
              pTypeID: item.participant_typeid || 0,
              total,
              additionalCosts
            };
          });
          // }

          this.paymentInfoLoading = true;
          const response = await axios.post(`${dataURL}/getPaymentInfo`, {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            familyID: this.$loggedUser.familyId,
            eventIDs: eventIDs.join(","),
            total: totalCost,
            hasDeposit: false,
            participants
          });
          if (response.data.status == 1) {
            this.paymentInfo = response.data.data.paymentInfo || {};
            const balanceCredit = response.data.data.balanceCredit || 0;
            if(balanceCredit > 0) {
              this.balanceCredit = balanceCredit;
              this.balanceCreditFormatted = dollarFormat(balanceCredit);
            }

            // no allow pay monthly more if in selected to pay had a partial before
            const partialItem = selectedToPay.find(
              (item: any) =>
                (item.registrationStep || 0) == 3 ||
                (item.registrationStep || 0) == 4
            );
            if (partialItem || (this.paymentInfo.recurringAmount || 0) == 0) {
              this.paymentType = "full";
              this.paymentInfo.recurringAmount = 0;
              this.paymentInfo.recurringAmountFormatted = "$0.00";
              this.paymentInfo.chargeMonths = 0;
              this.paymentInfo.chargeDays = 0;
              this.paymentInfo.deposit = 0;
              this.paymentInfo.depositFormatted = "$0.00";
              this.paymentInfo.firstChargeDate = "";
              this.paymentInfo.lastChargeDate = "";
            }

            // set stripe public key
            if ((this.paymentInfo.stripePublishableKey || "") != "") {
              if (typeof Stripe == "undefined") {
                // load stripe
                await $.getScript("https://js.stripe.com/v2/", function(
                  data,
                  textStatus,
                  jqxhr
                ) {}).fail(function(jqxhr, settings, exception) {
                  // console.log("Stripe load failes");
                });
              }
              Stripe.setPublishableKey(this.paymentInfo.stripePublishableKey);
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.paymentInfoLoading = false;
      }
    }
  }

  selectAllCheckbox() {
    const registrations = this.unpaidRegistration.filter(
      (item: any) => item.sourceType == 1
    );
    if (registrations.length) {
      const self = this;
      this.selectedToPay = [];
      if ($("input[name=selectAll]").prop("checked") == true) {
        // checked
        $(".table_container input[type=checkbox].registration").each(function(
          i,
          inputObj
        ) {
          self.selectedToPay.push($(inputObj).data("value"));
        });
      }
      this.getPaymentInfo();
    }
  }

  validateCardName() {
    //
  }

  disablePaymentForm() {
    $(
      "#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button"
    ).prop("disabled", true);
  }

  enablePaymentForm() {
    $(
      "#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button"
    ).prop("disabled", false);
  }

  async payNow() {
    if (this.paying) {
      return;
    }

    if (this.applyFullDiscount()) {
      await this.doPay();
      return;
    }

    this.form.cardName.error = "";
    this.form.cardNumber.error = "";
    this.form.expMoth.error = "";
    this.form.expyear.error = "";
    this.form.cvc.error = "";
    this.form.zipcode.error = "";
    this.paymentErrMessage = "";
    let hasError = false;
    if (this.form.cardName.value === "") {
      this.form.cardName.error = "Name is required";
      hasError = true;
    }
    if (this.form.cardNumber.value === "") {
      this.form.cardNumber.error = "Card Number is required";
      hasError = true;
    }
    if (this.form.expMoth.value === "") {
      this.form.expMoth.error = "Exp Month is required";
      hasError = true;
    }
    if (this.form.expyear.value === "") {
      this.form.expyear.error = "Exp Year is required";
      hasError = true;
    }
    if (this.form.cvc.value === "") {
      this.form.cvc.error = "CVC is required";
      hasError = true;
    }
    if (this.form.zipcode.value !== "" && this.form.zipcode.value.length < 5) {
      this.form.zipcode.error = "Zipcode is invalid";
      hasError = true;
    }

    // validate
    this.paymentErrMessage = "";
    if (this.paymentType == "") {
      this.paymentErrMessage = "Please select a payment option";
    }

    if (
      this.paymentType != "" &&
      this.selectedToPay.length &&
      (this.paymentInfo.totalCost || 0) > 0
    ) {
      // validate stripe form
      let isValidStripe = !hasError;
      // if (this.cardName != "" && this.cardNumber != "" && this.expMoth != "" && this.expyear != "" && this.cvc != "") {
      //   isValidStripe = true;
      // }
      if (isValidStripe) {
        var $this = this;
        $this.stripeToken = "";
        this.paying = true;
        this.cardInfoError = "";
        try {
          Stripe.card.createToken($("#stripeFrm"), async (status, response) => {
            //Stripe token failure...
            if (response.error) {
              var msg = "card name";
              const stripeMessage = response.error?.message || "";
              if (response.error.param == "number") {
                $this.stripeError = 2;
                this.form.cardNumber.error = "Card Number is invalid";
                msg = "card number";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_month") {
                $this.stripeError = 3;
                this.form.expMoth.error = "Exp Month is invalid";
                msg = "month";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_year") {
                $this.stripeError = 4;
                this.form.expyear.error = "Exp Year is invalid";
                msg = "year";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "cvc") {
                $this.stripeError = 5;
                this.form.cvc.error = "CVC is invalid";
                msg = "cvc";
                $this.cardInfoError = stripeMessage;
              } else {
                $this.stripeError = 1;
                $this.paymentErrMessage = stripeMessage;
              }
            } else {
              $this.stripeToken = response.id;
              $this.stripeLast4 = response.card.last4;
              $this.stripeError = 0;
            }

            if ($this.stripeToken != "" && $this.stripeError == 0) {
              await this.doPay();
            } else {
              this.paying = false;
            }
          });
        } catch(e) {
          this.paying = false;
          ApiHelper.showErrorMessage('Your payment is unable to be accepted at this time. Please contact your camp administrator.');
        }
      }
    }
  }

  async doPay() {
    let ret = false;
    // try {
      this.paying = true;
      this.disablePaymentForm();
      const paymentInfo = this.paymentInfo;
      if (paymentInfo.stripePublishableKey) {
        delete paymentInfo.stripePublishableKey;
      }
      const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));
      let discounts = JSON.parse(JSON.stringify(this.appliedDiscount));
      const discountHasNoCode = discounts.filter(
        (item: any) => item.discountCode == ""
      );
      discounts = discounts.filter((item: any) => item.discountCode != "");

      const requestObj = {
        callFrom: "portal",
        saveParticipant: false,
        uuid: this.$loggedUser.entityUUID,
        applications: selectedToPay.map((item: any) => {
          let additionalCosts =
            item.registrationStep == 1
              ? item.additionalCosts || []
              : item.registrationStep == 4
              ? item.dynamicAdditionalCosts || []
              : [];
          additionalCosts = [...additionalCosts];
          if (item.registrationStep == 1 && (item.totalFunds || 0) > 0) {
            additionalCosts.push({
              link_event_service_Id: 0,
              addonServiceId: 5,
              serviceName: "Total Camp Store Funds",
              cost: item.totalFunds,
              isFundBucket: 1,
              costFormatted: ApiHelper.dollarFormat(item.totalFunds),
              isTotalFunds: true
            });
          }

          const discountInfo: any = [];
          if (item.sourceType == 1 && item.registrationStep == 1) {
            // apply discount without discount code firstly
            if (
              (item.discountAmount || 0) > 0 &&
              discountHasNoCode.find(t => (t.appliedFor || []).includes(item.participantID))
            ) {
              const discountAmount = item.discountAmount;
              item.participantCost -= discountAmount;
              item.participantCost = parseFloat(
                item.participantCost.toFixed(2)
              );
              item.cost -= discountAmount;
              item.cost = parseFloat(item.cost.toFixed(2));
              if (item.discount) {
                discountInfo.push(item.discount);
              }
            }

            // apply discount if input a discount code
            if (discounts.length) {
              for (const discount of discounts) {
                if (
                  (discount.eventId == item.eventID &&
                    discount.participant_typeId == item.participant_typeid) ==
                  false
                ) {
                  continue;
                }

                // check if applied this discount for this participant
                const inPreApplied = this.preAppliedDiscounts.find(t => t.participantId == item.participantID && t.discountId == discount.discountId);
                if(inPreApplied) {
                  // no send totalUsed in discountInfo
                  const itemDiscount = { ...discount };
                  delete itemDiscount.totalUsed;
                  delete itemDiscount.appliedFor;
                  discountInfo.push(itemDiscount);
                  continue;
                }

                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  // apply if pass the condition
                  item.participantCost -= discountAmount;
                  item.participantCost = parseFloat(
                    item.participantCost.toFixed(2)
                  );
                  item.cost -= discountAmount;
                  item.cost = parseFloat(item.cost.toFixed(2));

                  // no send totalUsed in discountInfo
                  const itemDiscount = { ...discount };
                  if (itemDiscount.totalUsed != undefined) {
                    delete itemDiscount.totalUsed;
                  }

                  if (itemDiscount.appliedFor) {
                    // remove appliedFor info
                    delete itemDiscount.appliedFor;
                  }
                  discountInfo.push(itemDiscount);

                  // update totalUsed
                  discount.totalUsed += 1;
                }
              }
            }
          }

          return {
            sourceType: item.sourceType || 1,
            addonServiceId: item.addonServiceId || 0,
            profileid: item.profileid || 0,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            participantID: item.participantID || 0,
            eventID:
              item.sourceType == 2
                ? item.lnkServiceEventId || 0
                : item.eventID || 0,
            eventName: item.eventName || "",
            serviceName: item.serviceName || "",
            participantTypeID: item.participant_typeid || 0,
            participantAmount:
              item.registrationStep == 1
                ? parseFloat(item.participantCost)
                : item.registrationStep == 4
                ? parseFloat(item.originalBalance)
                : parseFloat(item.cost),
            pRegistrationStep: item.registrationStep,
            planID: item.planId,
            additionalCosts,
            totalCost: item.cost,
            participants:
              (item.sourceType || 1) == 2
                ? []
                : item.participants.map((p: any) => ({
                    eventID: p.eventID || 0,
                    ev_name: p.ev_name || "",
                    eventName: p.ev_name || "",
                    participantID: p.participantID,
                    profileid: p.profileid,
                    firstName: p.p_fname || "",
                    lastName: p.p_lname || "",
                    participantCost: p.participantCost,
                    pRegistrationStep: p.pRegistrationStep,
                    planId: p.planId || "",
                    balance: p.originalBalance || 0,
                    participantAmountFormatted: ApiHelper.dollarFormat(
                      p.originalBalance || 0
                    ),
                    additionalCosts: p.dynamicAdditionalCosts
                  })) || [],
            discountInfo
          };
        }),
        type: this.paymentType,
        completePayment: 1,
        cardInfo: {
          token: this.stripeToken,
          cardName: this.cardName,
          number: this.cardNumber,
          expMonth: this.expMoth,
          expYear: this.expyear,
          zipCode: this.zipcode,
          last4: this.stripeLast4
        },
        paymentInfo: paymentInfo,
        mainProfileID: this.$loggedUser.id,
        familyID: this.$loggedUser.familyId,
        applyFullDiscount: this.applyFullDiscount() ? 1 : 0
      };

      const response = await axios.post(
        `${dataURL}/registrationComplete`,
        requestObj
      );
      if (response.data.status == 1) {
        ret = true;

        const paymentResult = response.data.data.paymentResult;
        if (paymentResult.status == 1) {
          // pay successfully
          this.resetPaymentInfo();
          const btn = $(".checkout_btn.slideDiv");
          btn.html("Close");
          $(".slide_wrapper").animate({ left: "-200%" }, 100, "linear");

          // remove related item in cart
          if (this.cartItems.length) {
            for (const item of selectedToPay) {
              this.cartItems = this.cartItems.filter(
                (cartItem: any) =>
                  !(
                    cartItem.eventID == item.eventID &&
                    cartItem.profile.profileid == item.profileid &&
                    cartItem.pType.participantTypeID == item.participant_typeid
                  )
              );
            }
          }
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
          await ApiHelper.updateProfileStats();
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.refresh();
          if (
            typeof this.$loggedUser.stats.OutstandingFormatted != "undefined"
          ) {
            this.outstandingBalanceFmt = this.$loggedUser.stats.OutstandingFormatted;
          }
          this.paying = false;
          await this.fetchData();
          this.form.cardName.value = "";
          this.form.cardNumber.value = "";
          this.form.expMoth.value = "";
          this.form.expyear.value = "";
          this.form.cvc.value = "";
          this.form.zipcode.value = "";
        } else {
          const message = paymentResult.message || "";
          if(message) {
            throw message;
          }
          this.paymentErrMessage =
            "Something went wrong with your credit card. Please check your card information and try again.";
        }
      }else {
        const message = response.data.message || "";
        if(message) {
          throw message;
        }
      }
    // } catch (error) {
    //   // console.log(error);
    // } finally {
    //   this.enablePaymentForm();
    //   this.paying = false;
    // }

    this.enablePaymentForm();
    this.paying = false;

    return ret;
  }

  resetPaymentInfo() {
    this.paymentInfo = {};
    this.stripeToken = "";
    this.stripeLast4 = "";
    this.cardName = "";
    this.cardNumber = "";
    this.expMoth = "";
    this.expyear = "";
    this.cvc = "";
    this.stripeError = 0;
    this.zipcode = "";
    this.paymentErrMessage = "";
  }

  // loadSavedCart() {
  //   if(localStorage[this.$campCartKey]) {
  //       const campCart = JSON.parse(localStorage[this.$campCartKey]);
  //       this.cartItems = campCart.cartItems || [];
  //   }
  // }

  dynamicCostInput(registration, cost) {
    if (cost.cost < 0) {
      return;
    }

    if (
      registration.pRegistrationStep == 1 ||
      registration.pRegistrationStep == 4
    ) {
      const regInList = this.unpaidRegistration.find(
        (item: any) =>
          item.eventID == registration.eventID &&
          item.participant_typeID == registration.participant_typeID &&
          item.profileid == registration.profileid
      );
      if (regInList) {
        // update for additionalCosts
        const additionalCosts = regInList.additionalCosts || [];
        const costInList = additionalCosts.find(
          (item: any) => item.addonServiceId == cost.addonServiceId
        );
        if (costInList) {
          if (isNaN(cost.cost) || cost.cost < 0) {
            costInList.cost = 0;
          } else {
            costInList.cost = parseFloat(cost.cost);
          }
          costInList.costFormatted = ApiHelper.dollarFormat(costInList.cost);
          cost.costFormatted = costInList.costFormatted;

          // update total cost for the registration in list
          if (registration.pRegistrationStep == 1) {
            let totalCost = regInList.participantCost;
            const totalAdditionalCosts = additionalCosts
              .map((c: any) => c.cost)
              .reduce(
                (totalAdditionalCosts, cost) => totalAdditionalCosts + cost
              );
            totalCost += totalAdditionalCosts;
            totalCost = parseFloat(totalCost.toFixed(2));

            regInList.totalCost = totalCost;
            regInList.totalCostFormatted = ApiHelper.dollarFormat(totalCost);

            // update balance
            regInList.balance = parseFloat(
              (totalCost - regInList.paidAmount).toFixed(2)
            );
            regInList.balanceFormatted = ApiHelper.dollarFormat(
              regInList.balance
            );
          } else if (registration.pRegistrationStep == 4) {
            let totalAdditionalCosts = 0;
            for (const dynamicCost of regInList.dynamicAdditionalCosts) {
              totalAdditionalCosts += isNaN(parseFloat(dynamicCost.cost))
                ? 0
                : parseFloat(dynamicCost.cost);
            }
            let totalCost = regInList.originalBalance + totalAdditionalCosts;
            totalCost = parseFloat(totalCost.toFixed(2));

            // update balance
            regInList.balance = totalCost;
            regInList.balanceFormatted = ApiHelper.dollarFormat(
              regInList.balance
            );

            // console.log(regInList);
          }
        }
      }
    } else {
      // registration is in participants list
      // find regisrtation in list
      let regInList: any = null;
      let regInParticipants: any = null;
      for (const item of this.unpaidRegistration) {
        if ((item.participants || []).length) {
          regInParticipants = item.participants.find(
            (p: any) =>
              p.eventID == registration.eventID &&
              p.participant_typeID == registration.participant_typeID &&
              p.profileid == registration.profileid
          );
          if (regInParticipants) {
            regInList = item;
            break;
          }
        }
      }
      if (regInList && regInParticipants) {
        const additionalCosts = regInParticipants.additionalCosts || [];
        const costInList = additionalCosts.find(
          (item: any) => item.addonServiceId == cost.addonServiceId
        );
        if (isNaN(cost.cost) || cost.cost < 0) {
          costInList.cost = 0;
        } else {
          costInList.cost = parseFloat(cost.cost);
        }
        costInList.costFormatted = ApiHelper.dollarFormat(costInList.cost);
        cost.costFormatted = costInList.costFormatted;

        // calculate for registration in participants list
        let totalAdditionalCosts = 0;
        for (const dynamicCost of regInParticipants.dynamicAdditionalCosts) {
          totalAdditionalCosts += isNaN(parseFloat(dynamicCost.cost))
            ? 0
            : parseFloat(dynamicCost.cost);
        }
        let totalCost =
          regInParticipants.originalBalance + totalAdditionalCosts;
        totalCost = parseFloat(totalCost.toFixed(2));

        // update balance
        regInParticipants.balance = totalCost;
        regInParticipants.balanceFormatted = ApiHelper.dollarFormat(
          regInParticipants.balance
        );

        // update for totalBalance of parent registration
        let totalBalance = 0;
        for (const p of regInList.participants) {
          totalBalance += p.balance || 0;
        }
        regInList.totalBalance = parseFloat(totalBalance.toFixed(2));
      }
    }
  }

  dynamicCostBlur(registration, cost) {
    if (isNaN(cost.cost) || cost.cost < 0) {
      cost.cost = 0;
      cost.costFormatted = "$0.00";
    }
  }

  dynamicCostChange(registration, cost) {
    this.getPaymentInfo();
  }

  fundTxtInput(p) {
    if (this.selectedToPay.length) {
      // update value in selectedToPay
      const inList = this.selectedToPay.find(
        (item: any) =>
          item.eventID == p.eventID &&
          item.participantID == p.participantID &&
          item.profileid == p.profileid &&
          item.sourceType == p.sourceType &&
          item.lnkServiceEventId == p.lnkServiceEventId
      );
      if (inList) {
        inList.cost = parseFloat(p.balance).toFixed(2);
      }
    }
  }
  fundTxtKeydown(e) {
    if (e.key == "-") {
      e.preventDefault();
    }
  }

  fundTxtChange(p) {
    if (parseFloat(p.balance) > 0) {
      // auto select for this funding line
      this.selectedToPay = this.selectedToPay.filter(
        (item: any) =>
          !(
            (item.addonServiceId || 0) == p.eventID &&
            (item.profileid || 0) == p.profileid &&
            item.sourceType == p.sourceType &&
            item.lnkServiceEventId == p.lnkServiceEventId
          )
      );
      this.selectedToPay.push({
        sourceType: p.sourceType || 1,
        eventID: p.eventID,
        participantID: p.participantID,
        profileid: p.profileid,
        cost: parseFloat(p.balance).toFixed(2),
        addonServiceId: p.eventID,
        serviceName: p.ev_name,
        firstName: p.p_fname,
        lastName: p.p_lname,
        lnkServiceEventId: p.lnkServiceEventId
      });
    } else {
      this.selectedToPay = this.selectedToPay.filter(
        (item: any) =>
          !(
            (item.addonServiceId || 0) == p.eventID &&
            (item.profileid || 0) == p.profileid &&
            item.sourceType == p.sourceType &&
            item.lnkServiceEventId == p.lnkServiceEventId
          )
      );
    }

    this.toggleSelectedToPay(p);
    // this.getPaymentInfo();
  }

  fundTxtBlur(p) {
    if (isNaN(p.balance) || p.balance < 0 || p.balance == "") {
      p.balance = "0.00";
      this.fundTxtInput(p);
    } else {
      p.balance = parseFloat(p.balance).toFixed(2);
    }
  }

  inSelectedAndEmpty(p) {
    // p is registration for funding
    if (this.selectedToPay.length) {
      const thisP = this.selectedToPay.find(
        (item: any) =>
          item.sourceType == p.sourceType &&
          item.addonServiceId == p.eventID &&
          item.profileid == p.profileid &&
          item.lnkServiceEventId == p.lnkServiceEventId
      );
      if (thisP && (thisP.cost <= 0 || thisP.cost == "" || isNaN(thisP.cost))) {
        return true;
      }
    }
    return false;
  }

  getRegistrationID(item) {
    let pIDs = "";
    if ((item.participants || []).length) {
      pIDs = item.participants.map((p: any) => p.participantID).join("_");
    } else {
      pIDs = item.participantID;
    }
    return `p_${pIDs}`;
  }

  async performExport() {
    this.exporting = true;
    const response = await axios.post(`${dataURL}/finance/exportPDF`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      familyId: this.$loggedUser.familyId,
      profileId: this.$route.query.profileId
    });
    this.exporting = false;
    if (response.data.status == 1) {
      const fileUrl = response.data.data.S3URL || "";
      if (fileUrl != "") {
        downloadFileUrl(fileUrl);
      }
      // test export html
      // const exportData = response.data.data.exportData || {};
      // const template = require("../templates/financials/financials.handlebars");
      // const html = template(exportData);
      // if (!html) return;
      // var fileNameExport = "Export_" + Math.round(+new Date() / 1000) + ".html";
      // downloadFile(fileNameExport, html);
    }
  }

  getNewPaymentSummary() {
    let summary = `${
      this.selectedToPay.filter(item => item.sourceType == 1).length
    } Registrations`;
    const totalAddons = this.selectedToPay.filter(item => item.sourceType == 2)
      .length;
    if (totalAddons) {
      summary += ` and ${totalAddons} Addons Selected`;
    } else {
      summary += ` Selected`;
    }
    return summary;
  }

  onClickTab(tabName: string) {
    this.activeTab = tabName;
  }

  showTransactionDetails(transactionId: string, transactionIds = "") {
    this.viewTransactionId = transactionId;
    this.viewTransactionIds = transactionIds;
  }

  showPlanDetails(planId: string) {
    this.viewPlanId = planId;
  }

  showPopupPlan(planId: string) {
    this.closeTransactionDetails();
    this.showPlanDetails(planId);
  }

  showPopupTransaction(transactionId: string) {
    this.closePlanDetails();
    this.showTransactionDetails(transactionId);
  }

  closeTransactionDetails() {
    this.viewTransactionId = "";
    this.viewTransactionIds = "";
  }

  closePlanDetails() {
    this.viewPlanId = "";
  }

  getCheckoutCost(item: any) {
    if (
      item.sourceType == 1 &&
      item.pRegistrationStep == 1 &&
      (item.discountAmount || 0) > 0
    ) {
      const balance = parseFloat(
        (item.balance - item.discountAmount).toFixed(2)
      );
      return ApiHelper.dollarFormat(balance);
    }

    return item.balanceFormatted;
  }

  toggleSelectedToPay(toggleItem: any) {
    // apply discount for selected items
    const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));

    this.appliedDiscount = [];
    this.preAppliedDiscounts = [];
    // let totalDiscountNoCode = 0;
    // const appliedFor: any = [];
    for (const item of selectedToPay) {
      if (!(item.sourceType == 1 && item.registrationStep == 1)) continue;
      const discountAmount = item.discountAmount || 0;
      if (discountAmount > 0) {
        // totalDiscountNoCode += discountAmount;
        item.participantCost -= discountAmount;
        item.participantCost = parseFloat(item.participantCost.toFixed(2));
        item.cost -= discountAmount;
        item.cost = parseFloat(item.cost.toFixed(2));
        // appliedFor.push(item.participantID);
      }
    }

    // save applied discount
    // if (totalDiscountNoCode > 0) {
    //   this.appliedDiscount = [
    //     {
    //       discountId: 0,
    //       discountCode: "",
    //       discountAmount: parseFloat(totalDiscountNoCode.toFixed(2)),
    //       appliedFor
    //     }
    //   ];
    // }

    // if saved a discount before
    for (const item of selectedToPay) {
      if (!(item.sourceType == 1 && item.registrationStep == 1)) continue;
      const discountItems = item.discountItems || [];
      if(discountItems.length) {
        for(const discount of discountItems) {
          const inList = this.appliedDiscount.find(d => d.discountId == discount.discountId && d.appliedFor.includes(item.participantID));
          if(!inList) {
          //   if(!inList.appliedFor.includes(item.participantID)) {
          //     inList.appliedFor.push(item.participantID);
          //     this.preAppliedDiscounts.push({
          //       discountId: discount.discountId,
          //       participantId: item.participantID
          //     });
          //   }
          // }else {
            this.appliedDiscount.push({
              eventId: discount.eventId,
              participant_typeId: discount.participant_typeId,
              discountId: discount.discountId,
              discountCode: discount.discountCode || "",
              discountName: discount.discountName || "",
              discountAmount: discount.discountAmount || 0,
              appliedFor: [item.participantID]
            });
            this.preAppliedDiscounts.push({
              discountId: discount.discountId,
              participantId: item.participantID
            });
          }
        }
      }
    }

    this.getPaymentInfo(selectedToPay);
  }

  showDiscountSection() {
    if (this.discountsHasCode.length == 0) return false;

    let show = false;
    for (const item of this.selectedToPay) {
      const existedDiscount = this.discountsHasCode.find(
        (discount: any) =>
          item.eventID == discount.eventId &&
          item.participant_typeid == discount.pTypeId
      );
      if (existedDiscount) {
        show = true;
        break;
      }
    }

    return show;
  }

  showAppliedDiscount() {
    if (this.appliedDiscount.length) {
      return true;
    }
    return false;
  }

  async applyDiscount() {
    if (this.discountCode == "") return;

    if (this.selectedToPay.length > 0) {
      try {
        this.applyingDiscount = true;
        const response = await axios.post(`${dataURL}/getAvailableDiscounts`, {
          participants: this.selectedToPay
            .filter(item => item.sourceType == 1 && item.registrationStep == 1)
            .map((item: any) => {
              return {
                eventID: item.eventID,
                pTypeID: item.participant_typeid
              };
            }),
          discountCode: this.discountCode
        });
        // getPaymentInfo
        if (response.data.status == 1) {
          this.availableDiscounts = response.data.data.availableDiscounts || [];
          if (this.availableDiscounts.length) {
            const selectedToPay = JSON.parse(
              JSON.stringify(this.selectedToPay)
            );
            const availableDiscounts = JSON.parse(
              JSON.stringify(this.availableDiscounts)
            );

            // check if returned available discounts can apply for selectedToPay
            let canApplyDiscountCode = false;
            for (const item of selectedToPay) {
              if (!(item.sourceType == 1 && item.registrationStep == 1))
                continue;
              const discount = availableDiscounts.find(
                (d: any) =>
                  d.eventId == item.eventID &&
                  d.participant_typeId == item.participant_typeid
              );
              if (discount) {
                canApplyDiscountCode = true;
                break;
              }
            }
            if (canApplyDiscountCode) {
              // apply auto discount
              const discountHasNoCode = this.appliedDiscount.find(
                (item: any) => item.discountCode == ""
              );
              for (const item of selectedToPay) {
                if (!(item.sourceType == 1 && item.registrationStep == 1))
                  continue;
                const discountAmount = item.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discountHasNoCode?.appliedFor || []).includes(
                    item.participantID
                  )
                ) {
                  item.participantCost -= discountAmount;
                  item.participantCost = parseFloat(
                    item.participantCost.toFixed(2)
                  );
                  item.cost -= discountAmount;
                  item.cost = parseFloat(item.cost.toFixed(2));
                }
              }

              // collect discounts can apply and store in appliedDiscounts
              for (const item of selectedToPay) {
                if (!(item.sourceType == 1 && item.registrationStep == 1))
                  continue;
                const discount = availableDiscounts.find(
                  (d: any) =>
                    d.eventId == item.eventID &&
                    d.participant_typeId == item.participant_typeid
                );
                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  const inApplied = this.appliedDiscount.find(
                    (t: any) =>
                      t.eventId == discount.eventId &&
                      t.participant_typeId == discount.participant_typeId &&
                      t.discountCode.toLowerCase() ==
                        discount.discountCode.toLowerCase()
                  );
                  if (!inApplied) {
                    discount.appliedFor = [item.participantID];
                    this.appliedDiscount.push({ ...discount });
                  } else if (
                    !inApplied.appliedFor.includes(item.participantID)
                  ) {
                    inApplied.appliedFor.push(item.participantID);
                  }

                  // update totalUsed
                  discount.totalUsed += 1;
                }
              }

              // apply discount based on appliedDiscounts
              const discountHasCode = this.appliedDiscount.filter(
                (item: any) => item.discountCode != ""
              );
              for (const discountItem of discountHasCode) {
                const relatedSelected = selectedToPay.filter(
                  (item: any) =>
                    item.sourceType == 1 &&
                    item.registrationStep == 1 &&
                    discountItem.appliedFor.includes(item.participantID || 0)
                );

                const finalAppliedFor: any = [];
                for (const item of relatedSelected) {
                  // check if applied this discount for this participant
                  const inPreApplied = this.preAppliedDiscounts.find(t => t.participantId == item.participantID && t.discountId == discountItem.discountId);
                  if(inPreApplied) {
                    finalAppliedFor.push(item.participantID);
                    continue;
                  }

                  const amount =
                    item.participantCost - discountItem.discountAmount;
                  // prevent if applying discount and make amount < 0
                  if (amount >= 0) {
                    finalAppliedFor.push(item.participantID);

                    item.participantCost -= discountItem.discountAmount;
                    item.participantCost = parseFloat(
                      item.participantCost.toFixed(2)
                    );
                    item.cost -= discountItem.discountAmount;
                    item.cost = parseFloat(item.cost.toFixed(2));
                  }
                }
                discountItem.appliedFor = finalAppliedFor;
              }

              // remove discounts has empty appliedFor
              this.appliedDiscount = this.appliedDiscount.filter(
                (item: any) => item.appliedFor.length
              );

              // get new paymentInfo
              await this.getPaymentInfo(selectedToPay);
              if (this.applyFullDiscount()) {
                const btn = $(".checkout_btn.slideDiv");
                btn.html("Submit");
              }

              this.paymentErrMessage = "";
              const inApplied = this.appliedDiscount.find(
                (item: any) =>
                  item.discountCode.toLowerCase() ==
                  this.discountCode.toLowerCase()
              );
              if (inApplied) {
                // show success message
                this.$swal.fire({
                  // icon: "success",
                  text: `Applied discount code ${this.discountCode.toUpperCase()} successfully`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              } else {
                this.$swal.fire({
                  // icon: "warning",
                  html: `Cannot apply discount code ${this.discountCode.toUpperCase()}`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              }
              // clear inputted discount code
              this.discountCode = "";
            } else {
              // cannot apply this discount code for event/ptype in selectedToPay
              this.paymentErrMessage = "Discount code not found";
            }
          }
        } else {
          const errorCode = response.data.errorCode || "";
          if (errorCode == "DISCOUNT_NOT_FOUND") {
            this.paymentErrMessage = "Discount code not found";
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.applyingDiscount = false;
      }
    }
  }

  getAppliedDiscountInfo() {
    if (this.showAppliedDiscount()) {
      // group by discountCode
      const grouped: any = [];
      for (const item of this.appliedDiscount) {
        const discountCode = (item.discountCode || "").toUpperCase();
        const inGrouped = grouped.find(
          d => (d.discountCode || "") == discountCode
        );
        if (inGrouped) {
          continue;
        }
        grouped.push({
          discountCode,
          items: this.appliedDiscount.filter(
            d => (d.discountCode || "").toUpperCase() == discountCode
          )
        });
      }

      let codes: any = [];
      for (const group of grouped) {
        const discountCode = group.discountCode;
        let totalAmount = 0;
        for (const discount of group.items) {
          const discountAmount = discount.discountAmount || 0;
          if (discountCode == "") {
            totalAmount += discountAmount;
          } else {
            totalAmount += discountAmount * (discount.appliedFor || []).length;
          }
        }
        const discountAmountFormatted = dollarFormat(totalAmount);
        codes.push(
          `<strong>${discountCode}</strong> (-${discountAmountFormatted})`
        );
      }

      return `Applied discount: ${codes.join(", ")}`;
    }

    return "";
  }

  applyFullDiscount() {
    if ((this.paymentInfo.totalCost || 0) == 0) {
      return true;
    }
    return false;
  }

  async balancePayNow(item: any) {
    if ((item.inActivePlan || 0) == 1) {
      // allow pay in full for a plan item
      // prepare selectedToPay
      const balanceItem = JSON.parse(JSON.stringify(item));
      const selectedToPay = [
        {
          sourceType: 1,
          participants: [
            {
              sourceType: 1,
              eventID: balanceItem.eventId,
              ev_name: balanceItem.eventName,
              participantID: balanceItem.participantId,
              profileid: balanceItem.profileId,
              p_fname: balanceItem.firstName,
              p_lname: balanceItem.lastName,
              participantCost: balanceItem.participantCost,
              pRegistrationStep: balanceItem.pRegistrationStep,
              planId: balanceItem.planId,
              originalBalance: balanceItem.balance
            }
          ],
          cost: balanceItem.balance || 0,
          registrationStep: balanceItem.pRegistrationStep || 1,
          planId: balanceItem.planId || "",
          isPlanItemPayFull: 1
        }
      ];
      this.paymentModal.selectedToPay = selectedToPay;
      // show payment modal
      this.paymentModal.visible = true;
    } else {
      await this.toggleSlideUp();
      ApiHelper.sleep(300);
      const payForParticipant = item.participantId || 0;
      if(parseInt(payForParticipant) > 0 && $(`#p_${payForParticipant}`).length) {
        // auto select participant
        $(`#p_${payForParticipant}`).click();
      }

      // const payForProfile = item.profileId || 0;
      // const relatedRegistrations = this.unpaidRegistration.filter(
      //   (item2: any) => {
      //     if ((item2.sourceType || 1) == 2) {
      //       return false;
      //     }

      //     let ret = false;
      //     if ((item2.profileid || 0) == payForProfile) {
      //       ret = true;
      //     } else if ((item2.participants || []).length) {
      //       const inThisPlan = item2.participants.find(
      //         (p: any) => (p.profileid || 0) == payForProfile
      //       );
      //       if (inThisPlan) {
      //         ret = true;
      //       }
      //     }
      //     return ret;
      //   }
      // );

      // let relatedIDs: any = [];
      // for (const item2 of relatedRegistrations) {
      //   relatedIDs.push(this.getRegistrationID(item2));
      // }
      // if (relatedIDs.length) {
      //   this.selectedToPay = [];
      //   for (const id of relatedIDs) {
      //     this.selectedToPay.push($(`#${id}`).data("value"));
      //   }
      //   await this.getPaymentInfo();
      // }
    }
  }

  setIsPaying(status = false) {
    this.paying = status;
  }

  async reloadData() {
    await this.fetchData();
    const pageHeader: any = this.$refs.pageHeader;
    pageHeader.refresh();
  }
}
