




























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "../helpers/all";
import directives from "../helpers/directives";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: false,
  components: {},
  directives
})
export default class RoommateRequest extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: false })
  fullHeight?: boolean;

  @Prop({ required: false, default: 0 })
  profileId?: number;

  @Prop({ required: false, default: 0 })
  participantId?: number;

  @Prop({ required: false, default: 0 })
  eventId?: number;

  @Prop({ required: false, default: 0 })
  pTypeId?: number;

  $validator: any;
  inviteCode = 0;
  inviteData = {
    isProcessing: false,
    code: "",
    email: "",
    emailError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    valid: false,
    verified: false
  };

  created() {
    this.inviteCode = ApiHelper.getRandomInt(100000, 999999);
  }

  async validateInviteForm() {
    if (
      ApiHelper.validateEmail(this.inviteData.email) &&
      this.inviteData.firstName
    ) {
      this.inviteData.valid = true;
    } else {
      this.inviteData.valid = false;
    }
  }

  async sendInvitation() {
    this.inviteData.emailError = "";
    this.inviteData.firstNameError = "";
    this.inviteData.lastNameError = "";

    if (this.inviteData.firstName == "") {
      this.inviteData.firstNameError = "First Name is required";
      this.inviteData.valid = false;
    }
    if (this.inviteData.email == "") {
      this.inviteData.emailError = "Email is required";
      this.inviteData.valid = false;
    } else if (!ApiHelper.validateEmail(this.inviteData.email)) {
      this.inviteData.emailError = "Email is invalid";
      this.inviteData.valid = false;
    } else if (!ApiHelper.checkRoommateEmail(this.inviteData.email, this.participantId)) {
      this.inviteData.emailError = "Request has already been sent";
      this.inviteData.valid = false;
    }

    if (!this.inviteData.valid) {
      return;
    }
    this.inviteData.isProcessing = true;
    const loggedUser = ApiHelper.getLoggedUser();

    const result = await ApiHelper.apiPost("/saveRoommateInvite", {
      uuid: loggedUser.entityUUID,
      profileID: this.profileId,
      participantID: this.participantId,
      pTypeID: this.pTypeId,
      eventID: this.eventId,
      code: this.inviteCode,
      email: this.inviteData.email.toLowerCase(),
      firstName: this.inviteData.firstName,
      lastName: this.inviteData.lastName
    });
    this.inviteData.isProcessing = false;
    if (result.status == 1) {
      // ApiHelper.showSuccessMessage(
      //   "An invitation email was sent to " + this.inviteData.email
      // );
      this.$emit("closeModal");
      this.$emit("reloadData");
      await this.$swal.fire({
        // icon: "success",
        title: "Success",
        html: `An invitation email with invite code <strong>${this.inviteCode}</strong> was sent to ${this.inviteData.email}`,
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "yellow_btn SecondaryColor FontColor"
        }
      });
      this.inviteData.email = "";
      this.inviteData.firstName = "";
      this.inviteData.lastName = "";
      this.inviteData.valid = false;
      // refresh invite code for next request
      this.inviteCode = ApiHelper.getRandomInt(100000, 999999);
      this.$forceUpdate();
    } else {
      const errorCode = result.errorCode;
      let message = result.message || "";
      if (errorCode == "profiles_not_found") {
        message =
          "Profile you want to invite not found. Please check your inputted information";
      }
      ApiHelper.showErrorMessage(message, "Oops");
    }
  }
}
