


































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class FormToggle extends TSXComponent<Props, Events> {
  @Prop({ required: true, default: {} })
  data!: any;
}
