






























































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "../helpers/all";
import NoData from "../components/Common/NoData.vue";
import Loading from "../components/Common/Loading.vue";
import ApexChart from "../components/Chart/ApexChart.vue";
import moment from "moment";
import PageHeader from "@/components/PageHeader.vue";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    NoData,
    Loading,
    Pagination,
    ApexChart
  },
})
export default class TransactionDetailsPage extends TSXComponent<void> {
  pageData: {
    hasError: boolean,
    errorMessage: string
  } = {
    hasError: false,
    errorMessage: ''
  };


  loading = false;
  $loggedUser: any;
  $campCartKey: any;
  $router: any;
  $route: any;
  $swal: any;
  planId = 0;
  planDetails: any = [];
  planInfo: any = {};
  transactionIds = '';
  transactionType = '';
  transactionDetails: any = [];
  transactionLines: any = [];
  transactionInfos: any = [];
  externalId = "";
  cancelling = false;
  // totalDiscounts = 0;
  // totalDiscountsFormatted = "";
  discounts: any = [];

  async created() {
    if (typeof this.$route.query.planId !== 'undefined') {
      this.planId = this.$route.query.planId;
      await this.fetchData();
    }
    if (typeof this.$route.query.transactionID !== 'undefined') {
      this.transactionIds = this.$route.query.transactionID;
      this.transactionType = this.$route.query.type || 'plan';
      await this.fetchTransactionData();
    }

    $(".plan-info .row:visible:last").addClass("last-row");
  }

  async fetchData() {
    try {
      this.pageData.hasError = false;
      this.loading = true;
      const result = await axios.post(`${dataURL}/finance/planDetails`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: this.$loggedUser.id,
        planId: this.planId
      });
      if (result.data.status == 1) {
        this.planDetails = result.data.data.planDetails;
        this.planInfo = result.data.data.planInfo;
        this.planInfo.createdTSFormatted = moment(this.planInfo.planTS).format('MMM DD, YYYY');
      } else {
        this.pageData.hasError = true;
        this.pageData.errorMessage = result.data.message || JSON.stringify(result.data);
      }
    } catch (err) {
      ApiHelper.showErrorMessage(JSON.stringify(err), 'Oops');
      // console.log(err);
      this.pageData.hasError = true;
      this.pageData.errorMessage = JSON.stringify(err);
    } finally {
      this.loading = false;
    }
  }

  // async cancelPlan() {
  //   const confirm = await this.$swal.fire({
  //     // icon: "warning",
  //     title: "Are you sure?",
  //     text: "You are about to cancel this plan. Are you sure?",
  //     confirmButtonText: "CONFIRM CANCEL PLAN",
  //     showCloseButton: true,
  //     customClass: {
  //       container: "swal2-container-custom",
  //       confirmButton: "btn button SecondaryColor FontColor"
  //     }
  //   });
  //   if (confirm.isConfirmed) {
  //     try {
  //       this.cancelling = true;
  //       const result = await axios.post(`${dataURL}/cancelPlan`, {
  //         domain: ApiHelper.getDomain(),
  //         uuid: ApiHelper.getUuid(),
  //         profileID: this.$loggedUser.id,
  //         planId: this.planId
  //       });
  //       if (result.data.status == 1) {
  //         this.$router.push({ name: "Finance" });
  //       }
  //     } catch (err) {
  //       // console.log(err);
  //     } finally {
  //       this.cancelling = false;
  //     }
  //   }
  // }

  // transaction details
  async fetchTransactionData() {
    try {
      this.loading = true;
      const result = await axios.post(`${dataURL}/finance/transactionDetails`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: this.$loggedUser.id,
        transactionIds: this.transactionIds,
        type: this.transactionType
      });
      if (result.data.status == 1) {
        this.transactionDetails = result.data.data.transactionDetails.map((item) => {
          item.transactionTSFormatted =  ApiHelper.formatDate(item.transactionTS, 'MMM DD, YYYY HH:mm:ss', '');
          return item;
        });
        this.transactionInfos = result.data.data.transactionInfos || [];
        this.transactionLines =  result.data.data.transactionLines || [];
        // this.totalDiscounts = result.data.data.totalDiscounts || 0;
        // this.totalDiscountsFormatted = result.data.data.totalDiscountsFormatted || "";
        this.discounts = result.data.data.discounts || [];
      }
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }
  dollarFormat(amount) {
    return ApiHelper.dollarFormat(amount);
  }

  getDiscounts(transactionId: number) {
    // if(!transactionId || !this.discounts.length) return "";
    // const discount = this.discounts.filter(item => item.parentId == transactionId);
    // let totalDiscounts = 0;
    // for(const item of discount) {
    //   totalDiscounts += item.transactionAmount || 0;
    // }
    // if(totalDiscounts > 0) {
    //   return `Discount: -${ApiHelper.dollarFormat(totalDiscounts)}`;
    // }
    return "";
  }
}
