



































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import { ApiHelper } from "@/helpers/all";
import FormText from "@/components/Form/FormText.vue";
import Loading from "@/components/Common/Loading.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    FormPassword,
    FormSelect,
    FormEmail,
    FormDate,
    Loading,
    FormText,
    FormUpload,
    FormPhoto,
    Pagination,
  },
})
export default class Profile extends TSXComponent<void> {
  formData: any = {
    isLoading: false,
    controls: {
      id: {
        error: '',
        value: 0
      },
      firstName: {
        error: '',
        label: 'First Name',
        placeholder: 'First Name',
        value: ''
      },
      lastName: {
        error: '',
        label: 'Last Name',
        placeholder: 'Last Name',
        value: ''
      },
      email: {
        error: '',
        label: 'Email',
        placeholder: 'Email',
        value: ''
      },
      dob: {
        error: '',
        label: 'Date Of Birth',
        placeholder: 'Date Of Birth',
        value: ''
      },
      phone: {
        error: '',
        label: 'Phone',
        placeholder: 'Phone',
        maxlength: 14,
        type: 'phone',
        value: ''
      },
      gender: {
        error: '',
        label: 'Gender',
        placeholder: 'Select Gender',
        value: '',
        options: [
          {
            value: '1',
            text: 'Male'
          },
          {
            value: '2',
            text: 'Female'
          },
          {
            value: '3',
            text: 'Undefined/Other/Prefer Not to Say'
          }
        ]
      },
      logo: {
        error: '',
        label: '',
        preview: '',
        value: '',
        base64: ''
      },
      password: {
        error: '',
        mode: 'password',
        label: 'Password',
        placeholder: 'Password',
        value: '',
        valid: false
      },
    }
  }

  async created() {
    await this.loadData();
  }

  async loadData() {
    const loggedUser = ApiHelper.getLoggedUser();
    this.formData.isLoading = true;
    const result = await ApiHelper.apiPost('/profiles/' + loggedUser.id, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid()
    });
    this.formData.isLoading = false;
    if (result.status === 1) {
      const profileSetting = result.data.profileSetting || {};
      this.formData.controls.id.value = loggedUser.id;
      this.formData.controls.firstName.value = profileSetting.p_fname || ''
      this.formData.controls.lastName.value = profileSetting.p_lname || ''
      // this.formData.controls.dob.value = ApiHelper.convertToFormDateValue(profileSetting.p_dob);
      this.formData.controls.dob.value = profileSetting.dobFormatted || "";
      this.formData.controls.email.value = profileSetting.p_email || ''
      this.formData.controls.phone.value = profileSetting.p_phone || ''
      this.formData.controls.gender.value = profileSetting.p_gender || ''
      this.formData.controls.logo.preview = profileSetting.p_logo || ''
      this.formData.controls.logo.value = profileSetting.p_logo || ''
    } else {
      ApiHelper.showErrorMessage(result.message, 'Oops');
    }
  }

  private async changeProfileAvatar(base64: string, type?: string) {
    let hasError = false;
    let message = '';
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      ApiHelper.apiPost('/updateProfileAvatar', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        logoBase64: base64,
        type: type,
        profileID: this.formData.controls.id.value
      }).then((result2) => {
        if (result2.status == 1) {
          this.formData.controls.logo.value = result2.data.url || '';
          this.formData.controls.logo.preview = result2.data.url || '';
          ApiHelper.updateProfilePhoto(result2.data.url || '');
          $('.headerAvatar').html(`<img src="${result2.data.url}" style="width: 50px; height: 50px; border-radius: 50%;">`)
        } else {
          ApiHelper.showErrorMessage(result2.message, 'Oops');
        }
      });
    }
  }

  private async onSubmit(event: Event) {
    event.preventDefault();

    let hasError = false;
    let message = '';
    if (this.formData.controls.firstName.value === '') {
      message = 'First Name is required';
      hasError = true;
    }
    if (this.formData.controls.lastName.value === '') {
      message = 'Last Name is required';
      hasError = true;
    }
    if (this.formData.controls.email.value === '') {
      message = 'Email is required';
      hasError = true;
    }
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/updateProfile', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        fname: this.formData.controls.firstName.value,
        lname: this.formData.controls.lastName.value,
        email: this.formData.controls.email.value,
        phone: this.formData.controls.phone.value,
        dob: this.formData.controls.dob.value,
        gender: this.formData.controls.gender.value,
        profileID: this.formData.controls.id.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        ApiHelper.showSuccessMessage('Updated Profile');
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  private async onSendLink(event: Event) {
    event.preventDefault();
    let hasError = false;
    let message = '';
    /*if (this.formData.controls.password.value === '') {
      message = 'Password is required';
      hasError = true;
    }*/
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/sendPasswordLink', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        password: this.formData.controls.password.value,
        profileID: this.formData.controls.id.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        this.formData.controls.password.value = '';
        ApiHelper.showSuccessMessage('Sent Link');
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  private async onUpdatePassword(event: Event) {
    event.preventDefault();
    let hasError = false;
    let message = '';
    if (this.formData.controls.password.value === '') {
      message = 'Password is required';
      hasError = true;
    } else if (!this.formData.controls.password.valid) {
      message = 'The new password is too simple. Please choose another password.';
      hasError = true;
    }
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/updatePassword', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        password: this.formData.controls.password.value,
        profileID: this.formData.controls.id.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        this.formData.controls.password.value = '';
        ApiHelper.showSuccessMessage('Updated Password');
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  mounted() {
    $(document).ready(function() {
      $(".ProfileEditPage").on("keydown", ".phoneMask, input[type=tel]", function(e) {
        const key = e.charCode || e.keyCode || 0;
        const $phone = $(e.target);
        let $val: any = $phone.val();

        if ($val.length === 0) {
          $phone.val("(");
        } else if ($val.length > 0) {
          if ($val.indexOf("(") == -1) {
            $val = "(" + $val;
            $phone.val($val);
          }
        }

        // Auto-format- do not expose the mask as the user begins to type
        if (key !== 8 && key !== 9) {
          const phoneNumber = new String($phone.val() || "");
          if (phoneNumber.length === 4) {
            $phone.val(phoneNumber + ")");
          }
          if (phoneNumber.length === 5) {
            $phone.val(phoneNumber + " ");
          }
          if (phoneNumber.length === 9) {
            $phone.val(phoneNumber + "-");
          }
        }

        // Allow numeric (and tab, backspace, delete) keys only
        return (
          key == 8 ||
          key == 9 ||
          key == 46 ||
          (key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105)
        );
      });

      $(".ProfileEditPage").on("paste", ".phoneMask, input[type=tel]", function(
        e: any
      ) {
        const $phone = $(e.target);
        const phoneData: any = e.originalEvent.clipboardData.getData("Text");

        const number = phoneData.match(/\d/g, "");
        const joinedNumber = number.join("");
        const regex = /^(\d{3})(\d{3})(\d{4})$/;
        const final = joinedNumber.replace(regex, "($1) $2-$3");

        $phone.val(final);
      });

      $(".ProfileEditPage").on(
        "keydown.autocomplete",
        ".phoneMask, input[type=tel]",
        function(e) {
          const key = e.charCode || e.keyCode;
          const $phone = $(e.target);

          if (typeof key == "undefined") {
            setTimeout(function() {
              const phoneData: any = $phone.val();

              const number = phoneData.match(/\d/g, "");
              const joinedNumber = number.join("");
              const regex = /^(\d{3})(\d{3})(\d{4})$/;
              const final = joinedNumber.replace(regex, "($1) $2-$3");

              $phone.val(final);
            }, 10);
          }
        }
      );

      $(".ProfileEditPage").on(
        "focus click",
        ".phoneMask, input[type=tel]",
        function(e) {
          const $phone: any = $(e.target);

          if ($phone.val() && $phone.val().length === 0) {
            $phone.val("(");
          } else {
            const val = $phone.val();
            $phone.val("").val(val);
          }
        }
      );

      $(".ProfileEditPage").on("blur", ".phoneMask, input[type=tel]", function(e) {
        const $phone = $(e.target);
        if ($phone.val() === "(") {
          $phone.val("");
        }
      });
    });
  }


  passwordData = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };

  checkStrongPassword(password: string) {
    this.passwordData = ApiHelper.getPasswordInfo(password);
    this.formData.controls.password.valid = this.passwordData.valid;
  }

}
