




































import { Component, Prop } from "vue-property-decorator";
import { Component as TSXComponent } from "vue-tsx-support/lib/api";

interface Props {
  noPadding: Boolean;
  pageData: Object;
  gotoPage: Function;
  onClickPrev: Function;
  onClickNext: Function;
}

interface Events {
}

declare const $: any

@Component({
  inheritAttrs: true
})
export default class Pager extends TSXComponent<Props, Events> {
  @Prop() noPadding?: Boolean;
  @Prop() pageData!: Object;
  @Prop() gotoPage!: Object;
  @Prop() onClickPrev!: Object;
  @Prop() onClickNext!: Object;

  onChange(event: any) {
    if (this.$props.gotoPage) {
      this.$props.gotoPage(event.target.value);
    }
  }
}
