














import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { getDomain } from "../helpers/ApiHelper";
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
  },
})
export default class DomainNotFound extends TSXComponent<void> {
  domain = "";
  created() {
    this.domain = getDomain();
  }
}
