

















































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "@/helpers/all";
import moment from "moment";
import Loading from "@/components/Common/Loading.vue";
import NoData from "@/components/Common/NoData.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    PageHeader,
    Loading,
    Pagination,
    NoData,
  },
})
export default class Message extends TSXComponent<void> {
  pageData: any = {
    isLoading: false,
    items: [],
    includeTaskSpecificDiscussion: true,
    currentProfile: {
      email: '',
      entityId: 0,
      firstName: '',
      lastName: '',
      profileId: 0
    },
    currentUser: {
      email: '',
      entityName: '',
      firstname: '',
      lastname: '',
    },
  }
  formData: any = {
    isProcessing: false,
    controls: {
      message: {
        value: ''
      }
    }
  }

  async created() {
    await this.fetchData();
  }

  private async onSubmit() {
    const query = this.$route.query;
    const loggedUser = ApiHelper.getLoggedUser();
    this.formData.isProcessing = true;
    this.formData.isLoading = true;
    const message = this.formData.controls.message.value.replace(
      /\n/g,
      "<br/>"
    );
    const result = await ApiHelper.apiPost('/createMessage', {
      uuid: ApiHelper.getUuid(),
      domain: ApiHelper.getDomain(),
      profileID: loggedUser.id,
      message: message,
      isPreview: loggedUser.type == 2 ? 1 : 0,
    });
    if (result.status == 1) {
      const askBalance = parseInt(`${query.askBalance}`) || 0;
      this.formData.controls.message.value = '';
      if(askBalance) {
        this.$router.replace({query: {...query, askBalance: undefined}});
      }else {
        await this.fetchData();
      }
      this.formData.isProcessing = false;
    } else {
      this.formData.isProcessing = false;
      ApiHelper.showErrorMessage(result.message, 'Oops');
    }
  }

  public async fetchData() {
    const query = this.$route.query;
    const loggedUser = ApiHelper.getLoggedUser();
    this.formData.isProcessing = false;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost('/messages', {
      uuid: ApiHelper.getUuid(),
      domain: ApiHelper.getDomain(),
      profileID: loggedUser.id,
      includeTaskSpecificDiscussion: this.pageData.includeTaskSpecificDiscussion ? 1 : 0,
      askBalance: parseInt(`${query.askBalance}`) || 0
    });
    this.pageData.isLoading = false;
    if (result.status == 1) {
      const profileInfo = result.data.messages.profile || {};
      this.pageData.currentProfile.profileId = profileInfo.profileId || 0;
      this.pageData.currentProfile.firstName = profileInfo.firstName || '';
      this.pageData.currentProfile.lastName = profileInfo.lastName || '';
      const userInfo = result.data.messages.user || {};
      this.pageData.currentUser.firstname = userInfo.firstname || '';
      this.pageData.currentUser.lastname = userInfo.lastname || '';
      this.pageData.currentUser.entityName = userInfo.entityName || '';
      const list = result.data.messages.data.map((item: any) => {
        let createdDate = moment(item.createTs).calendar() || "";
        if (createdDate.includes('Last')) {
          createdDate = moment(item.createTs).format('MM/DD/YYYY');
        }
        return {
          id: parseInt(item.id),
          isChecked: false,
          message: item.message || "",
          name: item.isAdmin ? item.firstName + ' ' + item.lastName + ' @' + item.entityName : "You",
          ts: createdDate,
          isAdmin: item.isAdmin || false,
          tsFormatted: item.tsFormatted || "",
          participantId: item.participantId || 0,
          eventName: item.eventName || "",
          participantTypeName: item.participantTypeName || "",
          formId: item.formId || 0,
          formName: item.formName || ""
        };
      });
      this.pageData.items = list;

      // reset loggedUser.stats.NewMessages
      loggedUser.stats.NewMessages = 0;
      ApiHelper.saveLoggedUser(loggedUser);

      /* setTimeout(() => {
        const container = $('.messages-content.ps');
        container.animate({ scrollTop: container.scrollTop() - container.offset().top + container.find('li:last-child').offset().top }, {
          duration: 'medium',
          easing: 'swing'
        });
        $('.top_item_amount.totalUnreadMessages').html('');
      }, 300); */

      $('.top_item_amount.totalUnreadMessages').html('');

      // ask balance
      const askBalance = result.data.askBalance || 0;
      const askBalanceFormatted = result.data.askBalanceFormatted || "";
      if(askBalance > 0 && askBalanceFormatted != "") {
        this.formData.controls.message.value = `Hello, I have a credit of ${askBalanceFormatted}`;
        setTimeout(() => {
          $(".mes-field").focus();  
        }, 300);
      }
    }
  }

  async onKeydown($evemt: any) {
    if ($evemt.code === "Enter" && this.formData.controls.message.value.trim() !== "") {
      this.formData.controls.message.value = this.formData.controls.message.value.trim();
      await this.onSubmit();
    }
    return false;
  }
}
