
















import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {
	notifier,
	downloadFile,
	printHtml,
	downloadFileUrl,
} from "../models/common";
declare let dataURL: string;
declare const $: any;

@Component({
	inheritAttrs: false,
	components: {
		Pagination,
	},
})
export default class AtCamp extends TSXComponent<void> {
    
}
