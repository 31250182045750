import { render, staticRenderFns } from "./TicketsMessage.vue?vue&type=template&id=4bfe9127&scoped=true&"
import script from "./TicketsMessage.vue?vue&type=script&lang=tsx&"
export * from "./TicketsMessage.vue?vue&type=script&lang=tsx&"
import style0 from "./TicketsMessage.vue?vue&type=style&index=0&id=4bfe9127&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bfe9127",
  null
  
)

export default component.exports