import { render, staticRenderFns } from "./FormYesNo.vue?vue&type=template&id=68fd09c0&scoped=true&"
import script from "./FormYesNo.vue?vue&type=script&lang=tsx&"
export * from "./FormYesNo.vue?vue&type=script&lang=tsx&"
import style0 from "./FormYesNo.vue?vue&type=style&index=0&id=68fd09c0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fd09c0",
  null
  
)

export default component.exports