


































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "../helpers/all";
import NoData from "../components/Common/NoData.vue";
import Loading from "../components/Common/Loading.vue";
import directives from "../helpers/directives";
import Vue from "vue";
import { dollarFormat } from "@/helpers/ApiHelper";
import PageHeader from "@/components/PageHeader.vue";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    Pagination,
    NoData,
    Loading
  },
  directives
})
export default class CompleteApplication extends TSXComponent<void> {
  $router: any;
  $campCartKey: any;
  $loggedUser: any;
  $route: any;
  $swal: any;

  cartItems: any = [];
  unpaidRegistration: any = [];
  selectedToPay: any = [];
  paymentInfoLoading = false;
  paymentInfo: any = {};
  paymentType = "";
  paying = false;

  stripeToken = "";
  stripeLast4 = "";

  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  zipcode = "";
  cvc = "";

  form = {
    cardName: {
      value: "",
      error: ""
    },
    cardNumber: {
      value: "",
      error: ""
    },
    expMoth: {
      value: "",
      error: ""
    },
    expyear: {
      value: "",
      error: ""
    },
    cvc: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    },
  }

  stripeError = 0;
  paymentErrMessage = "";
  cardInfoError = "";
  loading = false;
  discountCode = "";
  applyingDiscount = false;
  availableDiscounts: any = [];
  discountsHasCode: any = [];
  appliedDiscount: any = [];

  beginApplication() {
    $("#applicationCompleteModal").modal("hide");
    this.$router.push({ name: "BeginApplication" });
  }

  hideModals() {
    $("#applicationCompleteModal").modal("hide");
  }

  async created() {
    // load stripe
    $.getScript("https://js.stripe.com/v2/", function (data, textStatus, jqxhr) {
    }).fail(function (jqxhr, settings, exception) {
      // console.log("Stripe load failes");
    });

    await this.fetchData();
  }

  async fetchData() {
    // load cart was saved before
    // this.loadSavedCart();
    this.cartItems = await ApiHelper.loadSavedCart(true);
    await this.getUnpaidRegistration();
  }

  async getUnpaidRegistration() {
    this.loading = true;
    const notSavedItems = this.cartItems.filter((item: any) => (item.participantID || 0) == 0);
    const response = await axios.post(`${dataURL}/unpaidRegistration`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      familyID: this.$loggedUser.familyId,
      tmpProfileIDs: notSavedItems.map((item: any) => item.profile.profileid),
      tmpEventIDs: notSavedItems.map((item: any) => item.eventID)
    });
    let tempProfilesInfo = [];
    if (response.data.status == 1) {
      this.unpaidRegistration = response.data.data.unpaidRegistration;
      tempProfilesInfo = response.data.data.tempProfilesInfo || [];
    }

    // prepend cart items was not save as a participant in unpaid list
    if (notSavedItems.length) {
      for (let i = notSavedItems.length - 1; i >= 0; i--) {
        const item = notSavedItems[i];

        // ignore if notSavedItems was in unpaid items
        const existed = this.unpaidRegistration.find(t => 
          t.eventID == item.eventID && 
          t.participant_typeid == item.pType.participantTypeID && 
          t.profileid == item.profile.profileid
        );
        if(existed) continue;

        const profile: any = tempProfilesInfo.find((p: any) => p.profileid == item.profile.profileid);
        if (profile) {
          let totalCost = item.pType.participantAmount;
          let additionalCosts = [];
          const dynamicAdditionalCosts = 
            additionalCosts.length == 0
                ? []
                : additionalCosts.filter((cost: any) => cost.isFundBucket == 1);

          this.unpaidRegistration.unshift({
            eventID: item.eventID,
            ev_name: item.pType.eventName,
            participant_typeid: item.pType.participantTypeID,
            participant_typename: item.pType.participantTypeName,
            cost: item.pType.participantAmount,
            profileid: profile.profileid,
            p_fname: profile.p_fname || "",
            p_lname: profile.p_lname || "",
            p_logo: profile.p_logo || "",
            age: profile.age || null,
            pRegistrationStep: 0,
            participantID: 0,
            questionsNeeded: 0,
            questionsDone: 0,
            percentComplete: 0,
            costFormatted: item.pType.ParticipantAmount_formated,
            additionalCosts,
            totalCost,
            totalCostFormatted: ApiHelper.dollarFormat(totalCost),
            staticAdditionalCosts: [],                
            dynamicAdditionalCosts: dynamicAdditionalCosts,
            originalDynamicCosts: dynamicAdditionalCosts
          });
        }
      }
    }

    this.discountsHasCode = response.data.data.discountsHasCode || [];
    // append discount info for each registration
    const availableDiscounts = response.data.data.availableDiscounts || [];

    // if(availableDiscounts.length) {
      this.unpaidRegistration = this.unpaidRegistration.map(item => {
        item.discountItems = [];
        let finalCost = item.cost;
        const discount = availableDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.participant_typeid);
        let discountAmount = discount?.discountAmount || 0;
        if(discount && (item.cost - discountAmount) >= 0) {
          // auto discount
          finalCost -= discountAmount;
          finalCost = parseFloat(finalCost.toFixed(2));
          item.discountItems.push({
            discountId: discount.discountId,
            discountName: discount.discountName,
            discountAmount: discount.discountAmount || 0,
            discountAmountFormatted: dollarFormat(discount.discountAmount || 0)
          });
        }else {
          discountAmount = 0;
        }
        item.discountAmount = discountAmount;
        item.discountAmountFormatted = ApiHelper.dollarFormat(item.discountAmount);
        item.discount = discount;
        item.finalCost = finalCost;
        item.finalCostFormatted = dollarFormat(finalCost);

        // if using scholarship as a discount before
        if (
          (item.paidAmount || 0) == 0 &&
          (item.participantDiscountCost || 0) > 0
        ) {
          let pPaymentJson = JSON.parse(item.participant_paymentJson || '{}');
          for(const d of (pPaymentJson.discountInfo || [])) {
            item.discountItems.push({
              discountId: d.discountId,
              discountName: d.discountName || d.discountCode,
              discountAmount: d.discountAmount || 0,
              discountAmountFormatted: dollarFormat(d.discountAmount || 0)
            });
          }
        }

        return item;
      });
    // }

    // remove item paid in cart
    // const finishedAppItems = this.cartItems.filter((item: any) => (item.pRegistrationStep || 0) == 1);
    // if(finishedAppItems.length) {
    //   for(const item of finishedAppItems) {
    //     const inUnpaid = this.unpaidRegistration.find((t: any) => t.participantID == (item.participantID || 0));
    //     if(!inUnpaid) {
    //       // remove this item in cart
    //       this.cartItems = this.cartItems.filter((t: any) => !(
    //         t.eventID == item.eventID &&
    //         t.pType.participantTypeID == item.pType.participantTypeID && 
    //         t.profile.profileid == item.profile.profileid
    //       ));
    //     }
    //   }
    //   ApiHelper.updateCartItems({ cartItems: this.cartItems });
    // }

    this.loading = false;
  }

  arvatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }

  inSelectedToPay(item: any) {
    const selected = this.selectedToPay.find((registration: any) =>
      registration.eventID == item.eventID &&
      registration.participant_typeid == item.participant_typeid &&
      registration.profileid == item.profileid
    );
    return selected ? true : false;
  }

  toggleSelectedToPay(item: any) {
    $('.has-additional-costs.show').trigger('click');
    if (this.inSelectedToPay(item)) {
      this.selectedToPay = this.selectedToPay.filter((registration: any) => !(
        registration.eventID == item.eventID &&
        registration.participant_typeid == item.participant_typeid &&
        registration.profileid == item.profileid)
      );
    } else {
      this.selectedToPay.push(item);
    }
    if (this.selectedToPay.length === 0) {
      const slideup = $(".slideupContainer");
      if (!slideup.hasClass("invisible")) {
        slideup.animate({ height: 0 }, 100, "linear", function () {
          $(".slide_container").removeAttr("style");
          slideup.removeAttr("style");
          slideup.addClass("invisible");
        });
      }
    }
  }

  async getPaymentInfo(selectedItems: any = []) {
    this.paymentInfo = {};
    this.paymentType = "";
    let selectedToPay = this.selectedToPay;
    if (selectedItems.length > 0) {
      selectedToPay = selectedItems;
    }

    if (selectedToPay.length > 0) {
      try {
        const eventIDs = selectedToPay.map((item: any) => item.eventID);
        let totalCost = 0;
        for (const item of selectedToPay) {
          totalCost += item.cost || 0;
        }
        totalCost = parseFloat(totalCost.toFixed(2));

        let totalFund = 0;
        if(totalCost == 0) {
          // check if selected a fund?
          for(const item of selectedToPay) {
            for(const fund of (item.additionalCosts || [])) {
              totalFund += fund.cost;
            }
          }
          totalFund = parseFloat(totalFund.toFixed(2));
        }

        if ((totalCost > 0 || (totalCost + totalFund > 0)) && eventIDs.length) {
          this.paymentInfoLoading = true;
          const response = await axios.post(`${dataURL}/getPaymentInfo`, {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            familyID: this.$loggedUser.familyId,
            eventIDs: eventIDs.join(","),
            total: totalCost,
            hasDeposit: false,
            participants: selectedToPay.map((item: any) => {
              const additionalCosts = item.additionalCosts.map((item: any) => ({
                serviceName: item.serviceName,
                cost: item.cost,
                chargeMonthly: item.chargeMonthly || 0
              }));

              // add total funds
              if((item.totalFunds || 0) > 0) {
                additionalCosts.push({
                  serviceName: "Total Camp Store Funds",
                  cost: item.totalFunds,
                  isTotalFunds: true
                });
              }

              return {
                eventID: item.eventID,
                pTypeID: item.participant_typeid,
                total: item.cost,
                additionalCosts
              };
            })
          });
          if (response.data.status == 1) {
            this.paymentInfo = response.data.data.paymentInfo || {};

            // auto select pay full if have no payment monthly option
            if ((this.paymentInfo.recurringAmount || 0) == 0) {
              this.paymentType = "full";
            }

            // set stripe public key
            if ((this.paymentInfo.stripePublishableKey || "") != "") {
              if (typeof Stripe == "undefined") {
                // load stripe
                await $.getScript("https://js.stripe.com/v2/", function (data, textStatus, jqxhr) {
                }).fail(function (jqxhr, settings, exception) {
                  // console.log("Stripe load failes");
                });
              }
              Stripe.setPublishableKey(this.paymentInfo.stripePublishableKey);
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.paymentInfoLoading = false;
      }
    }
  }

  async payNow() {
    if (this.paying) {
      return;
    }

    if(this.applyFullDiscount()) {
      await this.doPay();
      return;
    }

    this.form.cardName.error = '';
    this.form.cardNumber.error = '';
    this.form.expMoth.error = '';
    this.form.expyear.error = '';
    this.form.cvc.error = '';
    this.form.zipcode.error = '';
    this.paymentErrMessage = '';
    let hasError = false;
    if (this.form.cardName.value === '') {
      this.form.cardName.error = 'Name is required';
      hasError = true;
    }
    if (this.form.cardNumber.value === '') {
      this.form.cardNumber.error = 'Card Number is required';
      hasError = true;
    }
    if (this.form.expMoth.value === '') {
      this.form.expMoth.error = 'Exp Month is required';
      hasError = true;
    }
    if (this.form.expyear.value === '') {
      this.form.expyear.error = 'Exp Year is required';
      hasError = true;
    }
    if (this.form.cvc.value === '') {
      this.form.cvc.error = 'CVC is required';
      hasError = true;
    }
    if (this.form.zipcode.value !== '' && this.form.zipcode.value.length < 5) {
      this.form.zipcode.error = 'Zipcode is invalid';
      hasError = true;
    }
    // validate
    this.paymentErrMessage = "";
    if (this.paymentType == "") {
      this.paymentErrMessage = "Please select a payment option";
    }

    if (this.paymentType != "" && this.selectedToPay.length && (this.paymentInfo.totalCost || 0) > 0) {
      // validate stripe form
      let isValidStripe = !hasError;
      // if (this.cardName != "" && this.cardNumber != "" && this.expMoth != "" && this.expyear != "" && this.cvc != "") {
      //   isValidStripe = true;
      // }
      if (isValidStripe) {
        var $this = this;
        $this.stripeToken = "";
        this.paying = true;
        this.cardInfoError = "";
        try {
          Stripe.card.createToken($("#stripeFrm"), async (status, response) => {
            //Stripe token failure...
            if (response.error) {
              var msg = "card name";
              const stripeMessage = response.error?.message || "";
              if (response.error.param == "number") {
                $this.stripeError = 2;
                this.form.cardNumber.error = 'Card Number is invalid';
                msg = "card number";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_month") {
                $this.stripeError = 3;
                this.form.expMoth.error = 'Exp Month is invalid';
                msg = "month";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_year") {
                $this.stripeError = 4;
                this.form.expyear.error = 'Exp Year is invalid';
                msg = "year";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "cvc") {
                $this.stripeError = 5;
                this.form.cvc.error = 'CVC is invalid';
                msg = "cvc";
                $this.cardInfoError = stripeMessage;
              } else {
                $this.stripeError = 1;
                $this.paymentErrMessage = stripeMessage;
              }
            } else {
              $this.stripeToken = response.id;
              $this.stripeLast4 = response.card.last4;
              $this.stripeError = 0;
            }

            if ($this.stripeToken != "" && $this.stripeError == 0) {
              await this.doPay();
            } else {
              this.paying = false;
            }
          });
        } catch(e) {
          this.paying = false;
          ApiHelper.showErrorMessage('Your payment is unable to be accepted at this time. Please contact your camp administrator.');
        }
      }
    }
  }

  async doPay() {
    try {
      this.paying = true;
      this.disablePaymentForm();
      const paymentInfo = this.paymentInfo;
      if (paymentInfo.stripePublishableKey) {
        delete paymentInfo.stripePublishableKey;
      }
      const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));
      const availableDiscounts = JSON.parse(JSON.stringify(this.availableDiscounts));

      let discounts = JSON.parse(JSON.stringify(this.appliedDiscount));
      const discountHasNoCode = discounts.find(
        (item: any) => item.discountCode == ""
      );
      discounts = discounts.filter((item: any) => item.discountCode != "");

      const requestObj = {
        callFrom: "portal",
        saveParticipant: false,
        uuid: this.$loggedUser.entityUUID,
        applications: selectedToPay.map((item: any) => {
          const additionalCosts = [...(item.additionalCosts || [])];
          if((item.totalFunds || 0) > 0) {
            additionalCosts.push({
              link_event_service_Id: 0,
              addonServiceId: 5,
              serviceName: "Total Camp Store Funds",
              cost: item.totalFunds,
              isFundBucket: 1,
              costFormatted: ApiHelper.dollarFormat(item.totalFunds),
              isTotalFunds: true
            });
          }

          const discountInfo: any = [];
          // apply discount without discount code firstly
          if((item.discountAmount || 0) > 0 && (discountHasNoCode?.appliedFor || []).includes(item.participantID)) {
            const discountAmount = item.discountAmount;
            item.cost -= discountAmount;
            item.cost = parseFloat(item.cost.toFixed(2));
            item.costFormatted = ApiHelper.dollarFormat(item.cost);
            item.totalCost -= discountAmount;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
            if(item.discount) {
              discountInfo.push(item.discount);
            }
          }
          // apply discount if input a discount code
          if(discounts.length) {
            // const discount = availableDiscounts.find((d: any) => d.eventId == item.eventID && d.participant_typeId == item.participant_typeid);
            // const discountAmount = discount?.discountAmount || 0;
            // if(discountAmount > 0 && (discount.maxUse == 0 || (discount.maxUse > 0 && discount.maxUse != discount.totalUsed))) {
            //   // apply if pass the condition
            //   item.cost -= discountAmount;
            //   item.cost = parseFloat(item.cost.toFixed(2));
            //   item.costFormatted = ApiHelper.dollarFormat(item.cost);
            //   item.totalCost -= discountAmount;
            //   item.totalCost = parseFloat(item.totalCost.toFixed(2));
            //   item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

            //   // no send totalUsed in discountInfo
            //   const itemDiscount = {...discount};
            //   if (itemDiscount.totalUsed != undefined) {
            //     delete itemDiscount.totalUsed;
            //   }
            //   discountInfo.push(itemDiscount);

            //   // update totalUsed
            //   discount.totalUsed += 1;
            // }

            for (const discount of discounts) {
              if ((discount.eventId == item.eventID && discount.participant_typeId == item.participant_typeid) == false) {
                continue;
              }

              const discountAmount = discount?.discountAmount || 0;
              if (
                discountAmount > 0 &&
                (discount.maxUse == 0 ||
                  (discount.maxUse > 0 &&
                    discount.maxUse > discount.totalUsed))
              ) {
                // apply if pass the condition
                item.cost -= discountAmount;
                item.cost = parseFloat(item.cost.toFixed(2));
                item.costFormatted = ApiHelper.dollarFormat(item.cost);
                item.totalCost -= discountAmount;
                item.totalCost = parseFloat(item.totalCost.toFixed(2));
                item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

                // no send totalUsed in discountInfo
                const itemDiscount = {...discount};
                if (itemDiscount.totalUsed != undefined) {
                  delete itemDiscount.totalUsed;
                }

                if (itemDiscount.appliedFor) {
                  // remove appliedFor info
                  delete itemDiscount.appliedFor;
                }
                discountInfo.push(itemDiscount);

                // update totalUsed
                discount.totalUsed += 1;
              }
            }
          }

          return {
            sourceType: item.sourceType || 1,
            profileid: item.profileid,
            firstName: item.p_fname || "",
            lastName: item.p_lname || "",
            participantID: item.participantID,
            eventID: item.eventID,
            eventName: item.ev_name || "",
            participantTypeID: item.participant_typeid,
            participantAmount: item.cost,
            pRegistrationStep: item.pRegistrationStep,
            additionalCosts,
            totalCost: item.totalCost,
            discountInfo,
          }
        }),
        type: this.paymentType,
        cardInfo: {
          token: this.stripeToken,
          cardName: this.cardName,
          number: this.cardNumber,
          expMonth: this.expMoth,
          expYear: this.expyear,
          zipCode: this.zipcode,
          last4: this.stripeLast4
        },
        paymentInfo: paymentInfo,
        mainProfileID: this.$loggedUser.id,
        familyID: this.$loggedUser.familyId,
        availableDiscounts: this.availableDiscounts,
        applyFullDiscount: this.applyFullDiscount() ? 1 : 0
      };

      const response = await axios.post(`${dataURL}/registrationComplete`, requestObj);
      if (response.data.status == 1) {
        const paymentResult = response.data.data.paymentResult;
        if (paymentResult.status == 1) {
          // remove related item in cart
          if (this.cartItems.length) {
            for (const item of this.selectedToPay) {
              this.cartItems = this.cartItems.filter((cartItem: any) => !(
                cartItem.eventID == item.eventID &&
                cartItem.profile.profileid == item.profileid &&
                cartItem.pType.participantTypeID == item.participant_typeid
              ));
            }
          }
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
          await ApiHelper.updateProfileStats();

          // go to registration page
          this.hideModals();
          this.$router.push({ name: "Registration" });
          Vue.swal.fire({
            // icon: "success",
            title: 'Registration & Payment Complete',
            customClass: {
              container: "swal2-container-custom",
              confirmButton: "SecondaryColor FontColor"
            }
          });
        } else {
          this.paying = false;
          this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
        }
      } else {
        this.paying = false;
        this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
      }
    } catch (error) {
      this.paying = false;
      this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
    } finally {
      this.enablePaymentForm();
    }
  }

  disablePaymentForm() {
    $("#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button").prop("disabled", true);
  }

  enablePaymentForm() {
    $("#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button").prop("disabled", false);
  }

  validateCardName() {
    // validateCardName
  }

  async removeParticipant(participant: any) {
    // show warning message
    const confirm = await this.$swal.fire({
      // icon: "warning",
      title: "Are you sure?",
      text: "Are you sure you want to remove this registration?",
      confirmButtonText: "Confirm",
      showCloseButton: true,
      customClass: {
        container: "swal2-container-custom",
        confirmButton: "btn button SecondaryColor FontColor"
      }
    });
    if(!confirm.isConfirmed) {
      return;
    }

    // do remove
    if ((participant.participantID || 0) > 0) {
      const response = await axios.post(`${dataURL}/removeParticipant`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantID: participant.participantID,
        familyID: this.$loggedUser.familyId
      });
      if (response.data.status == 1) {
        if ((participant.pRegistrationStep || 0) > 0) {
          await ApiHelper.updateProfileStats();
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.refresh();
        }

        // sync with cart
        const particiantInCart = this.cartItems.find((item: any) => (
          item.eventID == participant.eventID &&
          item.profile.profileid == participant.profileid &&
          item.pType.participantTypeID == participant.participant_typeid
        ));
        if (particiantInCart) {
          this.cartItems = this.cartItems.filter((item: any) => !(
            item.eventID == participant.eventID &&
            item.profile.profileid == participant.profileid &&
            item.pType.participantTypeID == participant.participant_typeid
          ));
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
        }
        // remove in selectedToPay
        if (this.inSelectedToPay(participant)) {
          this.selectedToPay = this.selectedToPay.filter((registration: any) => !(
            registration.eventID == participant.eventID &&
            registration.participant_typeid == participant.participant_typeid &&
            registration.profileid == participant.profileid)
          );
        }

        await this.getUnpaidRegistration();
      }
    } else {
      // case: remove items in cart (item was not saved as a participant yet)
      this.cartItems = this.cartItems.filter((item: any) => !(
        item.eventID == participant.eventID &&
        item.profile.profileid == participant.profileid &&
        item.pType.participantTypeID == participant.participant_typeid
      ));
      ApiHelper.updateCartItems({ cartItems: this.cartItems });

      // remove in unpaid list
      this.unpaidRegistration = this.unpaidRegistration.filter((item: any) => !(
        item.eventID == participant.eventID &&
        item.profileid == participant.profileid &&
        item.participant_typeid == participant.participant_typeid
      ));
    }
  }

  async toggleSlideUp() {
    this.form.cardName.error = '';
    this.form.cardNumber.error = '';
    this.form.expMoth.error = '';
    this.form.expyear.error = '';
    this.form.cvc.error = '';
    this.form.zipcode.error = '';
    this.form.cardName.value = '';
    this.form.cardNumber.value = '';
    this.form.expMoth.value = '';
    this.form.expyear.value = '';
    this.form.cvc.value = '';
    this.form.zipcode.value = '';
    this.paymentErrMessage = '';
    this.cardInfoError = '';

    const slideup = $(".slide_container");
    if (slideup.hasClass("invisible")) {
      this.appliedDiscount = [];
      $("body").addClass("no-scroll");
      this.paymentErrMessage = "";
      var fullHeight = slideup.outerHeight();
      const slideupContainer = $(".slideupContainer");
      const slideupContainerHeight = $(".slideupContainer").outerHeight();
      slideupContainer.css("transition", "height 0.8s ease-in-out");
      slideupContainer.css("height", 0).removeClass("invisible").animate({ height: slideupContainerHeight }, 50, "linear");
      slideup.css("height", 0).removeClass("invisible").animate({ height: fullHeight }, 100, "linear");
      setTimeout(() => {
        slideupContainer.css({"transition": "none", "height": "100vh"});
      }, 850);
      
      this.discountCode = "";
      this.availableDiscounts = [];
      
      // apply discount for selected items
      const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));
      let totalDiscountNoCode = 0;
      const appliedFor: any = [];
      for(const item of selectedToPay) {
        const discountAmount = item.discountAmount || 0;
        if(discountAmount > 0) {
          totalDiscountNoCode += discountAmount;
          item.cost -= discountAmount;
          item.cost = parseFloat(item.cost.toFixed(2));
          item.costFormatted = ApiHelper.dollarFormat(item.cost);
          item.totalCost -= discountAmount;
          item.totalCost = parseFloat(item.totalCost.toFixed(2));
          item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
          appliedFor.push(item.participantID);
        }
      }

      // save applied discount
      if(totalDiscountNoCode > 0) {
        this.appliedDiscount = [{
          discountId: 0,
          discountCode: "",
          discountAmount: parseFloat(totalDiscountNoCode.toFixed(2)),
          appliedFor
        }];
      }

      await this.getPaymentInfo(selectedToPay);
    } else {
      $("body").removeClass("no-scroll");
      slideup.animate({ height: 0 }, 300, "linear", function () {
        slideup.removeAttr("style").addClass("invisible");
        $(".slideupContainer").removeAttr("style").addClass("invisible");
      });
    }
  }

    dynamicCostInput(registration, cost) {
        const regInList = this.unpaidRegistration.find((item: any) => item.eventID == registration.eventID && item.participant_typeid == registration.participant_typeid && item.profileid == registration.profileid);
        if(regInList) {
            // update for additionalCosts
            const additionalCosts = regInList.additionalCosts || [];
            const costInList = additionalCosts.find((item: any) => item.addonServiceId == cost.addonServiceId);
            if(costInList) {
                if(isNaN(cost.cost) || cost.cost < 0) {
                    costInList.cost = 0;
                }else {
                    costInList.cost = parseFloat(cost.cost);    
                }
                costInList.costFormatted = ApiHelper.dollarFormat(costInList.cost);
                cost.costFormatted = costInList.costFormatted;

                // update total cost for the registration in list
                let totalCost = regInList.cost;
                const totalAdditionalCosts = additionalCosts
                    .map((c: any) => c.cost).reduce(
                        (totalAdditionalCosts, cost) => totalAdditionalCosts + cost,
                    );
                totalCost += totalAdditionalCosts;
                totalCost = parseFloat(totalCost.toFixed(2));

                regInList.totalCost = totalCost;
                regInList.totalCostFormatted = ApiHelper.dollarFormat(totalCost);
            }
        }
    }

    dynamicCostBlur(registration, cost) {
        if(isNaN(cost.cost) || cost.cost < 0) {
            cost.cost = 0;
            cost.costFormatted = "$0.00";
        }
    }

  async applyDiscount() {
    if(this.discountCode == "") return;

    if (this.selectedToPay.length > 0) {
      try {
        this.applyingDiscount = true;
        const response = await axios.post(`${dataURL}/getAvailableDiscounts`, {
          participants: this.selectedToPay.map((item: any) => {
            return {
              eventID: item.eventID,
              pTypeID: item.participant_typeid,
            };
          }),
          discountCode: this.discountCode,
        });
        // getPaymentInfo
        if (response.data.status == 1) {
          this.availableDiscounts = response.data.data.availableDiscounts || [];
          if(this.availableDiscounts.length) {
            const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));
            const availableDiscounts = JSON.parse(JSON.stringify(this.availableDiscounts));

            // check if returned available discounts can apply for selectedToPay
            let canApplyDiscountCode = false;
            for(const item of selectedToPay) {
              const discount = availableDiscounts.find((d: any) => d.eventId == item.eventID && d.participant_typeId == item.participant_typeid);
              if(discount) {
                canApplyDiscountCode = true;
                break;
              }
            }
            if(canApplyDiscountCode) {
              // apply auto discount
              const discountHasNoCode = this.appliedDiscount.find(
                (item: any) => item.discountCode == ""
              );
              for(const item of selectedToPay) {
                const discountAmount = item.discountAmount || 0;
                if(discountAmount > 0 && (discountHasNoCode?.appliedFor || []).includes(item.participantID)) {
                  item.cost -= discountAmount;
                  item.cost = parseFloat(item.cost.toFixed(2));
                  item.costFormatted = ApiHelper.dollarFormat(item.cost);
                  item.totalCost -= discountAmount;
                  item.totalCost = parseFloat(item.totalCost.toFixed(2));
                  item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
                }
              }

              // collect discounts can apply and store in appliedDiscounts
              for (const item of selectedToPay) {
                const discount = availableDiscounts.find(
                  (d: any) =>
                    d.eventId == item.eventID &&
                    d.participant_typeId == item.participant_typeid
                );
                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  const inApplied = this.appliedDiscount.find(
                    (t: any) =>
                      t.eventId == discount.eventId &&
                      t.participant_typeId == discount.participant_typeId &&
                      t.discountCode.toLowerCase() == discount.discountCode.toLowerCase()
                  );
                  if (!inApplied) {
                    discount.appliedFor = [item.participantID];
                    this.appliedDiscount.push({ ...discount });
                  } else if (
                    !inApplied.appliedFor.includes(item.participantID)
                  ) {
                    inApplied.appliedFor.push(item.participantID);
                  }

                  // update totalUsed
                  discount.totalUsed += 1;
                }
                }

              // apply discount based on appliedDiscounts
              const discountHasCode = this.appliedDiscount.filter(
                (item: any) => item.discountCode != ""
              );
              for (const discountItem of discountHasCode) {
                const relatedSelected = selectedToPay.filter((item: any) =>
                  discountItem.appliedFor.includes(item.participantID)
                );

                const finalAppliedFor: any = [];
                for (const item of relatedSelected) {
                  const amount =
                    item.cost - discountItem.discountAmount;
                  // prevent if applying discount and make amount < 0
                  if (amount >= 0) {
                    finalAppliedFor.push(item.participantID);

                    item.cost -= discountItem.discountAmount;
                    item.cost = parseFloat(item.cost.toFixed(2));
                    // item.balance -= discountItem.discountAmount;
                    // item.balance = parseFloat(item.balance.toFixed(2));
                    item.totalCost -= discountItem.discountAmount;
                    item.totalCost = parseFloat(item.totalCost.toFixed(2));
                    item.totalCostFormatted = ApiHelper.dollarFormat(
                      item.totalCost
                    );
                  }
                }
                discountItem.appliedFor = finalAppliedFor;
              }

              // remove discounts has empty appliedFor
              this.appliedDiscount = this.appliedDiscount.filter(
                (item: any) => item.appliedFor.length
              );

              // get new paymentInfo
              await this.getPaymentInfo(selectedToPay);

              this.paymentErrMessage = "";
              const inApplied = this.appliedDiscount.find(
                  (item: any) =>
                    item.discountCode.toLowerCase() == this.discountCode.toLowerCase()
                );
              if(inApplied) {
                // show success message
                this.$swal.fire({
                  // icon: "success",
                  text: `Applied discount code ${this.discountCode.toUpperCase()} successfully`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              }else {
                this.$swal.fire({
                  // icon: "warning",
                  html: `Cannot apply discount code ${this.discountCode.toUpperCase()}`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              }
              // clear inputted discount code
              this.discountCode = "";
            }else {
              // cannot apply this discount code for event/ptype in selectedToPay
              this.paymentErrMessage = "Discount code not found";  
            }
          }
        }else {
          const errorCode = response.data.errorCode || "";
          if (errorCode == "DISCOUNT_NOT_FOUND") {
            this.paymentErrMessage = "Discount code not found";
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.applyingDiscount = false;
      }
    }
  }

  discountCodeInput() {
    // 
  }

  showDiscountSection() {
    if(this.discountsHasCode.length == 0) return false;

    let show = false;
    for(const item of this.selectedToPay) {
      const existedDiscount = this.discountsHasCode.find((discount: any) => item.eventID == discount.eventId && item.participant_typeid == discount.pTypeId);
      if(existedDiscount) {
        show = true;
        break;
      }
    }

    return show;
  }

  showAppliedDiscount() {
    if(this.appliedDiscount.length) {
      return true;
    }
    return false;
  }

  getAppliedDiscountInfo() {
    if(this.showAppliedDiscount()) {
      // group by discountCode
      const grouped: any = [];
      for(const item of this.appliedDiscount) {
        const discountCode = (item.discountCode || "").toUpperCase();
        const inGrouped = grouped.find(d => (d.discountCode || "") == discountCode);
        if(inGrouped) {
          continue;
        }
        grouped.push({
          discountCode,
          items: this.appliedDiscount.filter(d => (d.discountCode || "").toUpperCase() == discountCode)
        });
      }

      let codes: any = [];
      for(const group of grouped) {
        const discountCode = group.discountCode;
        let totalAmount = 0;
        for(const discount of group.items) {
          const discountAmount = discount.discountAmount || 0;
          if(discountCode == "") {
            totalAmount += discountAmount;
          }else {
            totalAmount += discountAmount * (discount.appliedFor || []).length;
          }
        }
        const discountAmountFormatted = dollarFormat(totalAmount);
        codes.push(`<strong>${discountCode}</strong> (-${discountAmountFormatted})`);
      }

      return `Applied discount: ${codes.join(", ")}`;
    }

    return "";
  }

  getCheckoutCost(item: any) {
    if (item.participant_status === 4) {
      return ApiHelper.dollarFormat(0);
    }
    if((item.discountAmount || 0) > 0) {
      const totalCost = parseFloat((item.totalCost - item.discountAmount).toFixed(2));
      return ApiHelper.dollarFormat(totalCost);
    }

    return item.totalCostFormatted;
  }

  dollarFormat(number:number) {
    return ApiHelper.dollarFormat(number);
  }

  applyFullDiscount() {
    if((this.paymentInfo.totalCost || 0) == 0) {
      return true;
    }
    return false;
  }

  allowRemoveParticipant(item: any) {
    let ret = false;
    const expiredEvent = item.expiredEvent || 0;
    const pStep = item.pRegistrationStep || 0;
    if(!expiredEvent || (expiredEvent && [0, 1].includes(pStep))) {
      ret = true;
    }

    return ret;
  }
}
