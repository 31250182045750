













import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import Loading from "@/components/Common/Loading.vue";
import PageHeader from "@/components/PageHeader.vue";

declare let dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    Loading
  },
})
export default class TicketsMessage extends TSXComponent<void> {}
