
















































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import Loading from "../components/Common/Loading.vue";
import NoData from "../components/Common/NoData.vue";
import { ApiHelper } from "../helpers/all";
import Pager from "../components/Pager.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";
import AddFundTooltip from "@/components/AddFundTooltip.vue";
import { dollarFormat } from "@/helpers/ApiHelper";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    PageHeader,
    Loading,
    Pagination,
    Pager,
    NoData,
    AddFundTooltip
  }
})
export default class Registration extends TSXComponent<void> {
  $loggedUser: any;
  $route: any;
  $campCartKey: any;
  loading = true;
  loadingMore = false;
  upcomingItems: any = [];
  upcomingSkip = 0;
  upcomingTotalCnt = 0;
  priorItems: any = [];
  priorTotalCnt = 0;
  unpaidRegistration: any = [];
  unpaidRegistrationTotalCnt = 0;
  filters: any = {
    type: "",
    profileId: 0
  };
  cartItems: any = [];
  title = "Registrations";
  $router: any;
  deniedTotalCnt = 0;
  fundTooltipIndexVisible = -1;
  selectedFundToPay: any = [];
  paying = false;
  pageData: any = {
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    }
  };
  paymentInfo: any = {
    cartTotal: 0,
    chargeMonths: 0,
    recurringAmount: 0,
    cartTotalFormatted: "$0.00",
    recurringAmountFormatted: "$0.00"
  };
  autoDiscounts: any = [];

  async created() {
    const cartItems = await ApiHelper.syncCartItems();
    this.autoDiscounts = cartItems?.data?.autoDiscounts || [];
    this.cartItems = await ApiHelper.loadSavedCart();

    if (typeof this.$route.query.type == "string") {
      this.filters.type = this.$route.query.type;
    }
    if (this.$route.query.profileId != undefined) {
      this.filters.profileId = parseInt(this.$route.query.profileId) || 0;
    }
    const currentPage = parseInt(this.$route.query.page || 0);
    if (currentPage > 0) {
      this.pageData.pager.page = currentPage;
    }
    // await this.loadData('1');
    await this.fetchData();

    // set title when filter for a profile
    if (this.$route.query.profileId != undefined) {
      const response = await ApiHelper.apiPost(
        "/profiles/" + this.filters.profileId,
        {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          mainProfileID: this.$loggedUser.id,
          familyID: this.$loggedUser.familyId
        }
      );
      if (response.status === 1) {
        const profile = response.data.profileSetting || {};
        if (profile && profile.p_fname != "") {
          this.title = `${profile.p_fname}'s Registrations`;
        }
      }
    }
  }

  async fetchData() {
    this.loading = true;
    try {
      await this.loadList(
        this.filters.type || "upcoming,prior",
        "unpaidRegistrationTotalCnt,availableDiscounts"
      );

      // get total unpaid registrations
      // this.getUnpaidRegistrationTotalCnt();
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  updateRouters() {
    this.$router
      .replace({
        name: "Registration",
        query: {
          page: (this.pageData.pager.page || 1) + "",
          profileId: this.filters.profileId
            ? this.filters.profileId + ""
            : undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  }

  // scrollToTop(value: number) {
  //   $('html, body').scrollTop(value);
  // }

  gotoPage(page) {
    this.pageData.pager.page = parseInt(page);
    this.updateRouters();
    // this.scrollToTop(0);
    // this.loadList();
  }

  onClickNext() {
    if (this.pageData.pager.page < this.pageData.pager.totalPages) {
      this.pageData.pager.page += 1;
      this.updateRouters();
      // this.scrollToTop(0);
      // this.loadList();
    }
  }

  onClickPrev() {
    if (this.pageData.pager.page > 1) {
      this.pageData.pager.page -= 1;
      this.updateRouters();
      // this.scrollToTop(0);
      // this.loadList();
    }
  }

  getTotalPages() {
    return Math.ceil(
      this.pageData.paging.totalCount / this.pageData.paging.take
    );
  }

  getCurrentPage() {
    return Math.ceil(this.pageData.paging.skip / this.pageData.paging.take) + 1;
  }

  // async loadData(page) {
  //   this.pageData.pager.page = page;
  //   this.loadList();
  // }

  async loadList(registrationTypes = "upcoming", getInfo = "") {
    const loggedUser = ApiHelper.getLoggedUser();
    if (loggedUser.familyId == 0) {
      return;
    }

    this.pageData.isLoading = true;
    this.pageData.paging.skip =
      (this.pageData.pager.page - 1) * this.pageData.paging.take;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost("/registrations", {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: this.$loggedUser.id,
      familyID: this.$loggedUser.familyId,
      // type: "upcoming",
      type: registrationTypes,
      orderBy: 1,
      orderDirection: 2,
      skip: this.pageData.paging.skip,
      take: this.pageData.paging.take,
      filters: this.filters,
      getInfo
    });
    this.pageData.isLoading = false;

    if (result.status === 1) {
      this.deniedTotalCnt = result.data.deniedTotalCnt || 0;
      const typeArray = registrationTypes.split(",");
      // data for upcoming registrations
      if (typeArray.includes("upcoming")) {
        this.pageData.items = result.data.registrations.upcoming.data.map(
          item => {
            item.fullName = item.p_fname
              ? [item.p_fname, item.p_lname].join(" ")
              : "<<No Name>>";
            return item;
          }
        );
        const totalCount = result.data.registrations.upcoming.totalCount || 0;
        this.pageData.paging.totalCount = totalCount;

        this.pageData.pager.totalPages = Math.ceil(
          totalCount / this.pageData.paging.take
        );
        this.pageData.pager.total = totalCount;
        const pagerList: any[] = [];
        for (let i = 0; i < this.pageData.pager.totalPages; i++) {
          const pagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === this.pageData.pager.page
          };
          pagerList.push(pagerItem);
        }
        this.pageData.pager.items = pagerList;

        if (this.pageData.items.length == 0 && this.pageData.pager.page > 1) {
          this.pageData.pager.page = 1;
          this.updateRouters();
        }
      }

      // data for prior registrations
      if (typeArray.includes("prior")) {
        this.priorItems = result.data.registrations.prior.data;
        this.priorTotalCnt = result.data.registrations.prior.totalCount || 0;
      }

      // update more info
      if (getInfo != "") {
        const getInfoArr = getInfo.split(",");
        if (getInfoArr.includes("unpaidRegistrationTotalCnt")) {
          this.unpaidRegistrationTotalCnt =
            result.data.unpaidRegistrationTotalCnt?.totalCount || 0;
          if (this.cartItems.length) {
            const notSavedItems = this.cartItems.filter(
              (item: any) => (item.participantID || 0) == 0
            );
            this.unpaidRegistrationTotalCnt += notSavedItems.length || 0;
          }
        }

        if (
          getInfoArr.includes("availableDiscounts") &&
          typeArray.includes("upcoming")
        ) {
          // append auto discounts for upcomming items
          const availableDiscounts = result.data.availableDiscounts || [];
          for (const item of this.pageData.items || []) {
            if ((item.pRegistrationStep || 0) != 1) continue;
            const discount = availableDiscounts.find(
              discount =>
                discount.eventId == item.eventID &&
                discount.participant_typeId == item.participant_typeID
            );
            let discountAmount = discount?.discountAmount || 0;
            if (discount && item.participantCost - discountAmount >= 0) {
              // append auto discount
              item.discountAmount = discountAmount;
              item.discountAmountFormatted = ApiHelper.dollarFormat(
                item.discountAmount
              );
              item.discount = discount;
            }
          }
        }
      }
    } else {
      ApiHelper.showErrorMessage(result.message || JSON.stringify(result));
    }
  }

  get finalPriorTotalCnt() {
    // const totalCnt = this.pageData.paging.totalCount || 0;
    // const deniedTotalCnt = this.upcomingDeniedTotalCnt || 0;
    // const finalTotalCnt = totalCnt - deniedTotalCnt;
    const finalTotalCnt = this.priorTotalCnt;
    //  - this.deniedTotalCnt;
    return finalTotalCnt > 0 ? finalTotalCnt : 0;
  }

  toggleAddFunds(index: number, registration: any, fund: any) {
    const currentAddonId = this.selectedFundToPay[0]?.addonServiceId || 0;
    const fundItem = JSON.parse(JSON.stringify(fund));
    const regItem = JSON.parse(JSON.stringify(registration));

    if (
      currentAddonId == fundItem.addonServiceId &&
      this.fundTooltipIndexVisible == index
    ) {
      this.fundTooltipIndexVisible = -1;
      this.selectedFundToPay = [];
    } else {
      // prepare selectedToPay
      const selectedToPay = [
        {
          sourceType: 2,
          eventID: regItem.eventID,
          participantID: regItem.participantID,
          profileid: regItem.profileid,
          cost: fundItem.totalFunds || 0,
          addonServiceId: fundItem.addonServiceId,
          serviceName: fundItem.serviceName,
          firstName: regItem.p_fname,
          lastName: regItem.p_lname || "",
          lnkServiceEventId: regItem.eventID,
          isFundBucket: fundItem.isFundBucket,
          pRegistrationStep: regItem.pRegistrationStep || 0,
          chargeMonthly: fundItem.chargeMonthly || 0
        }
      ];
      this.selectedFundToPay = selectedToPay;
      this.fundTooltipIndexVisible = index;
      // setTimeout(() => {
      //   $("#fundAmount").val("").focus();
      // }, 100);
    }
  }

  reloadData() {
    this.fetchData();
  }

  setIsPaying(status = false) {
    this.paying = status;
  }

  gotoFinance(item: any) {
    // console.log("HERE");
    const queryObject: any = {
      payForParticipant: item.participantID
    };
    if ((item.balance || 0) > 0) {
      queryObject.action = "new";
    }
    this.$router.push({ name: "Finance", query: queryObject });
  }

  avatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }

  getParticipantTotalCost(item: any) {
    let totalCost = item.totalCost || item.pType.participantAmount || 0;
    const discount = this.autoDiscounts.find(
      discount =>
        discount.eventId == item.eventID &&
        discount.participant_typeId == item.pType.participantTypeID
    );
    if (discount) {
      const discountAmount = discount.discountAmount || 0;
      if (discountAmount > 0) {
        totalCost -= discountAmount;
      }
    }

    return dollarFormat(totalCost);
  }

  getCartTotal() {
    let total = 0;
    for (const item of this.cartItems) {
      let itemTotal = item.totalCost || item.pType.participantAmount || 0;
      const discount = this.autoDiscounts.find(
        discount =>
          discount.eventId == item.eventID &&
          discount.participant_typeId == item.pType.participantTypeID
      );
      const discountAmount = discount?.discountAmount || 0;
      if (discountAmount > 0) {
        itemTotal -= discountAmount;
        itemTotal = parseFloat(itemTotal.toFixed(2));
      }
      total += itemTotal;
      total = parseFloat(total.toFixed(2));
    }
    return dollarFormat(total);
  }
  async removeParticipant(participant: any) {
    // show warning message
    const confirm = await this.$swal.fire({
      // icon: "warning",
      title: "Are you sure?",
      text: "Are you sure you want to remove this registration?",
      confirmButtonText: "Confirm",
      showCloseButton: true,
      customClass: {
        container: "swal2-container-custom",
        confirmButton: "btn button SecondaryColor FontColor"
      }
    });
    if (!confirm.isConfirmed) {
      return;
    }

    // do remove
    if ((participant.participantID || 0) > 0) {
      const response = await axios.post(`${dataURL}/removeParticipant`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantID: participant.participantID,
        familyID: this.$loggedUser.familyId
      });
      if (response.data.status == 1) {
        if ((participant.pRegistrationStep || 0) > 0) {
          await ApiHelper.updateProfileStats();
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.refresh();
        }

        // sync with cart
        const particiantInCart = this.cartItems.find(
          (item: any) =>
            item.eventID == participant.eventID &&
            item.profile.profileid == participant.profile.profileid &&
            item.pType.participantTypeID == participant.pType.participantTypeID
        );
        if (particiantInCart) {
          this.cartItems = this.cartItems.filter(
            (item: any) =>
              !(
                item.eventID == participant.eventID &&
                item.profile.profileid == participant.profile.profileid &&
                item.pType.participantTypeID ==
                  participant.pType.participantTypeID
              )
          );
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
        }
        // remove in selectedToPay
        // if (this.inSelectedToPay(participant)) {
        //   this.selectedToPay = this.selectedToPay.filter((registration: any) => !(
        //     registration.eventID == participant.eventID &&
        //     registration.participant_typeid == participant.participant_typeid &&
        //     registration.profileid == participant.profileid)
        //   );
        // }
        //
        // await this.getUnpaidRegistration();
      }
    } else {
      // case: remove items in cart (item was not saved as a participant yet)
      this.cartItems = this.cartItems.filter(
        (item: any) =>
          !(
            item.eventID == participant.eventID &&
            item.profile.profileid == participant.profile.profileid &&
            item.pType.participantTypeID == participant.pType.participantTypeID
          )
      );
      ApiHelper.updateCartItems({ cartItems: this.cartItems });
      // remove in unpaid list
      // this.unpaidRegistration = this.unpaidRegistration.filter((item: any) => !(
      //   item.eventID == participant.eventID &&
      //   item.profileid == participant.profileid &&
      //   item.participant_typeid == participant.participant_typeid
      // ));
    }
    
    this.$forceUpdate();
  }
}
