













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "@/helpers/all";
import Vue from "vue";
import Loading from "../components/Common/Loading.vue";
import directives from "../helpers/directives";
import moment from "moment";
import NoData from "../components/Common/NoData.vue";
declare let dataURL: string;
declare const $: any;
import DatePicker from "vue2-datepicker";
import PageHeader from "@/components/PageHeader.vue";
import Modal from "@/components/Common/Modal.vue";
@Component({
  inheritAttrs: false,
  components: {
    Modal,
    PageHeader,
    Pagination,
    Loading,
    NoData,
    DatePicker
  },
  directives,
  beforeRouteLeave(to, from, next) {
    try {
      this.$swal.close();  
    } catch (error) {
      // do nothing
    }
    
    next();
  }
})
export default class NewRegistration extends TSXComponent<void> {
  $router: any;
  $campCartKey: any;
  $loggedUser: any;
  events: any = [];
  members: any = [];
  cartItems: any = [];
  cartItemsStorage: any = [];
  paymentInfo: any = {
    cartTotal: 0,
    chargeMonths: 0,
    recurringAmount: 0,
    cartTotalFormatted: "$0.00",
    recurringAmountFormatted: "$0.00"
  };
  newProfileName = "";
  newProfileDobDay = "";
  newProfileDobMonth = "";
  newProfileDobYear = "";
  newProfileGender = "";

  $route: any;

  filterDate: any = {
    fromDate: "",
    toDate: "",
  }

  genderOptions = [
    {
      id: "1",
      text: "Male",
      selected: false,
    },
    {
      id: "2",
      text: "Female",
      selected: false,
    },
  ];

  filterData = {
    active: false,
  };

  filters = {
    eventName: "",
    minDate: "",
    maxDate: "",
    minAge: "",
    maxAge: "",
    minCost: "",
    maxCost: "",
    genders: "",
    active: 0,
  };
  activeFilterTab = "";
  activeFilterTabMobile = "";
  viewInvitation = false;
  limitProfileId = 0;
  limitEventId = 0;
  limitPTypeId = 0;
  roommateRequestInfo: any = null;
  autoDiscounts: any = [];
  groupInfo: any = {
    groupId: 0
  };
  cancelledParticipants: any = [];
  viewMode = "all"

  beginApplication() {
    const query = this.$route.query;
    if (this.cartItemsStorage.length) {
      let firstApp: any = this.cartItemsStorage[0];
      const queryObj: any = {
        event: firstApp.eventID, 
        type: firstApp.pType.participantTypeID, 
        profile: firstApp.profile.profileid
      };
      if((query.code || "") != "") {
        queryObj.code = query.code;
      }
      if((query.destination || "") != "") {
        queryObj.destination = query.destination;
      }
      if(this.viewInvitation) {
        queryObj.event = this.limitEventId;
        queryObj.type = this.limitPTypeId;
        queryObj.profile = this.limitProfileId;

        const inMembers = this.members.find(item => item.profileid == this.limitEventId);
        if(inMembers) {
          const relatedEvent = (inMembers.joinedEvents || []).find(item => item.eventID == this.limitEventId && item.participant_typeID == this.limitPTypeId);
          const toParticipantId = relatedEvent?.participantID || 0;
          const step = relatedEvent?.pRegistrationStep || 0;
          if(step > 1 && toParticipantId) {
            // paid, go to view application page
            this.$router.push({
              name: "ViewApplication",
              params: {
                participantId: toParticipantId
              },
              query: {
                code: query.code,
                destination: "roommate_request"
              }
            });
            return;
          }
        }
      }
      
      this.$router.push({
        name: "BeginApplication",
        query: queryObj
      });
    }else {
      if(this.viewInvitation) {
        const inMembers = this.members.find(item => item.profileid == this.limitProfileId);
        if(inMembers) {
          const relatedEvent = (inMembers.joinedEvents || []).find(item => item.eventID == this.limitEventId && item.participant_typeID == this.limitPTypeId);
          const toParticipantId = relatedEvent?.participantID || 0;
          const step = relatedEvent?.pRegistrationStep || 0;
          if(step > 1 && toParticipantId) {
            // paid, go to view application page
            this.$router.push({
              name: "ViewApplication",
              params: {
                participantId: toParticipantId
              },
              query: {
                code: query.code,
                destination: "roommate_request"
              }
            });
            return;
          }
        }
      }
    }
  }

  async created() {
    const cartItems = await ApiHelper.syncCartItems();
    this.autoDiscounts = cartItems?.data?.autoDiscounts || [];

    const query = this.$route.query;

    // update filters from url
    this.filters.eventName = query.eventName || "";
    this.filters.minDate = query.minDate || "";
    this.filters.maxDate = query.maxDate || "";
    this.filters.minAge = query.minAge || "";
    this.filters.maxAge = query.maxAge || "";
    this.filters.genders = query.genders || "";
    this.filters.minCost = query.minCost || "";
    this.filters.maxCost = query.maxCost || "";
    this.filterData.active = query.active == "1" ? true : false;
    this.filters.active = this.filterData.active ? 1 : 0;
    if (this.filters.genders) {
      const genderIds = this.filters.genders.split(',');
      this.genderOptions.map((item) => {
        if (genderIds.includes(item.id)) {
          item.selected = true;
        }
      })
    }

    this.filterDate.fromDate = query.minDate && moment(query.minDate).isValid() ? moment(query.minDate).toDate() : undefined;
    this.filterDate.toDate = query.maxDate && moment(query.maxDate).isValid() ? moment(query.maxDate).toDate() : undefined;

    await this.fetchData();

    // auto add to cart if view an invitation
    if((query.code || "") != "") {
      this.viewInvitation = true;
      const eventId = query.event || 0;
      const pTypeId = query.type || 0;
      let profileId = query.profileId || 0;
      const event = this.events.find(item => item.eventID == eventId);
      const pType = (event?.pTypes || []).find(item => item.participantTypeID == pTypeId);
      const profileName = query.profileName.trim() || "";

      // validate if is viewing a valid invitation
      const result = await axios.post(`${dataURL}/getRoommateRequestInfo`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        mainProfileId: this.$loggedUser.id,
        inviteCode: query.code,
        eventId,
        pTypeId,
        profileId
      });
      const rmInfo = result.data.data.roommateRequestInfo || {};
      const requestId = rmInfo.requestId || 0;
      const fromProfileId = rmInfo.invitedProfileId || 0;
      let available = true;

      if(requestId == 0) {
        // || this.members.find(item => item.profileid == fromProfileId)
        available = false;
      }else {
        const toProfileId = rmInfo.profileId || 0;
        if (toProfileId > 0) {
          const inMembers = this.members.find(item => item.profileid == toProfileId);
          if(inMembers) {
            // found, check to auto fix for url
            profileId = toProfileId;

            // update route
            const fullPath = this.$router.resolve({ name: this.$route.name, query: { ...this.$route.query, profileId } });
            window.history.replaceState("", "", fullPath.href);
          }else {
            available = false;
          }
        }
      }
      /*
      if(!available) {
        await this.$swal.fire({
          // icon: "error",
          title: "Oops",
          html: `The invitation is not available`,
          customClass: {
            container: "swal2-container-custom",
            confirmButton: "SecondaryColor FontColor"
          }
        });
        this.$router.push({
          name: "Dashboard"
        });
        return;
      }
      */
      this.roommateRequestInfo = rmInfo;
      const member = this.members.find(item => item.profileid == profileId);
      const profile = this.cartItems.find(item =>
        item.eventID == eventId &&
        item.pTypeID == pTypeId &&
        item.profileID == profileId
      );
      let minAge = 0;
      let maxAge = 0;
      let memberAge = member ? member.age || 0 : 0;
      const eventAges = pType.eventAges || '';
      const arr = (eventAges || '').split('-');
      let hasAgeRange = false;
      if (arr.length == 2) {
        minAge = Number.parseInt(arr[0], 10) || 0;
        maxAge = Number.parseInt(arr[1], 10) || 0;
        hasAgeRange = true;
      } else if (eventAges) {
        const limitAge = Number.parseInt(eventAges, 10) || 0;
        if (limitAge > 0) {
          minAge = limitAge;
        }
      }
      let ageError = '';

      if(hasAgeRange && minAge > 0 && maxAge == 0) {
        // format e.g: 25- (participant age should less than 25)
        if(memberAge > minAge) {
          ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less or equal than <strong>' + minAge + '</strong>, would you like to proceed?';
        }
      }else {
        if (minAge) {
          if (memberAge < minAge) {
            ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be greater than <strong>' + minAge + '</strong>, would you like to proceed?';
          }
        }
        if (maxAge) {
          if (memberAge > maxAge) {
            ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less than <strong>' + maxAge + '</strong>, would you like to proceed?';
          }
        }
      }
      // ignore auto select if selected profile age is outside of the age range
      if (member && ageError != '') {
        ApiHelper.showErrorMessage(`<strong>${member.p_fname + ' ' + member.p_lname}</strong> is outside of the age range for <strong>${event.eventName}</strong>`)
        return;
      }

      if(event && pType && member && !profile) {
        // add to cart
        this.cartItems.push({
          eventID: eventId,
          pTypeID: pTypeId,
          profileID: profileId
        });
        this.buildCartItem(event, pType, member);
        this.updateCart();
      }

      this.limitProfileId = profileId;
      this.limitEventId = eventId;
      this.limitPTypeId = pTypeId;

      this.showLinkModal();
    }
    this.loadList().then(() => {
      // nothing
    }).catch(() => {
      // nothing
    });
    this.createDobOptions();
  }

  popupRoommate = {
    isShow: false,
    profileName: '',
    invitedFname: '',
    invitedLname: '',
    firstName: '',
    lastName: '',
    eventName: '',
    newFirstName: '',
    newFirstNameError: '',
    newLastName: '',
    newLastNameError: '',
    selectedProfileId: '',
    selectedProfileName: '',
    genderError: '',
    dobError: '',
  };

  showLinkModal() {
    const rmInfo = this.roommateRequestInfo || {};
    const query = this.$route.query;
    const profileName = query.profileName.trim() || "";
    if(this.limitProfileId == 0 && profileName != "") {
      const tmp = profileName.split(" ");
      const newFirstName = (tmp[0] || '').trim();
      const newLastName = (tmp[1] || '').trim();
      this.popupRoommate.newFirstName = newFirstName;
      this.popupRoommate.newLastName = newLastName;
      this.popupRoommate.profileName = profileName;
      this.popupRoommate.firstName = rmInfo.firstName;
      this.popupRoommate.lastName = rmInfo.lastName;
      this.popupRoommate.invitedFname = rmInfo.invitedFname;
      this.popupRoommate.invitedLname = rmInfo.invitedLname;
      this.popupRoommate.eventName = rmInfo.eventName;

      // determine if there was a members has the same first name
      const inMembers = this.members.find(item => item.p_fname.toLowerCase() == newFirstName.toLowerCase() && item.p_lname.toLowerCase() == newLastName.toLowerCase());
      if(inMembers) {
        this.popupRoommate.selectedProfileId = inMembers.profileid;
      }
      this.popupRoommate.isShow = true;
      // prepare member options
      /*
      const memberOptions: any = [];
      for(const item of this.members) {
        memberOptions.push(`
          <option data-profile='${JSON.stringify(item)}' value="${item.profileid}">
            ${[item.p_fname, item.p_lname].join(" ")}
          </option>
        `);
      }
      this.$swal.fire({
        // icon: "info",
        html: `
          <div class="text-left">
            <div>
              <strong>${rmInfo.invitedFname} ${rmInfo.invitedLname}</strong> invited ${rmInfo.firstName} ${rmInfo.lastName} to <strong>${rmInfo.eventName}</strong>
            </div>
            <div class="mt-3 new-profile-section${inMembers ? " d-none" : ""}">
              We need to create new person <strong>${profileName}</strong> to continue with this roommate request.
              <div>
                <input type="text" placeholder="First Name" class="form-control mt-3" value="${newFirstName}" id="confirmNewFName" disabled />
                <input type="text" placeholder="Last Name" class="form-control mt-3" value="${newLastName}" id="confirmNewLName" ${newLastName != "" ? "disabled" : ""} />
              </div>
            </div>
            <div class="mt-3 linked-member-section">
              Link this roommate request to an existing member
              <select class="form-select mt-3 select-linked-member">
                <option value="0">--- Select member ---</option>
                ${memberOptions.join("")}
              </select>
            </div>
          </div>
          <div class="swal2-actions-custom">
            <div class="swal2-loader"></div>
            <button type="button" class="swal2-confirm btn button SecondaryColor FontColor confirm-create-profile swal2-styled">
              Create
            </button>
          </div>
        `,
        confirmButtonText: "Create",
        customClass: {
          confirmButton: "btn button SecondaryColor FontColor confirm-create-profile"
        },
        allowOutsideClick: false,
        showConfirmButton: false,
        allowEscapeKey: false
      });
      $(".confirm-create-profile").focus();
      if(inMembers) {
        $(`.select-linked-member option[value=${inMembers.profileid}]`).prop("selected", true).trigger("change");
      }

      // select2
      $(".select-linked-member").select2({
        minimumResultsForSearch: -1,
        dropdownCssClass: "select-linked-member-open",
      });

      */
    }
  }

  mounted() {
    const self: any = this;
    $(document).off("keyup", "#confirmNewFName, #confirmNewLName").on("keyup", "#confirmNewFName, #confirmNewLName", function(e) {
      const obj = $(e.target);
      obj.removeClass("border-danger");
    });
    $(document).off("click", ".confirm-create-profile").on("click", ".confirm-create-profile", async function(e) {
      const finalFName = $("#confirmNewFName").removeClass("border-danger").val().trim();
      const finalLName = $("#confirmNewLName").removeClass("border-danger").val().trim();
      const fullName = `${finalFName} ${finalLName}`;
      const doLink = $(".select-linked-member").val() > 0 ? true : false;

      if (doLink) {
        await self.linkRoommateRequest();
      } else {
        // validate 
        let hasError = false;
        if(finalFName == "") {
          $("#confirmNewFName").addClass("border-danger");
          hasError = true;
        }
        if(finalLName == "") {
          $("#confirmNewLName").addClass("border-danger");
          hasError = true;
        }
        if(!hasError) {
          self.newProfileName = fullName;
          // create new profile and map this roommate request to new profile
          const newProfileId = await self.createProfile(true);
          if(newProfileId) {
            // update route
            const fullPath = self.$router.resolve({ name: self.$route.name, query: { ...self.$route.query, profileId: newProfileId } });
            window.history.replaceState("", "", fullPath.href);
            self.newProfileName = "";

            const confirm = await self.$swal.fire({
              // icon: "success",
              title: "Success",
              html: `Created <strong>${fullName}</strong> successfully.`,
              customClass: {
                container: "swal2-container-custom",
                confirmButton: "btn button SecondaryColor FontColor confirm-create-profile"
              },
              allowOutsideClick: false,
            });
            if(confirm.isConfirmed) {
              const query = self.$route.query;
              setTimeout(() => {
                $(`#tab-ev${query.event}-ptype${query.type}`).click();  
              }, 100);
            }
          }
        }
      }
    });
    $(document).off("change", ".select-linked-member").on("change", ".select-linked-member", async function(e) {
      const obj = $(e.target);
      const selectedMember = $(".select-linked-member option:selected");
      if(obj.val() == 0) {
        $(".confirm-create-profile").html("Create");
      }else {
        const profileInfo = selectedMember.data("profile");
        const firstName = profileInfo.p_fname || "";
        $(".confirm-create-profile").html(`Link to ${firstName}`);
      }
    });
    // custom click on select2 items
    $(document).off("mouseup", ".select-linked-member-open .select2-results__options").on("mouseup", ".select-linked-member-open .select2-results__options", (e) => {
      setTimeout(() => {
        const renderedOption = $(".select2-selection__rendered");
        if(renderedOption?.length) {
          const title = renderedOption.prop("title")?.trim() || "";
          renderedOption.prop("title", title);
        }
      }, 100);
    })
  }

  async createProfileRoommate() {
    const finalFName = this.popupRoommate.newFirstName;
    const finalLName = this.popupRoommate.newLastName;
    const fullName = `${finalFName} ${finalLName}`;
    const doLink = this.popupRoommate.selectedProfileId ? true : false;

    if (doLink) {
      await this.linkRoommateRequest(true);
    } else {
      // validate
      let hasError = false;
      if(finalFName == "") {
        this.popupRoommate.newFirstNameError = 'First Name is required';
        hasError = true;
      }
      if(finalLName == "") {
        this.popupRoommate.newLastNameError = 'First Name is required';
        hasError = true;
      }
      if(this.newProfileGender == "") {
        this.popupRoommate.genderError = 'Gender is required';
        hasError = true;
      }
      if(this.newProfileDobYear == "" || this.newProfileDobMonth == "" || this.newProfileDobDay == "") {
        this.popupRoommate.dobError = 'DOB is required';
        hasError = true;
      }
      if(!hasError) {
        this.newProfileName = fullName;
        // create new profile and map this roommate request to new profile
        const newProfileId = await this.createProfile(true);
        if(newProfileId) {
          // update route
          const fullPath = this.$router.resolve({ name: this.$route.name, query: { ...this.$route.query, profileId: newProfileId } });
          window.history.replaceState("", "", fullPath.href);
          this.newProfileName = "";

          const confirm = await this.$swal.fire({
            // icon: "success",
            title: "Success",
            html: `Created <strong>${fullName}</strong> successfully.`,
            customClass: {
              container: "swal2-container-custom",
              confirmButton: "btn button SecondaryColor FontColor confirm-create-profile"
            },
            allowOutsideClick: false,
          });
          if(confirm.isConfirmed) {
            const query = this.$route.query;
            setTimeout(() => {
              $(`#tab-ev${query.event}-ptype${query.type}`).click();
            }, 100);
          }
          this.popupRoommate.isShow = false;
        }

      }
    }
  }

  changeLinkedMember() {
    if(this.popupRoommate.selectedProfileId) {
      const profileInfo = this.members.find((item) => `${this.popupRoommate.selectedProfileId}` == `${item.profileid}`);
      const firstName = profileInfo.p_fname || "";
      this.popupRoommate.selectedProfileName = firstName;
    } else {
      this.popupRoommate.selectedProfileName = '';
    }
  }

  async linkRoommateRequest(useModal = false) {
    const query = this.$route.query;
    const eventId = query.event;
    const pTypeId = query.type;
    let selectedMember;
    let profileInfo;
    if (useModal) {
      profileInfo = this.members.find((item) => `${this.popupRoommate.selectedProfileId}` == `${item.profileid}`);
    } else {
      selectedMember = $(".select-linked-member option:selected");
      profileInfo = selectedMember.data("profile");
    }

    const toProfileId = profileInfo.profileid;
    const fullName = [profileInfo.p_fname, profileInfo.p_lname].join(" ");
    const inMembers = this.members.find(item => item.profileid == toProfileId)
    let toParticipantId = 0;
    if(inMembers) {
      const relatedEvent = (inMembers.joinedEvents || []).find(item => item.eventID == eventId && item.participant_typeID == pTypeId);
      toParticipantId = relatedEvent?.participantID || 0;
    }

    // prevent if map this request to same person has sent this
    const invitedProfileId = this.roommateRequestInfo.invitedProfileId;
    if(invitedProfileId == toProfileId) {
      await this.$swal.fire({
        title: "Oops",
        html: "Cannot link invite to same person",
        // icon: "error",
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor"
        },
      });
      this.showLinkModal();
      return;
    }
    
    const result = await axios.post(`${dataURL}/linkRoommateRequest`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      invitedCode: query.code,
      toProfileId,
      toParticipantId,
      eventId,
      pTypeId
    });
    if (result.data.status == 1) {
      await this.updateRoommateRequest(toProfileId);

      // update route
      const fullPath = this.$router.resolve({ name: this.$route.name, query: { ...this.$route.query, profileId: toProfileId } });
      window.history.replaceState("", "", fullPath.href);

      if (useModal) {
        this.popupRoommate.isShow = false;
      }
      const confirm = await this.$swal.fire({
        // icon: "success",
        title: "Success",
        html: `Linked to <strong>${fullName}</strong> successfully.`,
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor confirm-create-profile"
        },
        allowOutsideClick: false,
      });
      if(confirm.isConfirmed) {
        setTimeout(() => {
          $(`#tab-ev${query.event}-ptype${query.type}`).click();  
        }, 100);
      }
    }else {
      const message = result.data.message;
      await this.$swal.fire({
        title: "Oops",
        html: message,
        // icon: "error",
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor"
        },
      });
      this.popupRoommate.isShow = false;
    }
  }

  async getAvailableEvents(isSearch = false) {
    this.viewMode = "all";
    const query = this.$route.query;
    const group = (query.group || "").trim();
    this.isProcessing = true
    this.events = [];
    if(isSearch) {
      this.filters.genders = this.genderOptions.filter((item) => item.selected).map(item => item.id).join(',');
      this.filters.active = this.filterData.active ? 1 : 0;
      const queryObject = {
        ...this.$route.query,
        ...this.filters
      };
      const fullPath = this.$router.resolve({ name: this.$route.name, query: queryObject });
      window.history.replaceState("", "", fullPath.href);
    }
    const response = await axios.post(`${dataURL}/sessions`, {
      uuid: this.$loggedUser.entityUUID,
      hasDeposit: false,
      eventId: this.$route.query.event ? Number.parseInt(this.$route.query.event + '', 10) || 0 : 0,
      participantTypeId: this.$route.query.type ? Number.parseInt(this.$route.query.type + '', 10) || 0 : 0,
      filters: this.filters,
      group
    });
    this.isProcessing = false;
    if (response.data.status == 1) {
      const groupInfo = response.data.data.groupInfo || null;
      if(groupInfo) {
        this.groupInfo = groupInfo;
      }

      const availableEvents = response.data.data.availableEvents;

      // format data: group by events
      if (availableEvents.length) {
        for (const ev of availableEvents) {
          const existed = this.events.find(item => item.eventID == ev.eventID);
          if (!existed) {
            this.events.push({
              ...ev,
              show: true,
              eventSDateFormatted_mobile: ev.eventTsStartShortFormatted,
              eventEDateFormatted_mobile: ev.eventTsEndShortFormatted,
              pTypes: availableEvents.filter(item => item.eventID == ev.eventID).map(item => ({...item, show: true}))
            });
          }
        }
      }
    }
  }

  async fetchData() {
    // this.isProcessing = true;
    // get available events
    await this.getAvailableEvents();
    // this.isProcessing = false;
    // const response = await axios.post(`${dataURL}/sessions`, {
    //   uuid: this.$loggedUser.entityUUID,
    //   hasDeposit: false,
    //   eventId: this.$route.query.event ? Number.parseInt(this.$route.query.event + '', 10) || 0 : 0,
    //   participantTypeId: this.$route.query.type ? Number.parseInt(this.$route.query.type + '', 10) || 0 : 0,
    // });
    // this.isProcessing = false;
    // if (response.data.status == 1) {
    //   const availableEvents = response.data.data.availableEvents;

    //   // format data: group by events
    //   if (availableEvents.length) {
    //     for (const ev of availableEvents) {
    //       const existed = this.events.find(item => item.eventID == ev.eventID);
    //       if (!existed) {
    //         this.events.push({
    //           ...ev,
    //           eventSDateFormatted_mobile: ev.eventSDate ? moment(ev.eventSDate).format('MM/DD/YY') : "",
    //           eventEDateFormatted_mobile: ev.eventEDate ? moment(ev.eventEDate).format('MM/DD/YY') : "",
    //           pTypes: availableEvents.filter(item => item.eventID == ev.eventID)
    //         });
    //       }
    //     }
    //   }
    // }

    // get people
    this.loadFamilyMembers().then(() => {
      // nothing
    });

    // load cart was saved before
    await this.loadSavedCart();

    // get registration step of items in cart
    const participantIDs = this.cartItemsStorage.map((item: any) => item.participantID || 0).filter((id: any) => id > 0);
    if (participantIDs.length) {
      const response = await axios.post(`${dataURL}/getRegistrationStep`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantIDs: participantIDs.join(",")
      });
      if (response.data.status == 1) {
        const steps = response.data.data.registrationStep || [];
        const removedItems: any = [];
        for (const item of this.cartItemsStorage) {
          if ((item.participantID || 0) > 0) {
            const currentStep = steps.find((p: any) => p.participantID == item.participantID);
            if (currentStep) {
              item.pRegistrationStep = currentStep.pRegistrationStep || 0;
              if(item.pRegistrationStep > 1) {
                removedItems.push(item);
              }
            }
          }
        }
        // remove paid item in cart
        if(removedItems.length) {
          for(const item of removedItems) {
            this.cartItemsStorage = this.cartItemsStorage.filter((t: any) => t.participantID != item.participantID);
          }
          this.cartItems = this.cartItemsStorage.map((item: any) => ({
            eventID: item.eventID,
            pTypeID: item.pType.participantTypeID,
            profileID: item.profile.profileid
          }));
          this.updateCart(true);
        }
        this.$forceUpdate();
      }
    }
  }

  arvatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }

  async updateCart(save = false) {
    let eventIDs: any = [];
    let total = 0;
    // const cart = JSON.parse(localStorage.getItem(this.$campCartKey));
    // const cartItems = cart.cartItems || [];
    if (this.cartItemsStorage.length) {
      for (const item of this.cartItemsStorage) {
        // paticipant cost after included auto discount
        let participantCost = item.pType.participantAmount;
        const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.pType.participantTypeID);
        if(discount) {
          const discountAmount = discount.discountAmount || 0;
          if(discountAmount > 0) {
            participantCost -= discountAmount;
            participantCost = parseFloat(participantCost.toFixed(2));
          }
        }

        total += participantCost;
        if (!eventIDs.includes(item.eventID)) {
          eventIDs.push(item.eventID);
        }
      }
      const response = await axios.post(`${dataURL}/getPaymentInfo`, {
        uuid: this.$loggedUser.entityUUID,
        domain: "",
        familyID: this.$loggedUser.familyId,
        eventIDs: eventIDs.join(","),
        total: total,
        hasDeposit: false,
        participantIDs: this.cartItemsStorage
          .filter((item: any) => (item.participantID || 0) > 0)
          .map((item: any) => item.participantID)
          .join(","),
        participants: this.cartItemsStorage.map((item: any) => {
          const additionalCosts = (item.addOns || []).map((cost: any) => ({
            serviceName: cost.serviceName,
            cost: cost.cost
          }));

          // add total funds
          if((item.totalFunds || 0) > 0) {
            additionalCosts.push({
              serviceName: "Total Camp Store Funds",
              cost: item.totalFunds,
              isTotalFunds: true
            });
          }
          
          // paticipant cost after included auto discount
          let participantCost = item.pType.participantAmount;
          const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.pType.participantTypeID);
          if(discount) {
            const discountAmount = discount.discountAmount || 0;
            if(discountAmount > 0) {
              participantCost -= discountAmount;
              participantCost = parseFloat(participantCost.toFixed(2));
            }
          }

          return {
            eventID: item.eventID,
            pTypeID: item.pType.participantTypeID,
            total: participantCost,
            additionalCosts
          };
        })
      });
      if (response.data.status == 1) {
        const paymentInfo = response.data.data.paymentInfo;
        this.paymentInfo.cartTotal = paymentInfo.participantAmount || 0;
        this.paymentInfo.chargeMonths = paymentInfo.chargeMonths || 0;
        this.paymentInfo.recurringAmount = paymentInfo.recurringAmount || 0;
        this.paymentInfo.cartTotalFormatted = paymentInfo.participantAmountFormatted || "";
        this.paymentInfo.recurringAmountFormatted = paymentInfo.recurringAmountFormatted || "";

        // update additional costs for items in cart
        this.cartItemsStorage = this.cartItemsStorage.map((item: any) => ({
          ...item,
          totalAdditionalCosts: item.totalAdditionalCosts || 0,
          totalCost: item.totalCost || item.pType.participantAmount || 0,
          totalCostFormatted: item.totalCostFormatted || item.pType.ParticipantAmount_formated || ""
        }));
        const additionalCosts = response.data.data.additionalCosts || [];
        if(additionalCosts.length) {
          let cartTotalAdditionalCosts = 0;
          const participantsInfo = response.data.data.participantsInfo || [];
          for(const item of this.cartItemsStorage) {
              let totalAdditionalCosts = 0;
              let tmp = [];
              let pPaymentJson: any = {};
              if((item.participantID || 0) > 0) {
                const pInfo = participantsInfo.find((p: any) => p.participantID == item.participantID);
                pPaymentJson = pInfo ? JSON.parse(pInfo.participant_paymentJson || '{}') : {};
                const selectedAddOns = pPaymentJson.selectedAddOns || [];
                tmp = additionalCosts.filter((c: any) => {
                  const inSelected = selectedAddOns.find(
                    (selected: any) =>
                      c.addonServiceId == selected.addonServiceId,
                  );
                  if(
                    c.eventId == item.eventID && 
                    (c.ptypeId == 0 || c.ptypeId == item.pType.participantTypeID) && 
                    inSelected
                  ) {
                    Object.assign(c, inSelected);
                    return true;
                  }
                  return false;
                });
              }
              if(tmp.length) {
                totalAdditionalCosts = tmp
                    .map((c: any) => c.cost)
                    .reduce((totalAdditionalCosts, cost) => totalAdditionalCosts + cost);
                totalAdditionalCosts = parseFloat(totalAdditionalCosts.toFixed(2));
              }
              // include total funds
              totalAdditionalCosts += pPaymentJson.totalFunds || 0;

              item.totalAdditionalCosts = totalAdditionalCosts;
              item.totalCost = item.pType.participantAmount + item.totalAdditionalCosts;
              item.totalCost = parseFloat(item.totalCost.toFixed(2));
              item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
              cartTotalAdditionalCosts += totalAdditionalCosts;
          }
          this.paymentInfo.cartTotal += cartTotalAdditionalCosts;
          this.paymentInfo.cartTotalFormatted = ApiHelper.dollarFormat(this.paymentInfo.cartTotal);
        }
      }
    } else {
      this.resetPaymentInfo();
    }

    // save cart
    if(save) {
      localStorage[this.$campCartKey] = JSON.stringify({
        cartItems: this.cartItemsStorage
      });
    }
  }

  async loadSavedCart() {
    if (localStorage[this.$campCartKey]) {
      // const cart = JSON.parse(localStorage[this.$campCartKey]);
      // this.cartItemsStorage = cart.cartItems || [];
      this.cartItemsStorage = await ApiHelper.loadSavedCart();
      this.cartItems = this.cartItemsStorage.map((item: any) => ({
        eventID: item.eventID,
        pTypeID: item.pType.participantTypeID,
        profileID: item.profile.profileid
      }));
      this.updateCart();
    }
  }

  resetPaymentInfo() {
    this.paymentInfo.cartTotal = 0;
    this.paymentInfo.chargeMonths = 0;
    this.paymentInfo.recurringAmount = 0;
    this.paymentInfo.cartTotalFormatted = "$0.00";
    this.paymentInfo.recurringAmountFormatted = "$0.00";
  }

  isProcessing: boolean = true;
  activeTab: string = '';
  isValidNewProfile() {
    if(!this.newProfileName || !this.newProfileDobDay || !this.newProfileDobMonth || !this.newProfileDobYear || !this.newProfileGender) {
      return false;
    }
    return true;
  }
  async createProfile(updateRoommateRequest = false, ptype: any = null, event: any = null) {
    // $(".dropdown-menu.show .registration_new_person_wrapper").addClass("inProgress");
    // $(".dropdown-menu.show .registration_new_person_wrapper button").attr('data-state', $(".dropdown-menu.show .registration_new_person_wrapper button").attr('data-state') === 'inactive' ? 'active' : 'inactive');
    let newProfileId = 0;
    await ApiHelper.sleep(100);

    if (this.newProfileName != "" && !this.isProcessing) {
      this.newProfileName = ApiHelper.stripTags(this.newProfileName);
      const tmp = this.newProfileName.split(" ");
      const newFirstName = tmp[0] || '';
      const newLastName = tmp[1] || '';

      if (newFirstName && newLastName) {

        const existMember = this.members.find((item: any) => {
          return item.p_fname.toLowerCase() === newFirstName.toLowerCase()
          && item.p_lname.toLowerCase() === newLastName.toLowerCase();
        });
        let isConfirmed = true;
        if (existMember) {
          isConfirmed = await Vue.swal({
            title: "Are you sure?",
            html: `<strong>${this.newProfileName}</strong> has already been added are you sure you want to add them again?`,
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!",
            customClass: {
              container: "swal2-container-custom",
            }
          }).then(result => {
            if (result.isConfirmed) {
              return true;
            } else {
              this.newProfileName = '';
              return false;
            }
          });
          setTimeout(() => {
            $('#' + this.activeTab).dropdown('toggle');
          }, 1);
        }

        if (isConfirmed) {
          // if is viewing a roommate request has not tied to any profile yet, and creating a new profile, 
          // check if this new profile is same firstname with profile is invited
          const query = this.$route.query;
          this.isProcessing = true;
          const requestObject: any = {
            uuid: this.$loggedUser.entityUUID,
            fname: newFirstName,
            lname: newLastName,
            dob: this.newProfileDobYear && this.newProfileDobMonth && this.newProfileDobDay ? `${this.newProfileDobYear}-${this.newProfileDobMonth}-${this.newProfileDobDay}` : '',
            gender: this.newProfileGender,
            mainProfileID: this.$loggedUser.id,
            familyID: this.$loggedUser.familyId
          };
          if(updateRoommateRequest) {
            requestObject.updateRoommateRequest = true;
            requestObject.rqCode = query.code || "";
            requestObject.rqToProfileId = query.profileId || 0;
            requestObject.rqEventId = query.event || 0;
            requestObject.rqPTypeId = query.type || 0;
          }

          // $(".newProfileInput").addClass("filledIn");
          // $(".newProfileInput").next().removeClass("d-none");

          const response = await axios.post(`${dataURL}/createProfile`, requestObject);
          if (response.data.status == 1) {
            const resData = response.data.data || {};
            this.members.push({
              "profileid": resData.profileID,
              "p_fname": newFirstName,
              "p_lname": newLastName,
              "p_email": null,
              "p_dob": `${this.newProfileDobYear}-${this.newProfileDobMonth}-${this.newProfileDobDay}`,
              "p_gender": resData.gender,
              "genderName": resData.genderName,
              "p_logo": "",
              "age": resData.age,
              "balance": 0,
              "totalEvents": 0,
              "activeEvents": 0,
              "joinedEvents": []
            });

            // add to cart
            // this.cartItems.push({
            //   eventID: query.event || 0,
            //   pTypeID: query.type || 0,
            //   profileID: query.profileId || 0
            // });
            // this.buildCartItem(query.event, query.type, query.profileId);
            // this.updateCart(true);

            this.newProfileName = "";
            this.newProfileDobDay = "";
            this.newProfileDobMonth = "";
            this.newProfileDobYear = "";
            this.newProfileGender = "";

            // update total members stats
            this.$loggedUser.stats.TotalMembers += 1;
            ApiHelper.saveLoggedUser(this.$loggedUser);
            const pageHeader: any = this.$refs.pageHeader;
            pageHeader.refresh();

            if(updateRoommateRequest) {
              await this.updateRoommateRequest(response.data.data.rqToProfileId);
            }

            setTimeout(() => {
              const container: any = $(".registration_plus_participants.show .ps");
              if(!container.length) return;
              container.animate({ scrollTop: container.scrollTop() - container.offset().top + container.find('.registratoin_participants_list:last').offset().top }, {
                duration: 'medium',
                easing: 'swing'
              });
            }, 1000);

            newProfileId = response.data.data.profileID;
            if (ptype && event) {
              setTimeout(() => {
                $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${newProfileId}`).trigger('click');
              }, 100);
            }

          }
        }
        this.isProcessing = false;
      } else {
        this.isProcessing = false;
        await Vue.swal({
          title: "Oops",
          html: `Your name is too short. It should include first name and last name.`,
          // icon: "error"
        });
        setTimeout(() => {
          $('#' + this.activeTab).dropdown('toggle');
        }, 50);

      }

    }

    return newProfileId;
  }

  async buildCartItem(event, ptype, member, type = '') {
    const exsited = this.cartItems.find((item: any) =>
      item.eventID == event.eventID &&
      item.pTypeID == ptype.participantTypeID &&
      item.profileID == member.profileid
    );
    if (exsited) {
      const sameEvent = member.joinedEvents.find((session: any) => {
        return session.eventID == event.eventID;
      });

      if (sameEvent) {
        const message =
          "<strong>" +
          (member
            ? member.p_fname +
            " " +
            member.p_lname
            : "") +
          "</strong> is already registered for <strong>" +
          sameEvent.eventName +
          "</strong> as a <strong>" +
          sameEvent.participantTypeName +
          "</strong>, would you like to proceed?";
        Vue.swal({
          title: "Are you sure?",
          html: message,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, do it!",
          customClass: {
            container: "swal2-container-custom",
          }
        }).then(result => {
          if (result.isConfirmed) {
            // add
            this.cartItemsStorage.push({
              eventID: event.eventID,
              profile: {
                profileid: member.profileid,
                p_fname: member.p_fname,
                p_lname: member.p_lname,
                p_logo: member.p_logo,
                age: member.age
              },
              pType: {
                participantTypeID: ptype.participantTypeID,
                participantTypeName: ptype.participantTypeName,
                participantAmount: ptype.participantAmount,
                eventName: ptype.eventName,
                ParticipantAmount_formated: ptype.ParticipantAmount_formated
              },
              groupId: this.groupInfo?.groupId || 0
            });
            // save cart
            localStorage[this.$campCartKey] = JSON.stringify({
              cartItems: this.cartItemsStorage
            });
            this.updateCart();
          } else {
            if (type == 'mobile') {
              $(`#mobile-ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).prop('checked', false);
            } else {
              $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).prop('checked', false);
            }
          }
          if (type == 'mobile') {
            const button = $(`#mobile-ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).parents('.registration_plus').find('.registration_plus_btn');
            setTimeout(() => {
              button.dropdown('toggle');
            }, 50);
          } else {
            const button = $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).parents('.registration_plus').find('.registration_plus_btn');
            setTimeout(() => {
              button.dropdown('toggle');
            }, 50);
          }
        });
      } else {
        // add
        this.cartItemsStorage.push({
          eventID: event.eventID,
          profile: {
            profileid: member.profileid,
            p_fname: member.p_fname,
            p_lname: member.p_lname,
            p_logo: member.p_logo,
            age: member.age
          },
          pType: {
            participantTypeID: ptype.participantTypeID,
            participantTypeName: ptype.participantTypeName,
            participantAmount: ptype.participantAmount,
            eventName: ptype.eventName,
            ParticipantAmount_formated: ptype.ParticipantAmount_formated
          },
          groupId: this.groupInfo?.groupId || 0
        });
        // save cart
        localStorage[this.$campCartKey] = JSON.stringify({
          cartItems: this.cartItemsStorage
        });
        this.updateCart();
      }

      // re-register for a cancelled participant
      const inCancelledList = this.cancelledParticipants.find(item => item.eventId == event.eventID && item.pTypeId == ptype.participantTypeID && item.profileId == member.profileid);
      if(inCancelledList) {
        // show confirm message
        const confirm = await this.$swal.fire({
          text: `${[member.p_fname, member.p_lname].join(" ")} was cancelled before. Are you sure you want to register again?`,
          confirmButtonText: "Yes, do it!",
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            confirmButton: "btn button SecondaryColor FontColor",
            cancelButton: "cancel-btn",
            container: "swal2-container-custom",
          }
        });
        if(confirm.isConfirmed) {
          // update participant status
          await axios.post(`${dataURL}/registerAgain`, {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            familyId: this.$loggedUser.familyId,
            participantId: inCancelledList.participantId
          });
        }else {
          // remove out of cart
          if (type == 'mobile') {
            // $(`#mobile-ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).prop('checked', false);
            $(`#mobile-ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).click();
          } else {
            // $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).prop('checked', false);
            $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).click();
          }
        }
        if (type == 'mobile') {
          const button = $(`#mobile-ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).parents('.registration_plus').find('.registration_plus_btn');
          setTimeout(() => {
            button.dropdown('toggle');
          }, 50);
        } else {
          const button = $(`#ev${event.eventID}-ptype${ptype.participantTypeID}-profile${member.profileid}`).parents('.registration_plus').find('.registration_plus_btn');
          setTimeout(() => {
            button.dropdown('toggle');
          }, 50);
        }
      }
    } else {
      // remove
      this.cartItemsStorage = this.cartItemsStorage.filter((item: any) => !(
        item.eventID == event.eventID &&
        item.pType.participantTypeID == ptype.participantTypeID &&
        item.profile.profileid == member.profileid
      ));
    }

    // save cart
    localStorage[this.$campCartKey] = JSON.stringify({
      cartItems: this.cartItemsStorage
    });
  }

  isValidCapacity(section, profile) {
    const profileGender = profile.p_gender;
    switch (profileGender) {
      case 1: {
          if (section.participantTypeMaleCapacity == null) {
            return true;
          } else {
            const participantTypeMaleCapacity = section.participantTypeMaleCapacity || 0;
            if (participantTypeMaleCapacity == 0) {
              return false;
            }
          }
        }
        break;
      case 2: {
        if (section.participantTypeFemaleCapacity == null) {
          return true;
        } else {
          const participantTypeFemaleCapacity = section.participantTypeFemaleCapacity || 0;
          if (participantTypeFemaleCapacity == 0) {
            return false;
          }
        }
      }
    }
    return true;
  }

  isValidAge(age: number, range: string) {
    const eventAges = range || '';
    const arr = (eventAges || '').split('-');
    let hasAgeRange = false;
    let minAge = 0;
    let maxAge = 0;
    if (arr.length == 2) {
      minAge = Number.parseInt(arr[0], 10) || 0;
      maxAge = Number.parseInt(arr[1], 10) || 0;
      hasAgeRange = true;
    } else if (eventAges) {
      const limitAge = Number.parseInt(eventAges, 10) || 0;
      if (limitAge > 0) {
        minAge = limitAge;
      }
    }
    if(hasAgeRange && minAge > 0 && maxAge == 0) {
      // format e.g: 25- (participant age should less than 25)
      if(age > minAge) {
        return false;
      }
    } else {
      if (minAge) {
        if (age < minAge) {
          return false;
        }
      }
      if (maxAge) {
        if (age > maxAge) {
          return false;
        }
      }
    }
    return true;
  }

  allowAddCartItem(eventID, pTypeID, profileID) {
    let allowAdd = true;
    // check if this profile joined the event
    const profile = this.members.find((item: any) => item.profileid == profileID);
    if (profile) {
      const joinedEvents = profile.joinedEvents || [];
      const joined = joinedEvents.find((item: any) =>
        item.eventID == eventID &&
        item.participant_typeID == pTypeID &&
        item.profileID == profileID
      );
      if (joined) {
        allowAdd = false;
      }
    }

    // if registering under a group, check capacity/capacityFemale/capacityMale condition
    if(this.groupInfo.groupId > 0 && profile) {
      const link = (this.groupInfo.groupLinks || []).find(item => item.eventId == eventID && item.pTypeId == pTypeID);
      if(link) {
        // check capacityFemale
        if(profile.p_gender == 2 && link.capacityFemale > 0 && link.regFemaleCnt + 1 > link.capacityFemale) {
          allowAdd = false;
        }

        // check capacityMale
        if(profile.p_gender == 1 && link.capacityMale > 0 && link.regMaleCnt + 1 > link.capacityMale) {
          allowAdd = false;
        }
      }
    }

    return allowAdd;
  }

  isCartItem(eventID, pTypeID, profileID) {
    const cart: any = localStorage.getItem(this.$campCartKey);
    if (cart) {
      // check if in cart already
      const check = cart.find((item: any) => item.eventID = eventID &&
        item.pType.participantTypeID == pTypeID &&
        item.profile.profileid == profileID);
      if (check) {
        return true;
      }
    }

    return false;
  }

  closeMembersDropdown() {
    $(".registration_plus_btn.dropdown-toggle.show").dropdown('toggle');
  }

  async continueApplication() {
    // const eventIds = [... new Set(this.cartItems.map(item => item.eventID))].join(",");
    // let forcePayment = false;
    // if(this.cartItems.length > 1) {
    //   const responseEventSettings = await axios.post(`${dataURL}/eventSettings`, {
    //     domain: ApiHelper.getDomain(),
    //     uuid: ApiHelper.getUuid(),
    //     eventIds,
    //     settingType: "forcepayment"
    //   });
    //   if(responseEventSettings.data.status == 1) {
    //     forcePayment = responseEventSettings.data.data.forcePayment || false;
    //   }
    // }

    let firstApp: any = this.cartItems[0];
    this.$router.push({
      name: "BeginApplication",
      query: {
        event: firstApp.eventID, 
        type: firstApp.pTypeID, 
        profile: firstApp.profileID
      }
    });

    // if(forcePayment) {
    //   // goto fist app
    //   let firstApp: any = this.cartItems[0];
    //   this.$router.push({
    //     name: "BeginApplication",
    //     query: {
    //       event: firstApp.eventID, 
    //       type: firstApp.pTypeID, 
    //       profile: firstApp.profileID
    //     }
    //   });
    // }else {
    //   this.$router.push({
    //     name: "CompleteApplication",
    //   });
    // }
  }

  getEventDateRange(start: string, end: string) {
    const ret: any = [];
    if(start) {
      ret.push(start);
    }
    if(end) {
      ret.push(end);
    }
    return ret.join(" - ");
  }

  resetActiveFilterTab() {
    this.activeFilterTab = "";
    this.activeFilterTabMobile = "";
  }

  async filterEvents() {
    try {
      this.isProcessing = true;
      await this.getAvailableEvents(true);
    } catch (error) {
      // console.log(error);
    }finally {
      this.isProcessing = false;
    }
  }

  setActiveFilterTab(activeTab: string) {
    this.activeFilterTab = this.activeFilterTab == activeTab ? "" : activeTab;
  }

  hideFilterOptions(e: any) {
    if($(e.target).hasClass("filter-icon") || 
      $(e.target).closest(".filter__options").length
    ) return;

    this.activeFilterTab = "";
  }

  hideMobileFilterOptions() {
    this.activeFilterTabMobile = "";
  }

  resetFilterEvents(type: string) {
    if(type == "eventName") {
      this.filters.eventName = "";
    } else if(type == "date") {
      if(this.filters.minDate == "" && this.filters.maxDate == "") return;
      this.filters.minDate = "";
      this.filters.maxDate = "";
      this.filterDate.fromDate = undefined;
      this.filterDate.toDate = undefined;
    } else if(type == "age") {
      if(this.filters.minAge == "" && this.filters.maxAge == "") return;
      this.filters.minAge = "";
      this.filters.maxAge = "";
    } else if(type == "cost") {
      if(this.filters.minCost == "" && this.filters.maxCost == "") return;
      this.filters.minCost = "";
      this.filters.maxCost = "";
    }
    this.resetActiveFilterTab();
    this.filterEvents();
  }

  filterEventNameInput() {
    if(this.filters.eventName == "") {
      this.filterEvents();
    }
  }

  dateFilterEvents(mobileMode = false) {
    // validate input date
    let fromDateId = mobileMode ? "mobileFromDate" : "fromDate";
    let toDateId = mobileMode ? "mobileToDate" : "toDate";
    const fromDate = $(`#${fromDateId}`);
    const toDate = $(`#${toDateId}`);
    let fromDateBadInput = false;
    let toDateBadInput = false;
    if(fromDate.length) {
      fromDateBadInput = fromDate.get(0).validity?.badInput || false;
    }
    if(toDate.length) {
      toDateBadInput = toDate.get(0).validity?.badInput || false;
    }
    if(fromDateBadInput || toDateBadInput) {
      return;
    }

    this.resetActiveFilterTab();
    this.filterEvents();
  }

  async selectAllFilterGenders() {
    this.genderOptions.map((item) => {
      item.selected = true;
    });
    this.setActiveFilterTab('');
    await this.getAvailableEvents(true);
  }
  async resetFilterGenders() {
    this.genderOptions.map((item) => {
      item.selected = false;
    });
    this.setActiveFilterTab('');
    await this.getAvailableEvents(true);
  }
  async applyFilterGender() {
    this.setActiveFilterTab('');
    await this.getAvailableEvents(true);
  }

  async applyFilterActive() {
    this.setActiveFilterTab('');
    await this.getAvailableEvents(true);
  }

  onToDateChange(value: any) {
    this.disableClickOutside = true;
    this.disableClickOutsideMobile = true;
    this.filters.maxDate = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '';
    if(this.filterDate.fromDate > this.filterDate.toDate) {
      this.filterDate.toDate = '';
    }
  }

  onCloseDatetimePicker() {

  }

  onFromDateChange(value: any) {
    this.disableClickOutside = true;
    this.disableClickOutsideMobile = true;
    this.filters.minDate = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '';
  }

  onClickDatetimePicker() {
    this.disableClickOutside = true;
    this.disableClickOutsideMobile = true;
  }

  disableClickOutside = false;
  disableClickOutsideMobile = false;

  onClickOutsideDate() {
    setTimeout(() => {
      if (!this.disableClickOutside && this.activeFilterTab === 'date') {
        if ($('.mx-datepicker-popup').length === 0) {
          this.setActiveFilterTab('');
        }
      }
      this.disableClickOutside = false;
    }, 200);
  }

  onClickOutsideDateMobile() {
    setTimeout(() => {
      if (!this.disableClickOutsideMobile && this.activeFilterTabMobile === 'date') {
        if ($('.mx-datepicker-popup').length === 0) {
          this.activeFilterTabMobile = '';
        }
      }
      this.disableClickOutsideMobile = false;
    }, 200);
  }

  onClickOutsideAge() {
    if (this.activeFilterTab === 'age') {
      this.setActiveFilterTab('');
    }
  }
  onClickOutsideEvent() {
    if (this.activeFilterTab === 'event') {
      this.setActiveFilterTab('');
    }
  }

  onClickOutside(filterKey: string) {
    if (this.activeFilterTab === filterKey) {
      this.setActiveFilterTab('');
    }
  }

  cartContains(profileId, eventId, pTypeId) {
    if(!this.cartItems.length) return false;
    const inCart = this.cartItems.find(item => item.profileID == profileId && item.eventID == eventId && item.pTypeID == pTypeId);
    if(inCart) {
      return true;
    }

    return false;
  }

  async updateRoommateRequest(profileId: number) {
    const query = this.$route.query;
    this.limitProfileId = profileId;
    const eventId = query.event || 0;
    const pTypeId = query.type || 0;
    const event = this.events.find(item => item.eventID == eventId);
    const pType = (event?.pTypes || []).find(item => item.participantTypeID == pTypeId);
    const member = this.members.find(item => item.profileid == this.limitProfileId);
    if(event && pType && member && !this.cartContains(this.limitProfileId, eventId, pTypeId)) {
      // add to cart
      this.cartItems.push({
        eventID: eventId,
        pTypeID: pTypeId,
        profileID: this.limitProfileId
      });
      this.buildCartItem(event, pType, member);
      this.updateCart(true);
    }
  }

  getParticipantTotalCost(item: any) {
    const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.pType.participantTypeID);
    if(discount) {
      const discountAmount = discount.discountAmount || 0;
      if(discountAmount > 0) {
        const totalCost = item.totalCost - discountAmount;
        return ApiHelper.dollarFormat(totalCost);
      }
    }

    return item.totalCostFormatted;
  }

  pageData: any = {
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    }
  };
  async loadList(registrationTypes = "upcoming", getInfo = "") {
    const loggedUser = ApiHelper.getLoggedUser();
    if (loggedUser.familyId == 0) {
      return;
    }

    this.pageData.isLoading = true;
    this.pageData.paging.skip = (this.pageData.pager.page - 1) * this.pageData.paging.take;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost('/registrations', {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: loggedUser.id,
      familyID: loggedUser.familyId,
      type: registrationTypes,
      orderBy: 1,
      orderDirection: 2,
      skip: this.pageData.paging.skip,
      take: this.pageData.paging.take,
      filters: {
        type: "",
        profileId: 0,
      },
      getInfo
    });
    this.pageData.isLoading = false;

    if (result.status === 1) {
      const typeArray = registrationTypes.split(',');
      if (typeArray.includes("upcoming")) {
        const totalCount = result.data.registrations.upcoming.totalCount || 0;
        this.pageData.paging.totalCount = totalCount;
      }
    }
  }
  async autoBlur($event){
    if($event.key == "Backspace" || $event.key == "Delete"){
      if($event.target.parentElement.previousElementSibling != null && $event.target.previousSibling != null){
        $event.target.previousSibling.focus();
      }
      else if($event.target.previousSibling == null){
        $event.target.parentElement.previousElementSibling.lastChild.focus();
      }
    }
    else{
      if($event.target.nextSibling == null){
        $event.target.parentElement.nextElementSibling.firstChild.focus();
      }
      else{
        $event.target.nextSibling.focus();
      }
    }
  }
  days: any = [];
  months: any = [];
  years: any = [];
  createDobOptions() {
    const current = moment();
    const beginY = current.year() - 91;
    for (let _y = current.year(); _y >= beginY ; _y--) {
      this.years.push(_y);
    }
    for (let _m = 1; _m <= 12; _m++) {
      this.months.push(_m);
    }
    for (let _d = 1; _d <= 31; _d++) {
      this.days.push(_d);
    }
  }

  async updateProfile(member) {
    this.isProcessing = true;
    const loggedUser = ApiHelper.getLoggedUser();
    await ApiHelper.apiPost("/updateProfile", {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: member.profileid,
      mainProfileID:
        loggedUser.id !== member.profileid ? loggedUser.id : 0,
      dob: `${member.dobYear}-${member.dobMonth}-${member.dobDay}`,
      gender: member.gender,
    });
    await this.loadFamilyMembers();
    this.isProcessing = false;
  }

  async loadFamilyMembers() {
    const result = await ApiHelper.apiPost(`/familyMembers`, {
      familyID: this.$loggedUser.familyId,
      getAll: true
    });
    if (result.status == 1) {
      this.members = result.data.members.map((item) => {
        return {...item, dobYear: '', dobMonth: '', dobDay: '', gender: ''}
      });
      this.cancelledParticipants = result.data.cancelledParticipants || [];
    }
  }
  async removeParticipant(participant: any) {
    // show warning message
    const confirm = await this.$swal.fire({
      // icon: "warning",
      title: "Are you sure?",
      text: "Are you sure you want to remove this registration?",
      confirmButtonText: "Confirm",
      showCloseButton: true,
      customClass: {
        container: "swal2-container-custom",
        confirmButton: "btn button SecondaryColor FontColor"
      }
    });
    if (!confirm.isConfirmed) {
      return;
    }

    // do remove
    if ((participant.participantID || 0) > 0) {
      const response = await axios.post(`${dataURL}/removeParticipant`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantID: participant.participantID,
        familyID: this.$loggedUser.familyId
      });
      if (response.data.status == 1) {
        if ((participant.pRegistrationStep || 0) > 0) {
          await ApiHelper.updateProfileStats();
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.refresh();
        }

        // sync with cart
        const particiantInCart = this.cartItems.find(
          (item: any) =>
            item.eventID == participant.eventID &&
            item.profile.profileid == participant.profile.profileid &&
            item.pType.participantTypeID == participant.pType.participantTypeID
        );
        if (particiantInCart) {
          this.cartItems = this.cartItems.filter(
            (item: any) =>
              !(
                item.eventID == participant.eventID &&
                item.profile.profileid == participant.profile.profileid &&
                item.pType.participantTypeID ==
                  participant.pType.participantTypeID
              )
          );
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
        }
      }
    } else {
      this.cartItems = this.cartItems.filter(
        (item: any) =>
          !(
            item.eventID == participant.eventID &&
            item.profile.profileid == participant.profile.profileid &&
            item.pType.participantTypeID == participant.pType.participantTypeID
          )
      );
      ApiHelper.updateCartItems({ cartItems: this.cartItems });
    }
    this.$forceUpdate();
  }

  removeGroup() {
    this.$router.replace({ name: this.$route.NewRegistration });
  }
}
