





























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

interface Props {
  data: any;
  isDisabled?: boolean;
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class FormYesNo extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false })
  isDisabled?: boolean = false;

  randomId = ApiHelper.randomFormId();
}
