































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "../helpers/all";
import directives from "../helpers/directives";
import axios from "axios";

declare let dataURL: string;

interface Props {}
interface Events {}
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {},
  directives
})
export default class PaymentModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  selectedToPay?: any;

  $loggedUser: any;
  paymentInfo: any = {};
  paymentType = "";
  totalCheckout = 0;
  paymentInfoLoading = false;
  discountsHasCode: any = [];
  appliedDiscount: any = [];
  paymentErrMessage = "";
  cardInfoError = "";
  form = {
    cardName: {
      value: "",
      error: ""
    },
    cardNumber: {
      value: "",
      error: ""
    },
    expMoth: {
      value: "",
      error: ""
    },
    expyear: {
      value: "",
      error: ""
    },
    cvc: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    }
  };
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  zipcode = "";
  paying = false;
  stripeToken = "";
  stripeError = 0;
  stripeLast4 = "";
  cartItems: any = [];
  outstandingBalanceFmt = "";

  async created() {
    this.cartItems = await ApiHelper.loadSavedCart();
    this.setIsPaying(true);
    await this.getPaymentInfo(this.selectedToPay);
    this.setIsPaying(false);
  }

  async getPaymentInfo(selectedItems: any = []) {
    this.paymentInfo = {};
    this.paymentType = "";
    this.totalCheckout = 0;

    // $(".has-additional-costs.show").click();

    let selectedToPay = this.selectedToPay;
    if (selectedItems.length > 0) {
      selectedToPay = selectedItems;
    }

    if (selectedToPay.length > 0) {
      try {
        let totalCost = 0;
        for (const item of selectedToPay) {
          if ((item.sourceType || 1) == 1) {
            // total of registration cost
            totalCost +=
              item.registrationStep > 1 ? item.cost || 0 : item.participantCost;
          }
        }
        totalCost = parseFloat(totalCost.toFixed(2));

        let totalFund = 0;
        if (totalCost == 0) {
          // check if selected a fund?
          for (const item of selectedToPay) {
            if (
              (item.sourceType || 1) == 1 &&
              [0, 1].includes(item.registrationStep)
            ) {
              totalFund += parseFloat(item.totalFunds || 0);
              for (const fund of item.additionalCosts || []) {
                totalFund += fund.cost;
              }
            } else if ((item.sourceType || 1) == 2) {
              totalFund += isNaN(parseFloat(item.cost))
                ? 0
                : parseFloat(item.cost);
            }
          }
          totalFund = parseFloat(totalFund.toFixed(2));
        }

        if (totalCost > 0 || totalCost + totalFund > 0) {
          this.totalCheckout = totalCost + totalFund;
          // collect event ID
          const eventIDs = selectedToPay
            .map((item: any) => {
              if (item.sourceType == 2) return 0;

              let tempIDs = item.eventID || 0;
              if (item.participants && item.participants.length) {
                tempIDs = item.participants
                  .map((p: any) => p.eventID)
                  .join(",");
              }
              return tempIDs;
            })
            .join(",")
            .split(",");

          let participants = [];
          // const registrations = eventIDs.filter(id => id > 0);
          // if (registrations.length) {
          // send participants info if selected items has at least a registration
          participants = selectedToPay.map((item: any) => {
            let total = 0;
            let additionalCosts: any = [];
            if (item.sourceType == 2) {
              // fund bucket
              total = parseFloat(item.cost);
              additionalCosts = [];
            } else {
              // normal registrations
              total =
                item.registrationStep > 1 ? item.cost : item.participantCost;
              additionalCosts =
                item.registrationStep > 1
                  ? []
                  : item.additionalCosts.map((item: any) => ({
                      serviceName: item.serviceName,
                      cost: item.cost
                    }));

              // add total funds
              if ((item.totalFunds || 0) > 0) {
                additionalCosts.push({
                  serviceName: "Total Camp Store Funds",
                  cost: item.totalFunds,
                  isTotalFunds: true
                });
              }
            }

            return {
              sourceType: item.sourceType,
              eventID: item.eventID,
              pTypeID: item.participant_typeid || 0,
              total,
              additionalCosts
            };
          });
          // }

          this.paymentInfoLoading = true;
          const response = await axios.post(`${dataURL}/getPaymentInfo`, {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            familyID: this.$loggedUser.familyId,
            eventIDs: eventIDs.join(","),
            total: totalCost,
            hasDeposit: false,
            participants
          });
          if (response.data.status == 1) {
            this.paymentInfo = response.data.data.paymentInfo || {};

            // no allow pay monthly more if in selected to pay had a partial before
            const partialItem = selectedToPay.find(
              (item: any) =>
                (item.registrationStep || 0) == 3 ||
                (item.registrationStep || 0) == 4
            );
            if (partialItem || (this.paymentInfo.recurringAmount || 0) == 0) {
              this.paymentType = "full";
              this.paymentInfo.recurringAmount = 0;
              this.paymentInfo.recurringAmountFormatted = "$0.00";
              this.paymentInfo.chargeMonths = 0;
              this.paymentInfo.chargeDays = 0;
              this.paymentInfo.deposit = 0;
              this.paymentInfo.depositFormatted = "$0.00";
              this.paymentInfo.firstChargeDate = "";
              this.paymentInfo.lastChargeDate = "";
            }

            // set stripe public key
            if ((this.paymentInfo.stripePublishableKey || "") != "") {
              if (typeof Stripe == "undefined") {
                // load stripe
                await $.getScript("https://js.stripe.com/v2/", function(
                  data,
                  textStatus,
                  jqxhr
                ) {}).fail(function(jqxhr, settings, exception) {
                  // console.log("Stripe load failes");
                });
              }
              Stripe.setPublishableKey(this.paymentInfo.stripePublishableKey);
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.paymentInfoLoading = false;
      }
    }
  }

  showDiscountSection() {
    if (this.discountsHasCode.length == 0) return false;

    let show = false;
    for (const item of this.selectedToPay) {
      const existedDiscount = this.discountsHasCode.find(
        (discount: any) =>
          item.eventID == discount.eventId &&
          item.participant_typeid == discount.pTypeId
      );
      if (existedDiscount) {
        show = true;
        break;
      }
    }

    return show;
  }

  showAppliedDiscount() {
    if (this.appliedDiscount.length) {
      return true;
    }
    return false;
  }

  applyFullDiscount() {
    if ((this.paymentInfo.totalCost || 0) == 0) {
      return true;
    }
    return false;
  }

  async payNow() {
    if (this.paying) {
      return;
    }

    if (this.applyFullDiscount()) {
      await this.doPay();
      return;
    }

    this.form.cardName.error = "";
    this.form.cardNumber.error = "";
    this.form.expMoth.error = "";
    this.form.expyear.error = "";
    this.form.cvc.error = "";
    this.form.zipcode.error = "";
    this.paymentErrMessage = "";
    let hasError = false;
    if (this.form.cardName.value === "") {
      this.form.cardName.error = "Name is required";
      hasError = true;
    }
    if (this.form.cardNumber.value === "") {
      this.form.cardNumber.error = "Card Number is required";
      hasError = true;
    }
    if (this.form.expMoth.value === "") {
      this.form.expMoth.error = "Exp Month is required";
      hasError = true;
    }
    if (this.form.expyear.value === "") {
      this.form.expyear.error = "Exp Year is required";
      hasError = true;
    }
    if (this.form.cvc.value === "") {
      this.form.cvc.error = "CVC is required";
      hasError = true;
    }
    if (this.form.zipcode.value !== "" && this.form.zipcode.value.length < 5) {
      this.form.zipcode.error = "Zipcode is invalid";
      hasError = true;
    }

    // validate
    this.paymentErrMessage = "";
    if (this.paymentType == "") {
      this.paymentErrMessage = "Please select a payment option";
    }

    if (
      this.paymentType != "" &&
      this.selectedToPay.length &&
      (this.paymentInfo.totalCost || 0) > 0
    ) {
      // validate stripe form
      let isValidStripe = !hasError;
      // if (this.cardName != "" && this.cardNumber != "" && this.expMoth != "" && this.expyear != "" && this.cvc != "") {
      //   isValidStripe = true;
      // }
      if (isValidStripe) {
        var $this = this;
        $this.stripeToken = "";
        this.paying = true;
        this.setIsPaying(this.paying);
        this.cardInfoError = "";
        Stripe.card.createToken(
          $(".stripe-checkout-slide-up-frm"),
          async (status, response) => {
            //Stripe token failure...
            if (response.error) {
              var msg = "card name";
              const stripeMessage = response.error?.message || "";
              if (response.error.param == "number") {
                $this.stripeError = 2;
                this.form.cardNumber.error = "Card Number is invalid";
                msg = "card number";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_month") {
                $this.stripeError = 3;
                this.form.expMoth.error = "Exp Month is invalid";
                msg = "month";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "exp_year") {
                $this.stripeError = 4;
                this.form.expyear.error = "Exp Year is invalid";
                msg = "year";
                $this.cardInfoError = stripeMessage;
              } else if (response.error.param == "cvc") {
                $this.stripeError = 5;
                this.form.cvc.error = "CVC is invalid";
                msg = "cvc";
                $this.cardInfoError = stripeMessage;
              } else {
                $this.stripeError = 1;
                $this.paymentErrMessage = stripeMessage;
              }
            } else {
              $this.stripeToken = response.id;
              $this.stripeLast4 = response.card.last4;
              $this.stripeError = 0;
            }

            if ($this.stripeToken != "" && $this.stripeError == 0) {
              await this.doPay();
            } else {
              this.paying = false;
              this.setIsPaying(this.paying);
            }
          }
        );
      }
    }
  }

  async doPay() {
    let ret = false;
    // try {
      this.paying = true;
      this.setIsPaying(this.paying);
      this.disablePaymentForm();
      const paymentInfo = this.paymentInfo;
      if (paymentInfo.stripePublishableKey) {
        delete paymentInfo.stripePublishableKey;
      }
      const selectedToPay = JSON.parse(JSON.stringify(this.selectedToPay));
      let discounts = JSON.parse(JSON.stringify(this.appliedDiscount));
      const discountHasNoCode = discounts.find(
        (item: any) => item.discountCode == ""
      );
      discounts = discounts.filter((item: any) => item.discountCode != "");

      const requestObj = {
        callFrom: "portal",
        saveParticipant: false,
        uuid: this.$loggedUser.entityUUID,
        applications: selectedToPay.map((item: any) => {
          let additionalCosts =
            item.registrationStep == 1
              ? item.additionalCosts || []
              : item.registrationStep == 4
              ? item.dynamicAdditionalCosts || []
              : [];
          additionalCosts = [...additionalCosts];
          if (item.registrationStep == 1 && (item.totalFunds || 0) > 0) {
            additionalCosts.push({
              link_event_service_Id: 0,
              addonServiceId: 5,
              serviceName: "Total Camp Store Funds",
              cost: item.totalFunds,
              isFundBucket: 1,
              costFormatted: ApiHelper.dollarFormat(item.totalFunds),
              isTotalFunds: true
            });
          }

          const discountInfo: any = [];
          if (item.sourceType == 1 && item.registrationStep == 1) {
            // apply discount without discount code firstly
            if (
              (item.discountAmount || 0) > 0 &&
              (discountHasNoCode?.appliedFor || []).includes(item.participantID)
            ) {
              const discountAmount = item.discountAmount;
              item.participantCost -= discountAmount;
              item.participantCost = parseFloat(
                item.participantCost.toFixed(2)
              );
              item.cost -= discountAmount;
              item.cost = parseFloat(item.cost.toFixed(2));
              if (item.discount) {
                discountInfo.push(item.discount);
              }
            }

            // apply discount if input a discount code
            if (discounts.length) {
              for (const discount of discounts) {
                if (
                  (discount.eventId == item.eventID &&
                    discount.participant_typeId == item.participant_typeid) ==
                  false
                ) {
                  continue;
                }

                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  // apply if pass the condition
                  item.participantCost -= discountAmount;
                  item.participantCost = parseFloat(
                    item.participantCost.toFixed(2)
                  );
                  item.cost -= discountAmount;
                  item.cost = parseFloat(item.cost.toFixed(2));

                  // no send totalUsed in discountInfo
                  const itemDiscount = { ...discount };
                  if (itemDiscount.totalUsed != undefined) {
                    delete itemDiscount.totalUsed;
                  }

                  if (itemDiscount.appliedFor) {
                    // remove appliedFor info
                    delete itemDiscount.appliedFor;
                  }
                  discountInfo.push(itemDiscount);

                  // update totalUsed
                  discount.totalUsed += 1;
                }
              }
            }
          }

          return {
            sourceType: item.sourceType || 1,
            addonServiceId: item.addonServiceId || 0,
            profileid: item.profileid || 0,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            participantID: item.participantID || 0,
            eventID:
              item.sourceType == 2
                ? item.lnkServiceEventId || 0
                : item.eventID || 0,
            eventName: item.eventName || "",
            serviceName: item.serviceName || "",
            participantTypeID: item.participant_typeid || 0,
            participantAmount:
              item.registrationStep == 1
                ? parseFloat(item.participantCost)
                : item.registrationStep == 4
                ? parseFloat(item.originalBalance)
                : parseFloat(item.cost),
            pRegistrationStep: item.registrationStep,
            planID: item.planId,
            additionalCosts,
            totalCost: item.cost,
            participants:
              (item.sourceType || 1) == 2
                ? []
                : item.participants.map((p: any) => ({
                    eventID: p.eventID || 0,
                    ev_name: p.ev_name || "",
                    eventName: p.ev_name || "",
                    participantID: p.participantID,
                    profileid: p.profileid,
                    firstName: p.p_fname || "",
                    lastName: p.p_lname || "",
                    participantCost: p.participantCost,
                    pRegistrationStep: p.pRegistrationStep,
                    planId: p.planId || "",
                    balance: p.originalBalance || 0,
                    participantAmountFormatted: ApiHelper.dollarFormat(
                      p.originalBalance || 0
                    ),
                    additionalCosts: p.dynamicAdditionalCosts || []
                  })) || [],
            discountInfo
          };
        }),
        type: this.paymentType,
        cardInfo: {
          token: this.stripeToken,
          cardName: this.cardName,
          number: this.cardNumber,
          expMonth: this.expMoth,
          expYear: this.expyear,
          zipCode: this.zipcode,
          last4: this.stripeLast4
        },
        paymentInfo: paymentInfo,
        mainProfileID: this.$loggedUser.id,
        familyID: this.$loggedUser.familyId,
        applyFullDiscount: this.applyFullDiscount() ? 1 : 0
      };

      const response = await axios.post(
        `${dataURL}/registrationComplete`,
        requestObj
      );
      if (response.data.status == 1) {
        ret = true;

        const paymentResult = response.data.data.paymentResult;
        if (paymentResult.status == 1) {
          // pay successfully
          this.resetPaymentInfo();
          // const btn = $(".checkout_btn.slideDiv");
          // btn.html("Close");
          // $(".slide_wrapper").animate({ left: "-200%" }, 100, "linear");

          // remove related item in cart
          if (this.cartItems.length) {
            for (const item of selectedToPay) {
              this.cartItems = this.cartItems.filter(
                (cartItem: any) =>
                  !(
                    cartItem.eventID == item.eventID &&
                    cartItem.profile.profileid == item.profileid &&
                    cartItem.pType.participantTypeID == item.participant_typeid
                  )
              );
            }
          }
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
          await ApiHelper.updateProfileStats();
          // const pageHeader: any = this.$refs.pageHeader;
          // pageHeader.refresh();
          // if (
          //   typeof this.$loggedUser.stats.OutstandingFormatted != "undefined"
          // ) {
          //   this.outstandingBalanceFmt = this.$loggedUser.stats.OutstandingFormatted;
          // }
          // await this.fetchData();
          this.form.cardName.value = "";
          this.form.cardNumber.value = "";
          this.form.expMoth.value = "";
          this.form.expyear.value = "";
          this.form.cvc.value = "";
          this.form.zipcode.value = "";

          this.$swal.fire({
            // icon: "success",
            title: "Registration & Payment Complete",
            customClass: {
              container: "swal2-container-custom",
              confirmButton: "SecondaryColor FontColor"
            }
          });

          // call reload data
          this.paying = false;
          this.setIsPaying(this.paying);
          this.$emit("reloadData");
          this.closeSlideUp();
        } else {
          const message = paymentResult.message || "";
          if(message) {
            throw message;
          }
          this.paymentErrMessage =
            "Something went wrong with your credit card. Please check your card information and try again.";
        }
      }else {
        const message = response.data.message || "";
        if(message) {
          throw message;
        }
      }
    // } catch (error) {
    //   // console.log(error);
    // } finally {
    //   this.enablePaymentForm();
    //   this.paying = false;
    //   this.setIsPaying(this.paying);
    // }

    this.enablePaymentForm();
    this.paying = false;
    this.setIsPaying(this.paying);

    return ret;
  }

  resetPaymentInfo() {
    this.paymentInfo = {};
    this.stripeToken = "";
    this.stripeLast4 = "";
    this.cardName = "";
    this.cardNumber = "";
    this.expMoth = "";
    this.expyear = "";
    this.cvc = "";
    this.stripeError = 0;
    this.zipcode = "";
    this.paymentErrMessage = "";

    this.form.cardName.error = "";
    this.form.cardNumber.error = "";
    this.form.expMoth.error = "";
    this.form.expyear.error = "";
    this.form.cvc.error = "";
    this.form.zipcode.error = "";
    this.form.cardName.value = "";
    this.form.cardNumber.value = "";
    this.form.expMoth.value = "";
    this.form.expyear.value = "";
    this.form.cvc.value = "";
    this.form.zipcode.value = "";
    this.paymentErrMessage = "";
  }

  disablePaymentForm() {
    $(
      "#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button"
    ).prop("disabled", true);
  }

  enablePaymentForm() {
    $(
      "#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button"
    ).prop("disabled", false);
  }

  setIsPaying(status = false) {
    this.$emit("setIsPaying", status);
  }

  closeSlideUp() {
    this.$emit("closeSlideUp");
    // this.resetPaymentInfo();
  }
}
