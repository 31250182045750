


























































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import Loading from "@/components/Common/Loading.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";
import moment from "moment";

/* ------------------ QR CODE GENERATOR - IMPORT INTO VUE ------------------- */
/* -------------------- QR CODE FOR VUE REFERENCE SITE: --------------------- */
/* ---------- https://gerardreches.github.io/vue-qrcode-component/ ---------- */
import Vue from 'vue';
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);



declare let dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    PageHeader,
    Loading
  },
})


export default class TicketsDetail extends TSXComponent<void> {
  ticketUUID = "";
  formData: any = {
    isLoading: false,
    seconds: "",
    Event: "TEST",
    lastName: {
      error: '',
      label: 'Last Name',
      placeholder: 'Last Name',
      value: ''
    },
  };

  async created() {
    this.getQRcode();
  }

  async startCountdown() {
      this.formData.seconds = 60;
      const $this = this;
      var x = setInterval(function() {
        $this.formData.seconds = $this.formData.seconds - 1;

          if($this.formData.seconds < 10){
            $this.formData.seconds = "0" + $this.formData.seconds;
          }

          $("#countdown").html($this.formData.seconds + " seconds ");

          if ($this.formData.seconds < 1) {
            clearInterval(x);
            $(".scan").addClass("active");
            setTimeout(() => {
              $this.getQRcode();
            }, 1000);
          }
      }, 1000);
  }

  async getQRcode() {
      const loggedUser = ApiHelper.getLoggedUser();
      const response = await ApiHelper.apiPost(
        "/external_cfm",
        {
          entityid: 1,
          profile: loggedUser.id,
          eventID: this.$route.params.id,
          registerId: 4835,
          participantID: 234567,
          secret: "lakjwfiowaheiurbi23094u0238hf92uhf92823ryhunaufvb0a",
          route: "external/ticketing/Event_Ticketing_UUID"
        }
      );

      this.formData.Event = response.data.TicketsDetail.EVENT;
      this.formData.Location = response.data.TicketsDetail.LOCATION;
      this.formData.FirstName = response.data.TicketsDetail.FIRSTNAME;
      this.formData.LastName = response.data.TicketsDetail.LASTNAME;
      this.formData.Start = moment(String(response.data.TicketsDetail.STARTTIME)).format('MM/DD/YYYY');
      this.formData.End = moment(String(response.data.TicketsDetail.ENDTIME)).format('MM/DD/YYYY');
      this.formData.ticket = response.data.ticket;  
      this.ticketUUID = response.data.ticket;
      
      $(".scan").removeClass("active");
      $("#ticketUUID").html(response.data.ticket);
      this.startCountdown();
    }
}
