















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from 'moment'

interface Props {
  data: any;
}

interface Events {
}

declare const $: any;
@Component({
  inheritAttrs: false,
  components: {
  }
})
export default class FormDate extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  maxDate: string = moment().format('YYYY-MM-DD');
  textInput: any = null;

  mounted() {
    this.textInput = $(this.$el).find(".textInput");
    if(this.textInput && this.data.value == "") {
      this.textInput.prop("type", "text");
    }
  }

  @Watch('data.value', { immediate: true, deep: true })
  async dataChanged(val) {
    if(this.textInput) {
      if(val == "") {
        if(!this.textInput.is(":focus")) {
          this.textInput.prop("type", "text");
          this.textInput.trigger("blur");
        }
      }else {
        this.textInput.prop("type", "date");
      }
    }
  }

  textInputFocus() {
    if(this.textInput) {
      this.textInput.prop("type", "date");
    }
  }

  textInputBlur() {
    if(this.textInput && this.data.value == "") {
      this.textInput.prop("type", "text");
    }
  }
}
