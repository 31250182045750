







































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "../helpers/all";
import Loading from "../components/Common/Loading.vue";
import Pager from "../components/Pager.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    PageHeader,
    Loading,
    Pager,
    Pagination,
  },
})
export default class People extends TSXComponent<void> {
  $route: any;
  $router: any;
  pageData: any = {
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    }
  };


  updateRouters() {
    this.$router
      .replace({
        name: "People",
        query: {
          page: (this.pageData.pager.page || 1) + ""
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  }

  gotoPage(page) {
    this.pageData.pager.page = parseInt(page);
    this.updateRouters();
    this.loadList();
  }

  onClickNext() {
    if (this.pageData.pager.page < this.pageData.pager.totalPages) {
      this.pageData.pager.page += 1;
      this.updateRouters();
      this.loadList();
    }
  }

  onClickPrev() {
    if (this.pageData.pager.page > 1) {
      this.pageData.pager.page -= 1;
      this.updateRouters();
      this.loadList();
    }
  }

  getTotalPages() {
    return Math.ceil(this.pageData.paging.totalCount / this.pageData.paging.take);
  }

  getCurrentPage() {
    return Math.ceil(this.pageData.paging.skip / this.pageData.paging.take) + 1;
  }

  async created() {
    await this.loadData('1');
  }

  async loadData(page) {
    this.pageData.pager.page = page;
    this.loadList();
  }

  async loadList() {
    const loggedUser = ApiHelper.getLoggedUser();
    if(loggedUser.familyId == 0) {
      return;
    }

    this.pageData.pager.page = this.$route.query
      ? parseInt(this.$route.query.page ? this.$route.query.page + '' || '1' : '1') || 1
      : 1;
    this.pageData.isLoading = true;
    this.pageData.paging.skip = (this.pageData.pager.page - 1) * this.pageData.paging.take;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost('/familyMembers', {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      familyID: loggedUser.familyId,
      skip: this.pageData.paging.skip,
      take: this.pageData.paging.take
    });
    this.pageData.isLoading = false;
    if (result.status === 1) {
      this.pageData.items = result.data.members.map((item) => {
        item.fullName = item.p_fname ? [item.p_fname, item.p_lname].join(' ') : '<<No Name>>'
        return item;
      });
      const totalCount = result.data.paging.totalCount || 0;
      this.pageData.paging.totalCount = totalCount;

      this.pageData.pager.totalPages = Math.ceil(
        totalCount / this.pageData.paging.take
      );
      this.pageData.pager.total = totalCount;
      const pagerList: any[] = [];
      for (let i = 0; i < this.pageData.pager.totalPages; i++) {
        const pagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === this.pageData.pager.page
        };
        pagerList.push(pagerItem);
      }
      this.pageData.pager.items = pagerList;
    } else {
      ApiHelper.showErrorMessage(result.message || JSON.stringify(result));
    }
  }

  dollarFormat(amount) {
    return ApiHelper.dollarFormat(amount, false, {
      useBrackets: true,
    });
  }

  beforeRouteLeave(to, from, next) {
    next();
  }
}
