




























































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import Pagination from "../components/Pagination.vue";
import NoData from "../components/Common/NoData.vue";
import { ApiHelper } from "@/helpers/all";
import Pager from "../components/Pager.vue";
import Loading from "@/components/Common/Loading.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";

declare let dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    PageHeader,
    Loading
  },
})
export default class TicketsDetail extends TSXComponent<void> {
  loading = false;
  pageData: any = {
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    },
  };

  async created() {
    const loggedUser = ApiHelper.getLoggedUser();
    const response = await ApiHelper.apiPost(
      '/external_cfm', 
      {
        familyID: loggedUser.familyId,
        uuid: loggedUser.entityUUID,
        profile: loggedUser.id,
        secret: "lakjwfiowaheiurbi23094u0238hf92uhf92823ryhunaufvb0a",
        route: "external/ticketing/Event_Ticketing_List"
      }
    );

    this.pageData.paging.totalCount = (response.data.tickets).length;
    this.pageData.items = response.data.tickets;
  }
}
