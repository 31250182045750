





























import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";

interface Props {}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class PageHeader extends TSXComponent<Props, Events> {}
