









































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ImageUploader from "vue-image-upload-resize";
import { ApiHelper } from "@/helpers/all";


interface Props {
  data: any;
  hiddenText?: boolean;
}

interface Events {
  onChange?: Function;
}

@Component({
  inheritAttrs: false,
  components: {
    ImageUploader
  }
})
export default class FormUpload extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;
  hiddenText?: boolean;

  randomId: any = ApiHelper.randomFormId();

  async onChangeFile(base64: string) {
    if (this.$props.data) {
      this.$props.data.preview = base64;
      this.$props.data.base64 = base64;
    }
    this.$emit("change", base64, 'base64', this.$props.data);
  }
}
