




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "@/helpers/all";
import Loading from "@/components/Common/Loading.vue";
import Vue from "vue";
import Inputmask from "inputmask";
// import RoommateRequest from "@/components/RoommateRequest.vue";
import moment from "moment";
import SignaturePad from "signature_pad";
import axios from "axios";
import { ParticipantStatus, TodoStatus } from "@/helpers/ApiHelper";
import toDoQuestion from "@/components/Todo/toDoQuestion.vue";
import {
  registerAllergies,
  registerMedication,
  registerTransportation,
  registerAddressBlock,
  registerParentGuardian,
} from "@/helpers/FbControls";
import PageHeader from "@/components/PageHeader.vue";
import NoData from "@/components/Common/NoData.vue";
import FormPhotoToDo from "@/components/Form/FormPhotoToDo.vue";

declare let dataURL: string;
declare const $: any;
declare const window: any;
declare const bootstrap: any;

@Component({
  inheritAttrs: false,
  components: {
    NoData,
    PageHeader,
    toDoQuestion,
    Pagination,
    Loading,
    FormPhotoToDo
    // RoommateRequest
  }
})
export default class Todo extends TSXComponent<void> {
  $loggedUser: any;
  $route: any;
  $router: any;
  itemPhoto: any = [];
  loading = true;
  isSubmitting = false;
  activeTab: string = "";
  activeQuestionTab: string = "";
  activeTabGroup: string = "";
  todoItems: any = [];
  requirePhotoItems: any = [];
  requirePhotoCompleteItems: any = [];
  // roomateRequests: any = [];
  completeItems: any = [];
  outstandingTasksTotal = 0;
  completeTasksTotal = 0;
  orderBy: string = this.$route.query.order ? this.$route.query.order : "1";
  filters: any = {
    profileId: 0,
    participantId: 0,
  };
  title = "Outstanding Tasks";
  upcomingRegistrations: any = [];
  inviteActiveTab = "";
  filteredProfile: any = {};

  popupMessagesData = {
    participantId: 0,
    formId: 0,
    show: false
  };
  inviterInfo: any = {
    eventId: 0,
    pTypeId: 0,
    participantId: 0,
    profileId: 0,
    data: {}
  };

  selectedTodo: any = {};

  async created() {
    this.activeTab =
      typeof this.$route.query.tab == "string" ? this.$route.query.tab : "";
    if (this.$route.query.profileId != undefined) {
      this.filters.profileId = parseInt(this.$route.query.profileId) || 0;
    }
    if (this.$route.query.participantId != undefined) {
      this.filters.participantId = parseInt(this.$route.query.participantId) || 0;
    }

    // set title when filter for a profile
    if (this.$route.query.profileId != undefined) {
      const response = await ApiHelper.apiPost(
        "/profiles/" + this.filters.profileId,
        {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          mainProfileID: this.$loggedUser.id,
          familyID: this.$loggedUser.familyId
        }
      );
      if (response.status === 1) {
        const profile = response.data.profileSetting || {};
        if (profile && profile.p_fname != "") {
          this.title = `${profile.p_fname}'s Outstanding Tasks`;
        }
        this.filteredProfile = profile;
      }
    }

    await this.fetchData(true);
  }

  mounted() {
    const _root: any = this;
    // register custom types for formRender
    window.fbControls = [];
    window.fbControls.push(function(controlClass) {
      class ControlConditionalOptions extends controlClass {
        build() {
          const id = this.id;
          const required = this.required || false;
          return `
            <div class="radio-group">
              <div class="formbuilder-radio form-check">
                <input name="${id}" id="${id}-0" ${required ? 'required="required" aria-required="true"' : ""} value="Yes" type="radio" class="form-check-input">
                <label for="${id}-0" class="form-check-label">Yes</label>
              </div>
              <div class="formbuilder-radio form-check">
                <input name="${id}" id="${id}-1" ${required ? 'required="required" aria-required="true"' : ""} value="No" type="radio" class="form-check-input">
                <label for="${id}-1" class="form-check-label">No</label>
              </div>
            </div>
          `;
        }
        onRender() {
          const id = this.id;
          const field = $(`.field-${id}`);
          const userData = this.config.userData || [];
          if (field.length) {
            field.addClass("formbuilder-radio-group");
            if ((userData[0] || "") != "") {
              field
                .attr("data-value", userData[0].toLowerCase())
                .find(`input[type=radio][value=${userData[0]}]`)
                .prop("checked", true);
            }

            field.find(`input[type=radio]`).each((i, obj) => {
              $(obj).on("click", () => {
                const val = `${$(obj)?.val() || ""}`.toLowerCase();
                field.attr("data-value", val);
                if (val == "no") {
                  // clear explain textbox
                  $(`#explain-${id}`).val("");
                }

                const ps: any = _root.$refs.todops || [];
                for (const item of ps) {
                  item.update();
                }
              });
            });
          }
        }
      }

      // register this control
      controlClass.register("conditionalOptions", ControlConditionalOptions);
      return ControlConditionalOptions;
    });

    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
            <div class="signature-container signature-pad">
              <img src="${this.config.signature}" class="defaultImage" style="position: absolute;flex:1;" />
              <canvas id="${_root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name}" style="touch-action: none; background: white;flex:1;"></canvas>
              <div class="signatureButtons">
                <button class="btn-save"></button>
                <button class="btn-undo"></button>
                <button class="btn-clear"></button>
              </div>
              <div class="otherButtons">
                <label for="file_${_root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name}" class="button SecondaryColor FontColor btn">Upload Signature</label>
                <input type="file" class="file-upload"
                 accept="image/x-png,image/gif,image/jpeg"
                 id="file_${_root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name}" style="display: none"
                 target="${_root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name}"
                 />
              </div>
            </div>
          </div>
        `;
        }
        onRender() {
          const canvas: any = document.getElementById(_root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name);
          const signaturePad = new SignaturePad(canvas, {
            backgroundColor: "rgb(255, 255, 255)"
          });
          const signatureCanvas = $("#" + _root.selectedTodo.participantID + '_' + _root.selectedTodo.formID + '_' + this.config.name);
          const container = signatureCanvas.parents(".signature-container");
          if (this.config.signature) {
            signatureCanvas.attr("data-signature", this.config.signature);
            container.find(".defaultImage").show();
            container.find(".btn-undo").hide();
          } else {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
          }
          signaturePad.onEnd = () => {
            container.find(".defaultImage").hide();
            const signatureData = signaturePad.toDataURL();
            signatureCanvas.attr("data-signature", signatureData);
            signatureCanvas.attr("data-new", 1);
            _root.getPercent(_root.selectedTodo);
          };

          container.find(".btn-save").on("click", () => {
            ApiHelper.showSuccessMessage("Saved");
          });
          container.find(".file-upload").on("change", async (e) => {
            const files = e.target.files || [];
            const _this = $(e.target);
            if (files.length > 0) {
              const signatureCanvas2 = $("#" + _this.attr("target"));
              const file = files[0];
              const base64 = await ApiHelper.convertFileToBase64(file);
              _root.loading = true;
              const result = await ApiHelper.apiPost("/uploadFileFromBase64", {
                uuid: ApiHelper.getUuid(),
                domain: ApiHelper.getDomain(),
                base64: base64,
                group: "profiles/" + _root.profileID + "/signatures",
                id: _root.profileID,
                data: {
                  fileName: file.name
                }
              });
              _root.loading = false;
              container.find(".defaultImage").attr("src", "");
              if (result.status === 1) {
                container
                  .find(".defaultImage")
                  .attr("src", result.data.url)
                  .show();
                container.find(".btn-undo").hide();
                signatureCanvas2.attr("data-signature", result.data.url);
                signatureCanvas2.attr("data-new", 0);
                signaturePad.clear();
              }
            }
            _root.getPercent(_root.selectedTodo);
          });
          container.find(".btn-undo").on("click", () => {
            const data = signaturePad.toData();
            if (data) {
              data.pop(); // remove the last dot or line
              signaturePad.fromData(data);
            }
            if (data.length > 0) {
              const signatureData = signaturePad.toDataURL();
              signatureCanvas.attr("data-signature", signatureData);
            } else {
              signatureCanvas.attr("data-signature", "");
            }
            signatureCanvas.attr("data-new", 1);
            _root.getPercent(_root.selectedTodo);
          });
          container.find(".btn-clear").on("click", () => {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
            signaturePad.clear();
            signatureCanvas.attr("data-signature", "");
            signatureCanvas.attr("data-new", 1);
            _root.getPercent(_root.selectedTodo);
          });
        }
      }
      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });

    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split("-");
          let days: number[] = [];
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              days = ApiHelper.getDaysList(defaultYear, defaultMonth);
            }
          }
          const months: number[] = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          if (days.length == 0) {
            for (let i = 1; i < 32; i++) {
              days.push(i);
            }
          }

          const years: number[] = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }
          const isSmallSize = (this.config.className || "").includes("col-3");
          return `
          <div
          id="date-${this.config.name}"
          data-userData="${JSON.stringify(this.config.userData)}"
          class="row form-group ${
            this.config.className
          } shadow-none pe-0 d-flex" style="padding:0; margin: 0; border: none">
            <input id="${this.config.name}" type="date" style="display: none" ${
            (this.config.required || "") === "required"
              ? 'aria-required="true" required="required"'
              : ""
          } />
            <div class="col-4 ps-0 pe-lg-0">
              <select type="text" class="form-control px-3 text-center sel-month">
                <option value="">${isSmallSize ? "M" : "Month"}</option>
                ${months.map(
                  (value) => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-4 ps-1 pe-lg-0">
              <select class="form-control px-3 text-center sel-day">
                <option value="">${isSmallSize ? "D" : "Day"}</option>
                ${days.map(
                  (value) => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-4 pe-0 ps-1">
              <select type="text" class="form-control px-3 text-center sel-year">
                <option value="">${isSmallSize ? "Y" : "Year"}</option>
                ${years.map(
                  (value) => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
          </div>
        `;
        }
        onRender() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split("-");
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              $("#" + this.config.name).val(
                moment(dateString).format("YYYY-MM-DD")
              );
              $("#date-" + this.config.name)
                .find(".sel-year")
                .val(defaultYear);
              $("#date-" + this.config.name)
                .find(".sel-month")
                .val(defaultMonth);
              $("#date-" + this.config.name)
                .find(".sel-day")
                .val(defaultDay);
            }
          }
          $("#date-" + this.config.name).on("change", ".sel-month", () => {
            const dateContainer = $("#date-" + this.config.name);
            const year = parseInt(dateContainer.find(".sel-year").val());
            const month = parseInt(dateContainer.find(".sel-month").val());
            const day = dateContainer.find(".sel-day").val();
            const isSmallSize = (this.config.className || "").includes("col-3");
            const days = ApiHelper.getDaysList(year, month);
            dateContainer.find(".sel-day").html(`<option value="">${
              isSmallSize ? "D" : "Day"
            }</option>
                ${days.map(
                  (value) => `<option value="${value}">${value}</option>`
                )}`);
            dateContainer.find(".sel-day").val(day);
            if (month && year && day) {
              const newDate = moment(year + "-" + month + "-" + day).format(
                "YYYY-MM-DD"
              );
              $("#" + this.config.name).val(newDate);
            } else {
              $("#" + this.config.name).val("");
            }
          });
          $("#date-" + this.config.name).on(
            "change",
            ".sel-year,.sel-day",
            () => {
              const dateContainer = $("#date-" + this.config.name);
              const year = dateContainer.find(".sel-year").val();
              const month = dateContainer.find(".sel-month").val();
              const day = dateContainer.find(".sel-day").val();
              if (month && year && day) {
                const newDate = moment(year + "-" + month + "-" + day).format(
                  "YYYY-MM-DD"
                );
                $("#" + this.config.name).val(newDate);
              } else {
                $("#" + this.config.name).val("");
              }
            }
          );
        }
      }
      controlClass.register("dob", ControlDob);
      return ControlDob;
    });

    registerMedication();
    registerAllergies();
    registerTransportation();
    registerAddressBlock();
    registerParentGuardian();

    $(document).off('click', '.updatePs');
    $(document).on('click', '.updatePs', function() {
      const ps: any = _root.$refs.todops || [];
      for (const item of ps) {
        item.update();
      }
    });
  }

  updateRouters() {
    this.$router
      .replace({
        name: "Todo",
        query: {
          order: this.orderBy !== "1" ? this.orderBy : undefined,
          profileId: this.filters.profileId
            ? this.filters.profileId + ""
            : undefined,
          participantId: this.filters.participantId
            ? this.filters.participantId + ""
            : undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  }

  async fetchData(hasLoading = true) {
    const totalOpen = this.todoItems.filter((item) => item.isShow).length || 15;
    $("body").removeClass("no-scroll");
    const loggedUser = ApiHelper.getLoggedUser();
    // this.activeTab = "";
    if (hasLoading) {
      this.todoItems = [];
      this.completeItems = [];
      this.requirePhotoCompleteItems = [];
      this.completeTasksTotal = 0;
      this.loading = true;
    }
    try {
      // get payment plans
      const order = this.$route.query.order ? this.$route.query.order : "1"
      const todoList = await ApiHelper.apiPost(`/todo`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: loggedUser.id,
        participantID: this.filters.participantId,
        skip: 0,
        take: 999,
        order: order,
        filters: this.filters
      });
      if (todoList.status == 1) {
        const allTasks = todoList.data.todos;
        this.todoItems = allTasks
          .filter((item) => (item.link_epf_status < TodoStatus.Pending && !item.isPriorEvent && (item.participant_status || 0) != ParticipantStatus.CheckedIn))
          .map((item: any) => {
            item.isShow = false;
            item.questionsNeeded = 0;
            item.questionsDone = 0;
            item.formRenderInstance = undefined;
            item.dueDateFormatted = ApiHelper.formatDate(
              item.dueDate,
              "MMM DD, YYYY",
              ""
            );
            return item;
          });
        this.outstandingTasksTotal = this.todoItems.length;

        const pageHeader: any = this.$refs.pageHeader;
        pageHeader.refresh();
        //TODO: filter from client to get complete tasks with status = 2
        this.completeItems = allTasks
          .filter((item) => {
            return [TodoStatus.Complete, TodoStatus.Pending].includes(item.link_epf_status) || item.isPriorEvent || (item.participant_status || 0) == ParticipantStatus.CheckedIn;
          }
          )
          .map((item: any) => {
            item.isCompleted = true;
            item.isDueDate = ![TodoStatus.Complete, TodoStatus.Pending].includes(item.link_epf_status) && item.dueDateText == 'Due';
            item.isShow = false;
            item.formRenderInstance = undefined;
            item.dueDateFormatted = ApiHelper.formatDate(
              item.dueDate,
              "MMM DD, YYYY",
              ""
            );
            return item;
          });
        this.completeTasksTotal = this.completeItems.length;
        // this.roomateRequests = todoList.data.roomateRequests.data || [];
        // if (this.filters.profileId) {
        //   this.roomateRequests = this.roomateRequests.filter(
        //     (item: any) =>
        //       item.invitedProfileId == this.filters.profileId ||
        //       item.profileId == this.filters.profileId ||
        //       ((item.toFamilyMembers || false) &&
        //         item.firstName.toLowerCase() ==
        //           this.filteredProfile?.p_fname.toLowerCase() &&
        //         item.lastName.toLowerCase() ==
        //           this.filteredProfile?.p_lname.toLowerCase())
        //   );
        // }

        // this.roomateRequests.forEach((r, index) => {
        //   r.isShow = false;
        //   r.linkTSFormatted = ApiHelper.formatDate(
        //     r.linkTs,
        //     "MMM DD, YYYY",
        //     ""
        //   );
        // });
        // RequirePhotoTask order
        
        this.requirePhotoItems = todoList.data.requirePhoto;
        this.requirePhotoCompleteItems = todoList.data.requirePhoto;
        const currentYear = new Date().getFullYear();
        if (loggedUser.id) {
          this.requirePhotoItems = this.requirePhotoItems.filter(
            (item: any) =>
              currentYear != parseInt(item.yearUpdateLogo || "0") 
          );
          this.requirePhotoCompleteItems = this.requirePhotoCompleteItems.filter(
            (item: any) =>
              currentYear == parseInt(item.yearUpdateLogo) 
          );
        }
        this.todoItems = await [...this.todoItems, ...this.requirePhotoItems];
        if (parseInt(order) === 2) {
          await this.todoItems.sort((a, b) => (a.p_fname > b.p_fname) ? 1: -1);
        } else if (parseInt(order) === 4) {
          await this.todoItems.sort((a, b) => (a.formName > b.formName) ? 1 : -1);
        } else if (parseInt(order) === 3) {
          await this.todoItems.sort((a, b) => (a.ev_name > b.ev_name) ? 1 : -1);
        }
        
        this.completeItems = await [...this.completeItems, ...this.requirePhotoCompleteItems];
        if (parseInt(order) === 2) {
          await this.completeItems.sort((a, b) => (a.p_fname > b.p_fname) ? 1: -1);
        } else if (parseInt(order) === 4) {
          await this.completeItems.sort((a, b) => (a.formName > b.formName) ? 1 : -1);
        } else if (parseInt(order) === 3) {
          await this.completeItems.sort((a, b) => (a.ev_name > b.ev_name) ? 1 : -1);
        }

        this.showMoreTasks(totalOpen);
        this.showMoreCompleteTasks(5);
        this.upcomingRegistrations = todoList.data.upcomingRegistrations || [];
        if (this.filters.profileId) {
          this.upcomingRegistrations = this.upcomingRegistrations.filter(
            (item: any) => item.profileid == this.filters.profileId
          );
        }
        // this.listItemsShowMore(5, this.roomateRequests);
      }
      const activeTab =
        typeof this.$route.query.tab == "string"
          ? this.$route.query.tab
          : this.activeTab;
      const findItem = this.todoItems.find((item: any) => {
        return item.participantID + "_" + item.formID == activeTab;
      });

      if (findItem) {
        findItem.isShow = true;
        setTimeout(async () => {
          await this.loadForm(findItem);
          $(document).ready(function() {
            $("#tab_" + activeTab).trigger("click");
          });
        }, 100);
      }

      const findItem2 = this.completeItems.find((item: any) => {
        return item.participantID + "_" + item.formID == activeTab;
      });
      if (findItem2) {
        findItem2.isShow = true;
        setTimeout(async () => {
          await this.loadForm(findItem2);
          $(document).ready(function() {
            $("#tab_" + activeTab).trigger("click");
          });
        }, 100);
      }
    } catch (err) {
      ApiHelper.showSuccessMessage(JSON.stringify(err), "Oops");
    } finally {
      this.loading = false;
    }
  }

  getTotalHideTasks() {
    return this.todoItems.filter((item: any) => !item.isShow).length;
  }

  showMoreTasks(take: number) {
    let count = 0;
    this.todoItems.map((item: any) => {
      if (!item.isShow && count < take) {
        item.isShow = true;
        count = count + 1;
      }
    });
  }

  getTotalHideCompleteTasks() {
    return this.completeItems.filter((item: any) => !item.isShow).length;
  }

  showMoreCompleteTasks(take: number) {
    let count = 0;
    this.completeItems.map((item: any) => {
      if (!item.isShow && count < take) {
        item.isShow = true;
        count = count + 1;
      }
    });
  }

  async loadForm(item) {
    this.selectedTodo = item;
    const _this = this;
    this.inviteActiveTab = "";
    // this.activeTab = this.activeTab ? '' : item.participantID + '_' + item.formID;
    const activeTab = item.participantID + "_" + item.formID;
    if (activeTab == this.activeTab) {
      // click on same tab, close this
      this.activeTab = "";
      $("body").removeClass("no-scroll");
    } else {
      // open this tab
      this.activeTab = activeTab;
    }

    if (this.activeTab) {
      if (item.isCompleted) {
        this.activeTabGroup = "Completed";
      } else {
        this.activeTabGroup = "Outstanding";
      }
    } else {
      this.activeTabGroup = "";
    }
    const apiInfo = await ApiHelper.getApiURL();
    if (!item.formRenderInstance) {
      const appModuleJson = JSON.parse(item.formContent || '[]');
      appModuleJson.map((control) => {
        if (control.type == 'file' && control.subtype == 'fineuploader') {
          control.subtype = 'file';
        }
        // if (control.label || '') {
        //   control.label = ApiHelper.removeLastBr(control.label);
        // }
      });
      item.formRenderInstance = await $(
        "#form-" + item.participantID + "_" + item.formID
      ).formRender({
        formData: JSON.stringify(appModuleJson),
        controlConfig: {
          'file.fineuploader': {
            handler: apiInfo.dataURL + '/uploadFile',
          }
        },
        notify: {
          error: (message) => {
            return message;
          },
          success: (message) => {

            setTimeout(() => {
              _this.getPercent(item);
            }, 300);

            const form = $("#form-" + item.participantID + "_" + item.formID);

            if (
              // item.isCompleted ||
              // item.link_epf_status == TodoStatus.Pending
              (item.participant_status || 0) == ParticipantStatus.CheckedIn
            ) {
              $("#form-" + item.participantID + "_" + item.formID)
                .find("input, select, textarea")
                .prop("disabled", true);
            }
            const files = $(
              "#form-" + item.participantID + "_" + item.formID
            ).find("input[type=file]");
            files.each((index, value) => {
              const $file = $(value);
              $file.attr(
                "accept",
                "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg"
              );
              this.updateFilePreview($file);
              // this.updateFileDownload($file);
            });

            // show download link for file upload if has a template
            $("#form-" + item.participantID + "_" + item.formID).find("input[type=file]").each((index, value) => {
              this.updateFileDownload($(value));
            });

            $("#form-" + item.participantID + "_" + item.formID)
              .find(".formbuilder-radio input[type=radio]")
              .removeClass("form-control")
              .addClass("form-check-input");
            $("#form-" + item.participantID + "_" + item.formID)
              .find(".formbuilder-radio label")
              .addClass("form-check-label");
            $("#form-" + item.participantID + "_" + item.formID)
              .find(".formbuilder-radio")
              .addClass("form-check");
            $("#form-" + item.participantID + "_" + item.formID)
              .find(
                ".formbuilder-radio-inline label, .formbuilder-checkbox label, .formbuilder-checkbox-inline label"
              )
              .addClass("form-check-label");
            $("#form-" + item.participantID + "_" + item.formID)
              .find(
                ".formbuilder-radio-inline input[type=radio], .formbuilder-checkbox input[type=checkbox], .formbuilder-checkbox-inline input[type=checkbox]"
              )
              .removeClass("form-control")
              .addClass("form-check-input");
            $("#form-" + item.participantID + "_" + item.formID)
              .find(
                ".formbuilder-radio-inline, .formbuilder-checkbox-inline, .formbuilder-checkbox"
              )
              .addClass("form-check me-5");

            $("#form-" + item.participantID + "_" + item.formID).on(
              "click",
              ".delete-file",
              (e) => {
                const _this = $(e.target);
                Vue.swal({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  // icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  customClass: {
                    container: "swal2-container-custom",
                  }
                }).then((result: any) => {
                  if (result.isConfirmed) {
                    const fileName = _this.attr("data-file");
                    const fileSelector = _this
                      .closest(".form-group")
                      .find("input[type=file]");
                    const arrFiles = JSON.parse(
                      fileSelector.attr("files") || "[]"
                    );
                    fileSelector.attr(
                      "files",
                      JSON.stringify(
                        arrFiles.filter((file) => {
                          return file.name != fileName;
                        })
                      )
                    );

                    if (
                      (_this.prop("id") || "").indexOf("attach-prescription") !=
                      -1
                    ) {
                      // medication: no allow upload multi files for prescription
                      fileSelector.attr("files", "[]");
                    }

                    this.updateFilePreview(fileSelector);
                    this.getPercent(item);
                  }
                });
              }
            );

            $("#form-" + item.participantID + "_" + item.formID).on(
              "click",
              "input[type=file]",
              (e) => {
                const _this = $("#" + e.target.id);
                _this.removeClass("border-danger");
                _this.parents(".form-group").removeClass("text-danger");
              }
            );

            $("#form-" + item.participantID + "_" + item.formID).on(
              "change",
              "input[type=file]",
              (e) => {
                const _this = $("#form-" + item.participantID + "_" + item.formID).find("#" + e.target.id);
                _this.removeClass("border-danger");
                _this.parents(".form-group").removeClass("text-danger");
                const files = e.target.files || [];
                let arrFiles = JSON.parse(_this.attr("files") || "[]");
                for (const file of files) {
                  if (file.size > 5 * 1024 * 1024) {
                    _this.addClass("border-danger");
                    _this.parents(".form-group").addClass("text-danger");
                    _this.val(undefined);
                    ApiHelper.showErrorMessage(
                      file.name +
                        " is greater than 5MB. Please use another file",
                      "Oops"
                    );
                  } else {
                    if (file) {
                      this.isSubmitting = true;
                      ApiHelper.uploadFile(file.name, file, item.profileID)
                        .then((result: any) => {
                          _this.attr("file-path", result.filePath);
                          _this.attr("file-name", file.name);

                          if (
                            (_this.prop("id") || "").indexOf(
                              "attach-prescription"
                            ) != -1
                          ) {
                            // medication: no allow upload multi files for prescription
                            arrFiles = [];
                          }

                          arrFiles.push({
                            path: result.filePath,
                            name: file.name
                          });
                          _this.attr("files", JSON.stringify(arrFiles));
                          _this.val(undefined);
                          // ApiHelper.showSuccessMessage('Uploaded file');
                          this.updateFilePreview(_this);
                          this.getPercent(item);
                          this.isSubmitting = false;
                        })
                        .catch((error) => {
                          _this.addClass("border-danger");
                          _this.parents(".form-group").addClass("text-danger");
                          ApiHelper.showErrorMessage(
                            JSON.stringify(error),
                            "Oops"
                          );
                          this.isSubmitting = false;
                        });
                    }
                  }
                }
                /* const file = e.target.files[0];
                if (file) {
                  const arrFiles = JSON.parse(_this.attr('files') || '[]');
                  this.loading = true;
                  ApiHelper.uploadFile(file.name, file).then((result: any) => {
                    _this.attr('file-path', result.filePath);
                    _this.attr('file-name', file.name);
                    arrFiles.push({
                      path: result.filePath,
                      name: file.name
                    });
                    _this.attr('files', JSON.stringify(arrFiles));
                    _this.val(undefined);
                    ApiHelper.showSuccessMessage('Uploaded file');
                    this.updateFilePreview(_this);
                    this.loading = false;
                  }).catch((error) => {
                    ApiHelper.showErrorMessage(JSON.stringify(error), 'Oops');
                    this.loading = true;
                  });
                } */
              }
            );

            $("#form-" + item.participantID + "_" + item.formID).on(
              "change input",
              "input:not([type=file]),textarea,select",
              (e) => {
                const parent = $(e.target).closest(".form-group");
                parent.find(".text-danger").removeClass("text-danger");
                parent.find(".border-danger").removeClass("border-danger");
                parent.removeClass("text-danger");

                if (parent.parent().hasClass("formbuilder-transportation")) {
                  this.validateTransportation(parent.parent());
                }
                _this.getPercent(item);
              }
            );

            try {
              $("#form-" + item.participantID + "_" + item.formID)
                .find("input[inputmask=phonenumber]")
                .each(function(i, obj) {
                  if (
                    $(obj).attr("maxlength") &&
                    $(obj).attr("maxlength") < 14
                  ) {
                    $(obj).attr("maxlength", "14");
                  }
                });
            } catch {
              // nothing
            }
            // apply mask
            // phone
            $("#form-" + item.participantID + "_" + item.formID)
              .find("input[inputmask=phonenumber]")
              .each(function(i, obj) {
                Inputmask("(999) 999-9999", {
                  autoUnmask: true
                }).mask(obj);
              });
            // zipcode
            $("#form-" + item.participantID + "_" + item.formID)
              .find("input[inputmask=zipcode]")
              .each(function(i, obj) {
                Inputmask({ regex: "\\d*" }).mask(obj);
              });
            // number
            $("#form-" + item.participantID + "_" + item.formID)
              .find("input[inputmask=number]")
              .each(function(i, obj) {
                Inputmask({ regex: "\\d*" }).mask(obj);
              });
            // transportation: date and time of arrival
            $("#form-" + item.participantID + "_" + item.formID)
              .find("input[id*=airplane-selected-dateTime]")
              .each((i, obj) => {
                Inputmask("datetime", {
                  alias: "mm/dd/yyyy",
                  inputFormat: "mm/dd/yyyy HH:MM",
                  autoUnmask: true,
                  clearIncomplete: true
                }).mask(obj);
              });

            $("#form-" + item.participantID + "_" + item.formID).on(
              "paste blur focusout",
              "input,textarea",
              (e) => {
                let value = $(e.target).val();
                value = value.trim();
                $(e.target).val(ApiHelper.stripTags(value));
                _this.getPercent(item);
              }
            );

            // case: not allow select multiple for the checkbox group
            form.on("click", "input[name*=checkbox-group-]", (e) => {
              const cb = $(e.target);
              const multiple = cb.attr("multiple-selections") || "true";
              const cbId = cb.prop("id") || "";
              if (multiple == "false" && cbId != "") {
                cb.closest(".checkbox-group")
                  .find(`input[type=checkbox]:not(#${cbId})`)
                  .prop("checked", false);
              }
              _this.getPercent(item);
            });

            // case selected "other" option of a checkbox group
            $(
              "input[type=checkbox][name*=checkbox-group-][id*=-other][selected-other=true]"
            ).each((i, obj) => {
              const cb = $(obj);
              const otherValue = cb.attr("other-value") || "";
              cb.prop("checked", true);
              cb.next()
                .find("input[type=text].other-val")
                .val(otherValue.trim());
            });

            // remove <br> tag in .formbuilder-required
            $(".formbuilder-required").each((i, obj) => {
              $(obj)
                .closest("label")
                .addClass("field-label");
              const prev = $(obj).prev();
              if (prev.length && prev.prop("tagName") == "BR") {
                prev.remove();
              }
            });

            form.find('textarea').each((i, obj) => {
              const _this = $(obj);
              const val = _this.val();
              const userData = _this.attr('user-data');
              if (val == '' && userData) {
                _this.val(userData);
              }
            });

            return message;
          },
          warning: (message) => {
            return message;
          }
        }
      });

      if (item.isCompleted) {
        item.percentComplete = 100;
      } else {
        const percent = this.getPercent(item);
        item.percentComplete = percent.percentComplete;
      }
    }

    // scroll to activeTab
    if (this.activeTab != "") {
      const tabId = `tab_${item.participantID}_${item.formID}`;

      setTimeout(() => {
        if ($(`#${tabId}`).length) {
          // const header = $("#container > header");
          // let headerHeight = 0;
          // if(header.length) {
          //   headerHeight = header.outerHeight();
          // }
          // const scrollToPosition = $(`#${tabId}`).offset().top - headerHeight + $("#main").get(0).scrollTop - 5;
          // $('#main').animate({
          //     scrollTop: scrollToPosition
          //   },
          // );
          $("html, body").animate({
            scrollTop: $(`#${tabId}`).offset().top
          });
          // $("body").addClass("no-scroll");
        }
      }, 100);
    } else {
      $("body").removeClass("no-scroll");
    }
  }
  async showPopupPhoto(value = true) {
    const childFormPhoto: any = this.$refs.childFormPhoto;
    childFormPhoto.showPoupF(value);
  }
  async loadFormPhoto(item) {
    this.itemPhoto = item;
    this.inviteActiveTab = "";
    const activeTab = item.participantID + "_" + item.formID;
    if (activeTab == this.activeTab) {
      // click on same tab, close this
      this.activeTab = "";
      $("body").removeClass("no-scroll");
    } else {
      // open this tab
      this.activeTab = activeTab;
    }
    // scroll to activeTab
    if (this.activeTab != "") {
      const tabId = `tab_${item.participantID}_${item.formID}`;

      setTimeout(() => {
        if ($(`#${tabId}`).length) {
          $("html, body").animate({
            scrollTop: $(`#${tabId}`).offset().top
          });
        }
      }, 100);
    } else {
      $("body").removeClass("no-scroll");
    }
  }

  updateFilePreview(fileSelector: any) {
    // const fileName = fileSelector.attr('file-name') || '';
    // const filePath = fileSelector.attr('file-path') || '';
    const id = fileSelector.prop("id") || "";
    let showThumbnail = true;
    let isPrescription = false;
    if (id.indexOf("attach-prescription") != -1) {
      showThumbnail = false;
      isPrescription = true;
    }

    const files = JSON.parse(fileSelector.attr("files") || "[]") || [];
    let previewHtml = "";
    for (let file of files) {
      const fileName = file.name || "";
      const filePath = file.path;
      const ext = ApiHelper.getFileExt(fileName).toLowerCase();
      if ((ext == "jpg" || ext == "png" || ext == "gif") && showThumbnail) {
        previewHtml +=
          '<li><a href="' +
          filePath +
          '" target="_blank"><img src="' +
          filePath +
          '" alt="' +
          fileName +
          '" /></a> <a href="javascript:void(0)" class="delete-file" data-file="' +
          fileName +
          '">Delete</a></li>';
      } else {
        previewHtml +=
          '<li><a href="' +
          filePath +
          '" target="_blank">' +
          fileName +
          '</a> <a href="javascript:void(0)"  class="delete-file" data-file="' +
          fileName +
          '">Delete</a></li>';
      }
    }
    if (fileSelector.parent().find(".file-preview").length > 0) {
      fileSelector
        .parent()
        .find(".file-preview ul")
        .html(previewHtml);
    } else {
      fileSelector[0].outerHTML = "<div class='d-flex flex-column w-100'>" + fileSelector[0].outerHTML + "<div class='file-preview mt-1'><ul>" + previewHtml + "</ul></div></div>";
    }
  }

  updateFileDownload(fileSelector: any) {
    const downloadUrl = fileSelector.attr("download") || "";
    if (downloadUrl) {
      const fileName = ApiHelper.getFileName(downloadUrl);
      // const label = fileSelector.parent().find(".formbuilder-file-label");
      fileSelector.parent().append(
        `<span class="pt-1"><a class="download-link" target="_blank" download href="${downloadUrl}">Download Template <strong>${fileName}</strong></a></span>`
      );
    }
  }

  async askQuestion(item) {
    this.popupMessagesData.participantId = item.participantID;
    this.popupMessagesData.formId = item.formID;
    this.activeQuestionTab = `${item.participantID}_${item.formID}`;
    const form: any = $("#form-" + item.participantID + "_" + item.formID);
    const formParent: any = $("#form-" + item.participantID + "_" + item.formID).parent();
    const height = $("#form-" + item.participantID + "_" + item.formID + " .rendered-form").outerHeight();
    
    // Returns the height with padding,border and margin
    const heightPopup = 350;
    
    formParent.addClass("qActive");
  }

  async closeQuestion(item) {
    if (this.activeQuestionTab != `${item.participantID}_${item.formID}`) {
      this.activeQuestionTab = ``;
    }
    // if(event.target.className != "task_btn ask_question_btn" && event.target.className != ""){
    //   $("button.close").click();
    // }
  }

  async getFormValues(item) {
    const renderDiv: any = $("#form-" + item.participantID + "_" + item.formID);
    const formContent = JSON.parse(item.formContent);
    const fileFields = formContent.filter(
      (content: any) => content.type === "file"
    );
    const formData: any = {};

    for (const fileField of fileFields) {
      const $input: HTMLInputElement = renderDiv.find(
        `input#${fileField.name}`
      ) as any;

      const files = ($input as any)[0].files || [];
      if (files.length === 0) {
        const existData = {
          files: renderDiv.find(`input#${fileField.name}`).attr("files"),
          filePath: renderDiv.find(`input#${fileField.name}`).attr("file-path"),
          fileName: renderDiv.find(`input#${fileField.name}`).attr("file-name")
        };
        // set to existing value
        formData[fileField.name] = existData;
        continue;
      }

      const file = ($input as any)[0].files[0];

      const result = await ApiHelper.uploadFile(fileField.name, file);
      if (!result) {
        return;
      }

      formData[fileField.name] = {
        filePath: result.filePath,
        fileName: file.name
      };
    }

    const formArray: any = item.formRenderInstance.userData;
    const formControls: any[] = [];
    for(const field of formArray) {
      const tmp = field;
      const id = tmp.name || "";
      if (tmp.type === "file") {
        const foundControl = formData[field.name] || {};
        tmp.files = foundControl.files || undefined;
        tmp.fileName = foundControl.fileName || undefined;
        tmp.filePath = foundControl.filePath || undefined;
      }

      // fix saving for checkbox group
      if (tmp.type == "checkbox-group") {
        const userData = tmp.userData || [];
        if (userData.length) {
          for (const val of tmp.values) {
            if (userData.includes(val.value)) {
              val.selected = true;
            } else {
              val.selected = false;
            }
          }
        }
        const other = $(`#${id}-other`);
        const otherValue = $(`#${id}-other-value`);
        if (other.length && other.prop("checked") && otherValue.length) {
          tmp.selectedOther = true;
          tmp.otherValue = otherValue.val().trim();
        } else {
          delete tmp.selectedOther;
          delete tmp.otherValue;
        }
      }

      // collect userData for medication element
      if (tmp.type == "medication") {
        const noneOption = renderDiv.find(`#${id}-none-option`);
        const noneOptionChecked = noneOption.prop("checked") == true;
        tmp.noneOptionChecked = noneOptionChecked;
        tmp.userData = [];
        renderDiv.find(`.field-${id} .medication-info`).each((i, obj) => {
          const medicationInfo: any = $(obj);
          let medicalId = medicationInfo.data("medical-id") || 0;
          // Handle using data rewind  
          if (item.isRecycled === 2) {
            medicalId = 0;
          }
          const medicationName =
            medicationInfo
              .find(`input[type=text][id*="medication-name"]`)
              .val()
              .trim() || "";
          // const medicationRoute =
          //   medicationInfo.find(`select[id*="medication-route"]`).val() || "";
          // const frequency =
          //   medicationInfo.find(`select[id*="frequency"]`).val() || "";
          // const notes =
          //   medicationInfo
          //     .find(`input[type=text][id*="-notes"]`)
          //     .val()
          //     .trim() || "";
          const medicationStrength = medicationInfo
            .find(`input[type=text][id*="medication-strength"]`)
            .val()
            .trim() || "";
          const dispenseMethod = medicationInfo.find(`select[id*="medication-dispense-method"]`).val() || "";
          const dosagesVal = medicationInfo.find(`.medication-dosages-cb input[type=checkbox]:checked`).map((i, e) => $(e).val()).toArray();
          let dosageOther = "";
          if(dosagesVal.includes("6")) {
            dosageOther = medicationInfo.find(`input[type=text][id*="medication-other-dosage"]`).val().trim() || "";
          }
          const specialInstructions = medicationInfo.find(`textarea[id*="medication-instructions"]`).val().trim() || "";
          const prescribingDoctor =
            medicationInfo
              .find(`input[type=text][id*="prescribing-doctor"]`)
              .val()
              .trim() || "";
          const prescriptionObj =
            medicationInfo
              .find(`input[type=file][id*="attach-prescription"]`)
              .attr("files") || "[]";
          const files = JSON.parse(prescriptionObj);
          let prescriptionName = "";
          let prescription = "";
          if (files.length) {
            prescriptionName = files[0].name || "";
            prescription = files[0].path || "";
          }
          tmp.userData.push({
            medicalId,
            medicationName,
            // medicationRoute,
            // frequency,
            // notes,
            medicationStrength,
            dispenseMethod,
            dosages: dosagesVal.join(","),
            dosageOther,
            specialInstructions,
            prescribingDoctor,
            prescriptionName,
            prescription
          });
        });

        // if checked N/A option
        const deletedIds =
          renderDiv.find(`.field-${id}`).attr("data-deleted-medicalids") || "";
        let currentMedicalId = [];
        if (noneOptionChecked) {
          currentMedicalId = tmp.userData
            .filter((t) => t.medicalId > 0)
            .map((t) => t.medicalId);
          tmp.userData = [];
        }

        // append deletedIds
        tmp.deletedIds = [
          ...deletedIds.split(",").filter((id) => id != ""),
          ...currentMedicalId
        ].join(",");
      }

      // collect userData for allergies
      if (tmp.type == "allergies") {
        const noneOption = renderDiv.find(`#allergies-${id}-none-option`);
        const noneOptionChecked = noneOption.prop("checked") == true;
        tmp.noneOptionChecked = noneOptionChecked;
        tmp.userData = [];
        renderDiv.find(`.field-${id} .allergies-info`).each((i, obj) => {
          const info: any = $(obj);
          let allergiesId = info.data("allergies-id") || 0;
           // Handle using data rewind  
           if (item.isRecycled === 2) {
            allergiesId = 0;
          }
          const allergiesName =
            info
              .find(`input[type=text][id*="allergies-name"]`)
              .val()
              .trim() || "";
          const allergiesCategory =
            info.find(`select[id*="allergies-category"]`).val() || "";
          const allergiesSeverity =
            info.find(`select[id*="allergies-severity"]`).val() || "";

          tmp.userData.push({
            allergiesId,
            allergiesName,
            allergiesCategory,
            allergiesSeverity
          });
        });

        // if checked N/A option
        const deletedIds =
          renderDiv.find(`.field-${id}`).attr("data-deleted-allergiesids") ||
          "";
        let currentAllergiesId = [];
        if (noneOptionChecked) {
          currentAllergiesId = tmp.userData
            .filter((t) => t.allergiesId > 0)
            .map((t) => t.allergiesId);
          tmp.userData = [];
        }

        // append deletedIds
        tmp.deletedIds = [
          ...deletedIds.split(",").filter((id) => id != ""),
          ...currentAllergiesId
        ].join(",");
      }

      // collect userData for transportation
      if (tmp.type == "transportation") {
        tmp.userData = {
          arriving: {
            value: "",
            whoDrivingYou: "",
            airlines: "",
            flightNumber: "",
            dateTime: "",
            other: ""
          },
          departing: {
            value: "",
            whoDrivingYou: "",
            airlines: "",
            flightNumber: "",
            dateTime: "",
            other: ""
          }
        };
        const field = renderDiv.find(`.field-${id}`);
        // arriving
        const arriving = field.find(".arrival-section");
        const selectedArriving = arriving.find("input:checked")?.val() || "";
        tmp.userData.arriving.value = selectedArriving;
        tmp.userData.arriving.whoDrivingYou =
          selectedArriving == "Car"
            ? arriving
                .find(`input[type=text][id*="-car-selected"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.arriving.airlines =
          selectedArriving == "Airplane"
            ? arriving
                .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.arriving.flightNumber =
          selectedArriving == "Airplane"
            ? arriving
                .find(`input[type=text][id*="-airplane-selected-flightNumber"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.arriving.dateTime =
          selectedArriving == "Airplane"
            ? arriving
                .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.arriving.other =
          selectedArriving == "Other"
            ? arriving
                .find(`input[type=text][class*="transportation-other"]`)
                .val()
                .trim() || ""
            : "";

        // departing
        const departing = field.find(".departure-section");
        const selectedDeparting = departing.find("input:checked")?.val() || "";
        tmp.userData.departing.value = selectedDeparting;
        tmp.userData.departing.whoDrivingYou =
          selectedDeparting == "Car"
            ? departing
                .find(`input[type=text][id*="-car-selected"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.departing.airlines =
          selectedDeparting == "Airplane"
            ? departing
                .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.departing.flightNumber =
          selectedDeparting == "Airplane"
            ? departing
                .find(`input[type=text][id*="-airplane-selected-flightNumber"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.departing.dateTime =
          selectedDeparting == "Airplane"
            ? departing
                .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                .val()
                .trim() || ""
            : "";
        tmp.userData.departing.other =
          selectedDeparting == "Other"
            ? departing
                .find(`input[type=text][class*="transportation-other"]`)
                .val()
                .trim() || ""
            : "";
      }

      // collect userData for medication element
      if (tmp.type == "addressBlock") {
           tmp.userData = [];
          $(`.field-${id} .address-block-info`).each((i, obj) => {
            const addressBlockInfo: any = $(obj);
            const lAddressInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-address"]`)
                .val()
                .trim() || "";
            const lAddressInput2 =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-address2"]`)
                .val()
                .trim() || "";
            const lStateInput =
            addressBlockInfo.find(`select[id*="address-block-state"]`).val() || "";
            const lCityInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-city"]`)
                .val()
                .trim() || "";
            const lZipInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-zip"]`)
                .val()
                .trim() || "";

            tmp.userData.push({
              lAddressInput,
              lAddressInput2,
              lCityInput,
              lStateInput,
              lZipInput
            });
          });

        }

      if (tmp.type === "signature") {

        const divCanvas = $('canvas#' + item.participantID + "_" + item.formID + "_" + field.name);
        const signatureData = divCanvas.attr('data-signature') || '';
        const signatureNew = divCanvas.attr('data-new') || '';
        if (signatureNew === '1') {
          const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
            uuid: ApiHelper.getUuid(),
            domain: ApiHelper.getDomain(),
            base64: signatureData,
            group: "profiles/" + item.profileID + '/signatures',
            id: item.profileID,
            data: {
              fileName: ''
            }
          });
          if (result.status === 1) {
            tmp.signature = result.data.url;
            divCanvas.attr('data-new', '');
          } else {
            tmp.signature = signatureData;
          }
        } else {
          tmp.signature = signatureData;
        }

      }

      formControls.push(tmp);
    }

    return formControls;
  }

  handleInputErrors(item) {
    const $this = this;
    let valid = true;
    const form: any = $("#form-" + item.participantID + "_" + item.formID);
    form
      .find(".rendered-form .border-danger, .rendered-form .text-danger")
      .removeClass("border-danger text-danger");
    form.find("input[type=radio][required]").each(function(index, control) {
      var _input = $(control);
      if (_input.attr("type") == "radio") {
        var radioName = _input.attr("name");
        if (
          form.find('input[type=radio][name="' + radioName + '"]:checked')
            .length == 0 &&
          form.find('input[type=radio][name="' + radioName + '[]"]:checked')
            .length == 0
        ) {
          $(control).addClass("border-danger");
          $(control)
            .parents(".form-group")
            .addClass("text-danger");
          valid = false;
        } else {
          if (
            form
              .find('input[type=radio][name="' + radioName + '"]:checked')
              .val() == ""
          ) {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        }
      }
    });
    form
      .find(
        "input[type=text][required], input[type=email][required], input[type=date][required]"
      )
      .each(function(index, control) {
        if ($(control).val() == "") {
          $(control).addClass("border-danger");
          $(control)
            .parents(".form-group")
            .addClass("text-danger");
          valid = false;
        }
      });
    form.find("input[type=file][required]").each(function(index, control) {
      const files = JSON.parse($(control).attr("files") || "[]");
      if (files.length == 0) {
        $(control).addClass("border-danger");
        $(control)
          .parents(".form-group")
          .addClass("text-danger");
      }
    });
    form.find("textarea[required]").each(function(index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control)
          .parents(".form-group")
          .addClass("text-danger");
        valid = false;
      }
    });
    form.find(".formbuilder-radio-group").each(function(index, control) {
      if (
        $(control).find("input[type=radio][required]").length > 0 &&
        $(control).find("input[type=radio][required]:checked").length == 0
      ) {
        $(control).addClass("border-danger");
        $(control)
          .parents(".form-group")
          .addClass("text-danger");
        valid = false;
      }
    });
    form.find(".formbuilder-checkbox-group").each(function(index, control) {
      if (
        $(control).find("input[type=checkbox][required]").length > 0 &&
        $(control).find("input[type=checkbox][required]:checked").length == 0
      ) {
        $(control)
          .addClass("border-danger text-danger")
          .find("input[type=checkbox]")
          .addClass("border-danger");
        valid = false;
      }
    });
    form.find("select[required]").each(function(index, control) {
      if ($(control).val() == 0) {
        $(control).addClass("border-danger");
        $(control)
          .parent()
          .addClass("text-danger");
        valid = false;
      }
    });

    // validate for formbuilder-medication
    this.validateMedication(item);
    $(".formbuilder-medication.text-danger").each(function(index, control) {
      const noneOption = $(control).find(
        "input[type=checkbox][id*='none-option']"
      );
      const noneOptionChecked = noneOption.prop("checked") == true;
      if (noneOptionChecked) {
        $(control)
          .find(".text-danger, .border-danger")
          .removeClass("text-danger border-danger");
      }

      $(control).removeClass("text-danger");
      $(control)
        .find(".medication-notes")
        .removeClass("text-danger")
        .find("input[type=text]")
        .removeClass("border-danger");
      if ($(control).find(".text-danger").length) {
        $(control)
          .find("label.formbuilder-medication-label")
          .addClass("text-danger");
      }
    });

    // validate for formbuilder-allergies
    this.validateAllergies(item);
    $(".formbuilder-allergies.text-danger").each(function(index, control) {
      const noneOption = $(control).find(
        "input[type=checkbox][id*='none-option']"
      );
      const noneOptionChecked = noneOption.prop("checked") == true;
      if (noneOptionChecked) {
        $(control)
          .find(".text-danger, .border-danger")
          .removeClass("text-danger border-danger");
      }

      $(control).removeClass("text-danger");
      if ($(control).find(".text-danger").length) {
        $(control)
          .find("label.formbuilder-allergies-label")
          .addClass("text-danger");
      }
    });

    // validate for transportation
    $(".formbuilder-transportation.text-danger").each(function(index, control) {
      $this.validateTransportation($(control));
    });

    // validate for address block
    this.validateAddressBlock(item);
    $(".formbuilder-addressBlock.text-danger").each(function(index, control) {
      $(control).removeClass("text-danger");
      if ($(control).find(".text-danger").length) {
        $(control)
          .find("label.formbuilder-addressBlock-label")
          .addClass("text-danger");
      }
    });

    if (!valid) {
      form.animate(
        {
          scrollTop:
            form.scrollTop() -
            form.offset().top +
            form.find(".border-danger:first").offset().top
        },
        {
          duration: "medium",
          easing: "swing"
        }
      );
    }
  }

  async saveForm(item) {
    // if ((item.link_epf_status || 0) == TodoStatus.Pending) return;

    this.isSubmitting = true;
    const formControls = await this.getFormValues(item);
    const loggedUser = ApiHelper.getLoggedUser();
    const result = await ApiHelper.apiPost("/saveTask", {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: loggedUser.id,
      formID: item.formID,
      participantID: item.participantID,
      linkevfID: item.linkevfID,
      linkContent: JSON.stringify(formControls),
      todoProfileId: item.profileID
    });
    this.isSubmitting = false;
    if (result.status === 1) {
      ApiHelper.showSuccessMessage("Saved");
      this.activeTab = "";
      await ApiHelper.updateProfileStats();
      await this.fetchData(false);
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  }

  async submitForm(item) {
    // if ((item.link_epf_status || 0) == TodoStatus.Pending) return;

    const percentInfo = this.getPercent(item);
    const loggedUser = ApiHelper.getLoggedUser();
    if (
      percentInfo.percentComplete === 100 &&
      this.validateMedication(item) == true &&
      this.validateAllergies(item) == true && 
      this.validateAddressBlock(item) == true
    ) {
      this.isSubmitting = true;
      const formControls = await this.getFormValues(item);
      const result = await ApiHelper.apiPost("/submitTask", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: loggedUser.id,
        formID: item.formID,
        percent: percentInfo.percentComplete,
        participantID: item.participantID,
        linkevfID: item.linkevfID,
        linkContent: JSON.stringify(formControls),
        todoProfileId: item.profileID
      });
      this.isSubmitting = false;
      if (result.status === 1) {
        ApiHelper.showSuccessMessage("Submitted");
        await ApiHelper.updateProfileStats();
        this.activeTab = "";
        await this.fetchData(false);
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    } else {
      this.handleInputErrors(item);
      ApiHelper.showErrorMessage(
        "You have not completed the form yet",
        "Form Error"
      );
    }
  }

  private async changeProfileAvatar(base64: string, type?: string, item?: any) {
    let hasError = false;
    let message = '';
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      if (item.profileid) {
        // this.formData.isLoading = true;
        ApiHelper.apiPost('/updateProfileAvatar', {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          logoBase64: base64,
          type: type,
          profileID: item.profileid
        }).then(async (result2) => {
          this.showPopupPhoto(false);
          // this.formData.isLoading = false;
          if (result2.status == 1) {
            await this.fetchData(false);
            ApiHelper.showSuccessMessage('Updated Photo');
          } else {
            ApiHelper.showErrorMessage(result2.message, 'Oops');
          }
        });
      }
       
    }
  }

  validateMedication(formItem) {
    // check if inputted medication info correctly
    // validate for medication not required, but not inputted full info for medication name/route/frequency
    let isValid = true;
    const data = formItem.formRenderInstance.userData;
    const medication = data.filter(
      (item) => item.type == "medication" && !item.required
    );

    for (const item of medication) {
      const id = item.name;
      const noneOption = $(`#${id}-none-option`);
      const noneOptionChecked = noneOption.prop("checked") == true;
      if (noneOptionChecked) continue;

      $(`.field-${id} .medication-info`).each((i, obj) => {
        const medicationInfo: any = $(obj);
        const medicationName = medicationInfo.find(
          "input[type=text][id*=medication-name]"
        );
        const medicationNameVal = (medicationName?.val() || "").trim();

        // const medicationRoute = medicationInfo.find(
        //   "select[id*=medication-route]"
        // );
        // const medicationRouteVal = (medicationRoute?.val() || "").trim();
        // const frequency = medicationInfo.find("select[id*=frequency]");
        // const frequencyVal = (frequency?.val() || "").trim();

        const dispenseMethod = medicationInfo.find("select[id*=medication-dispense-method]");
        const dispenseMethodVal = (dispenseMethod?.val() || "").trim();
        const dosagesVal = medicationInfo.find(`.medication-dosages-cb input[type=checkbox]:checked`).map((i, e) => $(e).val()).toArray();
        const dosageOtherVal = medicationInfo.find(`input[type=text][id*="medication-other-dosage"]`).val().trim() || "";
        const prescribingDoctor = medicationInfo.find(
          `input[type=text][id*="prescribing-doctor"]`
        );
        const prescribingDoctorVal = (prescribingDoctor?.val() || "").trim();

        if (
          !(
            medicationNameVal == "" &&
            // medicationRouteVal == "" &&
            // frequencyVal == "" &&
            dispenseMethodVal == "" && 
            !dosagesVal.length && 
            prescribingDoctorVal == ""
          )
        ) {
          if (medicationNameVal == "") {
            medicationName
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
            isValid = false;
          }
          if (dispenseMethodVal == "") {
            dispenseMethod
              .addClass("border-danger")
              .closest(".formbuilder-select")
              .addClass("text-danger");
            isValid = false;
          }
          if(!dosagesVal.length) {
            medicationInfo.find(`.medication-dosages-cb input[type=checkbox]`).addClass("border-danger");
            isValid = false;
          }else if(dosagesVal.includes("6") && dosageOtherVal == "") {
            isValid = false;
          }

          // if (medicationRouteVal == "") {
          //   medicationRoute
          //     .addClass("border-danger")
          //     .closest(".formbuilder-select")
          //     .addClass("text-danger");
          //   isValid = false;
          // }
          // if (frequencyVal == "") {
          //   frequency
          //     .addClass("border-danger")
          //     .closest(".formbuilder-select")
          //     .addClass("text-danger");
          //   isValid = false;
          // }
          if (prescribingDoctorVal == "") {
            prescribingDoctor
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
            isValid = false;
          }
        }
      });
    }

    return isValid;
  }

  validateAddressBlock(formItem) {
    // check if inputted medication info correctly
    // validate for medication not required, but not inputted full info for medication name/route/frequency
    let isValid = true;
    const data = formItem.formRenderInstance.userData;
    const addressblock = data.filter(
      (item) => item.type == "addressBlock" && !item.required
    );
    for (const item of addressblock) {
      const id = item.name;
       
      $(`.field-${id} .address-block-info`).each((i, obj) => {
        const addressBlockInfo: any = $(obj);
        const lAddressInput = addressBlockInfo.find(
          "input[type=text][id*=address-block-address]"
        );
        const lAddressInputVal = (lAddressInput?.val() || "").trim();
        const lStateInput = addressBlockInfo.find(
          "select[id*=address-block-state]"
        );
        const lStateInputVal = (lStateInput?.val() || "").trim();
        const lCityInput = addressBlockInfo.find("input[type=text][id*=address-block-city]");
        const lCityInputVal = (lCityInput?.val() || "").trim();
        const lZipInput = addressBlockInfo.find(
          `input[type=text][id*="address-block-zip"]`
        );
        const lZipInputVal = (lZipInput?.val() || "").trim();

        if (
          !(
            lAddressInputVal == "" &&
            lStateInputVal == "" &&
            lCityInputVal == "" &&
            lZipInputVal == ""
          )
        ) {
          if (lAddressInputVal == "") {
            lAddressInput
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
            isValid = false;
          }
          if (lStateInputVal == "") {
            lStateInput
              .addClass("border-danger")
              .closest(".formbuilder-select")
              .addClass("text-danger");
            isValid = false;
          }
          if (lCityInputVal == "") {
            lCityInput
              .addClass("border-danger")
              .closest(".formbuilder-select")
              .addClass("text-danger");
            isValid = false;
          }
          if (lZipInputVal == "") {
            lZipInput
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
            isValid = false;
          }
        }
      });
    }

    return isValid;
  }

  validateAllergies(formItem) {
    // check if inputted allergies info correctly
    // validate for allergies not required, but not inputted full info for name/category/severity
    let isValid = true;
    const renderDiv: any = $(
      "#form-" + formItem.participantID + "_" + formItem.formID
    );
    const data = formItem.formRenderInstance.userData;
    const allergies = data.filter(
      (item) => item.type == "allergies" && !item.required
    );
    for (const item of allergies) {
      const id = item.name;
      const noneOption = renderDiv.find(`#${id}-none-option`);
      const noneOptionChecked = noneOption.prop("checked") == true;
      if (noneOptionChecked) continue;

      renderDiv.find(`.field-${id} .allergies-info`).each((i, obj) => {
        const info: any = $(obj);
        const allergiesName = info.find("input[type=text][id*=allergies-name]");
        const allergiesCategory = info.find("select[id*=allergies-category]");
        const allergiesSeverity = info.find("select[id*=allergies-severity]");
        const allergiesNameVal = allergiesName.val().trim();
        const allergiesCategoryVal = allergiesCategory.val().trim();
        const allergiesSeverityVal = allergiesSeverity.val().trim();

        if (!(allergiesNameVal == "" && allergiesCategoryVal == "")) {
          if (allergiesNameVal == "") {
            allergiesName
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
            isValid = false;
          }
          if (allergiesCategoryVal == "") {
            allergiesCategory
              .addClass("border-danger")
              .closest(".formbuilder-select")
              .addClass("text-danger");
            isValid = false;
          }
          if (allergiesCategoryVal == "Food" && allergiesSeverityVal == "") {
            allergiesSeverity
              .addClass("border-danger")
              .closest(".formbuilder-select")
              .addClass("text-danger");
            isValid = false;
          }
        }
      });
    }

    return isValid;
  }

  validateTransportation(transportationDiv: any) {
    if (transportationDiv.find(".text-danger").length == 0) return;

    transportationDiv.removeClass("text-danger");
    const field = transportationDiv;
    let invalid = false;
    // arriving
    const arriving = field.find(".arrival-section").removeClass("text-danger");
    const selectedArriving = arriving.find("input:checked")?.val() || "";
    if (selectedArriving != "") {
      if (selectedArriving == "Car") {
        const whoDrivingYou = arriving.find(
          `input[type=text][id*="-car-selected"]`
        );
        if ((whoDrivingYou?.val().trim() || "") == "") {
          invalid = true;
          whoDrivingYou
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
      } else if (selectedArriving == "Airplane") {
        const airlines = arriving.find(
          `input[type=text][id*="-airplane-selected-airlines"]`
        );
        const flightNumber = arriving.find(
          `input[type=text][id*="-airplane-selected-flightNumber"]`
        );
        const dateTime = arriving.find(
          `input[type=text][id*="-airplane-selected-dateTime"]`
        );
        if ((airlines?.val().trim() || "") == "") {
          invalid = true;
          airlines
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
        if ((flightNumber?.val().trim() || "") == "") {
          invalid = true;
          flightNumber
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
        if ((dateTime?.val().trim() || "") == "") {
          invalid = true;
          dateTime
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
      }
    } else {
      invalid = true;
      arriving.addClass("text-danger");
    }

    // departing
    const departing = field
      .find(".departure-section")
      .removeClass("text-danger");
    const selectedDeparting = departing.find("input:checked")?.val() || "";
    if (selectedDeparting != "") {
      if (selectedDeparting == "Car") {
        const whoDrivingYou = departing.find(
          `input[type=text][id*="-car-selected"]`
        );
        if ((whoDrivingYou?.val().trim() || "") == "") {
          invalid = true;
          whoDrivingYou
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
      } else if (selectedDeparting == "Airplane") {
        const airlines = departing.find(
          `input[type=text][id*="-airplane-selected-airlines"]`
        );
        const flightNumber = departing.find(
          `input[type=text][id*="-airplane-selected-flightNumber"]`
        );
        const dateTime = departing.find(
          `input[type=text][id*="-airplane-selected-dateTime"]`
        );
        if ((airlines?.val().trim() || "") == "") {
          invalid = true;
          airlines
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
        if ((flightNumber?.val().trim() || "") == "") {
          invalid = true;
          flightNumber
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
        if ((dateTime?.val().trim() || "") == "") {
          invalid = true;
          dateTime
            .addClass("border-danger")
            .closest(".formbuilder-text")
            .addClass("text-danger");
        }
      }
    } else {
      invalid = true;
      departing.addClass("text-danger");
    }
    if (invalid) {
      field
        .find("label.formbuilder-transportation-label")
        .addClass("text-danger");
    } else {
      field.find(".text-danger").removeClass("text-danger");
    }
  }

  getPercent(formItem) {
    let ret = {
      questionsNeeded: 0,
      questionsDone: 0,
      percentComplete: 0
    };
    const renderDiv: any = $(
      "#form-" + formItem.participantID + "_" + formItem.formID
    );
    const data = formItem.formRenderInstance.userData;
    if (data.length) {
      const requires = data.filter(
        (item: any) =>
          item.required &&
          (item.name || "").indexOf("explain-conditionalOptions-") == -1
      );
      ret.questionsNeeded = requires.length;
      for (const item of requires) {
        const id = item.name || "";
        if (
          ["text", "date", "textarea"].includes(item.type) &&
          typeof item.userData[0] == "string" &&
          item.userData[0] != ""
        ) {
          ret.questionsDone += 1;
        }
        if (
          ["select", "checkbox-group", "radio-group"].includes(item.type) &&
          typeof item.userData != "undefined"
        ) {
          ret.questionsDone += 1;
        }
        if (item.type === "file") {
          try {
            const files = JSON.parse(
              $("#form-" + formItem.participantID + "_" + formItem.formID)
                .find("#" + item.name)
                .attr("files") || "[]"
            );
            if (files.length > 0) {
              ret.questionsDone += 1;
            }
          } catch (e) {
            // nothing
          }
        }

        // validate for conditional options item
        if (item.type == "conditionalOptions" && (item.userData || []).length) {
          const itemVal = item.userData[0] || "";
          if (itemVal == "Yes") {
            // check "please explain" textbox
            const pleaseExplanTxt = data.find(
              (t) => t.type == "text" && t.name == `explain-${item.name}`
            );
            const val = pleaseExplanTxt?.userData[0] || "";
            if (val != "") {
              ret.questionsDone += 1;
            }
          } else if (itemVal == "No") {
            ret.questionsDone += 1;
          }
        }

        // validate for medication
        if (item.type == "medication") {
          const noneOption = $(`#${id}-none-option`);
          const noneOptionChecked = noneOption.prop("checked") == true;
          if (noneOptionChecked) {
            ret.questionsDone += 1;
            continue;
          }

          let invalidMedication = false;
          $(`.field-${id} .medication-info`).each((i, obj) => {
            const medicationInfo: any = $(obj);
            const medicationName =
              medicationInfo
                .find("input[type=text][id*=medication-name]")
                .val()
                .trim() || "";
            const dispenseMethod = medicationInfo.find(`select[id*="medication-dispense-method"]`).val() || "";
            const dosagesVal = medicationInfo.find(`.medication-dosages-cb input[type=checkbox]:checked`).map((j, e) => $(e).val()).toArray();
            let dosageOther = "";
            if(dosagesVal.includes("6")) {
              dosageOther = medicationInfo.find(`input[type=text][id*="medication-other-dosage"]`).val().trim() || "";
            }
            if(dispenseMethod == "") {
              invalidMedication = true;
            }
            if(!dosagesVal.length || (dosagesVal.includes("6") && dosageOther == "")) {
              invalidMedication = true;
            }
            // const medicationRoute =
            //   medicationInfo
            //     .find("select[id*=medication-route]")
            //     .val()
            //     .trim() || "";
            // const frequency =
            //   medicationInfo
            //     .find("select[id*=frequency]")
            //     .val()
            //     .trim() || "";
            const prescribingDoctor =
              medicationInfo
                .find(`input[type=text][id*="prescribing-doctor"]`)
                .val()
                .trim() || "";

            if (medicationName == "") {
              invalidMedication = true;
            }
            // if (medicationRoute == "") {
            //   invalidMedication = true;
            // }
            // if (frequency == "") {
            //   invalidMedication = true;
            // }
            if (prescribingDoctor == "") {
              invalidMedication = true;
            }
          });
          if (!invalidMedication) {
            ret.questionsDone += 1;
          }
        }

        // validate for allergies
        if (item.type == "allergies") {
          const noneOption = renderDiv.find(`#allergies-${id}-none-option`);
          const noneOptionChecked = noneOption.prop("checked") == true;
          if (noneOptionChecked) {
            ret.questionsDone += 1;
            continue;
          }

          let invalid = false;
          renderDiv.find(`.field-${id} .allergies-info`).each((i, obj) => {
            const info: any = $(obj);
            const allergiesName =
              info
                .find("input[type=text][id*=allergies-name]")
                .val()
                .trim() || "";
            const allergiesCategory =
              info
                .find("select[id*=allergies-category]")
                .val()
                .trim() || "";
            const allergiesSeverity =
              info
                .find("select[id*=allergies-severity]")
                .val()
                .trim() || "";
            if (
              allergiesName == "" ||
              allergiesCategory == "" ||
              (allergiesCategory == "Food" && allergiesSeverity == "")
            ) {
              invalid = true;
            }
          });
          if (!invalid) {
            ret.questionsDone += 1;
          }
        }

        // validate for transportation
        if (item.type == "transportation") {
          const field = renderDiv.find(`.field-${id}`);
          let invalid = false;
          // arriving
          const arriving = field.find(".arrival-section");
          const selectedArriving = arriving.find("input:checked")?.val() || "";
          if (selectedArriving != "") {
            if (selectedArriving == "Car") {
              const whoDrivingYou =
                arriving
                  .find(`input[type=text][id*="-car-selected"]`)
                  .val()
                  .trim() || "";
              if (whoDrivingYou == "") {
                invalid = true;
              }
            } else if (selectedArriving == "Airplane") {
              const airlines =
                arriving
                  .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                  .val()
                  .trim() || "";
              const flightNumber =
                arriving
                  .find(
                    `input[type=text][id*="-airplane-selected-flightNumber"]`
                  )
                  .val()
                  .trim() || "";
              const dateTime =
                arriving
                  .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                  .val()
                  .trim() || "";
              if (airlines == "" || flightNumber == "" || dateTime == "") {
                invalid = true;
              }
            }
          } else {
            invalid = true;
          }

          // departing
          const departing = field.find(".departure-section");
          const selectedDeparting =
            departing.find("input:checked")?.val() || "";
          if (selectedDeparting != "") {
            if (selectedDeparting == "Car") {
              const whoDrivingYou =
                departing
                  .find(`input[type=text][id*="-car-selected"]`)
                  .val()
                  .trim() || "";
              if (whoDrivingYou == "") {
                invalid = true;
              }
            } else if (selectedDeparting == "Airplane") {
              const airlines =
                departing
                  .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                  .val()
                  .trim() || "";
              const flightNumber =
                departing
                  .find(
                    `input[type=text][id*="-airplane-selected-flightNumber"]`
                  )
                  .val()
                  .trim() || "";
              const dateTime =
                departing
                  .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                  .val()
                  .trim() || "";
              if (airlines == "" || flightNumber == "" || dateTime == "") {
                invalid = true;
              }
            }
          } else {
            invalid = true;
          }

          if (!invalid) {
            ret.questionsDone += 1;
          }
        }

        // validate for address block 
        if (item.type == "addressBlock") {
          let invalidAddressBlock = false;
          $(`.field-${id} .address-block-info`).each((i, obj) => {
            const addressBlockInfo: any = $(obj);
            const lAddressInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-address"]`)
                .val()
                .trim() || "";
            const lStateInput =
            addressBlockInfo.find(`select[id*="address-block-state"]`).val() || "";
            const lCityInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-city"]`)
                .val()
                .trim() || "";
            const lZipInput =
            addressBlockInfo
                .find(`input[type=text][id*="address-block-zip"]`)
                .val()
                .trim() || "";

            if (lAddressInput == "") {
              invalidAddressBlock = true;
            }
            if (lStateInput == "") {
              invalidAddressBlock = true;
            }
            if (lCityInput == "") {
              invalidAddressBlock = true;
            }
            if (lZipInput == "") {
              invalidAddressBlock = true;
            }
          });
          if (!invalidAddressBlock) {
            ret.questionsDone += 1;
          }
        }

        if(["signature"].includes(item.type)) {
          const signatureCanvas = $('#'+ formItem.participantID + "_" + formItem.formID + "_" + item.name);
          const strSignature = signatureCanvas.attr('data-signature');
          if (strSignature) {
            ret.questionsDone += 1;
          }
        }

      }
      if (ret.questionsNeeded > 0) {
        ret.percentComplete = parseFloat(
          ((ret.questionsDone / ret.questionsNeeded) * 100).toFixed(2)
        );
      } else {
        ret.percentComplete = 100;
      }
    } else {
      ret.percentComplete = 100;
    }
    const findItem = this.todoItems.find((item2: any) => {
      return item2.participantID == formItem.participantID && item2.formID == formItem.formID;
    });
    if (findItem) {
      findItem.questionsNeeded = ret.questionsNeeded;
      findItem.questionsDone = ret.questionsDone;
    }
    return ret;
  }

  async openInviteForm(item) {
    const inviteActiveTab = `invite_${item.participantID}`;
    if (inviteActiveTab == this.inviteActiveTab) {
      // click on same tab, close this
      this.inviteActiveTab = "";
    } else {
      // open this tab
      this.inviteActiveTab = inviteActiveTab;
    }

    // scroll to inviteActiveTab
    if (this.inviteActiveTab != "") {
      const tabId = `invite_${item.participantID}`;

      setTimeout(() => {
        if ($(`#${tabId}`).length) {
          $("html, body").animate({
            scrollTop: $(`#${tabId}`).offset().top
          });
          // $("body").addClass("no-scroll");
        }
      }, 100);
    } else {
      $("body").removeClass("no-scroll");
    }
  }

  // async acceptRoomateRequest(accepted = true, roomateItem: any = {}) {
  //   if (
  //     this.loading == true ||
  //     ((roomateItem.toParticipantId || 0) > 0 &&
  //       (roomateItem.toParticipantStatus || 0) == ParticipantStatus.Denied)
  //   ) {
  //     return;
  //   }

  //   try {
  //     this.loading = true;
  //     const requestObject: any = {
  //       domain: ApiHelper.getDomain(),
  //       uuid: ApiHelper.getUuid(),
  //       roommateRequestId: roomateItem.requestId,
  //       eventID: roomateItem.eventId,
  //       profileID: roomateItem.profileId,
  //       participantID: roomateItem.toParticipantId,
  //       acceptInviteCode: roomateItem.invitedCode,
  //       accepted
  //     };

  //     // more info incase accept/reject a request on list "You've been invited by"
  //     if ((roomateItem.ptypeId || 0) > 0) {
  //       requestObject.pTypeId = roomateItem.ptypeId;
  //     }

  //     const response = await axios.post(
  //       `${dataURL}/saveEventCabins`,
  //       requestObject
  //     );
  //     if (response.data.status == 1) {
  //       if (accepted) {
  //         if ((roomateItem.ptypeId || 0) > 0) {
  //           roomateItem.linkActive = 1;
  //         }
  //         ApiHelper.showSuccessMessage("Accepted the invitation successfully");
  //       } else {
  //         // message for reject a request
  //         roomateItem.linkActive = 2;

  //         // remove in roommate requests list
  //         this.roomateRequests = this.roomateRequests.filter(
  //           (item) => item.requestId != roomateItem.requestId
  //         );

  //         ApiHelper.showSuccessMessage("You rejected the invitation");
  //       }
  //     } else if (response.data.message != "") {
  //       ApiHelper.showErrorMessage(response.data.message);
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  listItemsShowMore(take: number, list: any = []) {
    let count = 0;
    list.map((item: any) => {
      if (!item.isShow && count < take) {
        item.isShow = true;
        count = count + 1;
      }
    });
    this.$forceUpdate();
  }

  listItemsGetTotalHide(list: any = []) {
    return list.filter((item: any) => !item.isShow).length;
  }

  updateTotalQuestions(participantId: number, formId: number, total: number) {
    this.todoItems.map((item) => {
      if (item.participantID == participantId && item.formID == formId) {
        item.questions = total;
      }
    });
    this.completeItems.map((item) => {
      item.questionsNeeded = 0;
      item.questionsDone = 0;
      if (item.participantID == participantId && item.formID == formId) {
        item.questions = total;
      }
    });
  }

  hideQuestion(){
    $(".todo_question").css("display", "none");
  }

  // onClickSendRmButton() {
  //   setTimeout(() => {
  //     const ps: any = this.$refs.participantsPs;
  //     if (ps) {
  //       ps.update();
  //     }
  //   }, 200);
  // }

  // selectedInviter(item: any) {
  //   this.inviterInfo = {
  //     eventId: item.eventID,
  //     pTypeId: item.participant_typeID,
  //     participantId: item.participantID,
  //     profileId: item.profileid,
  //     data: item
  //   };
  //   const rmRequestModal: any = this.$refs.rmRequestModal;
  //   if (rmRequestModal && rmRequestModal.inviteData) {
  //     rmRequestModal.inviteData.email = "";
  //     rmRequestModal.inviteData.firstName = "";
  //     rmRequestModal.inviteData.lastName = "";
  //   }

  //   // hide current tooltip
  //   $(
  //     ".send-rm-button .dropdown-toggle, .send-rm-button .dropdown-menu"
  //   ).removeClass("show");

  //   // show send roommate requests modal
  //   const rmModal = new bootstrap.Modal($("#send-rm-modal").get(0), {
  //     //
  //   });
  //   rmModal.show();
  // }

  // closeRoommateRequestsModal() {
  //   $("#send-rm-modal .btn-close").click();
  // }

  // async getRoommateRequests() {
  //   try {
  //     this.loading = true;
  //     const result = await axios.post(`${dataURL}/roommateRequests`, {
  //       domain: ApiHelper.getDomain(),
  //       uuid: ApiHelper.getUuid(),
  //       profileID: this.$loggedUser.id
  //     });
  //     if (result.data.status == 1) {
  //       const data = result.data.data;
  //       this.roomateRequests = data.roomateRequests?.data || [];
  //       this.roomateRequests.forEach((r) => {
  //         r.isShow = false;
  //         r.linkTSFormatted = ApiHelper.formatDate(r.linkTs, 'MMM DD, YYYY', '');
  //       });
  //       this.listItemsShowMore(5, this.roomateRequests);
  //     }
  //   } catch (err) {
  //     // console.log(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  avatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }

}
