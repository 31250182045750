























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
  },
})
export default class Error404 extends TSXComponent<void> {

}
