







































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "../helpers/all";
import directives from "../helpers/directives";
import axios from "axios";

declare let dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: false,
  components: {},
  directives
})
export default class TransactionDetails extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  transactionId?: string;

  @Prop({ required: false, default: "" })
  transactionIds?: string;

  $loggedUser: any;
  transactionDetails: any = {};
  transactionLines: any = [];
  transactionInfos: any = [];
  discounts: any = [];

  async created() {
    const result = await axios.post(`${dataURL}/finance/transactionDetails`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: this.$loggedUser.id,
      transactionId: this.transactionId,
      transactionIds: this.transactionIds,
      type: "transaction"
    });
    if (result.data.status == 1) {
      this.transactionDetails = result.data.data.transactionDetails.map(
        item => {
          item.transactionTSFormatted = ApiHelper.formatDate(
            item.transactionTS,
            "MM/DD/YYYY hh:mm A",
            ""
          );
          return item;
        }
      )[0];
      this.transactionInfos = result.data.data.transactionInfos || [];
      this.transactionLines = result.data.data.transactionLines || [];
      this.discounts = result.data.data.discounts || [];
    }
  }
}
