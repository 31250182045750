




























































































import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: {
    isLoading: Boolean,
    onClose: Function,
    hideFooter: Boolean,
    hideScroll: Boolean,
    title: String,
    size: String,
    bgGray: Boolean,
  }
});
