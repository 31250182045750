
















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import DatePicker from "vue2-datepicker";
import Inputmask from "inputmask";

interface Props {
  data: any;
  onChange: any;
  disabled: any;
}

interface Events {
}

declare const $: any;
@Component({
  inheritAttrs: false,
  components: {
    DatePicker,
  }
})
export default class FormDatePicker extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: true })
  onChange!: any;

  @Prop({ required: true })
  disabled!: boolean;

  randomId = ApiHelper.randomFormId().toString();
  hasError = false;

  mounted() {
    if ($("#datepicker-" + this.randomId).hasClass("date")) {
      Inputmask("99/99/9999", {
        alias: "date",
        placeholder: "MM/DD/YYYY",
        insertMode: false
      }).mask($("#datepicker-" + this.randomId).find(".mx-input"));
    }
  }

  doChange() {
    if (this.onChange) {
      this.onChange();
    }
  }

  disabledDate(date: any) {
    if (
      this.data &&
      (this.data.disabledBefore || this.data.disabledAfter)
    ) {
      if (this.data.disabledBefore && date < this.data.disabledBefore) {
        return true;
      }
      if (this.data.disabledAfter && date > this.data.disabledAfter) {
        return true;
      }
      return false;
    }
    return false;
  }
}
