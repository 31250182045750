var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar slide slide_right left-sidebar-menu"},[_c('router-link',{attrs:{"to":{ name: _vm.defaultRouteName }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [(_vm.$parent.$parent.headerData.isLoaded)?_c('a',{staticClass:"sidebar__logo",attrs:{"href":href}},[_c('picture',[(_vm.$parent.$parent.headerData.logo)?_c('img',{attrs:{"src":_vm.$parent.$parent.headerData.logo,"alt":_vm.$parent.$parent.headerData.title}}):_c('img',{attrs:{"src":require("../../public/img/logo__default.svg"),"alt":_vm.$parent.$parent.headerData.title}})])]):_vm._e()]}}])}),_c('nav',{staticClass:"sidebar__nav"},[_c('ul',{staticClass:"nav",class:{
        'justify-content-start': _vm.$parent.$parent.headerData.customView
      }},[(_vm.$parent.$parent.headerData.customView && _vm.$parent.$parent.headerData.customView.length)?_vm._l((_vm.$parent.$parent.headerData.customView),function(item,index){return _c('li',{key:index,staticClass:"sidebar__item",class:{
            sidebar__item_active: [item.routeName].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: item.routeName }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15.8335 2.375H3.16683C2.29238 2.375 1.5835 3.08388 1.5835 3.95833V11.875C1.5835 12.7495 2.29238 13.4583 3.16683 13.4583H15.8335C16.7079 13.4583 17.4168 12.7495 17.4168 11.875V3.95833C17.4168 3.08388 16.7079 2.375 15.8335 2.375Z","stroke":"red","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6.3335 16.625H12.6668","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M9.5 13.4584V16.625","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"menu_label text-uppercase"},[_vm._v(_vm._s(item.label))])])],1)}):[_c('li',{staticClass:"sidebar__item",class:{
            sidebar__item_active: ['Dashboard'].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'Dashboard' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15.8335 2.375H3.16683C2.29238 2.375 1.5835 3.08388 1.5835 3.95833V11.875C1.5835 12.7495 2.29238 13.4583 3.16683 13.4583H15.8335C16.7079 13.4583 17.4168 12.7495 17.4168 11.875V3.95833C17.4168 3.08388 16.7079 2.375 15.8335 2.375Z","stroke":"red","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6.3335 16.625H12.6668","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M9.5 13.4584V16.625","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"menu_label"},[_vm._v("DASHBOARD")])])],1),_c('li',{staticClass:"sidebar__item",class:{
            sidebar__item_active: ['Todo'].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'Todo' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.5 15.8334H16.625","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M13.0625 2.77081C13.3774 2.45586 13.8046 2.27893 14.25 2.27893C14.4705 2.27893 14.6889 2.32237 14.8927 2.40677C15.0964 2.49116 15.2816 2.61486 15.4375 2.77081C15.5934 2.92675 15.7171 3.11189 15.8015 3.31564C15.8859 3.51939 15.9294 3.73777 15.9294 3.95831C15.9294 4.17885 15.8859 4.39723 15.8015 4.60098C15.7171 4.80473 15.5934 4.98986 15.4375 5.14581L5.54167 15.0416L2.375 15.8333L3.16667 12.6666L13.0625 2.77081Z","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"menu_label"},[_vm._v("TO DO")])])],1),_c('li',{staticClass:"sidebar__item",class:{
            sidebar__item_active: [
              'Registration',
              'NewRegistration',
              'BeginApplication'
            ].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'Registration' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.1665 11.875C3.1665 11.875 3.95817 11.0834 6.33317 11.0834C8.70817 11.0834 10.2915 12.6667 12.6665 12.6667C15.0415 12.6667 15.8332 11.875 15.8332 11.875V2.37504C15.8332 2.37504 15.0415 3.16671 12.6665 3.16671C10.2915 3.16671 8.70817 1.58337 6.33317 1.58337C3.95817 1.58337 3.1665 2.37504 3.1665 2.37504V11.875Z","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M3.1665 17.4167V11.875","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"menu_label"},[_vm._v("REGISTRATIONS")])])],1),_c('li',{staticClass:"sidebar__item spacer-more",class:{
            sidebar__item_active: ['People', 'ProfileEdit'].includes(
              _vm.$route.name
            )
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'People' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_104_41)"}},[_c('path',{attrs:{"d":"M13.4582 16.625V15.0417C13.4582 14.2018 13.1245 13.3964 12.5307 12.8025C11.9368 12.2086 11.1314 11.875 10.2915 11.875H3.95817C3.11832 11.875 2.31286 12.2086 1.719 12.8025C1.12513 13.3964 0.791504 14.2018 0.791504 15.0417V16.625","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M7.12516 8.70833C8.87406 8.70833 10.2918 7.29057 10.2918 5.54167C10.2918 3.79276 8.87406 2.375 7.12516 2.375C5.37626 2.375 3.9585 3.79276 3.9585 5.54167C3.9585 7.29057 5.37626 8.70833 7.12516 8.70833Z","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M18.2085 16.625V15.0417C18.208 14.34 17.9744 13.6584 17.5446 13.1039C17.1147 12.5494 16.5128 12.1533 15.8335 11.9779","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12.6665 2.47791C13.3477 2.65231 13.9514 3.04846 14.3826 3.6039C14.8137 4.15934 15.0477 4.84248 15.0477 5.54561C15.0477 6.24875 14.8137 6.93189 14.3826 7.48733C13.9514 8.04277 13.3477 8.43892 12.6665 8.61332","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_104_41"}},[_c('rect',{attrs:{"width":"19","height":"19","fill":"white"}})])])]),_c('span',{staticClass:"menu_label"},[_vm._v("PEOPLE/FAMILY")])])],1),_c('li',{staticClass:"sidebar__item",class:{
            sidebar__item_active: ['Finance'].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'Finance' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_104_25)"}},[_c('path',{attrs:{"d":"M17.5002 3.33337H2.50016C1.57969 3.33337 0.833496 4.07957 0.833496 5.00004V15C0.833496 15.9205 1.57969 16.6667 2.50016 16.6667H17.5002C18.4206 16.6667 19.1668 15.9205 19.1668 15V5.00004C19.1668 4.07957 18.4206 3.33337 17.5002 3.33337Z","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M0.833496 8.33337H19.1668","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_104_25"}},[_c('rect',{attrs:{"width":"20","height":"20","fill":"white"}})])])]),_c('span',{staticClass:"menu_label"},[_vm._v("FINANCE "),_c('span',[_vm._v(_vm._s(_vm.getTotalBalance()))])])])],1),_c('li',{staticClass:"sidebar__item",class:{
            sidebar__item_active: ['Message'].includes(_vm.$route.name)
          }},[_c('router-link',{staticClass:"menu_icon",attrs:{"to":{ name: 'Message' }}},[_c('svg',{staticClass:"sidebar__icon",attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17.5 12.5C17.5 12.942 17.3244 13.366 17.0118 13.6785C16.6993 13.9911 16.2754 14.1667 15.8333 14.1667H5.83333L2.5 17.5V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V12.5Z","stroke":"#C3C3C3","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"menu_label"},[_vm._v(" MESSAGES "),(_vm.getUnreadMessages())?_c('span',[_vm._v(_vm._s(_vm.getUnreadMessages())+" UNREAD")]):_vm._e()])])],1)]],2)]),_c('div',{staticClass:"dropdown sidebar__item more-dropdown sidebar__more"},[_c('div',{staticClass:"welcome-label text-truncate"},[_c('router-link',{attrs:{"to":{ name: 'ProfileEdit', params: { id: _vm.$loggedUser.id + '' } }}},[_vm._v(" HI "+_vm._s(_vm.$loggedUser.firstName + " " + _vm.$loggedUser.lastName)+" ")])],1),(_vm.$loggedUser.type == 2)?_c('span',{staticClass:"preview-mode-text"},[_vm._v(" Preview Mode ")]):_vm._e(),_c('router-link',{staticClass:"ms-auto w-auto",attrs:{"to":{ name: 'Logout' }}},[_c('img',{attrs:{"src":require("../../public/portal/img/icons/icons.svg") + "#logout","width":"16","height":"16","alt":"Logout"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }