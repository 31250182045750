








import Vue from "vue";

export default Vue.extend({
  name: "NoData",
  props: {
    msg: {
      type: String,
      default: "No Records Found"
    },
    isFull: {
      type: Boolean,
      default: false,
    }
  }
});
