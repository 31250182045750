






















































import Vue from "vue";

export default Vue.extend({
  name: "Loading",
  props: {
    msg: String,
    hasSidebar: {
      type: Boolean,
      default: true,
    }
  }
});
