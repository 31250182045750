


















































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "../helpers/all";
import directives from "../helpers/directives";
import axios from "axios";
import moment from "moment";

declare let dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: false,
  components: {},
  directives
})
export default class PlanDetails extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  planId?: string;

  $loggedUser: any;
  planDetails: any = [];
  transactionLines: any = [];
  planInfo: any = {};
  discounts: any = [];

  async created() {
    const result = await axios.post(`${dataURL}/finance/planDetails`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: this.$loggedUser.id,
      planId: this.planId
    });
    if (result.data.status == 1) {
      this.planDetails = result.data.data.planDetails;
      this.transactionLines = result.data.data.transactionLines;
      this.planInfo = result.data.data.planInfo;
      this.planInfo.createdTSFormatted = moment(this.planInfo.planTS).format('MMM DD, YYYY');
      this.planInfo.scheduleDate = this.planInfo.planTS;
    }
  }
  
  dollarFormat(amount) {
    return ApiHelper.dollarFormat(amount);
  }
  dateFormat(date) {
    return ApiHelper.formatDate(
      date,
      "MM/DD/YYYY",
      ""
    );
  }
  dateSub(date, month) {
    date = moment(date).subtract(-1 * (month - 1), 'months').format('MMM DD, YYYY');
    return ApiHelper.formatDate(
      date,
      "MM/DD/YYYY",
      ""
    );
  }
  dateFormatFull(date) {
    return ApiHelper.formatDate(
      date,
      "MM/DD/YYYY hh:mm A",
      ""
    );
  }
  
}
