import { render, staticRenderFns } from "./DomainNotFound.vue?vue&type=template&id=9f797eec&scoped=true&"
import script from "./DomainNotFound.vue?vue&type=script&lang=tsx&"
export * from "./DomainNotFound.vue?vue&type=script&lang=tsx&"
import style0 from "./DomainNotFound.vue?vue&type=style&index=0&id=9f797eec&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f797eec",
  null
  
)

export default component.exports