
























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import moment from "moment";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class DateSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true, default: "" })
  strDate!: string;

  @Prop({ required: false, default: false })
  required?: boolean;
  
  $validator: any;
  _uid: any;
  dayVal = "";
  monthVal = "";
  yearVal = "";
  days: any = [];
  months: any = [];
  years: any = [];

  created() {
    const current = moment();
    const beginY = current.year() - 91;
    // for (let _y = beginY; _y <= current.year(); _y++) {
    for (let _y = current.year(); _y >= beginY ; _y--) {
      this.years.push(_y);
    }
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    for (let _y = 1; _y <= 12; _y++) {
      this.months.push({"month": _y, "monthName": _y});
    }
    for (let _y = 1; _y <= 31; _y++) {
      this.days.push(_y);
    }
  }

  @Watch('strDate', { immediate: true, deep: true })
  async strDateChanged(val) {
    if(this.strDate != "") {
      const arr = this.strDate.split("/");
      const current = moment();
      const beginY = current.year() - 91;
      const year = parseInt(arr[0]);
      this.yearVal = year >= beginY && year <= current.year() ? arr[0] : '';
      this.monthVal = arr[1];
      this.dayVal = arr[2];
      this.getDaysList();
    }
  }

  getDaysList() {
    // change days list based on month/year
    const daysInMonth = moment(`${this.yearVal}-${this.monthVal}`, "YYYY-MM").daysInMonth();
    this.days = [];
    for (let _y = 1; _y <= daysInMonth; _y++) {
      this.days.push(_y);
    }
    if(this.dayVal != "" && (daysInMonth < parseInt(this.dayVal))) {
      this.dayVal = "";
    }
  }

  dateChange() {
    this.getDaysList();

    // update strDate
    const newVal = `${this.yearVal}/${this.monthVal}/${this.dayVal}`;
    this.$emit("update", newVal);
  }
}
