

































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  TOTALPAGES: string;
  CURRENTPAGE: string;
}

interface Events {
  onLoadPage: number;
}

declare const $: any

@Component({
  inheritAttrs: true
})
export default class Pagination extends TSXComponent<Props, Events> {
  @Prop() TOTALPAGES!: string;
  @Prop() CURRENTPAGE!: string;

  get isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  get isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }

  get currentPage() {
    return parseInt(this.CURRENTPAGE)
  }

  get totalPages() {
    return parseInt(this.TOTALPAGES)
  }

  $refs!: {
    selection: HTMLDivElement
  }

  paginationDropdownVisible = false

  hidePaginationDropdown() {
    const $paginationDropDown = $(this.$refs.selection).find('.paginationDropDown')
    $paginationDropDown.animate(
      {"top": 0},
      1,
      () => {
        this.paginationDropdownVisible = false
      }
    );
  }


  async pagintionDropdown() {
    
    if (!this.paginationDropdownVisible) {
      this.paginationDropdownVisible = true
      await this.$nextTick()

      const $paginationDropDown = $(this.$refs.selection).find('.paginationDropDown')
      $paginationDropDown.animate({"top": "-" + $paginationDropDown.height(),}, 1)
    } else {
      this.hidePaginationDropdown()
    }
  }
  async hideDropdown(){
    this.paginationDropdownVisible = false;
  }
  async loadPage(page: number, type: 'prev' | 'next' | '') { 
    this.hidePaginationDropdown()  

    if (type === "prev") {
      this.$emit('loadPage', this.currentPage - 1)
    } else if (type === "next") {
      this.$emit('loadPage', this.currentPage + 1)
    } else {
      this.$emit('loadPage', page)
    }
  }
}
