var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group form-date-container",class:{ hasError: _vm.data.error }},[(_vm.data.label)?_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]):_vm._e(),_c('div',[_c('DatePicker',{ref:"datepicker",staticClass:"input__full",class:[_vm.data.type || 'date'],attrs:{"id":'datepicker-' + _vm.randomId,"format":_vm.data.format || 'MM/DD/YYYY',"placeholder":_vm.data.placeholder || 'MM/DD/YYYY',"use12h":true,"disabled":_vm.data.disabled || _vm.disabled,"not-before":_vm.data.disabledBefore,"not-after":_vm.data.disabledAfter,"disabledDate":_vm.disabledDate,"clearable":false,"popup-style":{
      left: 0
    },"append-to-body":false,"type":_vm.data.type || 'date'},on:{"change":function () {
        _vm.data.error = '';
        _vm.doChange();
      },"focus":function () {
            _vm.data.error = '';
          },"reset":function () {
        _vm.data.error = '';
        _vm.doChange();
      }},model:{value:(_vm.data.value),callback:function ($$v) {_vm.$set(_vm.data, "value", $$v)},expression:"data.value"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }