import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { CoolSelectPlugin } from 'vue-cool-select'
import VeeValidate, { Validator } from "vee-validate";
import axios from "axios";
// import VueSelect from "vue-cool-select";
import VueNumeric from 'vue-numeric'
import vClickOutside from 'v-click-outside'
import VueMonthlyPicker from 'vue-monthly-picker'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'ladda/dist/ladda-themeless.min.css'
import 'vue2-datepicker/index.css';
import Inputmask from "inputmask";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const alertOptions = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};

Vue.use(VueSweetalert2, alertOptions);

Vue.use(PerfectScrollbar)

Vue.use(vClickOutside)

import App from './App.vue'
import router from './router'
import store from './store'

import VueApexCharts from './components/VueApexCharts.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import Loader from './components/Loader.vue'
Vue.component('apexchart', VueApexCharts)

// import VueGtag from "vue-gtag";
import { ApiHelper } from './helpers/all';

// clear logined info if seeing a preview link
const paramUser = new URLSearchParams(
  window.location.hash.split("?")[1] || ""
).get("user");
if (paramUser && window.location.href.indexOf("preview=1") != -1) {
  ApiHelper.userLogout();  
}

// const loggedUser = ApiHelper.getLoggedUser();
// if(loggedUser.type != 2) {
//   Vue.use(VueGtag, {
//     config: { id: process.env.VUE_APP_GA_TRCKING_ID }
//   }, router);
// }

Vue.component('vue-monthly-picker', VueMonthlyPicker)
Vue.component('add-header', Header)
Vue.component('add-footer', Footer)
Vue.component('Loader', Loader)
Vue.filter('formatCurrency', function (value,isNeg) {
  value = +value
  if(isNeg){
    value = value * -1; 
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
})

Vue.directive("mask", {
  bind: function(el, binding) {
    const maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = true;
    maskOpts.clearIncomplete = true;
    Inputmask(maskOpts).mask(el);
  },
  unbind: function(el) {
    Inputmask.remove(el);
  }
});

Vue.config.productionTip = false

// declare const bootstrapVue: any
declare const VueAwesome: any
declare const VueECharts: any
declare const vuejsDatepicker: any
declare const AWN: any

// declare const LoadData: any
// declare const grabAddHardware: any
// declare const ServicePublicFunc: any
// declare const orderPublicFunc: any
// declare const addNotes: any
// declare const supportUpdates: any
// declare const dropdownInit: any
declare let dataURL: any

var notifier = new AWN({
  position: "top",
  duration: 3000
});

Vue.use(VueNumeric)
Vue.use(CoolSelectPlugin)
Vue.use(BootstrapVue)
Vue.component('icon', VueAwesome);
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
// Vue.component('chart', VueECharts);
Vue.component('datepicker', vuejsDatepicker);
Vue.config.warnHandler = function (err, vm, info) {
  // console.log('Custom vue warn handler: ', err, vm.name, info);
};
VeeValidate.Validator.extend('currencyCheck', {
  getMessage: field => 'Please enter valid amount for ' + field + ' .',
  validate: value => {
    if (value <= 0) {
      return false
    }
    return true
  }
});
VeeValidate.Validator.extend('skucheck', {
  getMessage: field => 'Sku should not start Special characters .',
  validate: value => {
    if (value.length) {
      if (value.charAt(0) == "#" || value.charAt(0) == "-") {
        return false
      }
    }
    return true
  }
});
// var options = {
//   position: "top",
//   duration: 3000
// }

// dataURL = process.env.VUE_APP_CAMP360API_URL

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
const win: any = window
win.setCookie = setCookie
win.getCookie = getCookie
win.eraseCookie = eraseCookie

function loadSessionFromLocalStorage(dataSession) {
  sessionStorage.setItem('sessionID', dataSession.sessionID);
  sessionStorage.setItem('userId', dataSession.userId);
}

axios.interceptors.request.use(
  function (config) {
    var tempsettingsDataObj = 0;

    try {
      tempsettingsDataObj = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
      if (tempsettingsDataObj != 0) {
        config.data = Object.assign(tempsettingsDataObj, {});
      }
    } catch (e) {
      // console.error(e)
    }

    // check is apply page by params in url
    const isApplyPage = window.location.href.indexOf("/apply") != -1 && window.location.href.indexOf("apply.dev-us") == -1;

    // send token if loginned
    if(router.currentRoute.name == "Apply" && isApplyPage) {
      // standalone page
      let loggedUser = JSON.parse(localStorage.getItem("CAMP360_LOGGED_USER_STANDALONE") || "{}");
      if(loggedUser && loggedUser.profileID > 0 && loggedUser.token) {
        config.headers['Authorization'] = 'Bearer ' + loggedUser.token;
      }
    }else {
      // participant portal
      const loggedUser = ApiHelper.getLoggedUser();
      if(loggedUser.id > 0 && loggedUser.token) {
        config.headers['Authorization'] = 'Bearer ' + loggedUser.token;
      }
    }
    
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  if (response.data.SESSIONSTATUS === -1 && !window.location.href.includes('passwordChange') && !window.location.href.includes('signUp')) {
    sessionStorage.clear();
    router.push('/')
  }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

new Vue({
  el: '#body-site',
  router,
  store,
  data: {
    items: [],
    tableColumns: [],
    details: {},
    saveFn: null,
    loading: true,
    loadNextpage: null,
    headers: [],
    allHeaders: [],
    headerContent: false,
  },
  methods: {
    notification(method: any, message: any) {
      notifier[method](message);
    },
    getUserRole() {
      return sessionStorage.getItem('userRole');
    },
    getMaxRows() {
      return sessionStorage.getItem('maxRows');
    }
  },
  // watch: {
  //   '$route'(to, from) {
  //     var self: any = this;
  //     var session = sessionStorage.getItem('sessionID');
  //     if(localStorage.rememberMe == 1 && !session ){
  //       session = sessionStorage.getItem('rememberMesession');
  //     }
  //     if(!session && localStorage.rememberMe == 1 && localStorage.lastSession){
  //       sessionStorage.setItem('sessionID',  JSON.parse(localStorage.lastSession).sessionID);
  //       sessionStorage.setItem('domain',  JSON.parse(localStorage.lastSession).domain);
  //       //storage session local when open multi tab
  //       setCookie('VAR360_SESSION_LOCAL', localStorage.lastSession,30);
  //       session = sessionStorage.getItem('sessionID');
  //     }
  //     if (!session && to.name != 'passwordChange' && to.name != 'signUp') {
  //       sessionStorage.clear();
  //       router.push('/').catch(err => { });
  //     }
  //     // Reset data to avoid the temp rendering before ajax data fetch
  //     self.items = [];
  //     self.tableColumns = [];

  //     if (to.name != undefined) {
  //       // console.log('LoadData', to.name, self);
  //       LoadData(to.name, self);
  //     }
  //   }
  // },
  // mounted: function () {
  //   var self: any = this;
  //   var curRoute = self._route.name;
  //   var session = sessionStorage.getItem('sessionID');
  //   if(!session && localStorage.rememberMe == 1 && localStorage.lastSession){
  //     //storage session local when open multi tab
  //     setCookie('VAR360_SESSION_LOCAL', localStorage.lastSession,30);
  //     if (curRoute == undefined){
  //       curRoute = "Dashboard"
  //     }
  //   }
  //   if (!session && !window.location.href.includes('passwordChange') && !window.location.href.includes('signup')) {
  //     sessionStorage.clear();
  //     router.push('/').catch(err => { });
  //   }

  //   if (curRoute == undefined)
  //     curRoute = 'Login';

  //   LoadData(curRoute, self);
  // },
  // computed: {
  //   loginUserName() {
  //     return sessionStorage.getItem('userName');
  //   },
  //   loginUserId() {
  //     return sessionStorage.getItem('userId');
  //   },
  //   loginUserRole() {
  //     return sessionStorage.getItem('userRole');
  //   }
  // },
  render: h => h(App)
})
