var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"todo_question",staticStyle:{"display":"none"},attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered m-0",class:{
      'modal-lg': _vm.size === 'large' || _vm.size === 'very-large',
      'modal-xlg': _vm.size === 'xlarge'
    },style:(_vm.size === 'very-large'
        ? 'max-width: 100%; max-height: 100%; height: 100%; margin: 0'
        : ''),attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content modal-container border-0",class:{
        isLoading: _vm.isLoading,
        hideScroll: _vm.hideScroll
      },style:(_vm.size === 'very-large'
          ? 'width: 100%; height: 100%; max-height: 100%'
          : '')},[_c('div',{staticClass:"modal-loader"},[_c('svg',{staticClass:"page-loader",attrs:{"version":"1.1","id":"L9","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","width":"80","height":"80","viewBox":"0 0 100 100","enable-background":"new 0 0 0 0","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"}},[_c('animateTransform',{attrs:{"attributeName":"transform","attributeType":"XML","type":"rotate","dur":"0.6s","from":"0 50 50","to":"360 50 50","repeatCount":"indefinite"}})],1)])]),_vm._t("header",[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title || "..."))]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{staticClass:"modal-body"},[_vm._t("body",[_vm._v(" default body ")])],2),(!_vm.hideFooter)?_c('div',{staticClass:"modal-footer"},[_vm._t("footer",[_vm._v(" default footer ")])],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }