


































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Vue } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import { ApiHelper } from "@/helpers/all";
import FormText from "@/components/Form/FormText.vue";
import Loading from "@/components/Common/Loading.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import Inputmask from "inputmask";
import FormToggle from "../components/FormToggle.vue";
import axios from "axios";
import NoData from "../components/Common/NoData.vue";
import moment from "moment";
import PageHeader from "@/components/PageHeader.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import PageTitle from "@/components/PageTitle.vue";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    FormDatePicker,
    PageHeader,
    FormPassword,
    FormSelect,
    FormEmail,
    FormDate,
    Loading,
    FormText,
    FormUpload,
    Pagination,
    FormYesNo,
    FormPhoto,
    FormToggle,
    NoData
  }
})
export default class ProfileEdit extends TSXComponent<void> {
  selectedProfileId: number = this.$route.params.id
    ? Number.parseInt(this.$route.params.id, 10)
    : 0;
  isCancel: boolean = false;
  pageData: any = {
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    }
  };
  formData: any = {
    isLoading: false,
    controls: {
      id: {
        error: "",
        value: 0
      },
      firstName: {
        label: "First Name",
        placeholder: "First Name",
        value: "",
        error: ""
      },
      lastName: {
        label: "Last Name",
        placeholder: "Last Name",
        value: "",
        error: ""
      },
      email: {
        label: "EMAIL ADDRESS",
        placeholder: "Email",
        value: "",
        error: ""
      },
      phone: {
        label: "PHONE NUMBER",
        placeholder: "Phone",
        value: "",
        type: "phone",
        maxlength: 14,
        error: ""
      },
      dob: {
        label: "DATE OF BIRTH",
        placeholder: "Date Of Birth",
        error: "",
        value: null,
        disabled: false,
        editable: true,
        disabledBefore: moment()
          .subtract(100, "years")
          .toDate(),
        disabledAfter: new Date()
      },
      type: {
        type: "select",
        options: [],
        placeholder: "THIS PERSON IS MY...",
        value: "",
        label: "THIS PERSON IS MY...",
        error: "",
        style: "custom"
      },
      guardianType: {
        type: "select",
        options: ApiHelper.getGuardianTypeOptions(),
        placeholder: "Select",
        value: "",
        label: "Guardian Type",
        error: "",
        required: true,
        style: "custom"
      },
      createUser: {
        label: "ADMIN PERMISSIONS?",
        error: "",
        type: "radios",
        value: "no",
        defaultValue: ""
      },
      isGuardian: {
        label: "IS GUARDIAN?",
        error: "",
        type: "radios",
        value: "no",
        defaultValue: ""
      },
      gender: {
        error: "",
        label: "Gender",
        placeholder: "Select Gender",
        value: "",
        options: [
          {
            value: "1",
            text: "Male"
          },
          {
            value: "2",
            text: "Female"
          },
          /*
          {
            value: "3",
            text: "Undefined/Other/Prefer Not to Say"
          }
          */
        ]
      },
      logo: {
        error: "",
        label: "",
        preview: "",
        value: "",
        base64: "",
        delete: 0,
      },
      currentPassword: {
        error: "",
        mode: "password",
        label: "Current Password",
        placeholder: "Current Password",
        value: ""
      },
      password: {
        error: "",
        mode: "password",
        label: "New Password",
        placeholder: "New Password",
        value: ""
      },
      passwordAgain: {
        error: "",
        mode: "password",
        label: "New Password Again",
        placeholder: "New Password Again",
        value: ""
      },
      logToggle: {
        error: "",
        label: "",
        labelOn: "Log",
        labelOff: "Profile",
        value: false
      }
    }
  };
  loggedUser: any;
  logsData: any = {
    medicalIncidents: [],
    notes: []
  };
  showPasswordCriterion = false;

  async created() {
    this.loggedUser = ApiHelper.getLoggedUser();
    if (this.loggedUser.type !== 1 && this.loggedUser.type !== 2) {
      ApiHelper.showErrorMessage(
        "You dont have the permission",
        "Access Denied"
      );
      ApiHelper.gotoPage({ name: "People" });
    } else {
      this.formData.controls.type.options = ApiHelper.getFamilyTypeOptions();
      if (this.selectedProfileId > 0) {
        await this.loadData();
      }
    }
    this.loadList().then(() => {
      // nothing;
    }).catch(()=> {
      // nothing;
    });
  }

  async loadList() {
    const loggedUser = ApiHelper.getLoggedUser();
    if(loggedUser.familyId == 0) {
      return;
    }
    this.pageData.pager.page = this.$route.query
      ? parseInt(this.$route.query.page ? this.$route.query.page + '' || '1' : '1') || 1
      : 1;
    this.pageData.isLoading = true;
    this.pageData.paging.skip = (this.pageData.pager.page - 1) * this.pageData.paging.take;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost('/familyMembers', {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      familyID: loggedUser.familyId,
      skip: this.pageData.paging.skip,
      take: this.pageData.paging.take
    });
    this.pageData.isLoading = false;
    if (result.status === 1) {

      const totalCount = result.data.paging.totalCount || 0;
      this.pageData.paging.totalCount = totalCount;
    }
  }

  async loadData() {
    const loggedUser = ApiHelper.getLoggedUser();
    this.formData.isLoading = true;
    const result = await ApiHelper.apiPost(
      "/profiles/" + this.selectedProfileId,
      {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        mainProfileID: loggedUser.id,
        familyID: loggedUser.familyId
      }
    );
    this.formData.isLoading = false;
    if (result.status === 1) {
      const profileSetting = result.data.profileSetting || {};
      this.formData.controls.id.value = this.selectedProfileId;
      this.formData.controls.firstName.value = (
        profileSetting.p_fname || ""
      ).replace(/[^\w\\'\- ]/gi, "");
      this.formData.controls.lastName.value = (
        profileSetting.p_lname || ""
      ).replace(/[^\w\\'\- ]/gi, "");
      // this.formData.controls.dob.value = ApiHelper.convertToFormDateValue(profileSetting.p_dob) || '';
      this.formData.controls.dob.value = profileSetting.dobFormatted
        ? new Date(profileSetting.dobFormatted)
        : undefined;
      this.formData.controls.email.value = profileSetting.p_email || "";
      this.formData.controls.phone.value = profileSetting.p_phone || "";
      this.formData.controls.gender.value = profileSetting.p_gender
        ? profileSetting.p_gender.toString()
        : "";
      this.formData.controls.logo.preview = profileSetting.p_logo || "";
      this.formData.controls.logo.value = profileSetting.p_logo || "";
      let relationTypeID = profileSetting.relationTypeID || 0;
      let guardianTypeID = profileSetting.guardianTypeID || "";
      const typeOptions = ApiHelper.getFamilyTypeOptions();
      if (!typeOptions.find(item => item.value === relationTypeID + "")) {
        relationTypeID = 0;
      }
      this.formData.controls.type.value = relationTypeID
        ? relationTypeID + ""
        : "";
      this.formData.controls.guardianType.value = guardianTypeID;
      this.formData.controls.createUser.value =
        profileSetting.p_type == 1 ? "yes" : "no";
      this.formData.controls.createUser.defaultValue =
        profileSetting.p_type == 1 ? "yes" : "no";
      this.formData.controls.isGuardian.value =
        profileSetting.p_isguardian == 1 ? "yes" : "no";
      /*
      if (profileSetting.p_type === 1) {
        ApiHelper.showErrorMessage('Cant edit other admin (' + profileSetting.p_fname + ' ' + profileSetting.p_lname + '.', 'Oops');
        ApiHelper.gotoPage({ name: 'People' });
      }
      */
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  }

  private async changeProfileAvatar(base64: string, type?: string) {
    let hasError = false;
    let message = "";
    if (hasError) {
      ApiHelper.showErrorMessage(message, "Error");
    } else {
      if (this.selectedProfileId > 0) {
        ApiHelper.apiPost("/updateProfileAvatar", {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          logoBase64: base64,
          type: type,
          profileID: this.formData.controls.id.value
        }).then(result2 => {
          if (result2.status == 1) {
            this.formData.controls.logo.value = result2.data.url || "";
            this.formData.controls.logo.preview = result2.data.url || "";
            // if profile is owner
            if (this.selectedProfileId == this.loggedUser.id) {
              ApiHelper.updateProfilePhoto(result2.data.url);
              $(".headerAvatar").html(
                `<img src="${result2.data.url}" style="width: 50px; height: 50px; border-radius: 50%;">`
              );
            }
          } else {
            ApiHelper.showErrorMessage(result2.message, "Oops");
          }
        });
      } else {
        this.formData.controls.logo.value = base64 || "";
        this.formData.controls.logo.preview = base64 || "";
        this.formData.controls.logo.s3url = base64 || "";
      }
      this.formData.controls.logo.delete = 0;
    }
  }

  validateForm() {
    let hasError = false;
    this.formData.controls.firstName.error = "";
    this.formData.controls.lastName.error = "";
    this.formData.controls.email.error = "";
    this.formData.controls.password.error = "";
    this.formData.controls.gender.error = "";
    this.formData.controls.phone.error = "";
    // this.formData.controls.phone.value = this.formData.controls.phone.value.replace(/[^0-9-() ]/g, '');
    if (this.formData.controls.phone.value !== "") {
      const phone = $("input[inputmask=phonenumber]");
      if (
        phone.length &&
        phone.get(0).inputmask &&
        phone.get(0).inputmask.isComplete() == false
      ) {
        hasError = true;
        this.formData.controls.phone.error = "Phone is invalid";
      }
    }

    if (this.formData.controls.firstName.value.trim() === "") {
      hasError = true;
      this.formData.controls.firstName.error = "First Name is required";
    }
    if (this.formData.controls.lastName.value.trim() === "") {
      hasError = true;
      this.formData.controls.lastName.error = "Last Name is required";
    }
    if (!this.formData.controls.dob.value) {
      hasError = true;
      this.formData.controls.dob.error = "DOB is required";
    } else {
      if (this.formData.controls.dob.value) {
        const nowDate = new Date();
        if (
          moment(this.formData.controls.dob.value)
            .toDate()
            .getTime() > nowDate.getTime()
        ) {
          hasError = true;
          this.formData.controls.dob.error = "DOB is invalid!";
        }
      }
    }
    if (this.formData.controls.gender.value.trim() === "") {
      hasError = true;
      this.formData.controls.gender.error = "Gender is required";
    }
    if (
      !this.formData.controls.type.value &&
      this.loggedUser.id !== this.selectedProfileId
    ) {
      hasError = true;
      this.formData.controls.type.error = "Relation Type is required";
    }

    if (this.formData.controls.isGuardian.value == 'yes' && !this.formData.controls.guardianType.value) {
      hasError = true;
      this.formData.controls.guardianType.error = "Guardian Type is required";
    }

    if (this.formData.controls.email.value.trim() !== "") {
      if (!ApiHelper.validateEmail(this.formData.controls.email.value.trim())) {
        hasError = true;
        this.formData.controls.email.error = "Email is invalid";
      }
    }

    if (this.formData.controls.phone.value == '') {
      hasError = true;
      this.formData.controls.phone.error = 'Phone is required'
    }

    if (this.formData.controls.createUser.value == "yes") {
      if (this.formData.controls.email.value.trim() === "") {
        hasError = true;
        this.formData.controls.email.error = "Email is required";
      }
      if (this.formData.controls.createUser.defaultValue == "no") {
        if (this.formData.controls.password.value.trim() === "") {
          hasError = true;
          this.formData.controls.password.error = "Password is required";
        }
      }
      if (
        this.formData.controls.password.value.trim() !== "" &&
        !this.formData.controls.password.valid
      ) {
        hasError = true;
        this.formData.controls.password.error =
          "The new password is too simple. Please choose another password.";
      }
    }
    return !hasError;
  }

  async onSubmit(event: Event, forceUseSameEmail = false) {
    event.preventDefault();
    const isValidForm = this.validateForm();
    if (isValidForm) {
      this.formData.isLoading = true;
      const loggedUser = ApiHelper.getLoggedUser();
      const guardianTypeId = Number.parseInt(this.formData.controls.guardianType.value, 10) || 0;
      const relationTypeId = Number.parseInt(this.formData.controls.type.value, 10) || 0;
      const result = this.selectedProfileId
        ? await ApiHelper.apiPost("/updateProfile", {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            fname: this.formData.controls.firstName.value,
            lname: this.formData.controls.lastName.value,
            email: this.formData.controls.email.value,
            phone: this.formData.controls.phone.value,
            dob: this.formData.controls.dob.value
              ? moment(this.formData.controls.dob.value).format("YYYY-MM-DD")
              : "",
            gender: this.formData.controls.gender.value,
            profileID: this.selectedProfileId,
            type: this.formData.controls.createUser.value == "yes" ? 1 : 2,
            isGuardian: this.formData.controls.isGuardian.value == "yes" ? 1 : 0,
            password:
              this.formData.controls.createUser.value == "yes" &&
              loggedUser.id !== this.selectedProfileId
                ? this.formData.controls.password.value
                : undefined,
            mainProfileID:
              loggedUser.id !== this.selectedProfileId ? loggedUser.id : 0,
            familyID: loggedUser.familyId,
            relationTypeID:
              loggedUser.id !== this.selectedProfileId
                ? relationTypeId
                : 0,
            guardianTypeID: guardianTypeId,
            forceUseSameEmail,
            deletePhoto: this.formData.controls.logo.delete,
          })
        : await ApiHelper.apiPost("/createProfile", {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            familyID: loggedUser.familyId,
            mainProfileID: loggedUser.id,
            fname: this.formData.controls.firstName.value,
            lname: this.formData.controls.lastName.value,
            email: this.formData.controls.email.value,
            phone: this.formData.controls.phone.value,
            gender: this.formData.controls.gender.value,
            dob: this.formData.controls.dob.value
              ? moment(this.formData.controls.dob.value).format("YYYY-MM-DD")
              : "",
            photoS3url: this.formData.controls.logo.s3url,
            type: this.formData.controls.createUser.value == "yes" ? 1 : 2,
            password:
              this.formData.controls.createUser.value == "yes"
                ? this.formData.controls.password.value
                : undefined,
            relationTypeID: relationTypeId,
            guardianTypeID: guardianTypeId,
            forceUseSameEmail
          });
      this.formData.isLoading = false;
      if (result.status == 1) {
        if (loggedUser.id == this.selectedProfileId) {
          loggedUser.firstName = this.formData.controls.firstName.value;
          loggedUser.lastName = this.formData.controls.lastName.value;
          ApiHelper.saveLoggedUser(loggedUser);
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.updateProfileName(this.formData.controls.firstName.value, this.formData.controls.lastName.value);
        }
        ApiHelper.showSuccessMessage(
          this.selectedProfileId ? "Updated Profile" : "Created Profile"
        );
        /* if (this.selectedProfileId !== this.loggedUser.id) { */
          ApiHelper.gotoPage({
            name: "People"
          });
        /* } */
      } else {
        const errorCode = result.errorCode || "";
        if (errorCode == "email_existed") {
          // confirm if admin wants to use same email for profiles in same family
          const confirm = await this.$swal({
            html: result.message,
            // icon: "error",
            title: "Oops",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!",
            customClass: {
              container: "swal2-container-custom",
            }
          });
          if (confirm.isConfirmed) {
            // recall save function
            await this.onSubmit(event, true);
          }
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
    }
  }

  async onSendLink(event: Event) {
    event.preventDefault();
    let hasError = false;
    let message = "";
    /*if (this.formData.controls.password.value === '') {
      message = 'Password is required';
      hasError = true;
    }*/
    if (hasError) {
      ApiHelper.showErrorMessage(message, "Error");
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost("/sendPasswordLink", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        password: this.formData.controls.password.value,
        profileID: this.formData.controls.id.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        this.formData.controls.password.value = "";
        ApiHelper.showSuccessMessage("Sent Link");
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  }

  async onUpdatePassword(event: Event) {
    event.preventDefault();
    let hasError = false;
    let message = "";
    this.showPasswordCriterion = false;

    // validate
    if (this.formData.controls.currentPassword.value === "") {
      message = "Current Password is required";
      hasError = true;
    } else {
      if (this.formData.controls.password.value === "") {
        message = "New Password is required";
        hasError = true;
      } else if (this.formData.controls.passwordAgain.value === "") {
        message = "New Password Again is required";
        hasError = true;
      } else if (
        this.formData.controls.passwordAgain.value !=
        this.formData.controls.password.value
      ) {
        message = "New Password and New Password Again must match";
        hasError = true;
      }
    }
    if (!hasError) {
      // check strong password
      this.checkStrongPassword(this.formData.controls.password.value);
      if (!this.passwordData.valid) {
        hasError = true;
        this.showPasswordCriterion = true;
      }
    }

    // if (this.formData.controls.password.value === "") {
    //   message = "Password is required";
    //   hasError = true;
    // } else if (!this.formData.controls.password.valid) {
    //   message =
    //     "The new password is too simple. Please choose another password.";
    //   hasError = true;
    // }

    if (hasError) {
      if (message != "") {
        ApiHelper.showErrorMessage(message, "Error");
      }
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost("/updatePassword", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        currentPassword: this.formData.controls.currentPassword.value,
        password: this.formData.controls.password.value,
        profileID: this.formData.controls.id.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        this.formData.controls.currentPassword.value = "";
        this.formData.controls.password.value = "";
        this.formData.controls.passwordAgain.value = "";
        ApiHelper.showSuccessMessage("Updated Password");
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  }

  mounted() {
    $(document).ready(function() {
      $(".ProfileEditPage").on(
        "keydown",
        ".phoneMask, input[type=tel]",
        function(e) {
          const key = e.charCode || e.keyCode || 0;
          const $phone = $(e.target);
          let $val: any = $phone.val();

          if ($val.length === 0) {
            $phone.val("(");
          } else if ($val.length > 0) {
            if ($val.indexOf("(") == -1) {
              $val = "(" + $val;
              $phone.val($val);
            }
          }

          // Auto-format- do not expose the mask as the user begins to type
          if (key !== 8 && key !== 9) {
            const phoneNumber = new String($phone.val() || "");
            if (phoneNumber.length === 4) {
              $phone.val(phoneNumber + ") ");
            }
            if (phoneNumber.length === 5) {
              $phone.val(phoneNumber + " ");
            }
            if (phoneNumber.length === 9) {
              $phone.val(phoneNumber + "-");
            }
          }

          // Allow numeric (and tab, backspace, delete) keys only
          return (
            key == 8 ||
            key == 9 ||
            key == 46 ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105)
          );
        }
      );

      $(".ProfileEditPage").on("paste", ".phoneMask, input[type=tel]", function(
        e: any
      ) {
        const $phone = $(e.target);
        const phoneData: any = e.originalEvent.clipboardData.getData("Text");

        const number = phoneData.match(/\d/g, "");
        const joinedNumber = number.join("");
        const regex = /^(\d{3})(\d{3})(\d{4})$/;
        const final = joinedNumber.replace(regex, "($1) $2-$3");

        $phone.val(final);
      });

      $(".ProfileEditPage").on(
        "keydown.autocomplete",
        ".phoneMask, input[type=tel]",
        function(e) {
          const key = e.charCode || e.keyCode;
          const $phone = $(e.target);

          if (typeof key == "undefined") {
            setTimeout(function() {
              const phoneData: any = $phone.val();

              const number = phoneData.match(/\d/g, "");
              const joinedNumber = number.join("");
              const regex = /^(\d{3})(\d{3})(\d{4})$/;
              const final = joinedNumber.replace(regex, "($1) $2-$3");

              $phone.val(final);
            }, 10);
          }
        }
      );

      $(".ProfileEditPage").on(
        "focus click",
        ".phoneMask, input[type=tel]",
        function(e) {
          const $phone: any = $(e.target);

          if ($phone.val() && $phone.val().length === 0) {
            $phone.val("(");
          } else {
            const val = $phone.val();
            $phone.val("").val(val);
          }
        }
      );

      $(".ProfileEditPage").on("blur", ".phoneMask, input[type=tel]", function(
        e
      ) {
        const $phone = $(e.target);
        if ($phone.val() === "(") {
          $phone.val("");
        }
      });
    });
  }

  passwordData = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };

  checkStrongPassword(password: string) {
    this.passwordData = ApiHelper.getPasswordInfo(password);
    this.formData.controls.password.valid = this.passwordData.valid;
  }

  async logToggleUpdate() {
    if (this.formData.controls.logToggle.value == true) {
      // get profile/participant logs
      try {
        this.formData.isLoading = true;
        const logs = await axios.post(`${dataURL}/profileLogs`, {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          profileId: this.selectedProfileId,
          familyId: this.loggedUser.familyId
        });
        if (logs.data.status == 1) {
          this.logsData = logs.data.data.logs || [];
          this.logsData.medicalIncidents = (
            this.logsData.medicalIncidents || []
          ).map(item => ({
            ...item,
            expanded: false
          }));
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.formData.isLoading = false;
      }
    }
  }

  logTitle(item: any) {
    const ret: any = [];
    if (item.medicalName) {
      ret.push(item.medicalName);
    } else if (item.medicationName) {
      ret.push(item.medicationName);
    }
    if (item.notes) {
      ret.push(item.notes);
    }
    return ret.join(", ");
  }

  toggleLogDetails(item: any) {
    item.expanded = !(item.expanded || false);
  }
}
