


















import { Component, Prop } from "vue-property-decorator";
import { Component as TSXComponent } from "vue-tsx-support/lib/api";

interface Props {
  barData: {
    isLoaded: boolean;
    data: {
      labels: any;
      datasets: any;
    }
  };
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class ApexChart extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  barData!: {
    isLoaded: boolean;
    data: {
      labels: any;
      datasets: any;
    }
  };


  chartData: any = {
    categories: [],
    label: "",
    data: [],
    series: []
  }

  async created() {
    if (this.barData) {
      const barData = this.barData || {
        data: {
          labels: [],
          datasets: [
            {
              label: "",
              data: []
            }
          ]
        }
      };
      this.chartData.categories = barData.data.labels;
      this.chartData.series = barData.data.datasets.map((item: any) => {
        return {
          name: item.label,
          data: item.data
        };
      });
    }
  }
}

