





























































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import Loading from "@/components/Common/Loading.vue";
import { dollarFormat, ParticipantStatus } from "@/helpers/ApiHelper";
import NoData from "@/components/Common/NoData.vue";
import PageHeader from "@/components/PageHeader.vue";
import RoommateRequest from "@/components/RoommateRequest.vue";

declare let dataURL: string;
declare const $: any;
declare const bootstrap: any;

@Component({
  inheritAttrs: false,
  components: { PageHeader, NoData, Loading, RoommateRequest }
})
export default class Dashboard extends TSXComponent<void> {
  $loggedUser: any;
  loading = false;
  outstandingBalance = 0;
	outstandingBalanceFmt = "$0";
  loggedName = "";
  todoItems: any = [];
  registeredEventItems: any = [];
  outstandingTasksTotal = 0;
  registeredEventTotal = 0;
  roomateRequests: any = [];
  nameEntity = "";
  upcomingRegistrations: any = [];
  inviterInfo: any = {
    eventId: 0,
    pTypeId: 0,
    participantId: 0,
    profileId: 0,
    data: {}
  };

  async created() {
    await this.fetchData();
  }

  async fetchData() { 
    const loggedUser = ApiHelper.getLoggedUser();
    this.loading = true;
    this.loggedName = loggedUser.firstName;

    // this.outstandingBalance = loggedUser.stats.Outstanding;
    try {
      const result = await axios.post(`${dataURL}/dashboard`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: loggedUser.id,
        skip: 0,
        take: 3
      });
      if (result.data.status == 1) {
        const data = result.data.data;
        let requirePhotoItems = result.data.data.requirePhoto;
        const currentYear = new Date().getFullYear();
        requirePhotoItems = requirePhotoItems.filter(
          (item: any) =>
            currentYear != parseInt(item.yearUpdateLogo || "0") 
        );
        this.todoItems = result.data.data.outstandingTasks.data;
        this.todoItems.forEach((t) => {
          t.dueDateFormatted = ApiHelper.formatDate(t.dueDate, 'MMM DD, YYYY', '');
        });
        if (requirePhotoItems.length) {
          this.todoItems = [...this.todoItems, ...requirePhotoItems];
        }
        this.outstandingTasksTotal = this.todoItems.length;

        this.registeredEventItems = result.data.data.registeredEvents.data;
        this.registeredEventTotal = this.registeredEventItems.length;

        this.outstandingBalance = result.data.data.totalBalance;
        this.roomateRequests = data.roomateRequests?.data || [];
        this.roomateRequests.forEach((r) => {
          r.isShow = false;
          r.linkTSFormatted = ApiHelper.formatDate(r.linkTs, 'MMM DD, YYYY', '');
        });
        this.listItemsShowMore(5, this.roomateRequests);
        // Name entity 
        this.nameEntity = result.data.data.nameEntity;
        this.upcomingRegistrations = result.data.data.upcomingRegistrations || [];
      }
			this.outstandingBalanceFmt = dollarFormat(this.outstandingBalance);
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  listItemsShowMore(take: number, list: any = []) {
    let count = 0;
    list.map((item: any) => {
      if (!item.isShow && count < take) {
        item.isShow = true;
        count = count + 1;
      }
    });
    this.$forceUpdate();    
  }

  listItemsGetTotalHide(list: any = []) {
    return list.filter((item: any) => !item.isShow).length;
  }

  async acceptRoomateRequest(accepted = true, roomateItem: any = {}) {
    if (
      this.loading == true ||
      ((roomateItem.toParticipantId || 0) > 0 &&
        (roomateItem.toParticipantStatus || 0) == ParticipantStatus.Denied)
    ) {
      return;
    }

    try {
      this.loading = true;
      const requestObject: any = {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        roommateRequestId: roomateItem.requestId,
        eventID: roomateItem.eventId,
        profileID: roomateItem.profileId,
        participantID: roomateItem.toParticipantId,
        acceptInviteCode: roomateItem.invitedCode,
        accepted
      };

      // more info incase accept/reject a request on list "You've been invited by"
      if ((roomateItem.ptypeId || 0) > 0) {
        requestObject.pTypeId = roomateItem.ptypeId;
      }

      const response = await axios.post(
        `${dataURL}/saveEventCabins`,
        requestObject
      );
      if (response.data.status == 1) {
        if (accepted) {
          if ((roomateItem.ptypeId || 0) > 0) {
            roomateItem.linkActive = 1;
          }
          ApiHelper.showSuccessMessage("Accepted the invitation successfully");
        } else {
          // message for reject a request
          roomateItem.linkActive = 2;

          // remove in roommate requests list
          this.roomateRequests = this.roomateRequests.filter(item => item.requestId != roomateItem.requestId);

          ApiHelper.showSuccessMessage("You rejected the invitation");
        }
      } else if (response.data.message != "") {
        ApiHelper.showErrorMessage(response.data.message);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  onClickSendRmButton() {
    setTimeout(() => {
      const ps: any = this.$refs.participantsPs;
      if (ps) {
        ps.update();
      }
    }, 200);
  }

  selectedInviter(item: any) {
    this.inviterInfo = {
      eventId: item.eventID,
      pTypeId: item.participant_typeID,
      participantId: item.participantID,
      profileId: item.profileid,
      data: item
    };

    const rmRequestModal: any = this.$refs.rmRequestModal;
    if (rmRequestModal && rmRequestModal.inviteData) {
      rmRequestModal.inviteData.email = "";
      rmRequestModal.inviteData.firstName = "";
      rmRequestModal.inviteData.lastName = "";
    }

    // hide current tooltip
    $(
      ".send-rm-button .dropdown-toggle, .send-rm-button .dropdown-menu"
    ).removeClass("show");

    // show send roommate requests modal
    const rmModal = new bootstrap.Modal($("#send-rm-modal").get(0), {
      //
    });

    rmModal.show();
  }

  closeRoommateRequestsModal() {
    $("#send-rm-modal .btn-close").click();
  }

  async getRoommateRequests() {
    try {
      this.loading = true;
      const result = await axios.post(`${dataURL}/roommateRequests`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: this.$loggedUser.id
      });
      if (result.data.status == 1) {
        const data = result.data.data;
        this.roomateRequests = data.roomateRequests?.data || [];
        this.roomateRequests.forEach((r) => {
          r.isShow = false;
          r.linkTSFormatted = ApiHelper.formatDate(r.linkTs, 'MMM DD, YYYY', '');
        });
        this.listItemsShowMore(5, this.roomateRequests);
      }
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  avatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }
}
