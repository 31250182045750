






























































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { ApiHelper } from "@/helpers/all";
import Vue from "vue";
import Loading from "@/components/Common/Loading.vue";

declare let dataURL: string;
@Component({
  inheritAttrs: false,
  components: {
    Loading
  },
  methods: validateFn,
})
export default class PasswordReset extends TSXComponent<void> {
  resetForm: any = {
    isLoading: false,
    controls: {
      action: {
        value: 'signin'
      },
      token: {
        placeholder: 'token',
        value: ''
      },
      pass: {
        placeholder: 'New Password',
        value: '',
        valid: false
      },
      repass: {
        placeholder: 'Repeat password',
        value: ''
      }
    }
  }

  async created() {
    ApiHelper.userLogout();
    this.resetForm.controls.token.value = this.$route.params.token || '';
    // const loggedUser = ApiHelper.getLoggedUser();
    // if (loggedUser.id > 0) {
    //   ApiHelper.gotoPage({
    //     name: 'Dashboard'
    //   });
    // }
  }

  async doReset(event: Event) {
    event.preventDefault();
    let hasError = false;
    let message = '';
    if (this.resetForm.controls.token.value === '') {
      message = 'Token is required';
      hasError = true;
    }
    if (this.resetForm.controls.pass.value === '') {
      message = 'Password is required';
      hasError = true;
    } else if (!this.resetForm.controls.pass.valid) {
      message = 'The new password is too simple. Please choose another password.';
      hasError = true;
    }
    if (this.resetForm.controls.repass.value === '') {
      message = 'Confirm Password is required';
      hasError = true;
    }
    if (this.resetForm.controls.repass.value !== '' && this.resetForm.controls.pass.value !== '' && this.resetForm.controls.repass.value !== this.resetForm.controls.pass.value) {
      message = 'Confirm Password is not match';
      hasError = true;
    }
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      this.resetForm.isLoading = true;
      const result = await ApiHelper.apiPost('/auth/resetPassword', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        token: this.resetForm.controls.token.value,
        pass: this.resetForm.controls.pass.value,
        repass: this.resetForm.controls.repass.value
      });
      this.resetForm.isLoading = false;
      if (result.status == 1) {
        this.resetForm.controls.action.value = 'signin';
        this.resetForm.controls.pass.value = '';
        this.resetForm.controls.repass.value = '';
        ApiHelper.showSuccessMessage(result.message);
        ApiHelper.gotoPage({ name: 'Login' });
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  passwordData = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };

  checkStrongPassword(password: string) {
    this.passwordData = ApiHelper.getPasswordInfo(password);
    this.resetForm.controls.pass.valid = this.passwordData.valid;
  }
}
