



















import Vue from "vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

export default Vue.extend({
  name: "VScroller",
  props: {
    options: {
      type: Object,
      default: () => ({
        wheelPropagation: false,
        suppressScrollX: true,
        minScrollbarLength: 15
      })
    },
    onScroll: Function
  }
});
