
























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { ApiHelper } from "@/helpers/all";
import Vue from "vue";
import Loading from "@/components/Common/Loading.vue";

declare let dataURL: string;
@Component({
  inheritAttrs: false,
  components: {
    Loading
  },
  methods: validateFn,
})
export default class Login extends TSXComponent<void> {
  isLoaded = false;
  formData: any = {
    controls: {
      action: {
        value: this.$route.query.tab ? this.$route.query.tab + '' : 'signin'
      },
      email: {
        placeholder: 'Email',
        value: this.$route.query.email ? this.$route.query.email + '' : '',
        error: ''
      },
      firstName: {
        placeholder: 'First Name',
        value: '',
        error: ''
      },
      lastName: {
        placeholder: 'Last Name',
        value: '',
        error: ''
      },
      pass: {
        placeholder: 'Password',
        value: '',
        error: ''
      },
      repass: {
        placeholder: 'Repeat password',
        value: '',
        error: ''
      }
    }
  }
  $route: any;
  $customViews: any;

  async created() {
    const loggedUser = ApiHelper.getLoggedUser();
    if (loggedUser.id > 0) {
      if (this.$route.query.redirect && this.$route.query.redirect != "") {
        ApiHelper.gotoPage({
          path: this.$route.query.redirect
        });
      } else {
        ApiHelper.gotoPage({
          name: 'Dashboard'
        });
      }
    }
    else{
      this.formData.isLoading = false;
      if(this.$route.name == "SignUp") {
        this.formData.controls.action.value = "signup";
      }else if(this.$route.name == "ForgotPassword") {
        this.formData.controls.action.value = "reset";
      }
    }
  }

  async doSubmit(event: Event) {
    event.preventDefault();
    this.resetFormErrors();
    switch (this.formData.controls.action.value) {
      case 'signin':
        await this.doLogin();
        break;
      case 'signup':
        await this.doRegister();
        break;
      case 'reset':
        await this.doForgotPassword();
        break;
      default:
        ApiHelper.showErrorMessage('invalid action: ' + this.formData.controls.action.value, 'Oops');
        break;
    }
  }


  private resetFormErrors() {
    this.formData.controls.email.error = '';
    this.formData.controls.pass.error = '';
    this.formData.controls.firstName.error = '';
    this.formData.controls.lastName.error = '';
    this.formData.controls.repass.error = '';
  }

  private async doLogin() {
    let hasError = false;
    let errors: string[] = [];
    let message = '';
    if (this.formData.controls.email.value === '' || !ApiHelper.validateEmail(this.formData.controls.email.value)) {
      errors.push('Email is required');
      this.formData.controls.email.error = 'Email is required';
      hasError = true;
    }
    if (this.formData.controls.pass.value === '') {
      errors.push('Password is required');
      this.formData.controls.pass.error = 'Password is required';
      hasError = true;
    }
    if (hasError) {
      if(errors.length>1)
      {
        message = 'Sign In Incomplete';
      }
      else
      {
        message = errors.join(', ');
      }
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/auth/login', {
        uuid: ApiHelper.getUuid(),
        domain: ApiHelper.getDomain(),
        username: this.formData.controls.email.value,
        password: this.formData.controls.pass.value
      });
      
      this.formData.isLoading = false;
      if (result.status == 1) {
        ApiHelper.saveLoggedUser(result.data);
        /*
        Vue.swal({
          position: "top-end",
          // icon: "success",
          title: "Welcome back, " + [result.data.firstName, result.data.lastName].join(' ') + '!',
          showConfirmButton: false,
          timer: 1000
        });
        */
        if (this.$route.query.redirect) {
          ApiHelper.gotoPage({
            path: this.$route.query.redirect
          });
        } else {
          ApiHelper.gotoPage({
            name: this.defaultRouteName
          });
        }

      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  private async doRegister() {
    let hasError = false;
    let errors: string[] = [];
    let message = '';
    let notmatchPwd = false;
    if (this.formData.controls.email.value === '') {
      errors.push('Email is required');
      this.formData.controls.email.error = 'Email is required';
      hasError = true;
    }
    if (this.formData.controls.pass.value === '') {
      errors.push('Password is required');
      this.formData.controls.pass.error = 'Password is required';
      hasError = true;
    }
    if (this.formData.controls.firstName.value === '') {
      errors.push('First Name is required');
      this.formData.controls.firstName.error = 'First Name is required';
      hasError = true;
    }
    if (this.formData.controls.lastName.value === '') {
      errors.push('Last Name is required');
      this.formData.controls.lastName.error = 'Last Name is required';
      hasError = true;
    }
    if (this.formData.controls.repass.value === '') {
      errors.push('Confirm Password is required');
      this.formData.controls.repass.error = 'Confirm Password is required';
      hasError = true;
    }
    if (this.formData.controls.repass.value !== '' && this.formData.controls.pass.value !== '' && this.formData.controls.repass.value !== this.formData.controls.pass.value) {
      this.formData.controls.repass.error = 'Confirm Password is not match';
      notmatchPwd = true;
      hasError = true;
    }
    if (hasError) {
      if(errors.length>0)
      {
        message = 'Sign Up Incomplete';
      }
      else if (notmatchPwd)
      {
        message = 'Confirm Password is not match';
      }
      else
      {
        message = 'Password must at least six characters';
      }

      ApiHelper.showErrorMessage(message, 'Error');

    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/auth/register', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        email: this.formData.controls.email.value,
        firstName: this.formData.controls.firstName.value,
        lastName: this.formData.controls.lastName.value,
        pass: this.formData.controls.pass.value,
        repass: this.formData.controls.repass.value
      });
      this.formData.isLoading = false;
      if (result.status == 1) {
        this.formData.controls.action.value = 'signin';
        this.formData.controls.firstName.value = '';
        this.formData.controls.lastName.value = '';
        this.formData.controls.pass.value = '';
        this.formData.controls.repass.value = '';
        this.$router.push({ name: "Login" });
        ApiHelper.showSuccessMessage(result.message);
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  private async doForgotPassword() {
    let hasError = false;
    let errors: string[] = [];
    let message = '';
    if (this.formData.controls.email.value === '') {
      errors.push('Email is required');
      this.formData.controls.email.error = 'Email is required';
      hasError = true;
    }
    if (hasError) {
      ApiHelper.showErrorMessage(errors.join(', ') || message, 'Error');
    } else {
      this.formData.isLoading = true;
      const result = await ApiHelper.apiPost('/auth/forgotPassword', {
        uuid: ApiHelper.getUuid(),
        domain: ApiHelper.getDomain(),
        email: this.formData.controls.email.value,
        useCode: 0,
      });
      this.formData.isLoading = false;
      // if (result.status == 1) {
      //   ApiHelper.showSuccessMessage('A reset password email was sent!');
      // } else {
      //   ApiHelper.showErrorMessage(result.message, 'Oops');
      // }
      Vue.swal.fire({
        // icon: 'success',
        title: "Success",
        text: 'If your email was found in our system we have sent you password reset instructions',
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "SecondaryColor FontColor"
        }
      });
      ApiHelper.gotoPage({ name: 'Login' });
    }
  }

  get showSignup() {
    const action = this.formData.controls.action.value;
    if(action == "signup") {
      return false;
    }

    return true;
  }

  get showSigin() {
    const action = this.formData.controls.action.value;
    if(action == "reset" || action == 'signin') {
      return false;
    }

    return true;
  }

  get defaultRouteName() {
    const ret = "Dashboard";
    if((this.$customViews || []).length) {
      return this.$customViews[0].routeName || "";
    }

    return ret;
  }
}
