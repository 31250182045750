
















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  data: any;
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {
  }
})
export default class FormPassword extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

}
