






























































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import Loading from "../components/Common/Loading.vue";
import { ApiHelper } from "../helpers/all";
import Pager from "../components/Pager.vue";
import NoData from "../components/Common/NoData.vue";
import ApexChart from "../components/Chart/ApexChart.vue";
import ApexLine from "../components/Chart/ApexLine.vue";
import PageHeader from "@/components/PageHeader.vue";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    PageHeader,
    Loading,
    NoData,
    Pager,
    Pagination,
    ApexChart,
    ApexLine
  }
})
export default class Plans extends TSXComponent<void> {
  pageData: {
    hasError: boolean;
    errorMessage: string;
    isLoading: boolean;
    items: any[];
    paging: {
      skip: number;
      take: number;
      totalCount: number;
    };
    pager: {
      page: number;
      items: any[];
      total: number;
      totalPages: number;
    };
  } = {
    hasError: false,
    errorMessage: "",
    isLoading: false,
    items: [],
    paging: {
      skip: 0,
      take: 8,
      totalCount: 0
    },
    pager: {
      page: 1,
      items: [],
      total: 0,
      totalPages: 0
    }
  };

  updateRouters() {
    this.$router
      .replace({
        name: "Plans",
        query: {
          page: (this.pageData.pager.page || 1) + ""
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  }

  gotoPage(page) {
    this.pageData.pager.page = parseInt(page);
    this.updateRouters();
    this.loadList();
  }

  onClickNext() {
    if (this.pageData.pager.page < this.pageData.pager.totalPages) {
      this.pageData.pager.page += 1;
      this.updateRouters();
      this.loadList();
    }
  }

  onClickPrev() {
    if (this.pageData.pager.page > 1) {
      this.pageData.pager.page -= 1;
      this.updateRouters();
      this.loadList();
    }
  }

  getTotalPages() {
    return Math.ceil(
      this.pageData.paging.totalCount / this.pageData.paging.take
    );
  }

  getCurrentPage() {
    return Math.ceil(this.pageData.paging.skip / this.pageData.paging.take) + 1;
  }

  async created() {
    await this.loadData("1");
  }

  async loadData(page) {
    this.pageData.pager.page = page;
    this.loadList();
  }

  async loadList() {
    this.pageData.hasError = false;
    this.pageData.errorMessage = "";

    this.pageData.pager.page = this.$route.query
      ? parseInt(
          this.$route.query.page ? this.$route.query.page + "" || "1" : "1"
        ) || 1
      : 1;
    this.pageData.isLoading = true;
    this.pageData.paging.skip =
      (this.pageData.pager.page - 1) * this.pageData.paging.take;

    this.pageData.isLoading = true;
    const loggedUser = ApiHelper.getLoggedUser();
    const result = await ApiHelper.apiPost("/plans", {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      profileID: loggedUser.id,
      skip: this.pageData.paging.skip,
      take: this.pageData.paging.take,
      filters: {}
    });
    this.pageData.isLoading = false;
    if (result.status === 1) {
      this.pageData.items = result.data.plans;
      const totalCount = result.data.paging.totalCount || 0;
      this.pageData.paging.totalCount = totalCount;

      this.pageData.pager.totalPages = Math.ceil(
        totalCount / this.pageData.paging.take
      );
      this.pageData.pager.total = totalCount;
      const pagerList: any[] = [];
      for (let i = 0; i < this.pageData.pager.totalPages; i++) {
        const pagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === this.pageData.pager.page
        };
        pagerList.push(pagerItem);
      }
      this.pageData.pager.items = pagerList;
    } else {
      this.pageData.hasError = true;
      this.pageData.errorMessage = result.message || JSON.stringify(result);
    }
  }

  dollarFormat(amount) {
    return ApiHelper.dollarFormat(amount);
  }

  beforeRouteLeave(to, from, next) {
    next();
  }
}
