




























import ModalDirection from "@/components/Modal.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

import router, { routerData } from './router'
import ErrorView from './pages/Error.vue'
import DomainNotFound from './pages/DomainNotFound.vue'
import { getApiURL } from "./helpers/ApiHelper";
import { Route } from "vue-router";
import { standaloneLoadScripts } from "./helpers/StandaloneJS";
import { ApiHelper } from "./helpers/all";
import Loading from "@/components/Common/Loading.vue";
import Vue from 'vue'
import VueGtag from "vue-gtag";

const data: any = routerData
declare const $: any;
declare let dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Loading,
    ModalDirection,
    ErrorView,
    DomainNotFound,
  },
})
export default class App extends TSXComponent<void> {
  headerData = {
    isLoaded: false,
    customView: null,
    title: '',
    logo: ''
  };
  appData = {
    isLoading: true,
  };

  $loggedUser: any;
  modalOpen = false;

  get message() {
    return data.errorData.message
  }

  get redirectName() {
    return data.errorData.redirectName
  }

  get redirectQuery() {
    return data.errorData.redirectQuery
  }

  openModal() {
    this.modalOpen = !this.modalOpen;
  }

  async mounted() {
    // if(dataURL == "") {
    //   dataURL = await getApiURL();
    // }
    const apiURL: any = await getApiURL(true);
    dataURL = apiURL.dataURL;

    // setup google analytic
    const ga = apiURL.ga || "";
    if(ga != "" && this.$loggedUser.type != 2) {
      Vue.use(VueGtag, {
        config: { id: ga }
      }, router);
    }

    let cssFile = "";
    // const registrationPortalData = await ApiHelper.getCustomizationData();
    const registrationPortalData = apiURL.registrationPortalData;
    let fontName = "";
    for(const item of registrationPortalData) {
      if (item.type === 'title') {
        if (item.name === 'PortalTitle') {
          document.title = item.value;
        }
      } else {
        let currentRule = "\t" + item['type'] + item['name'] + " {\n";

        $.each(item['rules'], function (i, elem) {
          if (elem['name'] == 'font-family') {
            // fontName = elem['value'];
          }else {
            currentRule = currentRule.concat("\t" + elem['name'] + ": " + elem['value'] + ";\n");
          }
        });
        currentRule = currentRule.concat("}\n\n");
        cssFile = cssFile.concat(currentRule);
      }
    }

    const GetBorderColor = registrationPortalData.find(item => (item.name || "") == "SecondaryColor" && (item.type || "") == ".") || {
      rules: [],
    };
    if (GetBorderColor.rules && GetBorderColor.rules.length) {
      cssFile += `
      .progress .left, .progress .right {
        border-color: ${GetBorderColor.rules[1].value};
      }
    `;
    }


    // load font
    if(fontName == "") {
      // default
      const fontLink = `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap" rel="stylesheet">`;
      $("head").append(fontLink);
      cssFile += `body { font-family: 'Roboto', sans-serif; }`;
    }else {
      // load custom font
      const fontLink = `<link href="https://fonts.googleapis.com/css2?family=${fontName.replace(/\s/g, "+")}:wght@100;300;400;500;600;700;900&amp;display=swap" rel="stylesheet">`;
      $("head").append(fontLink);
      cssFile += ` 
        .apexcharts-legend-text,
        .apexcharts-text tspan,
        .apexcharts-tooltip * {
          font-family: ${fontName} !important;
        }
      `;
    }

    // fix for secondary color at some input:checked + label style
    const secondaryColor = registrationPortalData.find(item => (item.name || "") == "SecondaryColor" && (item.type || "") == ".") || {
      rules: [],
    };
    if(secondaryColor && secondaryColor.rules.length) {
      let ruleString = "";
      let color = '';
      for(const rule of secondaryColor.rules) {
        ruleString += ` ${rule.name}: ${rule.value}; `;
        color = rule.value;
      }
      cssFile += `
        .send-rm-button .dropdown-menu .dropdown-menu-item:hover {
          ${ruleString}
        }
        .todo-page .accordion-body .ps__rail-y > .ps__thumb-y {
          ${ruleString}
        }
        .navbar-light .navbar-nav .nav-item .nav-link:before {
          ${ruleString}
        }
        .profile-pay-btn.isLoggedUser, .admin-label.isLoggedUser {
          border-bottom: 1px solid ${color}
        }
        .finance-page .tabs-list .tab-total {
          background: ${color}
        }
        .mx-calendar-content .cell.active {
            background-color: ${color};
        }
        .participant_checkout .btn-check:checked + label, .participant_checkout .btn-check + label:hover {
          background-color: ${color};
        }
        .swal2-confirm {
          background-color: ${color};
        }
        .mx-calendar-content .cell div {
          color: #939393 !important;
        }
        .mx-table-date .cell.not-current-month div {
          color: #ccc !important;
        }
        .mx-calendar-content .cell.active div {
          color: inherit !important;
        }
        .mx-datepicker-popup .mx-calendar-header *,
        .mx-table-date .today {
          color: ${color};
        }
        svg.secondaryColor, circle.circle-chart__circle.stroke {
          stroke: ${color};
        }
        .SecondaryCheckColor {
          stroke: ${color};
        }
        .registration_bottom_btns {
          /* background: ${color}; */
        }
      `;
    }

    // fix for font color at some input:checked + label style
    const fontColor = registrationPortalData.find(item => (item.name || "") == "FontColor" && (item.type || "") == ".");
    if(fontColor && fontColor.rules.length) {
      let ruleString = "";
      for(const rule of fontColor.rules) {
        ruleString += ` ${rule.name}: ${rule.value}; `;
      }
      // fill for svg based on primary color
      const fillColor = fontColor.rules.find(item => (item.name || "").toLowerCase() == "color")?.value || "";
      if(fillColor != "") {
        cssFile += `
          .swal2-confirm {
            color: ${fillColor};
          }
          body *, input:placeholder-shown, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder, ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-moz-placeholder, input::placeholder {
            color: ${fillColor} !important
          }
          .safeZoneDividers:first-child {
            border-right: 3px solid ${fillColor};
          }
          .cropImgWrapper {
            border: 3px solid ${fillColor};
          }
          .safeZoneContainer {
            color: ${fillColor};
          }
          .FontColor svg:not(.outline) path {
            fill: ${fillColor}
          }
          svg.page-loader path {
            fill: ${fillColor}
          }
          .sidebar__item_click_active > a::after, 
          .sidebar__item_click:hover > a::after,
          .sidebar__item_bottom_active > a::after,
          .sidebar__item_bottom:hover > a::after,
          .sidebar__item_active > a::after,
          .sidebar__item:hover > a::after { background: ${fillColor}}
          .registration_plus_participants .btn-check:checked + label {
            background-color: ${fillColor};
          }
        `;
      }
      
      cssFile += `
        .participant_checkout .btn-check:checked + label, 
        .participant_checkout .btn-check + label:hover,
        .registration_plus_participants .btn-check:checked + label {
          ${ruleString}
        }
        .participant_checkout .btn-check:checked + label .person_title, 
        .participant_checkout .btn-check:checked + label .person_subtitle, 
        .participant_checkout .btn-check + label:hover .person_title,
        .participant_checkout .btn-check + label:hover .person_subtitle,
        .registration_plus_participants .btn-check + label:hover,
        .registration_plus_participants .btn-check + label:hover .person_subtitle,
        .send-rm-button .dropdown-menu .dropdown-menu-item:hover > div,
        .send-rm-button .dropdown-menu .dropdown-menu-item:hover .person_title,
        .new-registration .event-name > span {
          ${ruleString}
        }
      `;
    }

    $("head").append("<style>\n" + cssFile + "\n</style>");

    const findMenu = registrationPortalData.find((item: any) => {
      return item.type === 'menu';
    });
    if (findMenu) {
      this.headerData.customView = findMenu.value || '';
    }
    const findLogo = registrationPortalData.find((item: any) => {
      return item.type === 'image' && item.name === 'Logo';
    });
    if (findLogo) {
      this.headerData.logo = findLogo.value || '';
    }
    const findTitle = registrationPortalData.find((item: any) => {
      return item.type === 'title' && item.name === 'PortalTitle';
    });
    if (findTitle) {
      this.headerData.title = findTitle.value || '';
    }
    this.headerData.isLoaded = true;
    $(document).on('click', '.btn-hamburger', () => {
      $('.sidebar.slide_right').toggleClass('sidebar_active');
      $('.overlay').toggleClass('overlay_active');
      $('.btn-hamburger').toggleClass('btn-hamburger_active');
      $('.header').toggleClass('header_sbfix');
      $('body').toggleClass('no-scroll');
    });

    const paramUser = new URLSearchParams(
      window.location.hash.split("?")[1] || ""
    ).get("user");
    const paramGroup = new URLSearchParams(
      window.location.hash.split("?")[1] || ""
    ).get("group");
    if (paramUser && window.location.href.indexOf("preview=1") != -1) {
      // case: preview profile
      const profileUUID = decodeURIComponent(paramUser);
      // authenticate previewed profile
      const response = await ApiHelper.apiPost(
        "/auth/preview",
        {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          user: profileUUID
        }
      );
      if (response.status === 1) {
        const previewProfile = response.data.profile;
        ApiHelper.setStorageItem(ApiHelper.getLoggedUserDataKey(), previewProfile);
        if (this.$route.query.redirect && this.$route.query.redirect != "") {
          ApiHelper.gotoPage({
            path: this.$route.query.redirect
          }, () => {
            this.appData.isLoading = false;
          });
        } else {
          ApiHelper.gotoPage({
            name: 'Dashboard'
          }, () => {
            this.appData.isLoading = false;
          });
        }
      } else {
        ApiHelper.gotoPage({
          name: 'Dashboard'
        }, () => {
          this.appData.isLoading = false;
        });
      }
    } else if (paramGroup) {
      // registering with this group
      ApiHelper.gotoPage({
        name: 'NewRegistration',
        query: {
          group: paramGroup
        }
      }, () => {
        this.appData.isLoading = false;
      });
    } else {
      this.appData.isLoading = false;
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    $("body").removeClass("no-scroll");
    $('.sidebar.slide_right').removeClass('sidebar_active');
    $('.overlay').removeClass('overlay_active');
    $('.btn-hamburger').removeClass('btn-hamburger_active');
    $('.header').toggleClass('header_sbfix');
    try {
      // make sure not load js/css of apply page
      if(this.$route.name != "Apply") {
        $("body")
          .removeAttr("id")
          .removeAttr("style")
          .removeClass("sideNav bgColor");
        
        // remove nicescroll
        $("body").getNiceScroll().remove();
        $("head .standaloneScripts, head .standaloneStyles").remove();
      }   
    } catch (error) {
      // console.log(error);
    }
  }
}
