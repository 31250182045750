






























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  pageTitle: string;
  titleBox?: boolean;
  hasPaddingX?: boolean;
  noMargin?: boolean;
  bottomFixed?: boolean;
  titleStyle?: object;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class PageTitle extends TSXComponent<Props, Events> {
  @Prop() pageTitle!: string;
  @Prop() titleBox?: boolean;
  @Prop() bottomFixed?: boolean;
  @Prop() hasPaddingX?: boolean;
  @Prop() noMargin?: boolean;
  @Prop() titleStyle?: object;
}
