var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.name != 'Apply')?_c('div',{staticClass:"w-100",class:['d-flex', {
    'login': (
      _vm.$route.name ==='Login'
      || _vm.$route.name ==='PasswordReset'
      || _vm.$route.name ==='signUp'
      || _vm.$route.name ==='SignUp'
      || _vm.$route.name ==='ForgotPassword'
      ),
    'preview-mode': _vm.$loggedUser.type == 2,
    'custom-views-ett': (_vm.$customViews || []).length
  }],attrs:{"id":"body-site"}},[(_vm.appData.isLoading)?_c('Loading',{attrs:{"has-sidebar":false}}):_vm._e(),(_vm.message)?_c('ErrorView',{attrs:{"message":_vm.message,"redirectName":_vm.redirectName,"redirectQuery":_vm.redirectQuery},on:{"back":_vm.back}}):(_vm.$domainNotFound)?_c('DomainNotFound'):_c('router-view',{key:_vm.$route.fullPath})],1):_c('router-view',{key:_vm.$route.fullPath})}
var staticRenderFns = []

export { render, staticRenderFns }