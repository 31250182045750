import { render, staticRenderFns } from "./TransactionDetails.vue?vue&type=template&id=e7bc1b74&scoped=true&"
import script from "./TransactionDetails.vue?vue&type=script&lang=tsx&"
export * from "./TransactionDetails.vue?vue&type=script&lang=tsx&"
import style0 from "./TransactionDetails.vue?vue&type=style&index=0&id=e7bc1b74&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7bc1b74",
  null
  
)

export default component.exports