export const standaloneLoadScripts = () => {
  const cssFiles = ["standalone_styles", "standalone_apply", "standalone_apps", "standalone_overrides", "standalone_skeuocard"];
  cssFiles.map((name) => {
    var link = document.createElement("link");
    link.href = '/css/' + name + ".css";
    link.type = "text/css";
    link.className="standaloneStyles"
    link.rel = "stylesheet";
    link.media = "screen,print";
    document.getElementsByTagName("head")[0].appendChild(link);
  });

  const jsFiles = [
    "https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js",
    "https://code.jquery.com/ui/1.12.1/jquery-ui.min.js",
    "https://formbuilder.online/assets/js/form-render.min.js",
    "js/jquery.nicescroll.js"
  ];
  jsFiles.map((name) => {
    var scriptElement = document.createElement("script");
    scriptElement.className="standaloneScripts"
    scriptElement.src = name;
    document.getElementsByTagName("head")[0].appendChild(scriptElement);
  });
};

export const standaloneApplyJS = () => {
  // js for phoneMask
  $(document).on("keydown.autocomplete", ".phoneMask", function (e) {
    const key = e.charCode || e.keyCode;
    const $phone = $(e.target);

    if (typeof key == 'undefined') {
      setTimeout(function () {
        const phoneData: any = $phone.val();
        const number = phoneData.match(/\d/g, "");
        const joinedNumber = number.join("");
        const regex = /^(\d{3})(\d{3})(\d{4})$/;
        const final = joinedNumber.replace(regex, "($1) $2-$3");

        $phone.val(final);
      }, 10);
    }
  });
  $(document).on("focus click", ".phoneMask", function (e) {
    const $phone: any = $(e.target);
    if ($phone.val().length === 0) {
      $phone.val('(');
    } else {
      const val = $phone.val();
      $phone.val('').val(val); // Ensure cursor remains at the end
    }
  });
  $(document).on("blur", ".phoneMask", function (e) {
    const $phone = $(e.target);

    if ($phone.val() === '(') {
      $phone.val('');
    }
  });
  $(document).on("paste", ".phoneMask", function (e: any) {
    const $phone = $(e.target);
    var phoneData = e.originalEvent.clipboardData.getData('Text');

    const number = phoneData.match(/\d/g, "");
    const joinedNumber = number.join("");
    const regex = /^(\d{3})(\d{3})(\d{4})$/;
    const final = joinedNumber.replace(regex, "($1) $2-$3");

    $phone.val(final);
  });
  $(document).on("keydown", ".phoneMask", function (e) {
    var key = e.charCode || e.keyCode || 0;
    const $phone: any = $(e.target);
    let $val: any = $phone.val();

    if ($val.length === 0) {
      $phone.val('(');
    } else if ($val.length > 0) {
      if ($val.indexOf('(') == -1) {
        $val = "(" + $val;
        $phone.val($val);
      }
    }

    // Auto-format- do not expose the mask as the user begins to type
    if (key !== 8 && key !== 9) {
      if ($phone.val().length === 4) {
        $phone.val($phone.val() + ')');
      }
      if ($phone.val().length === 5) {
        $phone.val($phone.val() + ' ');
      }
      if ($phone.val().length === 9) {
        $phone.val($phone.val() + '-');
      }
    }

    // Allow numeric (and tab, backspace, delete) keys only
    return (key == 8 ||
      key == 9 ||
      key == 46 ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105));
  });

  // createPassword
  $(document).on("keyup", "#createPassword", function (e) {
    const $outputTarget = $(e.target).closest('.password-field');
    const strength = checkPasswordStrength($(e.target).val());

    $outputTarget.removeClass(function (index, css) {
      return (css.match(/level\S+/g) || []).join(' ');
    });

    if ($(e.target).val() != "") {
      $outputTarget.find(".progress").removeClass("hidden");
      $outputTarget.addClass('level' + strength);
    } else {
      $outputTarget.find(".progress").addClass("hidden");
    }
  });
};

const checkPasswordStrength = (password: any) => {
  let strength = 0;

  // If password is 6 characters or longer
  if (password.length >= 8) {
    strength += 1;
  }

  // If password contains both lower and uppercase characters, increase
  // strength value.
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    strength += 1;
  }

  // If it has numbers and characters, increase strength value.
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    strength += 1;
  }

  return strength;
}