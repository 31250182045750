











import { Component as TSXComponent } from "vue-tsx-support"
import { Component, Prop, Vue } from 'vue-property-decorator';

interface Props {
}

@Component({
  inheritAttrs: false,
})
export default class Footer extends TSXComponent<Props> {
  
}
