

































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Select2 from "v-select2-component";

interface Props {
  data: any;
}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    Select2
  }
})
export default class FormSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false, default: false })
  select2?: boolean;

  options: any = [];

  created() {
    this.options = this.data.options.map((item) => ({
      id: item.value,
      text: item.text
    }));
  }

  onChange() {
    this.data.error = '';
    this.$emit('onChange');
  }
}
