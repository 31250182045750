import { ApiHelper } from "@/helpers/all";
import AtCamp from './pages/AtCamp.vue'
import BeginApplication from './pages/BeginApplication.vue'
import CompleteApplication from './pages/CompleteApplication.vue'
import Dashboard from './pages/Dashboard.vue'
import Error from './pages/Error.vue'
import Error404 from './pages/Error404.vue';
import Finance from './pages/Finance.vue'
import Login from './pages/Login.vue'
import Message from './pages/Message.vue'
import NewRegistration from './pages/NewRegistration.vue'
import PasswordReset from "@/pages/PasswordReset.vue";
import People from './pages/People.vue'
import PeopleAdd from './pages/PeopleAdd.vue'
import Profile from './pages/Profile.vue'
import ProfileEdit from './pages/ProfileEdit.vue'
import Registration from './pages/Registration.vue'
import Router from 'vue-router'
import Todo from './pages/Todo.vue'
import TransactionDetails from './pages/TransactionDetails.vue';
import Transactions from './pages/Transactions.vue';
import Plans from './pages/Plans.vue';
import Vue from 'vue'
import passwordChange from './pages/passwordChange.vue'
import Apply from './pages/Apply.vue'
import { getApiURL } from "./helpers/ApiHelper";

// define custom views
import Tickets from './pages/custom/Tickets.vue';
import TicketsDetail from './pages/custom/TicketsDetail.vue';
import TicketsMessage from './pages/custom/TicketsMessage.vue';

export const routerData = new Vue({
  data: {
    errorData: {}
  }
})

function resetRouterData() {
  Vue.set(routerData.errorData, 'message', '')
  Vue.set(routerData.errorData, 'redirectName', '')
  Vue.set(routerData.errorData, 'redirectQuery', {});
  const win = (window as any)
  win.errorData = null
}

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: Login
    },
    {
      path: '/forgotPassword',
      name: 'ForgotPassword',
      component: Login
    },
    {
      path: '/passwordChange/:id/:token',
      name: 'passwordChange',
      component: passwordChange,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reset-password/:id/:token',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/logout',
      name: 'Logout',
    },
    {
      path: '/activation',
      name: 'Activation',
    },
    {
      path: '/error',
      name: 'Error',
      component: Error,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/registration',
      name: 'Registration',
      component: Registration,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new-registration',
      name: 'NewRegistration',
      component: NewRegistration,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/begin-application',
      name: 'BeginApplication',
      component: BeginApplication,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/complete-application',
      name: 'CompleteApplication',
      component: CompleteApplication,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/view-application/:participantId',
      name: 'ViewApplication',
      component: BeginApplication,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/todo',
      name: 'Todo',
      component: Todo,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/people',
      name: 'People',
      component: People,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/people/:id/edit',
      name: 'ProfileEdit',
      component: ProfileEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/people/new',
      name: 'NewPerson',
      component: PeopleAdd,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/atcamp',
      name: 'AtCamp',
      component: AtCamp,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/finance',
      name: 'Finance',
      component: Finance,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/message',
      name: 'Message',
      component: Message,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/transaction',
      name: 'TransactionDetails',
      component: TransactionDetails,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/transactions',
      name: 'Transactions',
      component: Transactions,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/plans',
      name: 'Plans',
      component: Plans,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "*",
      name: "NotFound",
      component: Error404
    },
    {
      path: '/apply',
      name: 'Apply',
      component: Apply
    },
    // === define custom routes ===
    {
      path: '/tickets',
      name: 'Tickets',
      component: Tickets,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/ticketsDetail/:id',
      name: 'TicketsDetail',
      component: TicketsDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/ticketsMessage',
      name: 'TicketsMessage',
      component: TicketsMessage,
      meta: {
        requiresAuth: true
      }
    }
    // === end of define custom routes ===
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

Vue.prototype.$loggedUser = {};
Vue.prototype.$campCartKey = "";
Vue.prototype.$domainNotFound = false;
Vue.prototype.$statsUpdated = 0;
Vue.prototype.$customViews = [];
router.beforeEach(async (to, from, next) => {
  if(dataURL == "") {
    const apiURL: any = await getApiURL();
    dataURL = apiURL.dataURL;

    if(dataURL == "") {
      Vue.prototype.$domainNotFound = true;
    }
  }

  if (to.name === 'Logout') {
    ApiHelper.userLogout();
    /*
    Vue.swal({
      position: "top-end",
      // icon: "success",
      title: "Logged out!",
      showConfirmButton: false,
      timer: 1000
    });
    */
    next({
      name: 'Login'
    });
  }

  if (to.name === 'Activation') {
    ApiHelper.apiPost('/activeProfile', {
      email: to.query.email || '',
      code: to.query.code || ''
    }).then((result) => {
      if (result.status == 1) {
        ApiHelper.showSuccessMessage(result.message, 'Success');
        next({
          name: 'Login',
          query: {
            email: to.query.email
          }
        });
      } else {
        next({
          name: 'Login'
        });
      }
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const loggedUser = ApiHelper.getLoggedUser();
    Vue.prototype.$loggedUser = loggedUser;
    Vue.prototype.$campCartKey = `campCart${loggedUser.entityUUID}-${loggedUser.familyId}`;

    if (!loggedUser.id) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (to.name != "" && to.name != null) {
        const { checkHasAccess, statsUpdated, customViews } = await ApiHelper.checkHasAccess(to);
        if (checkHasAccess.canAccess == false) {
          Vue.set(routerData.errorData, 'message', 'Item not exist or you have no access to this.');
          Vue.set(routerData.errorData, 'redirectName', checkHasAccess.redirectName);
          Vue.set(routerData.errorData, 'redirectQuery', checkHasAccess.redirectQuery || {});
          const win = (window as any);
          win.errorData = routerData.errorData
          next();
          return;
        }
        // check if force to get stats
        Vue.prototype.$statsUpdated = statsUpdated;
        Vue.prototype.$customViews = customViews;
      }
      resetRouterData();
      next();
    }
  } else {
    resetRouterData();
    next() // make sure to always call next()!
  }
});

declare global {
  interface Window {
    router: Router
  }
}

window.router = router

export default router
