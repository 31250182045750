























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import Loading from "@/components/Common/Loading.vue";
import Vue from "vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import {
  FormSelect,
  FormEmail,
  FormDate,
  FormText,
  FormPassword,
  FormYesNo
} from "@/components/Form";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Loading,
    FormSelect,
    FormEmail,
    FormDate,
    FormText,
    FormPassword,
    FormYesNo,
    FormUpload,
    FormPhoto
  },
})
export default class PeopleAdd extends TSXComponent<void> {
  formData: any = {
    isLoading: false,
    controls: {
      firstName: {
        label: 'First Name',
        placeholder: 'First Name',
        value: '',
        error: ''
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'Last Name',
        value: '',
        error: ''
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
        value: '',
        error: ''
      },
      phone: {
        label: 'Phone',
        placeholder: 'Phone',
        type: 'phone',
        maxlength: 14,
        // regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        value: '',
        error: ''
      },
      dob: {
        label: 'Date Of Birth',
        placeholder: 'Date Of Birth',
        value: '',
        error: ''
      },
      gender: {
        error: '',
        label: 'Gender',
        placeholder: 'Select Gender',
        value: '',
        options: [
          {
            value: '1',
            text: 'Male'
          },
          {
            value: '2',
            text: 'Female'
          },
          {
            value: '3',
            text: 'Undefined/Other/Prefer Not to Say'
          }
        ]
      },
      type: {
        type: "select",
        options: [],
        placeholder: "This participant is my...",
        value: "",
        label: "Relationship to me is",
        error: "",
        style: "custom"
      },
      logo: {
        error: '',
        label: '',
        preview: '',
        value: '',
        s3url: '',
        base64: ''
      },
      createUser: {
        label: "Allow admin permissions?",
        error: "",
        type: "radios",
        value: "no"
      },
      password: {
        label: "Password",
        mode: "password",
        error: "",
        placeholder: "Enter a password",
        type: "password",
        value: "",
        style: "custom"
      }
    }
  }

  async created() {
    const loggedUser = ApiHelper.getLoggedUser();
    if (loggedUser.type !== 1) {
      ApiHelper.showErrorMessage('You dont have the permission', 'Access Denied')
     ApiHelper.gotoPage({ name: 'People' });
    } else {
      this.formData.controls.type.options = ApiHelper.getFamilyTypeOptions();
    }
  }


  validateForm() {
    let hasError = false;
    this.formData.controls.firstName.error = '';
    this.formData.controls.lastName.error = '';
    this.formData.controls.email.error = '';
    this.formData.controls.password.error = '';
    this.formData.controls.gender.error = '';
    this.formData.controls.phone.error = '';

    // this.formData.controls.phone.value = this.formData.controls.phone.value.replace(/[^0-9-() ]/g, '');

    if (this.formData.controls.firstName.value.trim() === '') {
      hasError = true;
      this.formData.controls.firstName.error = 'First Name is required'
    }
    if (this.formData.controls.lastName.value.trim() === '') {
      hasError = true;
      this.formData.controls.lastName.error = 'Last Name is required'
    }
    if (this.formData.controls.dob.value.trim() === '') {
      hasError = true;
      this.formData.controls.dob.error = 'DOB is required'
    }
    if (this.formData.controls.gender.value.trim() === '') {
      hasError = true;
      this.formData.controls.gender.error = 'Gender is required'
    }
    if (!this.formData.controls.type.value) {
      hasError = true;
      this.formData.controls.type.error = 'Relation Type is required'
    }
    if (this.formData.controls.email.value.trim() !== '') {
      if (!ApiHelper.validateEmail(this.formData.controls.email.value.trim())) {
        hasError = true;
        this.formData.controls.email.error = 'Email is invalid'
      }
    }

    if (this.formData.controls.phone.value !== '') {
      // if (!ApiHelper.validatePhone(this.formData.controls.phone.value)) {
      //   hasError = true;
      //   this.formData.controls.phone.error = 'Phone is invalid'
      // }
      const phone = $("input[inputmask=phonenumber]");
      if(phone.length && phone.get(0).inputmask && phone.get(0).inputmask.isComplete() == false) {
        hasError = true;
        this.formData.controls.phone.error = 'Phone is invalid'
      }
    }

    if (this.formData.controls.createUser.value == 'yes') {
      if (this.formData.controls.email.value.trim() === '') {
        hasError = true;
        this.formData.controls.email.error = 'Email is required'
      }
      if (this.formData.controls.password.value.trim() === '') {
        hasError = true;
        this.formData.controls.password.error = 'Password is required'
      } else {
        hasError = true;
        this.formData.controls.password.error = 'The new password is too simple. Please choose another password.';
      }
    }
    return !hasError;
  }

  private async changeProfileAvatar(base64: string, type?: string) {
    let hasError = false;
    let message = '';
    if (hasError) {
      ApiHelper.showErrorMessage(message, 'Error');
    } else {
      Vue.swal({
        title: "Change your photo",
        text: "Do you want to change the new photo?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
        allowOutsideClick: false,
        customClass: {
          container: "swal2-container-custom",
        }
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.formData.isLoading = true;
          const result2 = ApiHelper.apiPost('/updateProfileAvatar', {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            logoBase64: base64,
            type: type,
            profileID: 0
          }).then((result2) => {
            this.formData.isLoading = false;
            if (result2.status == 1) {
              this.formData.controls.logo.value = result2.data.url || '';
              this.formData.controls.logo.preview = result2.data.url || '';
              this.formData.controls.logo.s3url = result2.data.url || '';
              ApiHelper.showSuccessMessage('Updated Photo');
            } else {
              ApiHelper.showErrorMessage(result2.message, 'Oops');
            }
          });
        } else {
          this.formData.controls.logo.preview = this.formData.controls.logo.value;
        }
      });
    }
  }

  async onSubmit(event: Event) {
    event.preventDefault();
    const isValidForm = this.validateForm();
    if (isValidForm) {
      this.formData.isLoading = true;
      const loggedUser = ApiHelper.getLoggedUser();
      const result = await ApiHelper.apiPost('/createProfile', {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        familyID: loggedUser.familyId,
        mainProfileID: loggedUser.id,
        fname: this.formData.controls.firstName.value,
        lname: this.formData.controls.lastName.value,
        email: this.formData.controls.email.value,
        phone: this.formData.controls.phone.value,
        gender: this.formData.controls.gender.value,
        dob: this.formData.controls.dob.value,
        photoS3url: this.formData.controls.logo.s3url,
        type: this.formData.controls.createUser.value == 'yes' ? 1 : 2,
        password: this.formData.controls.createUser.value == 'yes' ? this.formData.controls.password.value : undefined,
        relationTypeID: Number.parseInt(this.formData.controls.type.value, 10) || 0
      });
      this.formData.isLoading = false;
      if (result.status === 1) {
        ApiHelper.showSuccessMessage('Created new profile');
        ApiHelper.updateProfileStats();
        ApiHelper.gotoPage({ name: 'People' });
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    }
  }

  dollarFormat(amount) {
    return ApiHelper.dollarFormat(amount);
  }


  mounted() {
    $(document).ready(function() {
      $(".ProfileEditPage").on("keydown", ".phoneMask, input[type=tel]", function(e) {
        const key = e.charCode || e.keyCode || 0;
        const $phone = $(e.target);
        let $val: any = $phone.val();

        if ($val.length === 0) {
          $phone.val("(");
        } else if ($val.length > 0) {
          if ($val.indexOf("(") == -1) {
            $val = "(" + $val;
            $phone.val($val);
          }
        }

        // Auto-format- do not expose the mask as the user begins to type
        if (key !== 8 && key !== 9) {
          const phoneNumber = new String($phone.val() || "");
          if (phoneNumber.length === 4) {
            $phone.val(phoneNumber + ") ");
          }
          if (phoneNumber.length === 5) {
            $phone.val(phoneNumber + " ");
          }
          if (phoneNumber.length === 9) {
            $phone.val(phoneNumber + "-");
          }
        }

        // Allow numeric (and tab, backspace, delete) keys only
        return (
          key == 8 ||
          key == 9 ||
          key == 46 ||
          (key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105)
        );
      });

      $(".ProfileEditPage").on("paste", ".phoneMask, input[type=tel]", function(
        e: any
      ) {
        const $phone = $(e.target);
        const phoneData: any = e.originalEvent.clipboardData.getData("Text");

        const number = phoneData.match(/\d/g, "");
        const joinedNumber = number.join("");
        const regex = /^(\d{3})(\d{3})(\d{4})$/;
        const final = joinedNumber.replace(regex, "($1) $2-$3");

        $phone.val(final);
      });

      $(".ProfileEditPage").on(
        "keydown.autocomplete",
        ".phoneMask, input[type=tel]",
        function(e) {
          const key = e.charCode || e.keyCode;
          const $phone = $(e.target);

          if (typeof key == "undefined") {
            setTimeout(function() {
              const phoneData: any = $phone.val();

              const number = phoneData.match(/\d/g, "");
              const joinedNumber = number.join("");
              const regex = /^(\d{3})(\d{3})(\d{4})$/;
              const final = joinedNumber.replace(regex, "($1) $2-$3");

              $phone.val(final);
            }, 10);
          }
        }
      );

      $(".ProfileEditPage").on(
        "focus click",
        ".phoneMask, input[type=tel]",
        function(e) {
          const $phone: any = $(e.target);

          if ($phone.val() && $phone.val().length === 0) {
            $phone.val("(");
          } else {
            const val = $phone.val();
            $phone.val("").val(val);
          }
        }
      );

      $(".ProfileEditPage").on("blur", ".phoneMask, input[type=tel]", function(e) {
        const $phone = $(e.target);
        if ($phone.val() === "(") {
          $phone.val("");
        }
      });
    });
  }

  passwordData = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };

  checkStrongPassword(password: string) {
    this.passwordData = ApiHelper.getPasswordInfo(password);
    this.formData.controls.password.valid = this.passwordData.valid;
  }
}
