













































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import VScroller from "@/components/Common/VScroller.vue";
import tdqSlideUp from "@/components/Common/tdqSlideUp.vue";
import NoData from "@/components/Common/NoData.vue";
import { ApiHelper } from "@/helpers/all";
import moment from "moment";
interface Props {
  data: any;
  participantId: number;
}

interface Events {
}

declare const $: any;
@Component({
  inheritAttrs: false,
  components: {
    VScroller,
    tdqSlideUp,
    NoData,
  }
})
export default class PopupTotoMessages extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: true })
  participantId!: number;

  @Prop({ required: true })
  formId!: number;

  pageData: any = {
    isLoading: false,
    items: [],
    currentProfile: {
      email: '',
      entityId: 0,
      firstName: '',
      lastName: '',
      profileId: 0
    },
    currentUser: {
      email: '',
      entityName: '',
      firstname: '',
      lastname: '',
    },
  }
  formData: any = {
    isProcessing: false,
    controls: {
      message: {
        value: ''
      }
    }
  }

  async created() {
    await this.fetchData();
  }

  private async onSubmit() {
    const loggedUser = ApiHelper.getLoggedUser();
    const message = ApiHelper.stripTags(this.formData.controls.message.value).replace(
      /\n/g,
      "<br/>"
    );
    if (!message) {
      this.formData.controls.message.error = 'Message is required';
      return false;
    }
    this.formData.isProcessing = true;
    this.formData.isLoading = true;
    this.formData.controls.message.value = '';
    const result = await ApiHelper.apiPost('/createMessage', {
      uuid: ApiHelper.getUuid(),
      domain: ApiHelper.getDomain(),
      profileID: loggedUser.id,
      participantID: this.participantId,
      formID: this.formId,
      message: message,
      isPreview: loggedUser.type == 2 ? 1 : 0,
    });
    this.formData.isLoading = false;
    if (result.status == 1) {
      this.formData.controls.message.value = '';
      await this.fetchData();
      this.formData.isProcessing = false;
    } else {
      this.formData.isProcessing = false;
      ApiHelper.showErrorMessage(result.message, 'Oops');
    }
  }

  private async fetchData() {
    const loggedUser = ApiHelper.getLoggedUser();
    this.formData.isProcessing = false;
    this.pageData.isLoading = true;
    const result = await ApiHelper.apiPost('/messages', {
      uuid: ApiHelper.getUuid(),
      domain: ApiHelper.getDomain(),
      profileID: loggedUser.id,
      participantID: this.participantId,
      formID: this.formId,
    });
    this.pageData.isLoading = false;
    if (result.status == 1) {
      const profileInfo = result.data.messages.profile || {};
      this.pageData.currentProfile.profileId = profileInfo.profileId || 0;
      this.pageData.currentProfile.firstName = profileInfo.firstName || '';
      this.pageData.currentProfile.lastName = profileInfo.lastName || '';
      const userInfo = result.data.messages.user || {};
      this.pageData.currentUser.firstname = userInfo.firstname || '';
      this.pageData.currentUser.lastname = userInfo.lastname || '';
      this.pageData.currentUser.entityName = userInfo.entityName || '';
      const list = result.data.messages.data.map((item: any) => {
        let createdDate = moment(item.createTs).calendar() || "";
        if (createdDate.includes('Last')) {
          createdDate = moment(item.createTs).format('MM/DD/YYYY');
        }
        return {
          id: parseInt(item.id),
          isChecked: false,
          message: item.message || "",
          name: item.isAdmin ? item.firstName + ' ' + item.lastName + ' @' + item.entityName : "You",
          ts: createdDate,
          isAdmin: item.isAdmin || false
        };
      });
      this.pageData.items = list;


      this.$emit('update', this.participantId, this.formId, this.pageData.items.length);

      // reset loggedUser.stats.NewMessages
      loggedUser.stats.NewMessages = 0;
      ApiHelper.saveLoggedUser(loggedUser);

      setTimeout(() => {
        const container: any = $(`#form-${this.participantId}_${this.formId} .chat__body.ps`);
        // const container: any = $(".chat-page");
        if (container && this.pageData.items.length) {
          container.animate({ scrollTop: container.scrollTop() - container.offset().top + container.find('.message_item:last-child').offset().top }, { 
            duration: 'medium',
            easing: 'swing'
          });
        }
      }, 300);


    }
  }

  async onKeydown($evemt: any) {
    const message = this.formData.controls.message.value || '';
    if ($evemt.code === "Enter" && message.trim() !== "") {
      this.formData.controls.message.value = message.trim();
      await this.onSubmit();
    }
    $evemt.preventDefault();
  }
  closeToDoQuestion() {
    const form: any = $("#form-" + this.participantId + "_" + this.formId);
    const height = $("#form-" + this.participantId + "_" + this.formId + " .rendered-form").outerHeight();
    const formParent: any = $("#form-" + this.participantId + "_" + this.formId).parent();
    
    // Returns the height with padding,border and margin
    const heightPopup = 350;
    
    formParent.removeClass("qActive");

    /* form
      .css("min-height", height - heightPopup)
      .css("max-height", height - heightPopup); */
    this.$emit('dismiss');
  }
  stripTags(string: string) {
    return ApiHelper.stripTags(string);
  }
}
