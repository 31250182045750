













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import Loading from "../components/Common/Loading.vue";
import moment from "moment";
import DateSelect from "../components/DateSelect.vue";
import directives from "../helpers/directives";
import NoData from "../components/Common/NoData.vue";
import { ApiHelper } from "../helpers/all";
import { standaloneApplyJS, standaloneLoadScripts } from "../helpers/StandaloneJS";
import { v4 as uuidv4 } from "uuid";
import SignaturePad from "signature_pad";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;
declare const window: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    DateSelect,
    Loading,
    NoData,
  },
  directives,
})
export default class Apply extends TSXComponent<void> {
  showMobileNav = false;
  $validator: any;
  logKey = "CAMP360_LOGGED_USER_STANDALONE";
  birthdaySelectUID = "";
  availableEvents: any = [];
  groupedByEvents: any = [];
  participantTypes: any = [];
  filterPtypeIds: number[] = [];
  events: any = [];
  $route: any;
  filterPTypeId = 0;
  filterEventId = 0;
  loginedEmail = "";
  loginedPassword = "";
  rpwCode = "";
  rpwPassword = "";
  rpwPasswordAgain = "";
  participantIndexVisible = "";
  domain = "";
  step = "";
  refId = 0;
  emailInputTimer: any = undefined;
  codeInputTimer: any = undefined;
  checkingEmail = false;
  checkingCode = false;
  logging = false;
  loggedInfo: any = {};
  pTypeSelecting: any = {};
  $swal: any;
  newProfileName = "";
  creatingProfile = false;
  loading = false;
  registeredInfo = {
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    rePassword: "",
  };
  selectedParticipants: any = [];
  cartItems: any = [];
  selectedPtypeId = 0;
  selectedEventId = 0;
  applications: any = [];
  totalCost = 0;
  totalCostFormatted = "";
  registrationStep = 0;
  roommate: any = [];
  app: any = {
    appID: 0,
    appJSON: "[]",
    appName: "",
    eventID: 0,
    pTypeID: 0,
    profileID: 0,
    profileName: "",
    participantID: 0,
    participantCost: 0,
    eventName: "",
    participantJSON: [],
    steps: [],
    pPaymentJson: {},

    // profile details
    fName: "",
    lName: "",
    pPrevEmail: "",
    pEmail: "",
    pBirthdayY: "",
    pBirthdayM: "",
    pBirthdayD: "",
    pPhone: "",
    pGender: "",
    pAddress: "",
    city: "",
    state: "",
    zip: "",
  };
  profileId = 0;
  eventId = 0;
  pTypeId = 0;
  addOns: any = [];
  appMenu: any = [];
  camStoreFund: any = {};
  inviteData: any = {
    isProcessing: false,
    code: "",
    email: "",
    valid: false,
    verified: false,
  };
  dynamicAdditionalCosts: any = [];
  currentSection = "sessions";
  birthday = "";
  totalFunds = "";
  addOnsTotal = 0;
  addOnsTotalFormatted = "$0.00";
  addOnsTotalMonthly = 0;
  addOnsTotalMonthlyFormatted = "$0.00";
  selectedAddOns: any = [];
  dynamicCostChangeTimeout: any = null;
  paymentInfo: any = {};
  paymentType = "";
  paying = false;
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  stripeError = 0;
  zipcode = "";
  paymentErrMessage = "";
  completeMessage = "";
  form = {
    cardName: {
      value: "",
      error: ""
    },
    cardNumber: {
      value: "",
      error: ""
    },
    expMoth: {
      value: "",
      error: ""
    },
    expyear: {
      value: "",
      error: ""
    },
    cvc: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    },
  }

  async beforeCreate() {
    standaloneLoadScripts();
  }

  async created() {
    $("body")
      .prop("id", "page-top")
      .addClass("sideNav bgColor");
    this.domain = window.location.hostname;
    this.loggedInfo = this.getLoggedInfo();
    if ((this.loggedInfo.profileID || 0) > 0) {
      this.step = "logged";
      await this.syncMembers();
    }

    await this.fetchData();
    /*
    setTimeout(() => {
      $(".event-groups").masonry({
        transitionDuration: 0,
      });
    }, 100);
    */
  }

  mounted() {
    window.fbControls = [];
    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
            <div class="signature-container signature-pad col-6 m-auto">
              <img src="${this.config.signature}" class="defaultImage" height="65" width="352" style="position: absolute; " />
              <canvas id="${this.config.name}" height="65" width="352" style="touch-action: none; background: white;"></canvas>
              <div class="signatureButtons">
                <button class="btn-save" type="button"></button>
                <button class="btn-undo" type="button"></button>
                <button class="btn-clear" type="button"></button>
              </div>
              <div class="otherButtons">
                <label for="file_${this.config.name}" class="button SecondaryColor FontColor btn">Upload Signature</label>
                <input type="file" class="file-upload"
                 accept="image/x-png,image/gif,image/jpeg"
                 id="file_${this.config.name}" style="display: none"
                 target="${this.config.name}"
                 />
              </div>
            </div>
          </div>
        `;
        }

        onRender() {
          const _root = this;
          const canvas: any = document.getElementById(this.config.name);
          const signaturePad = new SignaturePad(
            canvas,
            {
              backgroundColor: "rgb(255, 255, 255)"
            }
          );
          const signatureCanvas = $('#' + this.config.name);
          const container = signatureCanvas.parents('.signature-container');
          if (this.config.signature) {
            signatureCanvas.attr('data-signature', this.config.signature);
            container.find('.defaultImage').show();
            container.find('.btn-undo').hide();
          } else {
            container.find('.defaultImage').hide();
            container.find('.btn-undo').show();
          }
          signaturePad.onEnd = () => {
            container.find('.defaultImage').hide();
            const signatureData = signaturePad.toDataURL();
            signatureCanvas.attr('data-signature', signatureData)
            signatureCanvas.attr('data-new', 1);
          }
          container.find('.btn-save').on(
            'click',
            () => {
              ApiHelper.showSuccessMessage('Saved');
            }
          );
          container.find('.file-upload').on(
            'change',
            async (e) => {
              const files = e.target.files || [];
              const _this = $(e.target);
              if (files.length > 0) {
                const signatureCanvas2 = $('#' + _this.attr('target'));
                const file = files[0];
                const base64 = await ApiHelper.convertFileToBase64(file);
                _root.loading = true;
                const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
                  uuid: this.loggedInfo.uuid,
                  domain: ApiHelper.getDomain(),
                  base64: base64,
                  group: "profiles/" + _root.profileID + '/signatures',
                  id: _root.profileID,
                  data: {
                    fileName: file.name
                  }
                });
                _root.loading = false;
                container.find('.defaultImage').attr('src', '');
                if (result.status === 1) {
                  container.find('.defaultImage').attr('src', result.data.url).show();
                  container.find('.btn-undo').hide();
                  signatureCanvas2.attr('data-signature', result.data.url);
                  signatureCanvas2.attr('data-new', 0);
                  signaturePad.clear();
                }
              }
            }
          )
          container.find('.btn-undo').on(
            'click',
            () => {
              const data = signaturePad.toData();
              if (data) {
                data.pop(); // remove the last dot or line
                signaturePad.fromData(data);
              }
              if (data.length > 0) {
                const signatureData = signaturePad.toDataURL();
                signatureCanvas.attr('data-signature', signatureData)
              } else {
                signatureCanvas.attr('data-signature', '');
              }
              signatureCanvas.attr('data-new', 1);
            }
          );
          container.find('.btn-clear').on(
            'click',
            () => {
              container.find('.defaultImage').hide();
              container.find('.btn-undo').show();
              signaturePad.clear();
              signatureCanvas.attr('data-signature', '');
              signatureCanvas.attr('data-new', 1);
            }
          );
        }
      }

      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split('-');
          let days: number[] = [];
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              days = ApiHelper.getDaysList(defaultYear, defaultMonth);
            }
          }
          const months: number[] = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          if (days.length == 0) {
            for (let i = 1; i < 32; i++) {
              days.push(i);
            }
          }
          const years: number[] = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }
          const isSmallSize = (this.config.className || '').includes('col-3');
          return `
          <div
          id="date-${this.config.name}"
          data-userData="${JSON.stringify(this.config.userData)}"
          class="row form-group ${this.config.className} shadow-none pe-0 d-flex" style="padding:0; margin: 0; border: none">
            <input id="${this.config.name}" type="date" style="display: none" ${(this.config.required || '') === 'required' ? 'aria-required="true" required="required"' : ''} />
            <div class="col-4 ps-0 pe-lg-0">
              <select type="text" class="form-control px-3 text-center sel-month">
                <option value="">${isSmallSize ? 'M' : 'Month'}</option>
                ${months.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
            <div class="col-4 ps-1 pe-lg-0">
              <select class="form-control px-3 text-center sel-day">
                <option value="">${isSmallSize ? 'D' : 'Day'}</option>
                ${days.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
            <div class="col-4 pe-0 ps-1">
              <select type="text" class="form-control px-3 text-center sel-year">
                <option value="">${isSmallSize ? 'Y' : 'Year'}</option>
                ${years.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
          </div>
        `;
        }

        onRender() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split('-');
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              $('#' + this.config.name).val(moment(dateString).format('YYYY-MM-DD'));
              $('#date-' + this.config.name).find('.sel-year').val(defaultYear);
              $('#date-' + this.config.name).find('.sel-month').val(defaultMonth);
              $('#date-' + this.config.name).find('.sel-day').val(defaultDay);
            }
          }
          $('#date-' + this.config.name).on('change', '.sel-month', () => {
            const dateContainer = $('#date-' + this.config.name);
            const year = parseInt(dateContainer.find('.sel-year').val());
            const month = parseInt(dateContainer.find('.sel-month').val());
            const day = dateContainer.find('.sel-day').val();
            const isSmallSize = (this.config.className || '').includes('col-3');
            const days = ApiHelper.getDaysList(year, month);
            dateContainer.find('.sel-day').html(`<option value="">${isSmallSize ? 'D' : 'Day'}</option>
                ${days.map(value => `<option value="${value}">${value}</option>`)}`);
            dateContainer.find('.sel-day').val(day);
            if (month && year && day) {
              const newDate = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
              $('#' + this.config.name).val(newDate);
            } else {
              $('#' + this.config.name).val('');
            }
          });
          $('#date-' + this.config.name).on('change', '.sel-year,.sel-day', () => {
            const dateContainer = $('#date-' + this.config.name);
            const year = dateContainer.find('.sel-year').val();
            const month = dateContainer.find('.sel-month').val();
            const day = dateContainer.find('.sel-day').val();
            if (month && year && day) {
              const newDate = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
              $('#' + this.config.name).val(newDate);
            } else {
              $('#' + this.config.name).val('');
            }
          });
        }
      }

      controlClass.register("dob", ControlDob);
      return ControlDob;
    });
    standaloneApplyJS();
    $(document).off('change input', '.profile-details-frm input, .profile-details-frm textarea,.profile-details-frm select');
    $(document).on('change input', '.profile-details-frm input,.profile-details-frm textarea,.profile-details-frm select', (e) => {
      const parent = $(e.target).parents('.form-group');
      parent.find('.text-danger').removeClass('text-danger');
      $(e.target).removeClass('border-danger');
    });
  }

  async fetchData() {
    // get session
    try {
      this.loading = true;
      const response = await axios.post(`${dataURL}/sessions`, {
        domain: this.domain,
      });

      if (response.data.status == 1) {
        const data = response.data.data;
        this.participantTypes = data.participantTypes || [];
        this.events = data.eventList || [];
        this.filterPtypeIds = data.participantTypes.map((item) => {
          return item.participantTypeId
        });
        this.availableEvents = data.availableEvents;
        // group sessions by events
        this.groupedByEvents = this.groupByEvents(this.availableEvents);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  groupByEvents(availableEvents: any) {
    const groupedByEvents: any = [];
    for (const ev of availableEvents) {
      const existed = groupedByEvents.find(
        (item: any) => item.eventID == ev.eventID
      );
      if (!existed) {
        groupedByEvents.push({
          eventID: ev.eventID,
          eventName: ev.eventName,
          newAppLocation: ev.newAppLocation,
          eventDateRange: ev.eventDateRange,
          eventAges: ev.eventAges,
          eventSDate: ev.eventSDate,
          appFile: ev.appFile,
          eventLocation: ev.eventLocation,
          eventRegistrationActive: ev.eventRegistrationActive,
          eventFull: ev.eventFull,
          applicationNames: ev.applicationNames,
          pTypes: availableEvents.filter(
            (item: any) => item.eventID == ev.eventID
          ),
        });
      }
    }
    return groupedByEvents;
  }

  async doFilter() {
    let filteredEvents = [...this.availableEvents];
    this.filterPtypeIds = [];
    if (this.filterEventId > 0) {
      filteredEvents = filteredEvents.filter(
        (item) => item.eventID == this.filterEventId
      );
    }
    filteredEvents.map((item) => {
      this.filterPtypeIds.push(item.participantTypeID);
    });
    if (this.filterPTypeId > 0) {
      filteredEvents = filteredEvents.filter(
        (item) => item.participantTypeID == this.filterPTypeId
      );
    }
    this.groupedByEvents = this.groupByEvents(filteredEvents);
    await this.$forceUpdate();
    /*
    $(".event-groups")
      .masonry("destroy")
      .masonry({
        transitionDuration: 0,
      });
    */
  }

  nicescroll() {
    if ($("body").getNiceScroll().length == 0) {
      $("body").niceScroll();
    } else {
      $("body")
        .getNiceScroll()
        .resize();
    }
  }

  async updated() {
    try {
      setTimeout(() => {
        this.nicescroll();
      }, 200);
    } catch (error) {
      // console.log(error);
    }
  }

  async toggleParticipantsTooltip(pType, indexVisible) {
    if (this.pTypeSelecting !== pType) {
      this.participantIndexVisible = indexVisible;
      this.pTypeSelecting = pType;
      await this.$forceUpdate();
      // specify dropdown position
      const dropdownRow = $(
        `#dropdown-event${pType.eventID}-pType${pType.participantTypeID}`
      );
      var offset = dropdownRow.offset();
      var top = offset.top;
      var findDiff = top + dropdownRow.outerHeight();
      var bottom = $('#sessions').height() - findDiff;
      if (bottom <= 0) {
        dropdownRow.addClass("top");
      }
    } else {
      this.participantIndexVisible = "";
      this.pTypeSelecting = '';
      await this.$forceUpdate();
    }

  }

  closeParticipantsTooltip() {
    this.participantIndexVisible = "";
    this.pTypeSelecting = {};
  }

  async emailInput() {
    if (this.checkingEmail) return;
    // exit if invalid
    const valid = await this.$validator.validateAll();
    if (this.$validator.errors.has("emailInput") || this.loginedEmail == "") {
      this.step = "";
      return;
    }
    clearTimeout(this.emailInputTimer);
    this.emailInputTimer = setTimeout(async () => {
      try {
        this.checkingEmail = true;
        const response = await axios.post(`${dataURL}/registrationCheck`, {
          action: "checkEmailExist",
          email: this.loginedEmail,
          domain: this.domain,
        });
        // exit if invalid (re-check)
        if (
          this.$validator.errors.has("emailInput") ||
          this.loginedEmail == ""
        ) {
          this.step = "";
          return;
        }
        if (response.data.status == 1) {
          const existed = response.data.data.existed || false;
          this.step = existed ? "login" : "register";
          if (this.step == "login") {
            setTimeout(() => {
              $(".passwordContainer #passwordInput").focus();
            }, 100);
          } else if (this.step == "register") {
            // reset register form
            this.resetRegisterForm();
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.checkingEmail = false;
      }
    }, 800);
  }

  async doLogin() {
    if (this.loginedEmail == "" || this.loginedPassword == "") {
      return;
    }
    try {
      this.logging = true;
      const response = await axios.post(`${dataURL}/familyMembersByEmail`, {
        domain: this.domain,
        email: this.loginedEmail,
        password: this.loginedPassword,
      });

      if (response.data.status == 1) {
        const data = response.data.data;
        const members = data.members || [];
        const mainProfile = data.members.find(
          (item) => data.profileID == item.profileid
        );
        this.loggedInfo = {
          profileID: mainProfile.profileid || 0,
          firstName: mainProfile.p_fname || "",
          lastName: mainProfile.p_lname || "",
          phone: mainProfile.p_phone || "",
          email: this.loginedEmail,
          familyID: data.familyID || 0,
          uuid: data.uuid || "",
          token: data.token || "",
          members: members.map((item) => ({
            ...item,
            uuid: uuidv4(),
          })),
          applications: [],
        };
        this.updateLoggedInfo();
        this.step = "logged";
        $(".loginFailed").addClass("invisible");
      } else {
        // fail login
        $(".loginFailed").removeClass("invisible");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.logging = false;
    }
  }

  async doForgotPassword() {
    if (this.loginedEmail == "") {
      return;
    }
    this.loading = true;
    const result = await ApiHelper.apiPost(
      `/auth/forgotPassword`, {
        domain: this.domain,
        email: this.loginedEmail,
        useCode: 1,
      });
    this.loading = false;
    if (result.status == 1) {
      const data = result.data || {};
      this.step = 'forgot_password';
      this.refId = data.refId || 0;
    } else {
      ApiHelper.showErrorMessage(result.message, 'Oops');
    }
  }

  async doResetPassword() {
    this.loading = true;
    const result = await ApiHelper.apiPost(
      `/auth/resetPassword`, {
        domain: this.domain,
        token: this.rpwCode,
        pass: this.rpwPassword,
        repass: this.rpwPasswordAgain
      });
    this.loading = false;
    if (result.status == 1) {
      this.step = 'login';
      this.loginedPassword = this.rpwPassword;
      await this.doLogin();
    } else {
      ApiHelper.showErrorMessage(result.message, 'Oops');
    }
  }

  async codeInput() {
    if (this.checkingCode) return;
    if (this.rpwCode.length < 7) return;
    clearTimeout(this.codeInputTimer);
    this.codeInputTimer = setTimeout(async () => {
      this.checkingCode = true;
      const result = await ApiHelper.apiPost(`/auth/checkCode`, {
        refId: this.refId,
        token: this.rpwCode,
        domain: this.domain,
      });
      if (result.status == 1) {
        this.step = 'reset_password';
      } else {
        this.rpwCode = '';
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
      this.checkingCode = false;
    }, 800);
  }

  doLogout() {
    localStorage.removeItem(this.logKey);
    this.loggedInfo = {};
    this.step = "";
    this.loginedEmail = "";
    this.loginedPassword = "";
    this.cartItems = [];
    this.applications = [];
    this.eventId = 0;
    this.profileId = 0;
    this.pTypeId = 0;
    this.currentSection = "sessions";

    // $('#application').html('');
    // $('#payment').html('');
    this.$swal.fire({
      position: "top-end",
      // icon: "success",
      title: "Logged out!",
      showConfirmButton: false,
      timer: 1000,
    });

    // closeAllDropdownRows();
    // resetNav();
    // updateProfileLink();
  }

  updateLoggedInfo() {
    localStorage.setItem(this.logKey, JSON.stringify(this.loggedInfo));
  }

  getLoggedInfo() {
    const loggedInfo = JSON.parse(localStorage.getItem(this.logKey) || "{}");
    return loggedInfo;
  }

  isRegistered(member) {
    // check if registered for a selecting event/pType
    const existed = member.joinedEvents.find(
      (item) =>
        item.eventID == this.pTypeSelecting.eventID &&
        item.participant_typeID == this.pTypeSelecting.participantTypeID &&
        item.profileID == member.profileid
    );
    if (existed) {
      return true;
    }

    return false;
  }

  async createProfile() {
    if (this.newProfileName != "" && !this.creatingProfile) {
      this.newProfileName = ApiHelper.stripTags(this.newProfileName);
      const tmp = this.newProfileName.split(" ");
      const newFirstName = tmp[0] || "";
      const newLastName = tmp[1] || "";

      if (newFirstName && newLastName) {
        const existMember = this.loggedInfo.members.find((item: any) => {
          return (
            item.p_fname.toLowerCase() === newFirstName.toLowerCase() &&
            item.p_lname.toLowerCase() === newLastName.toLowerCase()
          );
        });
        let isConfirmed = true;
        if (existMember) {
          const confirm = await this.$swal({
            title: "Are you sure?",
            html: `<strong>${this.newProfileName}</strong> has already been added are you sure you want to add them again?`,
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!",
          });
          if (!confirm.isConfirmed) {
            isConfirmed = false;
            this.newProfileName = "";
          }
        }

        if (isConfirmed) {
          this.creatingProfile = true;
          const response = await axios.post(`${dataURL}/createProfile`, {
            uuid: this.loggedInfo.uuid,
            fname: newFirstName,
            lname: newLastName,
            mainProfileID: this.loggedInfo.profileID,
            familyID: this.loggedInfo.familyID,
          });
          if (response.data.status == 1) {
            this.loggedInfo.members.push({
              profileid: response.data.data.profileID,
              p_fname: newFirstName,
              p_lname: newLastName,
              p_email: null,
              p_dob: null,
              p_gender: null,
              p_logo: "",
              age: null,
              balance: 0,
              totalEvents: 0,
              activeEvents: 0,
              joinedEvents: [],
            });
            this.newProfileName = "";
            await this.$forceUpdate();
            $("#linkCamperList .ps").scrollTop(
              $(".camperListContainer").height()
            );
          }
        }
        this.creatingProfile = false;
      } else {
        this.creatingProfile = false;
        await this.$swal({
          title: "Oops",
          html: `Your name is too short. It should include first name and last name.`,
          // icon: "error",
          customClass: {
            confirmButton: "btn button SecondaryColor FontColor",
          },
        });
      }
    }
  }

  async syncMembers() {
    try {
      this.loading = true;
      const response = await axios.post(`${dataURL}/familyMembers`, {
        familyID: this.loggedInfo.familyID,
        getAll: true,
      });

      if (response.data.status == 1) {
        // update members
        const members = response.data.data.members;
        this.loggedInfo.members = members.map((item: any) => ({
          ...item,
          uuid: uuidv4(),
        }));
        this.updateLoggedInfo();
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  disableCreateMainProfile() {
    if (
      this.registeredInfo.password == "" ||
      this.registeredInfo.rePassword == "" ||
      this.creatingProfile
    ) {
      return true;
    }
    return false;
  }

  async createMainProfile() {
    // validate
    await this.$validator.validateAll();
    let valid = true;
    $(".passMissmatch ").remove();
    if (
      this.$validator.errors.has("emailInput") ||
      this.$validator.errors.has("newFname") ||
      this.$validator.errors.has("newLname") ||
      this.$validator.errors.has("newPhone") ||
      this.$validator.errors.has("createPassword") ||
      this.$validator.errors.has("passwordAgain")
    ) {
      valid = false;
    }
    if (
      this.registeredInfo.password != "" &&
      this.registeredInfo.password != this.registeredInfo.rePassword
    ) {
      valid = false;
      $(
        '<div class="passMissmatch text-danger">Passwords do not match, please try again thanks!</div>'
      ).insertAfter("label[for=passwordAgain]");
    }
    if (!valid) return;

    // create new main profile
    try {
      this.creatingProfile = true;
      const result = await ApiHelper.apiPost(`/auth/register`, {
        domain: this.domain,
        email: this.loginedEmail,
        firstName: this.registeredInfo.firstName,
        lastName: this.registeredInfo.lastName,
        pass: this.registeredInfo.password,
        repass: this.registeredInfo.rePassword,
        phone: this.registeredInfo.phone,
      });
      if (result.status == 1) {
        // const data = response.data.data;
        // var newProfileId = data.profileID || 0;
        // var newFamilyId = data.familyID || 0;
        // this.loggedInfo.uuid = data.entityUUID || "";
        // this.loggedInfo.familyID = newFamilyId;
        // this.loggedInfo.profileID = newProfileId;
        // this.loggedInfo.firstName = this.registeredInfo.firstName;
        // this.loggedInfo.lastName = this.registeredInfo.lastName;
        // this.loggedInfo.phone = this.registeredInfo.phone;
        // this.loggedInfo.email = this.loginedEmail;
        // this.loggedInfo.token = data.token || "";
        // this.loggedInfo.members = [
        //   {
        //     activeEvents: 0,
        //     age: 0,
        //     balance: 0,
        //     events: [],
        //     joinedEvents: [],
        //     p_dob: "",
        //     p_phone: this.registeredInfo.phone,
        //     p_email: this.loginedEmail,
        //     p_fname: this.registeredInfo.firstName,
        //     p_gender: 0,
        //     p_lname: this.registeredInfo.lastName,
        //     p_logo: null,
        //     profileid: newProfileId,
        //     totalEvents: 0,
        //     uuid: ApiHelper.getUuid(),
        //   },
        // ];
        // this.loggedInfo.applications = [];
        // this.updateLoggedInfo();
        // this.step = "logged";

        // show success message
        this.$swal({
          html: "Your account was registered. Please check email to activate your account.",
          // icon: "success",
          customClass: {
            confirmButton: "SecondaryColor FontColor",
          },
        });
        this.step = "login";
        this.loginedPassword = "";
        // this.registeredInfo.firstName = "";
        // this.registeredInfo.lastName = "";
        // this.registeredInfo.phone = "";
        // this.registeredInfo.password = "";
        // this.registeredInfo.rePassword = "";
      } else {
        ApiHelper.showErrorMessage(result.message, 'Oops');
      }
    } catch (error) {
      ApiHelper.showErrorMessage('Something was wrong', 'Oops');
    } finally {
      this.creatingProfile = false;
    }
  }

  resetRegisterForm() {
    this.registeredInfo = {
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      rePassword: "",
    };
  }

  async buildCartItem(eventId, pTypeId, member) {
    if (eventId == 0 && pTypeId == 0) return;
    const exsited = this.cartItems.find(
      (item: any) =>
        item.eventID == eventId &&
        item.pTypeID == pTypeId &&
        item.profileID == member.profileid
    );
    if (exsited) {
      const sameEvent = member.joinedEvents.find((session: any) => {
        return session.eventID == eventId;
      });

      if (sameEvent) {
        const message = `
          <strong>${member.p_fname} ${member.p_lname}</strong>
          is already registered for <strong>${sameEvent.eventName}</strong> as a <strong>${sameEvent.participantTypeName}</strong>,
          would you like to proceed?`;
        const confirm = await this.$swal({
          title: "Are you sure?",
          html: message,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, do it!",
        });
        if (confirm.isConfirmed) {
          // add in cartItemsStorage
          // this.cartItemsStorage.push({
          //   eventID: event.eventID,
          //   profile: {
          //     profileid: member.profileid,
          //     p_fname: member.p_fname,
          //     p_lname: member.p_lname,
          //     p_logo: member.p_logo,
          //     age: member.age,
          //   },
          //   pType: {
          //     participantTypeID: ptype.participantTypeID,
          //     participantTypeName: ptype.participantTypeName,
          //     participantAmount: ptype.participantAmount,
          //     eventName: ptype.eventName,
          //     ParticipantAmount_formated: ptype.ParticipantAmount_formated,
          //   },
          // });
          // save cart
          // localStorage[this.$campCartKey] = JSON.stringify({
          //   cartItems: this.cartItemsStorage,
          // });
          // this.updateCart();
        } else {
          // remove item out of cartItems
          this.cartItems = this.cartItems.filter(
            (item: any) =>
              !(
                item.eventID == eventId &&
                item.pTypeID == pTypeId &&
                item.profileID == member.profileid
              )
          );
        }
        setTimeout(() => {
          $(`#sessionRow-${eventId}-${pTypeId} .addParticipants`).click();
        }, 100);
      } else {
        // add
        // this.cartItemsStorage.push({
        //   eventID: event.eventID,
        //   profile: {
        //     profileid: member.profileid,
        //     p_fname: member.p_fname,
        //     p_lname: member.p_lname,
        //     p_logo: member.p_logo,
        //     age: member.age,
        //   },
        //   pType: {
        //     participantTypeID: ptype.participantTypeID,
        //     participantTypeName: ptype.participantTypeName,
        //     participantAmount: ptype.participantAmount,
        //     eventName: ptype.eventName,
        //     ParticipantAmount_formated: ptype.ParticipantAmount_formated,
        //   },
        // });
        // // save cart
        // localStorage[this.$campCartKey] = JSON.stringify({
        //   cartItems: this.cartItemsStorage,
        // });
        // this.updateCart();
      }
    } else {
      // remove in cartItemsStorage
      // this.cartItemsStorage = this.cartItemsStorage.filter(
      //   (item: any) =>
      //     !(
      //       item.eventID == event.eventID &&
      //       item.pType.participantTypeID == ptype.participantTypeID &&
      //       item.profile.profileid == member.profileid
      //     )
      // );
    }

    // save cart
    // localStorage[this.$campCartKey] = JSON.stringify({
    //   cartItems: this.cartItemsStorage
    // });

    // build applications
    this.buildApplications();
  }

  buildApplications() {
    this.applications = [];
    this.totalCost = 0;
    this.totalCostFormatted = "$0.00";
    for (const item of this.cartItems) {
      const registrationInfo: any = {};
      const profile = this.loggedInfo.members.find(
        (p: any) => p.profileid == item.profileID
      );
      const pType = this.availableEvents.find(
        (e: any) =>
          e.eventID == item.eventID && e.participantTypeID == item.pTypeID
      );
      registrationInfo.profileId = item.profileID;
      registrationInfo.firstName = profile.p_fname || "";
      registrationInfo.lastName = profile.p_lname || "";
      registrationInfo.eventId = pType.eventID || 0;
      registrationInfo.eventName = pType.eventName || "";
      registrationInfo.pTypeId = pType.participantTypeID || 0;
      registrationInfo.participantTypeName = pType.participantTypeName || "";
      registrationInfo.participantAmount = pType.participantAmount || 0;
      registrationInfo.participantAmountFormatted =
        pType.participantAmountFormatted || "";
      this.totalCost += registrationInfo.participantAmount;
      this.applications.push(registrationInfo);
    }
    this.totalCost = parseFloat(this.totalCost.toFixed(2));
    this.totalCostFormatted = ApiHelper.dollarFormat(this.totalCost);
  }

  removeRegistration(eventId, pTypeId, profileId) {
    this.cartItems = this.cartItems.filter(
      (item: any) =>
        !(
          item.eventID == eventId &&
          item.pTypeID == pTypeId &&
          item.profileID == profileId
        )
    );
    this.buildApplications();
  }

  async registerNow() {
    this.participantIndexVisible = '';
    this.pTypeSelecting = '';
    if (this.cartItems.length == 0) return;
    await this.saveCurrentAppState();
    const firstCartItem = this.cartItems[0];
    await this.beginApplication(
      firstCartItem.eventID,
      firstCartItem.pTypeID,
      firstCartItem.profileID
    );
    this.scrollToSection("application");
  }

  async beginApplication(eventId, pTypeId, profileId) {
    try {
      this.eventId = eventId;
      this.pTypeId = pTypeId;
      this.profileId = profileId;
      const currentApp = this.applications.find(
        (item: any) =>
          item.eventId == this.eventId &&
          item.pTypeId == this.pTypeId &&
          item.profileId == this.profileId
      );
      if (!currentApp) return;

      this.loading = true;
      let app: any = {};
      if ((currentApp.loaded || false) == false) {
        const responseApp = await axios.post(`${dataURL}/app`, {
          domain: this.domain,
          uuid: this.loggedInfo.uuid,
          familyID: this.loggedInfo.familyID,
          eventID: this.eventId,
          pTypeID: this.pTypeId,
          profileID: this.profileId,
        });
        if (responseApp.data.status == 1) {
          const data = responseApp.data.data;
          app = data.app;
          this.registrationStep = data.app.pRegistrationStep || 0;
          app = {
            ...app,
            appID: app.entityAppID,
            appName: app.entityApp_name,
            eventName: app.ev_name,
            eventID: app.eventID,
            pTypeID: app.participantTypeID,
            pTypeName: app.participant_typename || "",
            profileID: app.profileID,
            participantID: app.participantID,
            fName: app.pFname,
            lName: app.pLname,
            pPrevEmail: app.pEmail || "",
            pEmail: app.pEmail || "",
            pPhone: app.pPhone || "",
            pGender: app.pGender || "",
            pAge: app.age || "",
            pLogo: app.pLogo || "",
            inviteCode: app.inviteCode,
            profileName: [app.pFname, app.pLname].join(" "),
            participantCost: app.cost || 0,
            pPaymentJson: JSON.parse(app.participant_paymentJson || "{}"),
            pAddress: app.pAddress || "",
            city: app.pCity || "",
            state: app.pState || "",
            zip: app.pZip || "",
          };
          this.app = app;
          this.app.pBirthdayM = "";
          this.app.pBirthdayD = "";
          this.app.pBirthdayY = "";
          const dob = moment(app.pDob);
          if (dob.isValid()) {
            this.app.pBirthdayM = dob.month() + 1;
            this.app.pBirthdayD = dob.date();
            this.app.pBirthdayY = dob.year();
            this.birthday = `${this.app.pBirthdayY}/${this.app.pBirthdayM}/${this.app.pBirthdayD}`;
          }
          this.appMenu = data.appMenu.map((item: any) => ({
            ...item,
            formRenderInstance: undefined,
          }));
          this.totalFunds = "";
          this.camStoreFund = data.appMenu.find(
            (item) => item.isSystem == 1 && item.stepkeyword == "camp_store"
          );

          // roommate request
          this.roommate = (data.roommate || []).map((item: any) => {
            item.formRenderInstance = undefined;
            return item;
          });

          await this.$forceUpdate();

          // render forms
          for (const item of this.appMenu) {
            if ((item.isSystem || false) == false) {
              if (!item.formRenderInstance) {
                const moduleBuilderId = `profileId${this.profileId}-appModuleId${item.entityAppModuleId}`;
                item.formRenderInstance = await $(
                  `#${moduleBuilderId}`
                ).formRender({
                  formData: item.appModule_json,
                  notify: {
                    success: (message) => {
                      $(`#${moduleBuilderId}`).on('change input', 'input,textarea,select', (e) => {
                        const parent = $(e.target).parents('.form-group');
                        parent.find('.text-danger').removeClass('text-danger');
                        parent.find('.border-danger').removeClass('border-danger');
                        parent.removeClass('text-danger');
                      });
                      return message;
                    },
                    error: (message) => {
                      return message;
                    },
                    warning: (message) => {
                      return message;
                    },
                  },
                });
                // this.onFormChange(moduleBuilderId);

                // see this .rendered-form as a row
                const renderedForm = $(
                  `#${moduleBuilderId} > .rendered-form:first-child`
                );
                if (renderedForm.length) {
                  renderedForm.addClass("row");
                  this.applyCustomWidth(renderedForm);
                }
              }
            } else {
              // this.onFormChange(item.stepkeyword);
            }
          }
        }

        // get add ons for this participant
        const response = await axios.post(`${dataURL}/getAdditionalCosts`, {
          domain: ApiHelper.getDomain(),
          uuid: this.loggedInfo.uuid,
          eventIDs: `${this.eventId}`,
          hasDeposit: true,
        });
        const staticAdditionalCosts =
          response.data.data.staticAdditionalCosts || [];
        const dynamicAdditionalCosts =
          response.data.data.dynamicAdditionalCosts || [];
        this.addOns = staticAdditionalCosts.filter((item: any) => {
          return (
            item.eventId == this.eventId &&
            (item.ptypeId == 0 || item.ptypeId == this.pTypeId)
          );
        });
        this.dynamicAdditionalCosts = dynamicAdditionalCosts.filter(
          (item: any) => {
            return (
              item.eventId == this.eventId &&
              (item.ptypeId == 0 || item.ptypeId == this.pTypeId)
            );
          }
        );

        this.selectedAddOns = [];
        this.addOnsTotalFormatted = "$0.00";
        this.addOnsTotalMonthly = 0;
        this.addOnsTotalMonthlyFormatted = "$0.00";
        // reload selected addOns
        const savedSelectedAddOns = app.pPaymentJson?.selectedAddOns || [];
        if (
          savedSelectedAddOns.length &&
          (this.addOns.length || this.dynamicAdditionalCosts.length)
        ) {
          if (this.addOns.length) {
            for (const cost of this.addOns) {
              const inList = savedSelectedAddOns.find(
                (item: any) => item.addonServiceId == cost.addonServiceId
              );
              if (inList) {
                this.toggleSelectedAddOns(inList);
              }
            }
          }
          if (this.dynamicAdditionalCosts.length) {
            for (let cost of this.dynamicAdditionalCosts) {
              const inList = savedSelectedAddOns.find(
                (item: any) => item.addonServiceId == cost.addonServiceId
              );
              if (inList) {
                Object.assign(cost, inList);
                this.toggleSelectedAddOns(inList);
              }
            }
          }
        }
        // mark loaded this application
        currentApp.loaded = true;
      } else {
        // render application based on inputted info before
        this.registrationStep = currentApp.registrationStep;
        this.app = currentApp.app;
        this.birthday = `${this.app.pBirthdayY}/${this.app.pBirthdayM}/${this.app.pBirthdayD}`;
        this.appMenu = currentApp.appMenu;
        this.totalFunds = currentApp.totalFunds;
        this.camStoreFund = currentApp.camStoreFund;
        this.roommate = currentApp.roommate;
        this.addOns = currentApp.addOns;
        this.dynamicAdditionalCosts = currentApp.dynamicAdditionalCosts;
        this.selectedAddOns = currentApp.selectedAddOns;
        this.addOnsTotalFormatted = currentApp.addOnsTotalFormatted;
        this.addOnsTotalMonthly = currentApp.addOnsTotalMonthly;
        this.addOnsTotalMonthlyFormatted =
          currentApp.addOnsTotalMonthlyFormatted;

        await this.$forceUpdate();

        // render forms with inputted user data
        for (const item of this.appMenu) {
          if ((item.isSystem || false) == false) {
            const moduleBuilderId = `profileId${this.profileId}-appModuleId${item.entityAppModuleId}`;
            $(`#${moduleBuilderId}`).html("");
            item.formRenderInstance = await $(`#${moduleBuilderId}`).formRender(
              {
                formData: item.userData,
              }
            );
            // this.onFormChange(moduleBuilderId);

            // see this .rendered-form as a row
            const renderedForm = $(
              `#${moduleBuilderId} > .rendered-form:first-child`
            );
            if (renderedForm.length) {
              renderedForm.addClass("row");
              this.applyCustomWidth(renderedForm);
            }
          } else {
            // this.onFormChange(item.stepkeyword);
          }
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  dateNotValid(uid: string) {
    const errors = this.$validator.errors.items;
    if (
      errors.find((item: any) => item.field == `monthInput-${uid}`) ||
      errors.find((item: any) => item.field == `dayInput-${uid}`) ||
      errors.find((item: any) => item.field == `yearInput-${uid}`)
    ) {
      return true;
    }

    return false;
  }

  applyCustomWidth(renderedForm) {
    renderedForm.find("> div").addClass("col-12");
    // apply custom width for each module
    renderedForm.find("[class*='custom-w-']").each(function (i, obj) {
      const classList = obj.className.split(" ");
      const customW = classList.find((item) => item.indexOf("custom-w-") != -1);
      const customWClass = customW ? customW.replace("custom-w-", "") : "";
      if (customWClass != "") {
        const type = $(obj).prop("type");
        let parentNode: any = null;
        if (type == "checkbox" || type == "radio") {
          parentNode = $(obj).closest(".form-group");
        } else {
          parentNode = $(obj).parent();
        }
        if (parentNode.length) {
          parentNode.addClass(customWClass);
          if (customWClass != "col-12") {
            parentNode.removeClass("col-12");
          }
          $(obj).removeClass(customWClass);
        }
      }
    });
  }

  inSelectedAddOns(cost) {
    const inList = this.selectedAddOns.find(
      (item: any) => item.addonServiceId == cost.addonServiceId
    );
    if (inList) {
      return true;
    }

    return false;
  }

  dynamicCostBlur(cost) {
    if (isNaN(cost.cost) || cost.cost < 0 || cost.cost == "") {
      cost.cost = 0;
      cost.costFormatted = "$0.00";
      this.selectedAddOns = this.selectedAddOns.filter(
        (item: any) => item.addonServiceId != cost.addonServiceId
      );
      this.toggleSelectedAddOns(cost);
    } else {
      cost.cost = parseFloat(cost.cost);
    }
  }

  async dynamicCostChange(cost) {
    clearTimeout(this.dynamicCostChangeTimeout);
    this.dynamicCostChangeTimeout = setTimeout(async () => {
      // get payment info for this cost
      if (cost.cost > 0) {
        const response = await axios.post(`${dataURL}/getPaymentInfo`, {
          domain: ApiHelper.getDomain(),
          uuid: this.loggedInfo.uuid,
          familyID: this.loggedInfo.familyID,
          eventIDs: `${cost.eventId}`,
          total: parseFloat(cost.cost),
          hasDeposit: true,
        });
        if (response.data.status == 1) {
          const paymentInfo = response.data.data.paymentInfo;
          if (paymentInfo.stripePublishableKey) {
            delete paymentInfo.stripePublishableKey;
          }
          cost.costPaymentInfo = paymentInfo;
        }
        cost.costFormatted = ApiHelper.dollarFormat(cost.cost);
      } else {
        cost.costPaymentInfo.recurringAmount = 0;
      }

      this.selectedAddOns = this.selectedAddOns.filter(
        (item: any) => item.addonServiceId != cost.addonServiceId
      );
      this.toggleSelectedAddOns(cost);
    }, 300);
  }

  toggleSelectedAddOns(cost) {
    if (this.inSelectedAddOns(cost)) {
      this.selectedAddOns = this.selectedAddOns.filter(
        (item: any) => item.addonServiceId != cost.addonServiceId
      );
    } else {
      if (cost.cost > 0) {
        this.selectedAddOns.push(cost);
      }
    }

    // calculate for total addOns, and total for paying monthly
    this.addOnsTotal = 0;
    this.addOnsTotalMonthly = 0;
    this.addOnsTotalFormatted = ApiHelper.dollarFormat(this.addOnsTotal);
    this.addOnsTotalMonthlyFormatted = ApiHelper.dollarFormat(
      this.addOnsTotalMonthly
    );
    if (this.selectedAddOns.length) {
      for (const cost of this.selectedAddOns) {
        this.addOnsTotal += parseFloat(cost.cost || 0);
        this.addOnsTotalMonthly += cost.costPaymentInfo?.recurringAmount || 0;
      }
      this.addOnsTotal = parseFloat(this.addOnsTotal.toFixed(2));
      this.addOnsTotalFormatted = ApiHelper.dollarFormat(this.addOnsTotal);
      this.addOnsTotalMonthly = parseFloat(this.addOnsTotalMonthly.toFixed(2));
      this.addOnsTotalMonthlyFormatted = ApiHelper.dollarFormat(
        this.addOnsTotalMonthly
      );
    }
  }

  async storeFundTxtBlur() {
    // const valid = await this.$validator.validateAll();
    // if(!valid && this.$validator.errors.items.find((item: any) => item.field == "totalFunds")) {
    //   this.totalFunds = "";
    // }else if(this.totalFunds != "" && parseFloat(this.totalFunds) < 0) {
    //   // pass validate but < 0
    //   this.totalFunds = "";
    // }
  }

  async validateInviteForm() {
    if (ApiHelper.validateEmail(this.inviteData.email)) {
      this.inviteData.valid = true;
    } else {
      this.inviteData.valid = false;
    }
  }

  async sendInvitation() {
    try {
      this.inviteData.isProcessing = true;
      const result = await ApiHelper.apiPost("/saveRoommateInvite", {
        uuid: this.loggedInfo.uuid,
        profileID: this.app.profileID,
        // participantID: this.participantID,
        participantID: 0,
        pTypeID: this.app.pTypeID,
        eventID: this.app.eventID,
        code: this.app.inviteCode,
        email: this.inviteData.email.toLowerCase(),
      });
      if (result.status == 1) {
        ApiHelper.showSuccessMessage(
          "An invitation email was sent to " + this.inviteData.email
        );
        this.inviteData.email = "";
        this.inviteData.valid = false;

        // render new raw
        const pInfo = result.data;
        if (pInfo.participantId) {
          this.roommate.push({
            p_fname: pInfo.pfname || "",
            p_lname: pInfo.plname || "",
            p_city: pInfo.pcity || "",
            p_state: pInfo.pstate || "",
            age: pInfo.age || "",
          });
        }
      }
    } catch (error) {
      ApiHelper.showErrorMessage(`Can't send invitation email`, 'Oops');
    } finally {
      this.inviteData.isProcessing = false;
    }
  }

  async saveParticipant() {
    // validate
    let valid = true;
    const currentApp = this.applications.find(
      (item: any) =>
        item.eventId == this.eventId &&
        item.pTypeId == this.pTypeId &&
        item.profileId == this.profileId
    );
    if (currentApp && currentApp.loaded) {
      for (const item of this.appMenu) {
        if (!item.isSystem) {
          const tmpForm = $(`#profileId${currentApp.profileId}-appModuleId${item.entityAppModuleId}`);
          const isValid = this.validateStepForm(tmpForm);
          if (!isValid) {
            valid = false;
          }
        }
      }

      let minAge = 0;
      let maxAge = 0;
      let memberAge = this.app.pAge || 0;
      const profileDetailModule = this.appMenu.find((module: any) => module.stepkeyword == "profile_details");
      if (profileDetailModule) {
        const [birthdayValid] = await Promise.all([
          this.$refs.birthdaySelect[0].$validator.validateAll(),
          this.$validator.validateAll()
        ]);
        memberAge = birthdayValid ? moment().diff(this.birthday, 'years') : 0;
      }
      const eventAges = this.app.limitAges || '';
      const arr = (eventAges || '').split('-');
      let hasAgeRange = false;
      if (arr.length == 2) {
        minAge = Number.parseInt(arr[0], 10) || 0;
        maxAge = Number.parseInt(arr[1], 10) || 0;
        hasAgeRange = true;
      } else if (eventAges) {
        const limitAge = Number.parseInt(eventAges, 10) || 0;
        if (limitAge > 0) {
          minAge = limitAge;
        }
      }
      let ageError = '';

      if(hasAgeRange && minAge > 0 && maxAge == 0) {
        // format e.g: 25- (participant age should less than 25)
        if(memberAge > minAge) {
          ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less or equal than <strong>' + minAge + '</strong>, would you like to proceed?';
        }
      }else {
        if (minAge) {
          if (memberAge < minAge) {
            ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be greater than <strong>' + minAge + '</strong>, would you like to proceed?';
          }
        }
        if (maxAge) {
          if (memberAge > maxAge) {
            ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less than <strong>' + maxAge + '</strong>, would you like to proceed?';
          }
        }
      }

      if (ageError != '') {
        const isConfirmed = await Vue.swal({
          title: "Are you sure?",
          html: ageError,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, do it!"
        }).then(result => {
          return result.isConfirmed;
        });
        if (!isConfirmed) {
          valid = false;
        }
      }
      if (this.$validator.errors.items.length > 0) {
        valid = false;
      }
      for (const item of this.$validator.errors.items) {
        $(`.profile-details-frm #${item.field}`).addClass('border-danger');
      }

      if (
        valid &&
        !this.$validator.errors.has("emailInput") &&
        this.app.pEmail != "" &&
        this.app.pPrevEmail != "" &&
        this.app.pEmail != this.app.pPrevEmail) {
        this.loading = false;
        // user confirm wants to change email
        const confirm = await this.$swal.fire({
          // icon: "warning",
          text: `Are you sure you want to change email to ${this.app.pEmail}?`,
          confirmButtonText: "Confirm",
          showCloseButton: true,
          customClass: {
            confirmButton: "btn button SecondaryColor FontColor"
          }
        });
        if (!confirm.isConfirmed) {
          this.app.pEmail = this.app.pPrevEmail;
          valid = false;
        }
      }

    }
    if (valid) {
      const currentApp = await this.saveCurrentAppState();
      if (currentApp) {
        currentApp.done = true;
      }

      // load next application if any
      const notFinishedApp = this.applications.filter(
        (item: any) => (item.done || false) == false
      );
      if (notFinishedApp.length) {
        await this.beginApplication(
          notFinishedApp[0].eventId,
          notFinishedApp[0].pTypeId,
          notFinishedApp[0].profileId
        );
        this.scrollToSection("application");
      } else {
        // reset
        // this.eventId = 0;
        // this.pTypeId = 0;
        // this.eventId = 0;

        // get payment info
        await this.getPaymentInfo();
        this.scrollToSection("payment");
      }
    } else {
      this.scrollToSection("application");
    }
    this.$forceUpdate();
  }

  scrollToSection(elementId: string) {
    this.showMobileNav = false;
    const element: any = $(`#${elementId}`);
    // elementId == this.currentSection
    if (this.cartItems.length == 0 || element.length == 0) {
      return;
    }
    this.currentSection = elementId;
    element.removeClass("d-none");
    $("html, body").animate(
      {
        scrollTop: element.offset().top,
      },
      1000,
      "easeInOutExpo"
    );
  }

  async appLinksListItemClick(item) {
    // can click when this application loaded
    this.showMobileNav = false;
    const isActive =
      item.eventId == this.eventId &&
      item.pTypeId == this.pTypeId &&
      item.profileId == this.profileId
        ? true
        : false;
    if (isActive) {
      this.scrollToSection("application");
    } else if ((item.loaded || false) == true) {
      await this.saveCurrentAppState();
      await this.beginApplication(item.eventId, item.pTypeId, item.profileId);
      this.scrollToSection("application");
    }
  }

  async saveCurrentAppState() {
    // save current state for application of selected participant
    const currentApp = this.applications.find(
      (item: any) =>
        item.eventId == this.eventId &&
        item.pTypeId == this.pTypeId &&
        item.profileId == this.profileId
    );
    if (!currentApp || (currentApp.loaded || false) == false) return;
    this.loading = true;
    for (const item of this.appMenu) {
      item.percentComplete = 100;
      if (item.isSystem) {
        item.userData = '[]';
      } else {
        const tmpForm = $(`#profileId${currentApp.profileId}-appModuleId${item.entityAppModuleId}`);
        const userData = await this.getUserData(tmpForm, item);
        item.userData = JSON.stringify(userData.json);
      }
    }
    currentApp.appMenu = this.appMenu;
    // currentApp.appMenu = this.appMenu.map((item: any) => ({
    //   ...item,
    //   percentComplete: 100,
    //   userData: !(item.isSystem || false)
    //     ? JSON.stringify(item.formRenderInstance.userData)
    //     : "[]",
    // }));
    const profileDetailModule = this.appMenu.find((module: any) => module.stepkeyword == "profile_details");
    if (profileDetailModule) {
      const [birthdayValid, pDetailsValid] = await Promise.all([
        this.$refs.birthdaySelect[0].$validator.validateAll(),
        this.$validator.validateAll()
      ]);
      const requestObj = {
        uuid: this.loggedInfo.uuid,
        domain: this.domain,
        email: this.app.pEmail,
        familyID: this.loggedInfo.familyID,
        fname: this.app.fName,
        gender: this.app.pGender,
        lname: this.app.lName,
        mainProfileID: this.loggedInfo.profileID,
        phone: this.app.pPhone,
        profileID: currentApp.profileId,
        dob: birthdayValid ? this.birthday : undefined,
        address: this.app.pAddress,
        city: this.app.city,
        state: this.app.state,
        zip: this.app.zip
      };
      const response = await axios.post(
        `${dataURL}/updateProfile`,
        requestObj
      );
      if (response.data.status !== 1) {
        ApiHelper.showErrorMessage(response.data.message || `Can't save profile details`, 'Oops');
      }
    }

    currentApp.app = this.app;
    currentApp.totalFunds = this.totalFunds;
    currentApp.addOns = this.addOns;
    currentApp.dynamicAdditionalCosts = this.dynamicAdditionalCosts;
    currentApp.addOnsTotalFormatted = this.addOnsTotalFormatted;
    currentApp.addOnsTotalMonthly = this.addOnsTotalMonthly;
    currentApp.addOnsTotalMonthlyFormatted = this.addOnsTotalMonthlyFormatted;
    currentApp.selectedAddOns = this.selectedAddOns;
    currentApp.registrationStep = this.registrationStep;
    currentApp.roommate = this.roommate;
    currentApp.camStoreFund = this.camStoreFund;
    this.loading = false;
    return currentApp;
  }

  async getPaymentInfo() {
    if (
      this.applications.length == 0 ||
      this.applications.find((item: any) => (item.done || false) == false)
    ) {
      return;
    }
    this.paymentInfo = {};
    this.paymentType = "";
    try {
      const eventIDs = this.applications.map((item: any) => item.eventId);
      let totalCost = 0;
      for (const item of this.applications) {
        totalCost += item.participantAmount || 0;
      }
      totalCost = parseFloat(totalCost.toFixed(2));
      if (totalCost > 0 && eventIDs.length) {
        this.loading = true;
        const response = await axios.post(`${dataURL}/getPaymentInfo`, {
          domain: this.domain,
          uuid: this.loggedInfo.uuid,
          eventIDs: eventIDs.join(","),
          total: totalCost,
          hasDeposit: true,
          participants: this.applications.map((item: any) => {
            const additionalCosts = item.selectedAddOns.map((item: any) => ({
              serviceName: item.serviceName,
              cost: item.cost,
            }));

            // add total funds
            if ((item.totalFunds || 0) > 0) {
              additionalCosts.push({
                serviceName: "Total Camp Store Funds",
                cost: parseFloat(parseFloat(item.totalFunds).toFixed(2)),
                isTotalFunds: true,
              });
            }

            return {
              eventID: item.eventId,
              pTypeID: item.pTypeId,
              total: item.participantAmount,
              additionalCosts,
            };
          }),
        });
        if (response.data.status == 1) {
          this.paymentInfo = response.data.data.paymentInfo || {};

          // auto select pay full if have no payment monthly option
          if ((this.paymentInfo.recurringAmount || 0) == 0) {
            this.paymentType = "full";
          }

          // set stripe public key
          if ((this.paymentInfo.stripePublishableKey || "") != "") {
            if (typeof Stripe == "undefined") {
              // load stripe
              await $.getScript("https://js.stripe.com/v2/", function (
                data,
                textStatus,
                jqxhr
              ) {
              }).fail(function (jqxhr, settings, exception) {
                // console.log("Stripe load failes");
              });
            }
            Stripe.setPublishableKey(this.paymentInfo.stripePublishableKey);
          }
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async payNow(payLater = false) {
    if (this.paying) return;

    if (payLater) {
      this.paymentType = "later";
      await this.doPay();
    } else {
      // pay monthly or full
      this.form.cardName.error = '';
      this.form.cardNumber.error = '';
      this.form.expMoth.error = '';
      this.form.expyear.error = '';
      this.form.cvc.error = '';
      this.form.zipcode.error = '';
      this.paymentErrMessage = '';
      let hasError = false;
      if (this.form.cardName.value === '') {
        this.form.cardName.error = 'Name is required';
        hasError = true;
      }
      if (this.form.cardNumber.value === '') {
        this.form.cardNumber.error = 'Card Number is required';
        hasError = true;
      }
      if (this.form.expMoth.value === '') {
        this.form.expMoth.error = 'Exp Month is required';
        hasError = true;
      }
      if (this.form.expyear.value === '') {
        this.form.expyear.error = 'Exp Year is required';
        hasError = true;
      }
      if (this.form.cvc.value === '') {
        this.form.cvc.error = 'CVC is required';
        hasError = true;
      }
      if (this.form.zipcode.value !== '' && this.form.zipcode.value.length < 5) {
        this.form.zipcode.error = 'Zipcode is invalid';
        hasError = true;
      }
      
      // validate
      this.paymentErrMessage = "";
      if (this.paymentType == "") {
        this.paymentErrMessage = "Please select a payment option";
      }

      if (
        this.paymentType != "" &&
        this.applications.length &&
        (this.paymentInfo.participantAmount || 0) > 0
      ) {
        // validate stripe form
        let isValidStripe = !hasError;
        // if (
        //   this.cardName != "" &&
        //   this.cardNumber != "" &&
        //   this.expMoth != "" &&
        //   this.expyear != "" &&
        //   this.cvc != ""
        // ) {
        //   isValidStripe = true;
        // }
        if (isValidStripe) {
          const $this = this;
          $this.stripeToken = "";
          this.paying = true;
          Stripe.card.createToken($("#stripeFrm"), async (status, response) => {
            //Stripe token failure...
            const stripeMessage = response.error?.message || "";
            if (response.error) {
              if (response.error.param == "number") {
                $this.stripeError = 2;
                this.form.cardNumber.error = 'Card Number is invalid';
              } else if (response.error.param == "exp_month") {
                $this.stripeError = 3;
                this.form.expMoth.error = 'Exp Month is invalid';
              } else if (response.error.param == "exp_year") {
                $this.stripeError = 4;
                this.form.expyear.error = 'Exp Year is invalid';
              } else if (response.error.param == "cvc") {
                $this.stripeError = 5;
                this.form.cvc.error = 'CVC is invalid';
              } else {
                $this.stripeError = 1;
                $this.paymentErrMessage = stripeMessage;
              }
            } else {
              $this.stripeToken = response.id;
              $this.stripeLast4 = response.card.last4;
              $this.stripeError = 0;
            }

            if ($this.stripeToken != "" && $this.stripeError == 0) {
              await this.doPay();
            } else {
              this.paying = false;
            }
          });
        }
      }
    }
  }

  async doPay() {
    // try {
      this.paying = true;
      // this.disablePaymentForm();
      const paymentInfo = this.paymentInfo;
      if (paymentInfo.stripePublishableKey) {
        delete paymentInfo.stripePublishableKey;
      }
      const applications = JSON.parse(JSON.stringify(this.applications));
      // const availableDiscounts = JSON.parse(
      //   JSON.stringify(this.availableDiscounts)
      // );
      const requestObj = {
        uuid: this.loggedInfo.uuid,
        domain: this.domain,
        callFrom: "signup",
        saveParticipant: true,
        applications: applications.map((item: any) => {
          let totalCost = item.participantAmount;
          const additionalCosts = [...(item.selectedAddOns || [])];
          if ((item.totalFunds || 0) > 0) {
            additionalCosts.push({
              link_event_service_Id: 0,
              addonServiceId: 5,
              serviceName: "Total Camp Store Funds",
              cost: parseFloat(parseFloat(item.totalFunds).toFixed(2)),
              isFundBucket: 1,
              costFormatted: ApiHelper.dollarFormat(item.totalFunds),
              isTotalFunds: true,
            });
          }

          // const discountInfo: any = [];
          // apply discount without discount code firstly
          // if ((item.discountAmount || 0) > 0) {
          //   const discountAmount = item.discountAmount;
          //   item.cost -= discountAmount;
          //   item.cost = parseFloat(item.cost.toFixed(2));
          //   item.costFormatted = ApiHelper.dollarFormat(item.cost);
          //   item.totalCost -= discountAmount;
          //   item.totalCost = parseFloat(item.totalCost.toFixed(2));
          //   item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
          //   if (item.discount) {
          //     discountInfo.push(item.discount);
          //   }
          // }
          // apply discount if input a discount code
          // if (availableDiscounts.length) {
          //   const discount = availableDiscounts.find(
          //     (d: any) =>
          //       d.eventId == item.eventID &&
          //       d.participant_typeId == item.participant_typeid
          //   );
          //   const discountAmount = discount?.discountAmount || 0;
          //   if (
          //     discountAmount > 0 &&
          //     (discount.maxUse == 0 ||
          //       (discount.maxUse > 0 && discount.maxUse != discount.totalUsed))
          //   ) {
          //     // apply if pass the condition
          //     item.cost -= discountAmount;
          //     item.cost = parseFloat(item.cost.toFixed(2));
          //     item.costFormatted = ApiHelper.dollarFormat(item.cost);
          //     item.totalCost -= discountAmount;
          //     item.totalCost = parseFloat(item.totalCost.toFixed(2));
          //     item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

          //     // no send totalUsed in discountInfo
          //     const itemDiscount = { ...discount };
          //     if (itemDiscount.totalUsed != undefined) {
          //       delete itemDiscount.totalUsed;
          //     }
          //     discountInfo.push(itemDiscount);

          //     // update totalUsed
          //     discount.totalUsed += 1;
          //   }
          // }

          const addOns: any = {
            selectedAddOns: [],
            addOnsTotal: 0,
            addOnsTotalFormatted: "",
            addOnsTotalMonthly: 0,
            addOnsTotalMonthlyFormatted: "",
          };
          addOns.selectedAddOns = additionalCosts.filter(
            (cost) => (cost.isTotalFunds || false) == false
          );
          if (addOns.selectedAddOns.length) {
            for (const cost of addOns.selectedAddOns) {
              addOns.addOnsTotal += parseFloat(cost.cost || 0);
              addOns.addOnsTotalMonthly +=
                cost.costPaymentInfo?.recurringAmount || 0;
            }
            addOns.addOnsTotal = parseFloat(addOns.addOnsTotal.toFixed(2));
            addOns.addOnsTotalFormatted = ApiHelper.dollarFormat(
              addOns.addOnsTotal
            );
            addOns.addOnsTotalMonthly = parseFloat(
              addOns.addOnsTotalMonthly.toFixed(2)
            );
            addOns.addOnsTotalMonthlyFormatted = ApiHelper.dollarFormat(
              addOns.addOnsTotalMonthly
            );
          }
          totalCost += addOns.addOnsTotal;

          // total funds
          let totalFunds = 0;
          const existedTotalFunds = additionalCosts.find(
            (cost) => (cost.isTotalFunds || false) == true
          );
          if (existedTotalFunds) {
            totalFunds = existedTotalFunds.cost || 0;
            totalCost += totalFunds;
          }
          totalCost = parseFloat(totalCost.toFixed(2));

          const steps = item.appMenu
            .filter((module: any) => (module.isSystem || false) == false)
            .map((module: any) => ({
              moduleId: module.moduleId,
              entityAppModuleId: module.entityAppModuleId,
              percentComplete: {
                questionsNeeded: 0,
                questionsDone: 0,
                percentComplete: 100,
              },
              json: module.userData,
            }));

          return {
            appID: item.app.appID,
            steps,
            json: "[]",
            sourceType: item.sourceType || 1,
            profileid: item.profileId,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            participantID: item.participantId || 0,
            eventID: item.eventId,
            eventName: item.eventName || "",
            participantTypeID: item.pTypeId,
            participantAmount: item.participantAmount,
            pRegistrationStep: 1,
            additionalCosts,
            totalCost,
            addOns: addOns.selectedAddOns.length ? addOns : {},
            totalFunds,
            // discountInfo,
          };
        }),
        type: this.paymentType,
        cardInfo: {
          token: this.stripeToken,
          cardName: this.cardName,
          number: this.cardNumber,
          expMonth: this.expMoth,
          expYear: this.expyear,
          zipCode: this.zipcode,
          last4: this.stripeLast4,
        },
        paymentInfo: paymentInfo,
        mainProfileID: this.loggedInfo.profileID,
        familyID: this.loggedInfo.familyID,
        // availableDiscounts: this.availableDiscounts,
      };
      const response = await axios.post(
        `${dataURL}/registrationComplete`,
        requestObj
      );
      if (response.data.status == 1) {
        const paymentResult = response.data.data.paymentResult || {};
        if (paymentResult.status == 1 || this.paymentType === 'later') {
          // remove related item in cart
          // if (this.cartItems.length) {
          //   for (const item of this.selectedToPay) {
          //     this.cartItems = this.cartItems.filter(
          //       (cartItem: any) =>
          //         !(
          //           cartItem.eventID == item.eventID &&
          //           cartItem.profile.profileid == item.profileid &&
          //           cartItem.pType.participantTypeID == item.participant_typeid
          //         )
          //     );
          //   }
          // }

          // ApiHelper.updateCartItems({ cartItems: this.cartItems });
          // await ApiHelper.updateProfileStats();

          // go to complete section
          const eventNames = this.applications.map(
            (item: any) => `${item.eventName} ${item.participantTypeName}`
          );
          this.completeMessage =
            "We have received your application to attend " +
            eventNames.join(", ") +
            ". Thank you for your submission, you'll hear from us further as we begin the acceptance process!";
          await this.syncMembers();
          await this.$forceUpdate();
          this.scrollToSection("completion");
          // reset cart
          this.cartItems = [];
          this.applications = [];
          this.paymentInfo = {};
          this.eventId = 0;
          this.pTypeId = 0;
          this.profileId = 0;
          
          // reset card info
          this.form.cardName.value = "";
          this.form.cardName.error = "";
          this.form.cardNumber.value = "";
          this.form.cardNumber.error = "";
          this.form.expMoth.value = "";
          this.form.expMoth.error = "";
          this.form.expyear.value = "";
          this.form.expyear.error = "";
          this.form.cvc.value = "";
          this.form.cvc.error = "";
          this.form.zipcode.value = "";
          this.form.zipcode.error = "";
          this.paymentErrMessage = "";
        } else {
          const message = paymentResult.message || "";
          if(message) {
            throw message;
          }
          this.paymentErrMessage =
            "Something went wrong with your credit card. Please check your card information and try again.";
        }
      }else {
        const message = response.data.message || "";
        if(message) {
          throw message;
        }
      }
    // } catch (error) {
    //   ApiHelper.showErrorMessage('Something was wrong.', 'Oops');
    //   // console.log(error);
    // } finally {
    //   // this.enablePaymentForm();
    //   this.paying = false;
    // }
    this.paying = false;
  }

  // formRender functions
  getPercentStepComplete(formRenderInstance: any) {
    const data = formRenderInstance.userData || [];
    let ret = {
      questionsNeeded: 0,
      questionsDone: 0,
      percentComplete: 0
    };
    if (data.length) {
      const requires = data.filter((item: any) => item.required);
      ret.questionsNeeded = requires.length;
      for (const item of requires) {
        if (["text", "textarea", "date"].includes(item.type) && typeof item.userData[0] == "string" && item.userData[0] != "") {
          ret.questionsDone += 1;
        }
        if (["dob"].includes(item.type)) {
          const dateContainer = $('#date-' + item.name);
          if (dateContainer.find('.sel-day').val() && dateContainer.find('.sel-month').val() && dateContainer.find('.sel-year').val()) {
            ret.questionsDone += 1;
          }
        }
        if (["select", "checkbox-group", "radio-group"].includes(item.type) && typeof item.userData != "undefined") {
          ret.questionsDone += 1;
        }
      }
      if (ret.questionsNeeded > 0) {
        ret.percentComplete = parseInt(((ret.questionsDone / ret.questionsNeeded) * 100).toFixed(2));
      }
    }
    // case form have no required fields
    if (ret.questionsNeeded == 0) {
      ret.percentComplete = 100;
    }
    return ret;
  }

  async getUserData(form, item: any) {
    const jsonObj: any = [];
    const data = item.formRenderInstance.userData;
    const formContent = JSON.parse(item.appModule_json);
    const fileFields = formContent.filter(
      (content: any) => content.type === "file"
    );
    const formData: any = {};
    for (const fileField of fileFields) {
      const existData = {
        files: form.find(`input[name="${fileField.name}"]`).attr('files'),
        filePath: form.find(`input[name="${fileField.name}"]`).attr('file-path'),
        fileName: form.find(`input[name="${fileField.name}"]`).attr('file-name')
      };
      // set to existing value
      formData[fileField.name] = existData;
    }
    const formArray: any = item.formRenderInstance.userData;
    const formControls: any[] = [];
    for (const item of formArray) {
      let tmp: any = item;
      if (tmp.type === "file") {
        const foundControl = formData[item.name] || {};
        tmp.files = foundControl.files || undefined;
      }
      if (tmp.type === "signature") {
        const signatureData = $('canvas#' + item.name).attr('data-signature') || '';
        const signatureNew = $('canvas#' + item.name).attr('data-new') || '';
        if (signatureNew === '1') {
          const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
            uuid: this.loggedInfo.uuid,
            domain: ApiHelper.getDomain(),
            base64: signatureData,
            group: "profiles/" + this.loggedInfo.profileID + '/signatures',
            id: this.loggedInfo.profileID,
            data: {
              fileName: ''
            }
          });
          if (result.status === 1) {
            tmp.signature = result.data.url;
            $('canvas#' + item.name).attr('data-new', '');
          } else {
            tmp.signature = signatureData;
          }
        } else {
          tmp.signature = signatureData;
        }
      }
      if (tmp.type === "dob") {
        const dateContainer = $('#date-' + item.name);
        const selDay = dateContainer.find('.sel-day').val();
        const selMonth = dateContainer.find('.sel-month').val();
        const selYear = dateContainer.find('.sel-year').val();
        const date = moment(selYear + '-' + selMonth + '-' + selDay);
        if (date.isValid()) {
          tmp = { ...tmp, userData: [date.format('YYYY-MM-DD')] };
        }
      }
      formControls.push(tmp);
    }
    form.find(".form-group:not(.answer):not(.blank)").each(function (i, val) {
      const formItem: any = $(val);
      var item: any = {};
      item ["label"] = (formItem.find("label").html() || '').split("<span")[0];
      if (formItem.hasClass("formbuilder-radio-group")) {
        item ["field"] = formItem.find("input[type=radio]:checked").attr("name");
        item ["value"] = formItem.find("input[type=radio]:checked").val();
      } else if (formItem.hasClass("formbuilder-checkbox-group")) {
        item ["field"] = formItem.find("input").attr("name").replace("[]", "");
        const theField = data.find((f: any) => f.name == item.field);
        theField.values.map((checkbox: any, key: number) => {
          const isChecked = $('#' + item.field + '-' + key).is(':checked');
          checkbox.selected = isChecked;
        });
        if (theField) {
          item.userData = theField.userData;
          item.values = theField.values;
        }
      } else if (formItem.hasClass("formbuilder-textarea")) {
        item ["field"] = formItem.find("textarea").attr("name");
        item ["value"] = formItem.find("textarea").val();
      } else if (formItem.hasClass("formbuilder-select")) {
        item ["field"] = formItem.find("select").attr("name");
        item ["value"] = formItem.find("option:selected").val();
      } else {
        item ["field"] = formItem.find("input").attr("name");
        item ["value"] = formItem.find("input").val();
      }
      jsonObj.push(item);
    });
    const valid = this.validateForm();
    return {
      valid: valid,
      // json: data
      json: formControls
    }
  }

  validateStepForm(form) {
    let valid = true;
    form.find(".rendered-form .border-danger, .rendered-form .text-danger").removeClass("border-danger text-danger");
    form.find("input[type=radio][required]").each(function (index, control) {
      var _input = $(control);
      if (_input.attr('type') == 'radio') {
        var radioName = _input.attr('name');
        if ($('input[type=radio][name="' + radioName + '"]:checked').length == 0 && $('input[type=radio][name="' + radioName + '[]"]:checked').length == 0) {
          $(control).addClass("border-danger");
          $(control).parents('.form-group').addClass("text-danger");
          valid = false;
        } else {
          if ($('input[type=radio][name="' + radioName + '"]:checked').val() == '') {
            $(control).addClass("border-danger");
            $(control).parents('.form-group').addClass("text-danger");
            valid = false;
          }
        }
      }
    });
    form.find("input[type=text][required], input[type=email][required], input[type=date][required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("textarea[required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find(".formbuilder-radio-group").each(function (index, control) {
      if ($(control).find("input[type=radio][required]").length > 0 && $(control).find("input[type=radio][required]:checked").length == 0) {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("select[required]").each(function (index, control) {
      if ($(control).val() == 0) {
        $(control).addClass("border-danger");
        $(control).parent().addClass("text-danger");
        valid = false;
      }
    });
    return valid;
  }

  validateForm() {
    let valid = true;
    const form = $("#formBuilder");
    form.find(".rendered-form .border-danger, .rendered-form .text-danger").removeClass("border-danger text-danger");
    form.find("input[type=radio][required]").each(function (index, control) {
      var _input = $(control);
      if (_input.attr('type') == 'radio') {
        var radioName = _input.attr('name');
        if ($('input[type=radio][name="' + radioName + '"]:checked').length == 0 && $('input[type=radio][name="' + radioName + '[]"]:checked').length == 0) {
          $(control).addClass("border-danger");
          $(control).parents('.form-group').addClass("text-danger");
          valid = false;
        } else {
          if ($('input[type=radio][name="' + radioName + '"]:checked').val() == '') {
            $(control).addClass("border-danger");
            $(control).parents('.form-group').addClass("text-danger");
            valid = false;
          }
        }
      }
    });
    form.find("input[type=text][required], input[type=email][required], input[type=date][required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("textarea[required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find(".formbuilder-radio-group").each(function (index, control) {
      if ($(control).find("input[type=radio][required]").length > 0 && $(control).find("input[type=radio][required]:checked").length == 0) {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("select[required]").each(function (index, control) {
      if ($(control).val() == 0) {
        $(control).addClass("border-danger");
        $(control).parent().addClass("text-danger");
        valid = false;
      }
    });
    return valid;
  }
}
