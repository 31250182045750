

























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})
export default class Error extends TSXComponent<void> {
  @Prop({ required: true })
  message!: string;

  @Prop({ required: false })
  redirectName?: string;

  @Prop({ required: false, default: {} })
  redirectQuery?: object;
}
