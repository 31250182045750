import { ApiHelper } from "@/helpers/all";
import SelectOption from "@/models/formTypes";
import Vue from "vue";
import axios from "axios";
import router from "@/router";
import moment from "moment";

const LOGGED_USER_DATA = "CAMP360_LOGGED_USER";

export enum ParticipantStatus {
  Applied = 0,
  Accepted = 1,
  Registered = 1,
  Denied = 2,
  CheckedIn = 3,
  Waitlist = 4,
  RemovedFromPortal = 5,
  Incomplete = 6,
  Cancelled = 10
}

export enum TodoStatus {
  Incomplete = 0,
  Pending = 1,
  Complete = 2
}

export enum TransactionType {
  DebitCredit = 1,
  AccountBucket = 2,
  Cash = 3,
  Check = 4,
  Refund = 5,
  Scholarship = 6,
  Discount = 7,
  Transfer = 8,
  WriteOff = 9
}

export const getLoggedUserDataKey = () => {
  return LOGGED_USER_DATA;
};

export const getApiURL = async (forceRun = false) => {
  try {
    const key = `apiURL_${getDomain()}`;
    if (!forceRun) {
      const savedURL = sessionStorage.getItem(key);
      if (savedURL) {
        return {
          dataURL: savedURL
        };
      }
    }
    const response = await axios.get(
      `${process.env.VUE_APP_CAMP360CORE_API_URL}/entities/loadRegistrationPortal`,
      {
        params: {
          domain: getDomain()
        }
      }
    );
    if (response.data.status == 1) {
      const apiURL = response.data.data.apiEndpointUrl || "";
      const registrationPortalData =
        response.data.data.registrationPortalData || [];
      const ga = response.data.data.ga || "";
      sessionStorage.setItem(key, apiURL);

      return {
        dataURL: apiURL,
        registrationPortalData,
        ga
      };
    }
  } catch (error) {
    // console.log(error);
  }
  return {
    dataURL: "",
    registrationPortalData: []
  };
};

export const apiPost = async (endpoint: string, data: any) => {
  const loggedUser = ApiHelper.getLoggedUser();
  const headers = {};
  if (loggedUser.token) {
    headers["Authorization"] = "Bearer " + loggedUser.token;
    data["uuid"] = getUuid();
  }
  data["domain"] = getDomain();
  const res = await axios
    .post(`${dataURL}${endpoint}`, data, {
      headers: headers
    })
    .catch(error => {
      return {
        data: {
          status: 0,
          message: error.message || JSON.stringify(error)
        }
      };
    });
  return res.data || {};
};

export const gotoPage = (route: any, callback: any = null) => {
  router
    .push(route)
    .then(() => {
      if (callback) {
        try {
          callback();
        } catch (e) {
          // nothing
        }
      }
    })
    .catch((error: any) => {
      if (callback) {
        try {
          callback();
        } catch (e) {
          // nothing
        }
      }
      //console.log("gotoPage: error", error);
    });
};

export const getDomain = () => {
  return window.location.hostname;
};

export const getFileExt = (file: string) => {
  const re: any = /(?:\.([^.]+))?$/;
  const ext: any = re.exec(file)[1];
  return ext || "";
};

export const getFileName = (fullPath: string) => {
  const filename = fullPath.replace(/^.*[\\/]/, "");
  return filename;
};

export const getUuid = () => {
  const loggedUser = ApiHelper.getLoggedUser();
  return loggedUser.entityUUID;
};

export const saveLoggedUser = (info: any) => {
  ApiHelper.setStorageItem(LOGGED_USER_DATA, info);
};

export const userLogout = () => {
  ApiHelper.setStorageItem(LOGGED_USER_DATA, {
    id: 0,
    uuid: "",
    avatar: "",
    email: "",
    firstName: "",
    lastName: "",
    token: 0,
    type: "",
    username: ""
  });

  // remove camp cart
  // if(localStorage.getItem(Vue.prototype.$campCartKey) != null) {
  //   localStorage.removeItem(Vue.prototype.$campCartKey);
  // }

  const PORTAL_PARAMS = "PORTAL_PARAMS";
  const PORTAL_LAST_UPDATE = "PORTAL_LAST_UPDATE";
  localStorage.removeItem(PORTAL_PARAMS);
  localStorage.removeItem(PORTAL_LAST_UPDATE);
};
export const getLoggedUser = () => {
  // const paramUser = new URLSearchParams(
  //   window.location.hash.split("?")[1] || ""
  // ).get("user");
  // if (paramUser) {
  //   const paramUserObject = decodeURIComponent(paramUser);
  //   ApiHelper.setStorageItem(LOGGED_USER_DATA, JSON.parse(paramUserObject));
  // }

  const userInfo = {
    id: 0,
    uuid: "",
    familyId: 0,
    avatar: "",
    email: "",
    firstName: "",
    lastName: "",
    token: "",
    type: 0,
    username: "",
    stats: {
      OutstandingFormatted: "",
      Outstanding: 0,
      TotalTodos: 0,
      TotalEvents: 0,
      NewMessages: 0,
      IncompleteEvents: 0,
      TotalMembers: 0,
      IncomeFormatted: ""
    },
    entityUUID: ""
  };

  const value =
    ApiHelper.getStorageItem(LOGGED_USER_DATA, {
      id: 0,
      uuid: "",
      familyId: 0,
      avatar: "",
      email: "",
      firstName: "",
      lastName: "",
      token: "",
      type: 0,
      username: "",
      stats: {
        OutstandingFormatted: "",
        Outstanding: 0,
        TotalTodos: 0,
        TotalEvents: 0,
        NewMessages: 0,
        IncompleteEvents: 0,
        TotalMembers: 0,
        IncomeFormatted: ""
      },
      entityUUID: ""
    }) || {};
  userInfo.id = value.id || 0;
  userInfo.uuid = value.uuid || "";
  userInfo.familyId = value.familyId || 0;
  userInfo.username = value.username || "";
  userInfo.token = value.token || "";
  userInfo.type = value.type || "";
  userInfo.email = value.email || "";
  userInfo.avatar = value.avatar || "";
  userInfo.firstName = value.firstName || "";
  userInfo.lastName = value.lastName || "";
  userInfo.entityUUID = value.entityUUID || "";
  const stats = value.stats || {};
  userInfo.stats.OutstandingFormatted = stats.OutstandingFormatted || "";
  userInfo.stats.IncomeFormatted = stats.IncomeFormatted || "";
  userInfo.stats.Outstanding = stats.Outstanding || 0;
  userInfo.stats.TotalTodos = stats.TotalTodos || 0;
  userInfo.stats.TotalEvents = stats.TotalEvents || 0;
  userInfo.stats.NewMessages = stats.NewMessages || 0;
  userInfo.stats.IncompleteEvents = stats.IncompleteEvents || 0;
  userInfo.stats.TotalMembers = stats.TotalMembers || 0;

  return userInfo;
};

export const setStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageItem = (key: string, defaultValue?: any) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return defaultValue || null;
  }
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const checkRoommateEmail = async (email: string, participantID: any) => {
  try {
    const result = await ApiHelper.apiPost("/checkExistingRoommateRequests", {
      toEmail: email,
      participantID: participantID
    });
    /* console.dir(result);
    if (result.status == 1) {
      loggedUser.stats = result.data.stats || loggedUser.stats;
      Vue.prototype.$loggedUser = loggedUser;
      ApiHelper.saveLoggedUser(loggedUser);
    } */
  } catch (error) {
    // console.log(error);
  }
};

export const validatePhone = (phone: string) => {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3,4})[-. ]?([0-9]{4})$/;
  // const re = /^\(?([0-9]{3})\)?([0-9]{3,4})[-. ]?([0-9]{4})$/;
  return re.test(String(phone).toLowerCase());
};

export const dollarFormat = (
  value: number,
  useK = false,
  options = { useBrackets: false }
) => {
  const configOptions = options || {};
  const useBrackets = configOptions.useBrackets || false;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (!useK) {
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  } else {
    let newVal = value;
    if (newVal >= 1000 || newVal <= -1000) {
      newVal = newVal / 1000;
      if (useBrackets) {
        return value < 0
          ? `(${formatter.format(0 - newVal) + "k"})`
          : formatter.format(newVal) + "k";
      }
      return formatter.format(newVal) + "k";
    }
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  }
};

export const getRandomInt = (min: number, max: number) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
};

export const numberFormat = (value: number, useK = false) => {
  if (!useK) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  } else {
    let newVal = value;
    if (newVal > 999) {
      newVal = newVal / 1000;
      return new Intl.NumberFormat().format(newVal) + "k";
    }
    return new Intl.NumberFormat().format(newVal);
  }
};

export const showErrorMessage = (
  message: string,
  title?: string,
  showIcon = false
) => {
  // Vue.swal(title || "Error", message, "error");
  const icon = showIcon ? "error" : "";
  const obj: any = {
    title: title || "Oops",
    html: message || "Something was wrong!",
    customClass: {
      container: "swal2-container-custom",
      confirmButton: "SecondaryColor FontColor"
    }
  };
  if (icon) {
    obj.icon = icon;
  }
  Vue.swal.fire(obj);
};

export const showSuccessMessage = (
  message: string,
  title?: string,
  showIcon = false
) => {
  // Vue.swal(title || "Success", message, "success");
  const icon = showIcon ? "success" : "";
  const obj: any = {
    title: title || "Success",
    html: message,
    customClass: {
      container: "swal2-container-custom",
      confirmButton: "SecondaryColor FontColor"
    }
  };
  if (icon) {
    obj.icon = icon;
  }
  Vue.swal.fire(obj);
};

export const randomFormId = () => {
  const randomId = Math.floor(Math.random() * 10000000);
  return randomId;
};

export const convertToFormDateValue = (value: string) => {
  if (value) {
    return new Date(value).toISOString().substr(0, 10);
  }
  return undefined;
};

export const updateProfilePhoto = async (newUrl: string) => {
  const loggedUser = ApiHelper.getLoggedUser();
  loggedUser.avatar = newUrl;
  ApiHelper.saveLoggedUser(loggedUser);
};
export const updateProfileStats = async () => {
  const loggedUser = ApiHelper.getLoggedUser();
  try {
    const result = await ApiHelper.apiPost("/getProfileStats", {
      profileID: loggedUser.id,
      familyID: loggedUser.familyId
    });
    if (result.status == 1) {
      loggedUser.stats = result.data.stats || loggedUser.stats;
      Vue.prototype.$loggedUser = loggedUser;
      ApiHelper.saveLoggedUser(loggedUser);
    }
  } catch (error) {
    // console.log(error);
  }

  return undefined;
};

export const convertFileToBase64 = async (file: File) => {
  const toBase64 = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  const base64: string = await toBase64
    .then(res => {
      return res + "";
    })
    .catch(error => {
      return "";
    });
  const fileInfo: any = file;
  if (!fileInfo.type) {
    const extension = fileInfo.name.split(".").pop();
    if (extension === "docx") {
      return base64.replace(
        "data:application/octet-stream;",
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;"
      );
    }
    if (extension === "doc") {
      return base64.replace(
        "data:application/octet-stream;",
        "data:application/msword;"
      );
    }
  }
  return base64;
};

export const uploadFile = async (
  fieldName: string,
  file: File,
  profileId = 0
) => {
  const base64 = await ApiHelper.convertFileToBase64(file);
  const result = await ApiHelper.apiPost("/uploadFileFromBase64", {
    uuid: ApiHelper.getUuid(),
    domain: ApiHelper.getDomain(),
    base64: base64,
    group: profileId ? "profiles/" + profileId + "/files" : "uploads",
    id: profileId,
    data: {
      fieldName: fieldName,
      fileName: file.name
    }
  });
  if (result.status == 1) {
    const { url } = result.data;
    return { filePath: url, fileUrl: url };
  } else {
    return { filePath: "", fileUrl: "" };
  }
};

export const getRandomNumber = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));

export const updateCartItems = async (info: any = {}) => {
  const cartKey = Vue.prototype.$campCartKey;
  const cart = JSON.parse(localStorage.getItem(cartKey) || "{}");
  if (info.cartItems != undefined) {
    cart.cartItems = info.cartItems;
  }
  localStorage.setItem(cartKey, JSON.stringify(cart));
};

export const getSubProfileTypeName = (type: number) => {
  let name = "";
  switch (type) {
    case 1:
      name = "Child";
      break;
    case 2:
      name = "Spouse";
      break;
    case 3:
      name = "Sibling";
      break;
    case 4:
      name = "Friend";
      break;
    case 5:
      name = "Parent/Guardian";
      break;
    default:
      name = "Other";
      break;
  }
  return name;
};

export const getGuardianTypeName = (type: number) => {
  let name = "";
  switch (type) {
    case 1:
      name = "Dad/Mom";
      break;
    case 2:
      name = "Grandparents";
      break;
    case 3:
      name = "Siblings";
      break;
    default:
      name = "Other";
      break;
  }
  return name;
};

export const getFamilyTypeOptions = () => {
  const options: SelectOption[] = [];
  options.push({
    value: "1",
    text: getSubProfileTypeName(1)
  });
  options.push({
    value: "2",
    text: getSubProfileTypeName(2)
  });
  options.push({
    value: "3",
    text: getSubProfileTypeName(3)
  });
  options.push({
    value: "4",
    text: getSubProfileTypeName(4)
  });
  options.push({
    value: "5",
    text: getSubProfileTypeName(5)
  });
  options.push({
    value: "6",
    text: getSubProfileTypeName(6)
  });
  return options;
};

export const getGuardianTypeOptions = () => {
  const options: SelectOption[] = [];
  options.push({
    value: "1",
    text: getGuardianTypeName(1)
  });
  options.push({
    value: "2",
    text: getGuardianTypeName(2)
  });
  options.push({
    value: "3",
    text: getGuardianTypeName(3)
  });
  options.push({
    value: "6",
    text: getGuardianTypeName(6)
  });
  return options;
};

export const loadSavedCart = async (reload = false) => {
  let cartItems = [];
  if (localStorage.getItem(Vue.prototype.$campCartKey) != null) {
    const campCart = JSON.parse(localStorage[Vue.prototype.$campCartKey]);
    cartItems = campCart.cartItems || [];
    if (reload) {
      const result = await ApiHelper.apiPost("/getCartItems", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        cartItems
      });
      if (result.status == 1) {
        return result.data.cartItems;
      }
    }
  }
  return cartItems;
};

export const stripTags = (value: string) => {
  if (!value) {
    return value;
  }
  const strippedString = value.replace(/(<([^>]+)>)/gi, "");
  return strippedString;
};

export const checkHasAccess = async to => {
  const loggedUser = Vue.prototype.$loggedUser;
  const userType = loggedUser.type || 1;
  const response = await axios.post(`${dataURL}/checkHasAccess`, {
    domain: ApiHelper.getDomain(),
    uuid: ApiHelper.getUuid(),
    profileID: loggedUser.id,
    familyID: loggedUser.familyId,
    routeName: to.name,
    params: to.params,
    query: to.query
  });
  return {
    checkHasAccess: response.data.data.checkHasAccess,
    statsUpdated: userType == 2 ? 1 : response.data.data.statsUpdated || {},
    customViews: response.data.data.customViews || []
  };
};

export const getCustomizationData = async () => {
  const PORTAL_PARAMS = "PORTAL_PARAMS";
  const PORTAL_LAST_UPDATE = "PORTAL_LAST_UPDATE";
  let registrationPortalData = ApiHelper.getStorageItem(PORTAL_PARAMS, null);
  const result = await ApiHelper.apiPost("/entity/loadRegistrationPortal", {
    domain: window.location.hostname,
    entity: ""
  });
  if (result.status === 1) {
    registrationPortalData = result.data || [];
    ApiHelper.setStorageItem(PORTAL_PARAMS, registrationPortalData);
    ApiHelper.setStorageItem(PORTAL_LAST_UPDATE, new Date());
  } else {
    //TODO: Need to check for handle default setting of portal
  }

  return registrationPortalData;
};

export const downloadFileUrl = (fileUrl: string) => {
  if (!fileUrl) return;

  const link = document.createElement("a") as HTMLAnchorElement;

  link.setAttribute("target", "_blank");
  link.setAttribute("href", fileUrl);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (filename: string, content: string) => {
  var blob = new Blob([content], { type: "text/html" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var e: any = document.createEvent("MouseEvents"),
      a = document.createElement("a");
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/html", a.download, a.href].join(":");
    e.initEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  }
};

export const inputMaskApplyJS = () => {
  // phone mask
  $(document).on(
    "keydown.autocomplete",
    "input[inputmask=phonenumber]",
    function(e) {
      const key = e.charCode || e.keyCode;
      const $phone = $(e.target);

      if (typeof key == "undefined") {
        setTimeout(function() {
          const phoneData: any = $phone.val();
          const number = phoneData.match(/\d/g, "");
          const joinedNumber = number.join("");
          const regex = /^(\d{3})(\d{3})(\d{4})$/;
          const final = joinedNumber.replace(regex, "($1) $2-$3");

          $phone.val(final);
        }, 10);
      }
    }
  );
  $(document).on("focus click", "input[inputmask=phonenumber]", function(e) {
    const $phone: any = $(e.target);
    if ($phone.val().length === 0) {
      $phone.val("(");
    } else {
      const val = $phone.val();
      $phone.val("").val(val); // Ensure cursor remains at the end
    }
  });
  $(document).on("blur", "input[inputmask=phonenumber]", function(e) {
    const $phone = $(e.target);

    if ($phone.val() === "(") {
      $phone.val("");
    }
  });
  $(document).on("paste", "input[inputmask=phonenumber]", function(e: any) {
    const $phone = $(e.target);
    var phoneData = e.originalEvent.clipboardData.getData("Text");

    const number = phoneData.match(/\d/g, "");
    const joinedNumber = number.join("");
    const regex = /^(\d{3})(\d{3})(\d{4})$/;
    const final = joinedNumber.replace(regex, "($1) $2-$3");

    $phone.val(final);
  });
  $(document).on("keydown", "input[inputmask=phonenumber]", function(e) {
    var key = e.charCode || e.keyCode || 0;
    const $phone: any = $(e.target);
    let $val: any = $phone.val();

    if ($val.length === 0) {
      $phone.val("(");
    } else if ($val.length > 0) {
      if ($val.indexOf("(") == -1) {
        $val = "(" + $val;
        $phone.val($val);
      }
    }

    // Auto-format- do not expose the mask as the user begins to type
    if (key !== 8 && key !== 9) {
      if ($phone.val().length === 4) {
        $phone.val($phone.val() + ")");
      }
      if ($phone.val().length === 5) {
        $phone.val($phone.val() + " ");
      }
      if ($phone.val().length === 9) {
        $phone.val($phone.val() + "-");
      }
    }

    // Allow numeric (and tab, backspace, delete) keys only
    return (
      key == 8 ||
      key == 9 ||
      key == 46 ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105)
    );
  });

  // zipcode mask
  $(document).on("keydown", "input[inputmask=zipcode]", function(e) {
    const input: any = $(e.target);
    const key = e.charCode || e.keyCode || 0;
    // Allow numeric (and tab, backspace, delete, home, end) keys only
    return (
      [8, 9, 35, 36, 46].includes(key) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105)
    );
  });
};

export const htmlParse = (InputVal: any) => {
  const parsedHTML: any = $.parseHTML(InputVal);
  let returnedContent = "";
  if (parsedHTML !== null && parsedHTML.length > 0) {
    const firstHtml: any = parsedHTML[0];
    if (
      firstHtml &&
      firstHtml.hasOwnProperty &&
      firstHtml.textContent === null
    ) {
      returnedContent = "";
    } else {
      returnedContent = firstHtml.textContent;
    }
    return returnedContent;
  } else {
    return "";
  }
};

export const htmlCheck = (InputVal: any) => {
  if (InputVal.length > ApiHelper.htmlParse(InputVal).length) {
    return true;
  } else {
    return false;
  }
};

export const getPasswordInfo = (password: string) => {
  const data = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };
  data.hasCapital = !!password.match(/[A-Z]/);
  data.hasNumber = !!password.match(/\d/);
  data.has8characters = password.length >= 8;
  data.nonHtml = !ApiHelper.htmlCheck(password);
  data.valid =
    data.hasCapital && data.hasNumber && data.has8characters && data.nonHtml;
  return data;
};

export const getDaysList = (year: number, month: number) => {
  const currentYear = new Date().getFullYear();
  const daysInMonth = moment(
    `${year || currentYear}-${month}`,
    "YYYY-MM"
  ).daysInMonth();
  const days: number[] = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }
  return days;
};

export const replaceLast = (str: string, search: string, replace: string) => {
  return str.replace(
    new RegExp(search + "([^" + search + "]*)$"),
    replace + "$1"
  );
};

export const removeLastBr = (str: string) => {
  const search = "<br>";
  const replace = "";
  return ApiHelper.replaceLast(str, search, replace);
};

export const getMedicationFrequencyOptions = () => {
  return [
    {
      id: "Before meals",
      text: "Before meals"
    },
    {
      id: "After meals",
      text: "After meals"
    },
    {
      id: "As needed; freely",
      text: "As needed; freely"
    },
    {
      id: "When necessary",
      text: "When necessary"
    },
    {
      id: "Immediately; at once",
      text: "Immediately; at once"
    },
    {
      id: "Twice a day",
      text: "Twice a day"
    },
    {
      id: "Three times a day",
      text: "Three times a day"
    },
    {
      id: "Four times a day",
      text: "Four times a day"
    },
    {
      id: "Minute",
      text: "Minute"
    },
    {
      id: "Hour",
      text: "Hour"
    },
    {
      id: "Every hour",
      text: "Every hour"
    },
    {
      id: "Every 2 hours",
      text: "Every 2 hours"
    },
    {
      id: "Every 3 hours",
      text: "Every 3 hours"
    },
    {
      id: "Every 4 hours",
      text: "Every 4 hours"
    },
    {
      id: "Every 6 hours",
      text: "Every 6 hours"
    },
    {
      id: "Every 8 hours",
      text: "Every 8 hours"
    },
    {
      id: "Every 12 hours",
      text: "Every 12 hours"
    }
  ];
};

export const getMedicationDosageOptions = () => {
  return [
    {
      id: "Mouth (PO)",
      text: "Mouth (PO)"
    },
    {
      id: "Per rectum (PR)",
      text: "Per rectum (PR)"
    },
    {
      id: "Sublingually (SL)",
      text: "Sublingually (SL)"
    },
    {
      id: "Intramuscularly (IM)",
      text: "Intramuscularly (IM)"
    },
    {
      id: "Intravenously (IV)",
      text: "Intravenously (IV)"
    },
    {
      id: "Subcutaneously (SQ)",
      text: "Subcutaneously (SQ)"
    }
  ];
};

export const formatDate = (date: string, format: string, defaultValue = "") => {
  return date && moment(date).isValid()
    ? moment(date).format(format)
    : defaultValue;
};

export const sleep = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const syncCartItems = async () => {
  try {
    const loggedUser = ApiHelper.getLoggedUser();
    let cartItems = await ApiHelper.loadSavedCart();
    const eventIds = cartItems.map((item: any) => item.eventID);
    // if (!eventIds.length) return;

    const notSavedItems = cartItems.filter(
      (item: any) => (item.participantID || 0) == 0
    );
    const [unpaidReponse, cartInfoResponse] = await Promise.all([
      // get unpaid registrations
      ApiHelper.apiPost("/unpaidRegistration", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        familyID: loggedUser.familyId,
        tmpProfileIDs: notSavedItems.map((item: any) => item.profile.profileid),
        tmpEventIDs: notSavedItems.map((item: any) => item.eventID),
        includeRegisteredStatus: 1
      }),
      // get event status info
      ApiHelper.apiPost("/cartInfo", {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        eventIds,
        participants: cartItems.map(item => ({
          eventId: item.eventID,
          pTypeId: item.pType.participantTypeID,
          profileId: item.profile.profileid,
          participantId: item.participantID || 0
        }))
      })
    ]);

    if (unpaidReponse.status == 1) {
      const unpaidRegistration = unpaidReponse.data.unpaidRegistration;
      if (unpaidRegistration.length) {
        for (const item of unpaidRegistration) {
          const inCart = cartItems.find(
            t =>
              item.eventID == t.eventID &&
              item.participant_typeid == t.pType.participantTypeID &&
              item.profileid == t.profile.profileid
          );
          const additionalCosts = item.additionalCosts || [];
          // const pPaymentJson = JSON.parse(item.participant_paymentJson || "{}");
          // total additional costs
          let totalAdditionalCosts = item.totalFunds || 0;
          for (const cost of additionalCosts) {
            totalAdditionalCosts += cost.cost;
          }
          totalAdditionalCosts = parseFloat(totalAdditionalCosts.toFixed(2));

          if (!inCart) {
            // add to cart
            cartItems.push({
              eventID: item.eventID,
              profile: {
                profileid: item.profileid,
                p_fname: item.p_fname || "",
                p_lname: item.p_lname || "",
                p_logo: item.p_logo || "",
                age: item.age || ""
              },
              pType: {
                participantTypeID: item.participant_typeid,
                participantTypeName: item.participant_typename,
                participantAmount: item.cost || 0,
                eventName: item.ev_name || "",
                ParticipantAmount_formated: dollarFormat(item.cost || 0)
              },
              totalAdditionalCosts,
              totalCost: item.totalCost,
              totalCostFormatted: dollarFormat(item.totalCost),
              addOns: additionalCosts,
              participantID: item.participantID,
              totalFunds: item.totalFunds || 0,
              pRegistrationStep: item.pRegistrationStep || 0
            });
          } else {
            // update cart item
            inCart.profile.p_fname = item.p_fname || "";
            inCart.profile.p_lname = item.p_lname || "";
            inCart.profile.p_logo = item.p_logo || "";
            inCart.profile.age = item.age || "";
            inCart.pType.participantTypeName = item.participant_typename;
            inCart.pType.participantAmount = item.cost || 0;
            inCart.pType.eventName = item.ev_name || "";
            inCart.pType.ParticipantAmount_formated = dollarFormat(
              item.cost || 0
            );
            inCart.totalAdditionalCosts = totalAdditionalCosts;
            inCart.totalCost = item.totalCost;
            inCart.totalCostFormatted = dollarFormat(item.totalCost);
            inCart.addOns = additionalCosts;
            inCart.participantID = item.participantID;
            inCart.totalFunds = item.totalFunds || 0;
            inCart.pRegistrationStep = item.pRegistrationStep || 0;
          }
        }
      }
    }

    if (cartInfoResponse.status == 1) {
      // ignore inactive events
      const inactiveEvents = cartInfoResponse.data.evStatus
        .filter(item => item.evActive != 1)
        .map(item => item.eventId);

      if (inactiveEvents.length) {
        cartItems = cartItems.filter(
          (item: any) => !inactiveEvents.includes(item.eventID)
        );
      }

      const pInCart = cartInfoResponse.data.pInCart || [];
      const removeParticipants = pInCart.filter(
        item =>
          (item.pRegistrationStep > 1 ||
            item.pStatus == 2 ||
            item.pStatus == 4 ||
            item.pStatus == 10) &&
          item.participantId
      );
      if (removeParticipants.length) {
        cartItems = cartItems.filter(item => {
          const inRemoveParticipants = removeParticipants.find(
            t =>
              t.eventId == item.eventID &&
              t.pTypeId == item.pType.participantTypeID &&
              t.profileId == item.profile.profileid
          );
          if (inRemoveParticipants) return false;

          return true;
        });
      }
    }

    // update cart
    ApiHelper.updateCartItems({ cartItems });

    return cartInfoResponse;
  } catch (error) {
    // console.log(error);
  }

  return undefined;
};

export const getStateOptions = () => {
  return [
    { id: "AL", text: "Alabama" },
    { id: "AK", text: "Alaska" },
    { id: "AZ", text: "Arizona" },
    { id: "AR", text: "Arkansas" },
    { id: "CA", text: "California" },
    { id: "CO", text: "Colorado" },
    { id: "CT", text: "Connecticut" },
    { id: "DE", text: "Delaware" },
    { id: "DC", text: "District Of Columbia" },
    { id: "FL", text: "Florida" },
    { id: "GA", text: "Georgia" },
    { id: "HI", text: "Hawaii" },
    { id: "ID", text: "Idaho" },
    { id: "IL", text: "Illinois" },
    { id: "IN", text: "Indiana" },
    { id: "IA", text: "Iowa" },
    { id: "KS", text: "Kansas" },
    { id: "KY", text: "Kentucky" },
    { id: "LA", text: "Louisiana" },
    { id: "ME", text: "Maine" },
    { id: "MD", text: "Maryland" },
    { id: "MA", text: "Massachusetts" },
    { id: "MI", text: "Michigan" },
    { id: "MN", text: "Minnesota" },
    { id: "MS", text: "Mississippi" },
    { id: "MO", text: "Missouri" },
    { id: "MT", text: "Montana" },
    { id: "NE", text: "Nebraska" },
    { id: "NV", text: "Nevada" },
    { id: "NH", text: "New Hampshire" },
    { id: "NJ", text: "New Jersey" },
    { id: "NM", text: "New Mexico" },
    { id: "NY", text: "New York" },
    { id: "NC", text: "North Carolina" },
    { id: "ND", text: "North Dakota" },
    { id: "OH", text: "Ohio" },
    { id: "OK", text: "Oklahoma" },
    { id: "OR", text: "Oregon" },
    { id: "PA", text: "Pennsylvania" },
    { id: "RI", text: "Rhode Island" },
    { id: "SC", text: "South Carolina" },
    { id: "SD", text: "South Dakota" },
    { id: "TN", text: "Tennessee" },
    { id: "TX", text: "Texas" },
    { id: "UT", text: "Utah" },
    { id: "VT", text: "Vermont" },
    { id: "VA", text: "Virginia" },
    { id: "WA", text: "Washington" },
    { id: "WV", text: "West Virginia" },
    { id: "WI", text: "Wisconsin" },
    { id: "WY", text: "Wyoming" }
  ].map(option => {
    option.text = option.id;
    return option;
  });
};

export const getGenderName = gender => {
  switch (gender) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    default:
      return "Undefined";
  }
};

export const phoneFormat = (value: string) => {
  if (value == "") {
    return "";
  }
  try {
    const formattedNumber =
      "(" +
      value.substring(0, 3) +
      ") " +
      value.substring(3, 6) +
      " - " +
      value.substring(6, 10);
    return value ? formattedNumber : "";
  } catch (e) {
    return value;
  }
};

export const getDispenseMethods = () => {
  // use for form builder
  return [
    {
      id: 1,
      text: "Capsule(s)"
    },
    {
      id: 2,
      text: "Chewable(s)"
    },
    {
      id: 3,
      text: "Cream/ointment"
    },
    {
      id: 4,
      text: "Drop(s)"
    },
    {
      id: 5,
      text: "Patch(s)"
    },
    {
      id: 6,
      text: "Puff(s)"
    },
    {
      id: 7,
      text: "Shot(s)"
    },
    {
      id: 8,
      text: "Spray(s)"
    },
    {
      id: 9,
      text: "Tablet(s)"
    },
    {
      id: 10,
      text: "Teaspoon(s)"
    },
    {
      id: 11,
      text: "Other"
    }
  ];
};

export const getMedicationDosages = () => {
  const options: {
    title: string;
    value: number;
    disabled?: boolean;
  }[] = [
    {
      title: "Breakfast",
      value: 1,
      disabled: false
    },
    {
      title: "Lunch",
      value: 2,
      disabled: false
    },
    {
      title: "Dinner",
      value: 3,
      disabled: false
    },
    {
      title: "Bedtime",
      value: 4,
      disabled: false
    },
    {
      title: "As Needed",
      value: 5,
      disabled: false
    },
    {
      title: "Other",
      value: 6,
      disabled: false
    }
  ];

  return options;
};