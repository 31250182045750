import { render, staticRenderFns } from "./BeginApplication.vue?vue&type=template&id=160535c1&scoped=true&"
import script from "./BeginApplication.vue?vue&type=script&lang=tsx&"
export * from "./BeginApplication.vue?vue&type=script&lang=tsx&"
import style0 from "./BeginApplication.vue?vue&type=style&index=0&lang=less&"
import style1 from "./BeginApplication.vue?vue&type=style&index=1&id=160535c1&scoped=true&lang=less&"
import style2 from "./BeginApplication.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160535c1",
  null
  
)

export default component.exports